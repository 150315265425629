import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalService } from '@services/modal.service';
import { HelperService } from '@services/helper.service';
import { SubjectService } from '@app/services/subject.service';

@Component({
  selector: 'app-agreements',
  templateUrl: './agreements.component.html',
  styleUrls: ['./agreements.component.scss'],
})
export class AgreementsComponent implements OnInit, OnDestroy {
  public data1 = {
    tipo_documento: '',
    documento: '',
    codigo: '',
  };

  public activeEmptyState = true;

  public navItemTabs = [
    {
      href: '#active-agreement',
      description: 'Acuerdos activos',
      icon: 'icon-tab-active-agreement.svg',
    },
    {
      href: '#agreement-history',
      description: 'Historial de acuerdos',
      icon: 'icon-tab-agreement-history.svg',
    },
  ];

  public DataSelectFake = [
    { value: 1, description: 'Opción 1' },
    { value: 2, description: 'Opción 2' },
    { value: 3, description: 'Opción 3' },
  ];

  constructor(
    public modal: ModalService,
    public helper: HelperService,
    public subject: SubjectService
  ) {}

  ngOnInit() {
    this.subject.headerCollapse(true);
  }

  ngOnDestroy() {
    this.subject.headerCollapse(false);
  }
}
