import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  Input
} from "@angular/core";
import { SafeResourceUrl } from "@angular/platform-browser";
import { NgForm } from "@angular/forms";

@Component({
  selector: "app-accept-terms",
  templateUrl: "./accept-terms.component.html",
  styleUrls: ["./accept-terms.component.scss"]
})
export class AcceptTermsComponent implements OnInit {
  
  //TODO: Archivo con conflicto
  @Input() checked: boolean = false;
  @Output() formValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild("formReference") formReference: NgForm;
  
  public url: SafeResourceUrl;

  constructor() { }

  ngOnInit() { }

  ngDoCheck() {
    this.formValidate();
  }

  formValidate() {
    const isFormValid = this.formReference.valid;
    this.formValid.emit(isFormValid);
  }

}
