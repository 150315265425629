import { Component, OnInit, Input } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalService } from '@services/modal.service';
import {
  MyPaymentsRequest,
  MyPaymentsResponse,
  MyPaymentExportRequest,
} from '@models/myPayments.model';
import { MyPaymentsService } from '@services/my-payments.service';
import { NetsuiteRequest } from '@models/netsuiteBill.model';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '@services/auth.service';
import * as moment from 'moment';

@Component({
  selector: 'app-tab-bill-history',
  templateUrl: './tab-bill-history.component.html',
  styleUrls: ['./tab-bill-history.component.scss'],
})
export class TabBillHistoryComponent implements OnInit {

  public activeButtons = false;
  public selectedBill: MyPaymentsResponse;
  public myPaymentsRequest: MyPaymentsRequest = new MyPaymentsRequest();
  public myPaymentExportRequest: MyPaymentExportRequest =
    new MyPaymentExportRequest();
  public netsuiteRequest: NetsuiteRequest = new NetsuiteRequest();
  @Input() payments: Array<MyPaymentsResponse>;
  public totalPages: number = 1;

  constructor(
    private auth: AuthService,
    private spinner: NgxSpinnerService,
    private myPaymentsService: MyPaymentsService,
    private toastr: ToastrService,
    public modal: ModalService
  ) {}

  ngOnInit() {
    this.myPayments();
  }

  myPayments() {
    this.spinner.show();
    moment.locale('es');
    this.myPaymentsService.getMyPayments(this.myPaymentsRequest).subscribe(
      (resp: Array<MyPaymentsResponse>) => {
        console.log(resp);
        this.payments = resp.filter(({ state }) => state === 'CANCELADA');
        this.payments.forEach(item => {
          item.billing = moment(item.billing.split("/").reverse().join("/")).format('MMMM');
        });
        this.totalPages =
          resp.length > 0
            ? Math.ceil(parseInt(resp[0].numberOfRecords) / 10)
            : 1;
      },
      (err: any) => {
        console.error(err);
      },
      () => {
        this.spinner.hide();
      }
    );
  }

  pageSelected(page: any) {
    this.myPaymentsRequest.page = page.currentPage;
    this.myPayments();
  }

  exportMyPaymentDetails() {
    (window as any).gtag('event', 'descarga-factura', {
      event_category: 'facturas-por-pagar',
      event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
    });
    this.spinner.show();
    this.myPaymentExportRequest.bill = this.selectedBill.internalInvoice;
    this.myPaymentsService
      .exportMyPayment(this.myPaymentExportRequest)
      .subscribe(
        (res: any) => {
          const blob = new Blob([res], {
            type: 'application/ms-excel',
          });
          saveAs(blob, `${this.selectedBill.internalInvoice}.xlsx`);
        },
        (err: any) => {
          this.spinner.hide();
          console.error(err);
          this.toastr.error('No pudimos exportar el informe.', '', {
            timeOut: 3000,
          });
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  handleItemSelected(bill: MyPaymentsResponse) {
    this.selectedBill = bill;
    this.activeButtons = true;
  }

  netsuiteBill() {
    (window as any).gtag('event', 'ver-factura', {
      event_category: 'facturas-por-pagar',
      event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
    });
    this.spinner.show();
    this.myPaymentsService
      .getNetsuiteBill(this.netsuiteRequest, this.selectedBill.billNumber)
      .subscribe(
        (base64: string) => {
          if (base64) {
            this.modal.show('pdfBill', {
              base64,
            });
          }
        },
        (err: any) => {
          console.error(err);
          this.spinner.hide();
          this.toastr.error('Esta factura no está disponible.', '', {
            timeOut: 3000,
          });
        },
        () => {
          this.spinner.hide();
        }
      );
  }
}
