import {
  Component,
  OnInit,
  OnDestroy,
  EventEmitter,
  Output,
} from '@angular/core';
import { ModalService } from '@services/modal.service';

@Component({
  selector: 'app-modal-request-denied',
  templateUrl: './modal-request-denied.component.html',
  styleUrls: ['./modal-request-denied.component.scss'],
})
export class ModalRequestDeniedComponent implements OnDestroy {
  @Output() finish: EventEmitter<object> = new EventEmitter<object>();

  constructor(public modal: ModalService) {}

  ngOnDestroy() {
    this.modal.close();
  }

  accept() {
    this.modal.close();
    this.finish.emit();
  }
}
