import { Component, OnInit } from '@angular/core';
import { SubjectService } from '@app/services/subject.service';

@Component({
  selector: 'app-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.scss'],
})
export class AdministrationComponent implements OnInit {
  constructor(public subject: SubjectService) {}

  ngOnInit() {
    this.subject.headerCollapse(true);
  }

  ngOnDestroy() {
    this.subject.headerCollapse(false);
  }
}
