import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { HelperService } from '@services/helper.service';
import { ModalService } from '@services/modal.service';

@Component({
  selector: 'app-tab-claims',
  templateUrl: './tab-claims.component.html',
  styleUrls: ['./tab-claims.component.scss']
})
export class TabClaimsComponent implements OnInit {

    public dataTableFake = [
      { documento: 'N', numero_documento: 123456, nombre: 'Felipe Manchego', titulo_valor: 1000000, valor: 1000,
        completed: true, vencimiento: '01/Ene/2021', reclamacion: 100 },
      { documento: 'N', numero_documento: 123456, nombre: 'Felipe Manchego', titulo_valor: 1000000, valor: 1000,
        completed: false, vencimiento: '01/Ene/2021', reclamacion: 100 },
      { documento: 'N', numero_documento: 123456, nombre: 'Felipe Manchego', titulo_valor: 1000000, valor: 1000,
        completed: false, vencimiento: '01/Ene/2021', reclamacion: 100 },
      { documento: 'N', numero_documento: 123456, nombre: 'Felipe Manchego', titulo_valor: 1000000, valor: 1000,
        completed: false, vencimiento: '01/Ene/2021', reclamacion: 100 },
      { documento: 'N', numero_documento: 123456, nombre: 'Felipe Manchego', titulo_valor: 1000000, valor: 1000,
        completed: false, vencimiento: '01/Ene/2021', reclamacion: 100 },
    ];

    @Output() clickClaim: EventEmitter<MouseEvent> = new EventEmitter();

    // Radio
    public radioSel: any;
    public radioSelected: string;

  constructor(public helper: HelperService,
              public modal: ModalService) { }

  ngOnInit() {
  }

  search(dateRange: object) {
  }

  noPay() {
    this.modal.show('nopayConfirm');
  }

  handleClaim() {
   this.clickClaim.emit();
  }
}
