import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { LoginRequestUpdatePassword } from '@app/models/login.model';
import { ModalService } from '@app/services/modal.service';
import { AuthService } from '@app/services/auth.service';

@Component({
  selector: 'app-modal-force-update-password',
  templateUrl: './modal-force-update-password.component.html',
  styleUrls: ['./modal-force-update-password.component.scss']
})
export class ModalForceUpdatePasswordComponent implements OnDestroy {
  @ViewChild('formChangePass') formChangePass: NgForm;
  @Input() changePassword: boolean = false;

  public changePassRequest: LoginRequestUpdatePassword = new LoginRequestUpdatePassword(); // implementar model
  public message = `La contraseña debe ser mínimo de 8 caracteres y 
                    tener al menos una letra minúscula, una mayúscula y un número`;
  public message2 = `Las contraseñas no coinciden.`;

  public hideActualPass = false;
  public hideNewPass = false;
  public hideConfirmNewPass = false;

  @Output() confirm: EventEmitter<object> = new EventEmitter<object>();
  @Output() onSave: EventEmitter<LoginRequestUpdatePassword> =
    new EventEmitter<LoginRequestUpdatePassword>();
  public continue: boolean = false;
  public passAreEquals: boolean = false;
  public closePassword: boolean = false;



  constructor(
    public modal: ModalService,
    public spinner: NgxSpinnerService,
    public auth: AuthService) { }

  ngOnInit() {
    if (typeof (this.changePassword) === 'boolean') {
      this.closePassword = true;
    } else {
      this.closePassword = false;
    }
  }
  ngOnDestroy() {
    this.modal.close();
  }

  togglePassword(passwordStep) {
    switch (passwordStep) {
      case 1:
        this.hideActualPass = !this.hideActualPass;

        break;
      case 2:
        this.hideNewPass = !this.hideNewPass;
        break;
      default:
        this.hideConfirmNewPass = !this.hideConfirmNewPass;
        break;
    }
  }

  accept() {
    this.modal.close();
    this.confirm.emit();
  }

  passwordIsEqual() {
    const pass1 = this.changePassRequest.confirmNewPass;
    const pass2 = this.changePassRequest.newPassword;
    const pass1Isvalid = this.formChangePass.form.controls['newPass'].valid;
    const pass2IsValid =
      this.formChangePass.form.controls['confirmNewPass'].valid;

    if (!pass1Isvalid && !pass2IsValid) {
      this.continue = false;
      return;
    }

    if (pass1 === pass2 && this.formChangePass.form.valid) {
      this.continue = true;
      this.passAreEquals = true;
    } else if (pass1 !== pass2 && this.formChangePass.form.valid) {
      this.continue = false;
      this.passAreEquals = false;
    } else {
      this.continue = false;
    }
  }

  forceUpdatePassword() {
    this.spinner.show();
    this.auth.forceUpdatePassword(this.changePassRequest, this.modal.payload.user).subscribe(
      (resp: any) => {
        this.successUpdate();
      },
      (err: any) => {
        this.spinner.hide();
        Swal.fire({
          type: 'error',
          title: 'Alerta:',
          text: err.error.message || 'Error al actualizar la contraseña',
        });
      }
    );
  }

  successUpdate() {
    this.onSave.emit(this.changePassRequest);
    this.formChangePass.reset();
    this.modal.close();
  }
}
