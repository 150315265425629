import {
  Component,
  Input,
  OnDestroy,
  EventEmitter,
  Output,
} from '@angular/core';
import { ModalService } from '@services/modal.service';

@Component({
  selector: 'app-modal-request-threpayments',
  templateUrl: './modal-request-threpayments.component.html',
  styleUrls: ['./modal-request-threpayments.component.scss'],
})
export class ModalRequestThrepaymentsComponent implements OnDestroy {
  @Input() names: string;
  @Output() confirm: EventEmitter<object> = new EventEmitter();
  constructor(public modal: ModalService) {}

  ngOnDestroy() {
    this.modal.close();
  }

  accept() {
    this.modal.close();
    this.confirm.emit();
  }
}
