import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from '../app-routing.module';
import { SharedModule } from '../shared/shared.module';
import { ModalTransactionComponent } from './modals/modal-transaction/modal-transaction.component';
import { PaginationComponent } from './layout/pagination/pagination.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ModalSuccessComponent } from './modals/modal-success/modal-success.component';
import { ModalSuccessThreepaymentComponent } from './modals/modal-success-threepayments/modal-success-threepayments.component';
import { ModalReservationSuccessComponent } from './modals/modal-reservation-success/modal-reservation-success.component';
import { BackButtonComponent } from './back-button/back-button.component';
import { ModalFileUploadComponent } from './modals/modal-file-upload/modal-file-upload.component';
import { ModalInvalidCodeComponent } from './modals/modal-invalid-code/modal-invalid-code.component';
import { ModalNotRegisteredComponent } from './modals/modal-not-registered/modal-not-registered.component';
import { ModalRequestStatusComponent } from './modals/modal-request-status/modal-request-status.component';
import { ModalAvailableQuotaComponent } from './modals/modal-available-quota/modal-available-quota.component';
import { ModalRequestSuspendedStatusComponent } from './modals/modal-request-suspended-status/modal-request-suspended-status.component';
import { ModalRequestThrepaymentsComponent } from './modals/modal-request-threpayments/modal-request-threpayments.component';
import { ModalDeniedComponent } from './modals/modal-denied/modal-denied.component';
import { ModalLockedComponent } from './modals/modal-locked/modal-locked.component';
import { ModalReserveConfirmComponent } from './modals/modal-reserve-confirm/modal-reserve-confirm.component';
import { ModalTransactionConfirmComponent } from './modals/modal-transaction-confirm/modal-transaction-confirm.component';
import { DateRangeComponent } from './date-range/date-range.component';
import { SelectInputComponent } from './select-input/select-input.component';
import { ModalNopayConfirmComponent } from './modals/modal-nopay-confirm/modal-nopay-confirm.component';
import { ModalClaimSuccessComponent } from './modals/modal-claim-success/modal-claim-success.component';
import { ModalClaimConfirmComponent } from './modals/modal-claim-confirm/modal-claim-confirm.component';
import { ModalOperationsSuccessComponent } from './modals/modal-operations-success/modal-operations-success.component';
import { ModalOperationsDeniedComponent } from './modals/modal-operations-denied/modal-operations-denied.component';
import { ModalOperationsConfirmComponent } from './modals/modal-operations-confirm/modal-operations-confirm.component';
import { ModalTransactionCheckConfirmComponent } from './modals/modal-transaction-check-confirm/modal-transaction-check-confirm.component';
import { ModalConfigureProfileImgComponent } from './modals/modal-configure-profile-img/modal-configure-profile-img.component';
import { ModalUserDisableComponent } from './modals/modal-user-disable/modal-user-disable.component';
import { ModalUserEnableComponent } from './modals/modal-user-enable/modal-user-enable.component';
import { ModalUserDeleteComponent } from './modals/modal-user-delete/modal-user-delete.component';
import { ModalProfileUpdateSuccessComponent } from './modals/modal-profile-update-success/modal-profile-update-success.component';
import { ModalUserAddSuccessComponent } from './modals/modal-user-add-success/modal-user-add-success.component';
import { ModalUserDisableSuccessComponent } from './modals/modal-user-disable-success/modal-user-disable-success.component';
import { ModalPaymentAgreementConfirmComponent } from './modals/modal-payment-agreement-confirm/modal-payment-agreement-confirm.component';
import { ModalPaymentAgreementSuccessComponent } from './modals/modal-payment-agreement-success/modal-payment-agreement-success.component';
import { ModalPaymentSuccessComponent } from './modals/modal-payment-success/modal-payment-success.component';
import { ModalPaymentConfirmComponent } from './modals/modal-payment-confirm/modal-payment-confirm.component';
import { ModalRequestApprovedComponent } from './modals/modal-request-approved/modal-request-approved.component';
import { ModalRequestDeniedComponent } from './modals/modal-request-denied/modal-request-denied.component';
import { ModalRequestSettledComponent } from './modals/modal-request-settled/modal-request-settled.component';
import { ErrorMessageComponent } from './error-message/error-message.component';
import { ModalErrorComponent } from './modals/modal-error/modal-error.component';
import { ModalUserEnableSuccessComponent } from './modals/modal-user-enable-success/modal-user-enable-success.component';
import { ModalUserDeleteSuccessComponent } from './modals/modal-user-delete-success/modal-user-delete-success.component';
import { ModalUserUpdateSuccessComponent } from './modals/modal-user-update-success/modal-user-update-success.component';
import { ModalProfileChangePasswordComponent } from './modals/modal-profile-change-password/modal-profile-change-password.component';
import { ModalProfileSuccessChangePasswordComponent } from './modals/modal-profile-success-change-password/modal-profile-success-change-password.component';
import { ModalLogoutErrorComponent } from './modals/modal-logout/modal-logout-error.component';
import { CustomDropzonePreviewComponent } from './custom-dropzone-preview/custom-dropzone-preview.component';
import { UploadFileComponent } from './modals/upload-file/upload-file.component';
import { FileComponent } from './file/file.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalUploadMailFileComponent } from './modals/modal-upload-mail-file/modal-upload-mail-file.component';
import { ModalForceUpdatePasswordComponent } from './modals/modal-force-update-password/modal-force-update-password.component';
import { ModalUploadMailErrorComponent } from './modals/modal-upload-mail-error/modal-upload-mail-error.component';


@NgModule({
  declarations: [
    ModalReservationSuccessComponent,
    ModalNotRegisteredComponent,
    ModalInvalidCodeComponent,
    PaginationComponent,
    BackButtonComponent,
    ModalTransactionComponent,
    NotFoundComponent,
    ModalSuccessComponent,
    ModalSuccessThreepaymentComponent,
    ModalFileUploadComponent,
    ModalRequestStatusComponent,
    ModalAvailableQuotaComponent,
    ModalRequestSuspendedStatusComponent,
    ModalRequestThrepaymentsComponent,
    ModalDeniedComponent,
    ModalLockedComponent,
    ModalReserveConfirmComponent,
    ModalTransactionConfirmComponent,
    DateRangeComponent,
    SelectInputComponent,
    ModalNopayConfirmComponent,
    ModalClaimSuccessComponent,
    ModalClaimConfirmComponent,
    ModalOperationsSuccessComponent,
    ModalLogoutErrorComponent,
    ModalOperationsDeniedComponent,
    ModalOperationsConfirmComponent,
    ModalTransactionCheckConfirmComponent,
    ModalConfigureProfileImgComponent,
    ModalUserDisableComponent,
    ModalUserEnableComponent,
    ModalUserDeleteComponent,
    ModalProfileUpdateSuccessComponent,
    ModalUserAddSuccessComponent,
    ModalPaymentAgreementConfirmComponent,
    ModalPaymentAgreementSuccessComponent,
    ModalPaymentSuccessComponent,
    ModalPaymentConfirmComponent,
    ModalUserDisableSuccessComponent,
    ModalReservationSuccessComponent,
    ModalNotRegisteredComponent,
    ModalInvalidCodeComponent,
    ModalRequestApprovedComponent,
    ModalRequestDeniedComponent,
    ModalRequestSettledComponent,
    ErrorMessageComponent,
    ModalErrorComponent,
    ModalUserEnableSuccessComponent,
    ModalUserDeleteSuccessComponent,
    ModalUserUpdateSuccessComponent,
    ModalProfileChangePasswordComponent,
    ModalProfileSuccessChangePasswordComponent,
    CustomDropzonePreviewComponent,
    UploadFileComponent,
    FileComponent,
    ModalUploadMailFileComponent,
    ModalForceUpdatePasswordComponent,
    ModalUploadMailErrorComponent,
  ],
  imports: [CommonModule, AppRoutingModule, SharedModule,ReactiveFormsModule,
    FormsModule],
  exports: [
    ModalReservationSuccessComponent,
    ModalNotRegisteredComponent,
    ModalInvalidCodeComponent,
    PaginationComponent,
    BackButtonComponent,
    ModalTransactionComponent,
    NotFoundComponent,
    ModalSuccessComponent,
    ModalSuccessThreepaymentComponent,
    ModalFileUploadComponent,
    ModalRequestStatusComponent,
    ModalAvailableQuotaComponent,
    ModalRequestSuspendedStatusComponent,
    ModalRequestThrepaymentsComponent,
    ModalDeniedComponent,
    ModalLockedComponent,
    ModalReserveConfirmComponent,
    ModalTransactionConfirmComponent,
    DateRangeComponent,
    SelectInputComponent,
    ModalNopayConfirmComponent,
    ModalClaimSuccessComponent,
    ModalClaimConfirmComponent,
    ModalOperationsSuccessComponent,
    ModalLogoutErrorComponent,
    ModalOperationsDeniedComponent,
    ModalOperationsConfirmComponent,
    ModalTransactionCheckConfirmComponent,
    ModalConfigureProfileImgComponent,
    ModalUserDisableComponent,
    ModalUserEnableComponent,
    ModalUserDeleteComponent,
    ModalUserDisableSuccessComponent,
    ModalProfileUpdateSuccessComponent,
    ModalUserAddSuccessComponent,
    ModalPaymentAgreementConfirmComponent,
    ModalPaymentAgreementSuccessComponent,
    ModalPaymentSuccessComponent,
    ModalPaymentConfirmComponent,
    ModalRequestApprovedComponent,
    ModalRequestDeniedComponent,
    ModalRequestSettledComponent,
    ErrorMessageComponent,
    ModalErrorComponent,
    ModalUserDeleteSuccessComponent,
    ModalUserUpdateSuccessComponent,
    ModalUserEnableSuccessComponent,
    ModalUserUpdateSuccessComponent,
    ModalProfileChangePasswordComponent,
    ModalProfileSuccessChangePasswordComponent,
    UploadFileComponent,
    ModalUploadMailFileComponent,
    ModalForceUpdatePasswordComponent,
    ModalUploadMailErrorComponent
  ],
})
export class ComponentsModule {}
