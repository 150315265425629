import { ModalService } from '@services/modal.service';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2';
import { AuthService } from '@services/auth.service';
import { LoginModel, LoginRequestUpdatePassword } from '@models/login.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';



declare let $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  // Properties
  public userLogin: FormGroup;
  public hidePassword = false;
  public changePassword = false;
  public forceUpdatePassword = false;

  // public dataLogin: LoginModel = {
  //   username: 'CC860028462',
  //   password: 'Covinoc2019',
  // };

  public dataLogin: LoginModel = {
    username: '',
    password: '',
  };

  public year = new Date();

  constructor(
    private auth: AuthService,
    private spinner: NgxSpinnerService,
    private readonly route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private modal: ModalService
  ) {

    this.userLogin = new FormGroup({
      username: new FormControl('', [
        Validators.required,
        Validators.minLength(4),
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(4),
      ]),
    });

    this.userLogin.setValue(this.dataLogin);
  }

  ngOnInit() {
    this.auth.getGlobalData().subscribe();
    this.auth.getFamilyList().subscribe();
    this.auth.getCareersList().subscribe();
  }

  recordBackEvent() {
    (window as any).gtag('event', 'volver-inicio', {
      event_category: 'olvide-contrasena',
      event_label: 'codigo-enviado-para-recuperara',
    });
  }

  pass() {
    this.router.navigateByUrl('/contrasena');
  }

  changePage() {
    // window.open('https://tesos.covifactura.com', '_blank');
    (window as any).gtag('event', 'log-in', {
      event_category: 'sin-login',
      event_label: 'web-compradores',
    });
    window.location.href = environment.buyers;
  }

  login(form?: NgForm, dataLogin?: object) {
    if (dataLogin) {
      this.userLogin.setValue(dataLogin);
    }
    this.spinner.show();

    // if (form && form.invalid) { return; }

    this.auth.login(this.userLogin.value).subscribe(
      (resp: any) => {
        this.changePassword = resp['CAMBIO_CONTRASENA'];
        this.migrate();
        this.getPermissions();
        // if (this.changePassword === true) {
        //   this.forceUpdatePassword = true;
        //   // this.modal.show('forceUpdatePassword', { user: this.userLogin.value });
        //   this.spinner.hide();
        //   return;
        // }
        
        this.forceUpdatePassword = false;
      },
      (err) => {
        this.forceUpdatePassword = false;
        (window as any).gtag('event', 'error-en-log-in', {
          event_category: 'log-in',
          event_label: ``,
        });
        this.spinner.hide();
        Swal.fire({
          type: 'error',
          title: 'Alerta:',
          text: 'Usuario o contraseña no válidos',
        });
      }
    );
  }

  migrate() {
    this.auth.migrateUser(this.userLogin.value).subscribe(res => console.log(res));
  }

  getPermissions() {
    this.auth.getPermissions().subscribe(
      (resp: any) => {
        (window as any).gtag('event', 'log-in-exitoso', {
          event_category: 'log-in',
          event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
        });
        if (this.changePassword) {
          this.router.navigateByUrl(
            '/pages/administracion/perfil?changePassword=true'
          );
        } else {
          localStorage.setItem("popUpChristhmas", "open");
          this.router.navigateByUrl('/pages/transacciones');
        }
        this.spinner.hide();
      },
      (err: any) => {
        this.spinner.hide();
        Swal.fire({
          type: 'error',
          title: 'Alerta:',
          text: 'Usuario o contraseña no válidos',
        });
      }, () => { this.modal.close() }
    );
  }

  togglePassword() {
    this.hidePassword = !this.hidePassword;
  }

  toastNotification(titulo: string, descripcion: string) {
    this.toastr.success(titulo, descripcion);
  }

}
