import { Component, OnInit, HostListener, DoCheck } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AnalyticsModel } from './models/analytics.model';
import { environment } from 'src/environments/environment'; 
declare let gtag: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, DoCheck {
  public identity: any;
  public currentRoute: string;

  navigation = [
    { alt: 'Transacciones', img: 'icon-transactions.svg', path: '/transacciones' },
    { alt: 'Estudio de cliente', img: 'icon-client-study.svg', path: '/estudio-de-cliente' },
    { alt: 'Operaciones', img: 'icon-operations.svg', path: '/operaciones' },
    { alt: 'Reintegros', img: 'icon-claims.svg', path: '/reintegros' },
    // { alt: 'Reclamaciones', img: 'icon-claims.svg', path: '/reclamaciones' },
    { alt: 'Acuerdos de pago', img: 'icon-agreements.svg', path: '/acuerdos-de-pago' },
    { alt: 'Reportes', img: 'icon-reports.svg', path: '/reportes' },
    { alt: 'Administración', img: 'icon-administration.svg', path: '/administracion' },
  ];

  public analytics: AnalyticsModel;
  isShow: boolean;
  topPosToStartShowing = 100;
  @HostListener('window:scroll')
  checkScroll() {
  const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

  if (scrollPosition >= this.topPosToStartShowing) {
    this.isShow = true;
  } else {
    this.isShow = false;
   }
  }

  constructor( public router: Router) {
                      // Analytics
                      const navEndEvents$ = this.router.events
                      .pipe(
                        filter(event => event instanceof NavigationEnd)
                      );

                      navEndEvents$.subscribe((event: NavigationEnd) => {
                        this.currentRoute = event.url;
                        gtag('config', '', {
                          page_path: event.urlAfterRedirects
                        });
                      });
               }

    ngOnInit() {
      const fecha = new Date(2023,6,12) + ' --> Ajuste en bug encontrado del doble link en la landing.';
      this.analytics = JSON.parse(localStorage.getItem('identity')) || {};
      console.info(fecha);
    }

    ngDoCheck() {
      this.identity = JSON.parse(localStorage.getItem('identity')) || {};
      this.analytics = this.identity;
    }

    gotoTop() {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
    }

    noAuth() {
      const authRoutes = ['/login', '/forgot'];
      return authRoutes.includes(this.currentRoute);
    }

    logOut() {
      (window as any).gtag('event', 'cerrar_sesion',
      { event_category: `${this.analytics.tipo_documento} ${this.analytics.documento}` , event_label: ''});
      localStorage.clear();
      this.router.navigate(['/login']);
    }

}

function commitMessage(arg0: string, commitSHA: string, commitMessage: any) {
  throw new Error('Function not implemented.');
}

