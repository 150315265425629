import {
  Component,
  OnInit,
  OnDestroy,
  EventEmitter,
  Output
} from "@angular/core";
import { ModalService } from "@services/modal.service";

@Component({
  selector: "app-modal-user-disable-success",
  templateUrl: "./modal-user-disable-success.component.html",
  styleUrls: ["./modal-user-disable-success.component.scss"]
})
export class ModalUserDisableSuccessComponent implements OnDestroy {
  @Output() confirm: EventEmitter<object> = new EventEmitter();
  constructor(public modal: ModalService) {}

  ngOnDestroy() {
    this.modal.close();
  }

  accept() {
    this.confirm.emit();
    this.modal.close();
  }
}
