import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalService } from '@services/modal.service';
import { ToastrService } from 'ngx-toastr';
import { SubjectService } from '@services/subject.service';
import { MassiveRequest } from '@models/massiveRequest.model';
import { MassiveRecord } from '@models/massiveRecord.model';
import { MassiveRecordResponse } from '@models/massiveRecordResponse.model';
import { NoticeService } from '@services/notice.service';
import { AuthService } from '@services/auth.service';
@Component({
  selector: 'app-massive-nopay',
  templateUrl: './massive-nopay.component.html',
  styleUrls: ['./massive-nopay.component.scss'],
})
export class MassiveNopayComponent implements OnInit {
  @Output() massiveResponseEvent: EventEmitter<Array<MassiveRecordResponse>> =
    new EventEmitter<Array<MassiveRecordResponse>>();
  @Input() massiveRecivedRequet: MassiveRequest;

  public errorTitlesIndex: any = {
    'Tipo doc/to': 'documentType',
    'Número documento': 'documentNumber',
    'Número factura': 'valueTitle',
    'Valor deuda': 'value',
    'Fecha vencimiento': 'date',
  };

  public errorTitles: any = [
    'Tipo doc/to',
    'Número documento',
    'Número factura',
    'Valor deuda',
    'Fecha vencimiento',
  ];

  public correctTitlesIndex: any = {
    'Tipo doc/to': 'documentType',
    'Número documento': 'documentNumber',
    'Número factura': 'valueTitle',
    'Valor deuda': 'value',
    'Fecha vencimiento': 'date',
  };
  public correctTitles: any = [
    'Tipo doc/to',
    'Número documento',
    'Número factura',
    'Valor deuda',
    'Fecha vencimiento',
  ];

  public massiveRequest: MassiveRequest;
  public massiveResponse: Array<MassiveRecordResponse>;

  public togglePopOver = false;
  public messageTooltip: string;

  // Effects
  public hoverEffect = false;
  public hoverDiv: number;

  public activeContent = true;

  constructor(
    private auth: AuthService,
    public spinner: NgxSpinnerService,
    public modal: ModalService,
    private toastr: ToastrService,
    public subject: SubjectService,
    public notice: NoticeService
  ) {}

  ngOnInit() {
    this.massiveRequest = this.massiveRecivedRequet;
  }

  downloadExcel() {}

  validateRegisters(event?: any, row?: any, id?: any, type?: string) {}

  mouseEnter(index: any) {
    this.hoverEffect = true;
    this.hoverDiv = index;
  }

  mouseLeave(index: any) {
    this.hoverEffect = false;
    this.hoverDiv = index;
  }

  deleteRow(id: number) {
    this.massiveRequest.errorRecords.splice(id, 1);
  }

  deleteRows() {
    this.massiveRequest.errorRecords = [];
  }

  save() {
    this.spinner.show();
    this.notice.massiveVerify(this.massiveRequest, true).subscribe(
      (massiveRequests: MassiveRequest) => {
        this.massiveRequest = massiveRequests;
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  charge() {
    (window as any).gtag('event', 'hacer-transaccion', {
      event_category: 'aviso-masivo',
      event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
    });
    this.spinner.show();
    this.notice.massiveVerify(this.massiveRequest, false).subscribe(
      (massiveRequests: Array<MassiveRecordResponse>) => {
        this.massiveResponse = massiveRequests;
        this.spinner.hide();
        this.massiveResponseEvent.emit(this.massiveResponse);
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  enterTd(item: MassiveRecord, title: string, event: any) {
    if (item.message[this.errorTitlesIndex[title]] !== undefined) {
      this.messageTooltip = item.message[this.errorTitlesIndex[title]];
      this.subject.sendText(this.messageTooltip);
    } else {
      this.messageTooltip = '';
      this.subject.sendText(this.messageTooltip);
    }
  }

  leaveTd() {
    this.messageTooltip = '';
    this.subject.sendText(this.messageTooltip);
  }
}
