import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalService } from '@services/modal.service';
import { HelperService } from '@services/helper.service';
import { RefundsListRequest } from '@models/refundsListRequest.model';
import { PaginationComponent } from '@components/layout/pagination/pagination.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { Refund } from '@models/refund.model';
import { RefundService } from '@services/refund.service';
import { saveAs } from 'file-saver';
import { UsersService } from '@services/users.service';
import { PermissionModel } from '@models/permission.model';
import { CONSTANTS } from '@config/constants';
import * as moment from 'moment';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'app-tab-refunds',
  templateUrl: './tab-refunds.component.html',
  styleUrls: ['./tab-refunds.component.scss'],
})
export class TabRefundsComponent implements OnInit {
  @ViewChild('paginationComponent') paginationComponent: PaginationComponent;

  public refundRequest: RefundsListRequest = {
    page: 1,
    endDate: moment().toDate(),
    startDate: moment().subtract(7, 'days').toDate(),
  };

  public buttonDownloadStatus = 'A';

  public totalPages: number = 1;
  public pageLimit: number = 5;

  public refunds: Array<Refund> = [];
  public permissions: Array<PermissionModel>;
  public constants = CONSTANTS;

  constructor(
    private auth: AuthService,
    public modal: ModalService,
    public helper: HelperService,
    private spinner: NgxSpinnerService,
    public refundService: RefundService,
    private usersService: UsersService
  ) {}

  filterPermission(idOption) {
    return this.permissions.filter(
      (permission) => permission.idOption === idOption
    );
  }

  ngOnInit() {
    this.listRefunds();
    this.permissions = this.usersService.permissions;
    const noPayPermissionStatus = this.filterPermission('15');
    this.buttonDownloadStatus =
      noPayPermissionStatus.length > 0 ? noPayPermissionStatus[0].status : 'A';
  }

  listRefunds() {
    this.spinner.show();
    this.refundService.getRefunds(this.refundRequest).subscribe(
      (refunds: Array<Refund>) => {
        this.refunds = refunds;
        this.totalPages =
          refunds.length > 0
            ? Math.ceil(parseInt(refunds[0].numberOfRecords) / this.pageLimit)
            : 1;
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  search(dateRange: Array<Date>) {
    if (dateRange[0]) {
      this.refundRequest.startDate = dateRange[0];
    }
    if (dateRange[1]) {
      this.refundRequest.endDate = dateRange[1];
    }
    if (dateRange[0] && dateRange[1]) {
      this.listRefunds();
    }
  }

  pageSelected(page: any) {
    this.refundRequest.page = page.currentPage;
    this.listRefunds();
  }

  onSearch(search: string) {
    (window as any).gtag('event', 'busqueda-de-reintegro', {
      event_category: 'reintegros',
      event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
    });
    this.refundRequest.search = search || '';
    this.refundRequest.page = 1;
    this.paginationComponent.reset();
    this.listRefunds();
  }

  noPay() {
    this.modal.show('nopayConfirm');
  }

  downloadExcel() {
    (window as any).gtag('event', 'descargas-de-reportes', {
      event_category: 'reintegros',
      event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
    });
    this.spinner.show();
    this.refundService.exportRefundsResult(this.refundRequest).subscribe(
      (res: any) => {
        const blob = new Blob([res], {
          type: 'application/ms-excel',
        });
        saveAs(blob, 'reportereintegros.xlsx');
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }
}
