import { Component, Input, NgZone, OnInit } from "@angular/core";
import { CONSTANTS } from "@config/constants";
import { Bank } from "@models/bank.model";
import { Reserve } from "@models/reserve.model";
import { TransactionDue } from "@models/transactionDue.model";
import { TransactionRequest } from "@models/transactionRequest.model";
import { UserAvailableSpaceRequest } from "@models/userAvailableSpaceRequest.model";
import { UserAvailableSpaceResponse } from "@models/userAvailableSpaceResponse.model";
import { HelperService } from "@services/helper.service";
import { ModalService } from "@services/modal.service";
import { TransactionService } from "@services/transaction.service";
import * as moment from "moment";
import { NgxSpinnerService } from "ngx-spinner";
import { AuthService } from "@services/auth.service";



@Component({
  selector: "app-tab-check",
  templateUrl: "./tab-check.component.html",
  styleUrls: ["./tab-check.component.scss"],
})
export class TabCheckComponent implements OnInit {
  // Properties
  @Input() transactionUser: UserAvailableSpaceRequest;
  @Input() userAvailableSpace: UserAvailableSpaceResponse;
  @Input() reserveList: Array<Reserve>;

  public total = 0;
  public numberQuotas = [];
  public dateMessage = "";

  public allowWithoutQuote: any;
  public userAvailable: any;

  public transactionRequest: TransactionRequest = {
    idContry: "1",
    sellerDocumentType: "",
    sellerDocumentNumber: "",
    documentType: "",
    documentNumber: "",
    queryCode: "",
    reservationCode: "",
    transactionType: "",
    titleValue: "",
    username: "",
    expirationDate: moment(new Date()).toDate(),
    transactionValue: null,
    bank: "",
    acount: "",
    municipality: "0",
    phone: "0",
    celPhone: "0",
    addres: ".",
    transactionDues: [],
  };

  //   id_pais: 1
  // comprador_tipo_documento: N
  // tipo_documento: N
  // documento: 8600284621
  // comprador_documento: 8301342461
  // usuario: CC80036832
  // tipo_transaccion: D
  // num_titulovalor: 1
  // fechavence: 20201221
  // valor_transaccion: 100
  // StrJArray: [{"fecha":"20201221","valor":"100","titulo":1}]
  // banco: 59
  // cuenta: 24097074380
  // lista_municipio: 0
  // telefono: 0
  // celular: 0
  // direccion: .
  // codigo_consulta: 517144

  public minDate = moment(new Date()).toDate();
  public approvalCode: string;
  public errorMessage: string;
  public constants: any = CONSTANTS;
  public numberInstallments;

  public banks: Array<Bank>;

  public checkTypes = [
    {
      value: CONSTANTS.TRANSACTIONS.CHECK.UP_TO_DATE_ID,
      label: CONSTANTS.TRANSACTIONS.CHECK.UP_TO_DATE,
    },
    {
      value: CONSTANTS.TRANSACTIONS.CHECK.POSTDATED_ID,
      label: CONSTANTS.TRANSACTIONS.CHECK.POSTDATED,
    },
  ];

  public individualTransaction = true;

  private fDateB = false;

  private index = '0'; 

  public radioSelected: "";

  private firstDate: Date;

  public installments: Array<TransactionDue> = [];

  public round(num) {
    return Math.round(num);
  }

  constructor(
    private auth: AuthService,
    public helper: HelperService,
    public modal: ModalService,
    private spinner: NgxSpinnerService,
    private transactionService: TransactionService,
    public zone: NgZone
  ) {}

  ngOnInit() {
    this.banks = this.helper.banks;
    this.transactionRequest.reservationCode =
      this.transactionUser.reservationCode || "";
    const numberQuotas = [];
    for (
      let index = 0;
      index < parseInt(this.userAvailableSpace.maximumCheckQuotas, 10);
      index++
    ) {
      numberQuotas.push(index);
    }
    this.numberQuotas = numberQuotas;
    //this.userAvailableSpace.maximumDaysCheck = '60';
  }

  // dateMessage() {
  //   return `Las fechas deben ser mayores a la fecha actual, menores a ${this.userAvailableSpace.maximumDaysCheck} días, Los valores de las fechas no pueden ser iguales, y cada fecha debe ser mayor que la anterior.`;
  // }

  changeInstallments() {
    let installments: Array<TransactionDue> = [];
    const fee = this.transactionRequest.transactionValue
      ? this.transactionRequest.transactionValue / this.numberInstallments
      : 0;
    let initialDate = moment(new Date());
    const maximumDaysCheck = parseInt(
      this.userAvailableSpace.maximumDaysCheck,
      10
    );
    // const estimatedEpochDays = maximumDaysCheck / this.numberInstallments;
    // const epochDays = Math.floor(
    //   (maximumDaysCheck - estimatedEpochDays) / this.numberInstallments
    // );

    for (let index = 0; index < this.numberInstallments; index++) {
      if (maximumDaysCheck >= 60 && index == 0) {
        if(maximumDaysCheck > 60){
          let days = maximumDaysCheck-60;
          initialDate = initialDate.add(days, "days");
        }
        initialDate = initialDate.add(2, "month");
      } else if (maximumDaysCheck >= 30) {
        if(maximumDaysCheck > 30 && index == 0){
          let days = maximumDaysCheck-30;
          initialDate = initialDate.add(days, "days");
        }
        initialDate = initialDate.add(1, "month");
      } else {
        initialDate = initialDate.add(maximumDaysCheck, "days");
      }
      installments.push({
        date: this.transactionRequest.expirationDate,
        dateString: initialDate.format("DD/MM/YYYY"),
        title: `${index + 1}`,
        value: fee,
        check: false,
      });
    }
    this.installments = installments;
    this.firstDate =
      this.installments[0] != undefined
        ? moment(this.installments[0].dateString, "DD/MM/YYYY").toDate()
        : null;
  }

  checkValidDate() {
    let isValid = true;
    const maximumDaysCheck =
      parseInt(this.userAvailableSpace.maximumDaysCheck, 10);
    if (this.installments.length > 0) {
      let fDate = moment(
        this.installments[0].dateString,
        "DD/MM/YYYY"
      ).toDate();
      let iDate = moment(this.firstDate, "DD/MM/YYYY");
      if (moment(fDate).isAfter(iDate)) {
        this.dateMessage =
          "La fecha de la primera cuota, no puede ser mayor a la fecha actual";
        isValid = false;
        return;
      } else {
        this.calculateDate();
      }
    }
    for (let index = 0; index < this.installments.length; index++) {
      const installment = this.installments[index];
      const installmentDate = moment(installment.dateString, "DD/MM/YYYY");
      if (!installmentDate.isValid()) {
        this.dateMessage =
          "La fecha ingresada para la cuota " +
          installment.title +
          " no es valida";
        isValid = false;
        return;
      }
      if (index > 0) {
        const lastInstallment = this.installments[index - 1];
        const lastInstallmentDate = moment(
          lastInstallment.dateString,
          "DD/MM/YYYY"
        );
        if (lastInstallment.dateString == installment.dateString) {
          this.dateMessage = "Las fechas ingresadas no pueden ser iguales";
          isValid = false;
          break;
        }
        if (installmentDate < lastInstallmentDate) {
          this.dateMessage =
            "La fecha ingresada no puede ser menor que la anterior";

          isValid = false;
          break;
        }
        //Antes lastInstallmentDate
        //Actual installmentDate
        lastInstallmentDate.add(1, "month");
        if (installmentDate.diff(lastInstallmentDate, "days") < -2 || installmentDate.diff(lastInstallmentDate, "days") > 2) {
          this.dateMessage = `La fecha ingresada supera el parámetro del contrato`;
          isValid = false;
          break;
        }
      } else if (installmentDate < moment()) {
        this.dateMessage = "Ingrese una fecha mayor a la fecha actual";
        isValid = false;
        break;
      }
    }

    // return `Las fechas deben ser mayores a la fecha actual, menores a ${this.userAvailableSpace.maximumDaysCheck} días, Los valores de las fechas no pueden ser iguales, y cada fecha debe ser mayor que la anterior.`;
    // if (this.installments.length > 0) {
    //   const installmentDate = moment(
    //     this.installments[this.installments.length - 1].dateString,
    //     'DD/MM/YYYY'
    //   );
    //   const lastDate = moment().add(
    //     parseInt(this.userAvailableSpace.maximumDaysCheck, 10),
    //     'days'
    //   );
    //   if (installmentDate > lastDate) {
    //     return false;
    //   }
    // }
    return isValid;
  }

  calculateDate() {
    let iDate = moment(this.installments[0].dateString, "DD/MM/YYYY");
    const maximumDaysCheck = parseInt(
      this.userAvailableSpace.maximumDaysCheck,
      10
    );
    this.installments.forEach((element) => {
      if (element.title != "1" && this.fDateB) {
        if (maximumDaysCheck >= 30) {
          element.dateString = iDate.add(1, "month").format("DD/MM/YYYY");
        } else {
          element.dateString = iDate.add(maximumDaysCheck, "days").format("DD/MM/YYYY");
        }
      }
    });
  }

  calculateDateP1(){
    const maximumDaysCheck = parseInt(
      this.userAvailableSpace.maximumDaysCheck,
      10
    );
    let iDate = moment(this.installments[this.index].dateString, "DD/MM/YYYY");
    this.installments.forEach((element) => {
      if (Number(element.title) > Number(this.index + 1)) {
        if (maximumDaysCheck >= 30) {
          element.dateString = iDate.add(1, "month").format("DD/MM/YYYY");
        } else {
          element.dateString = iDate.add(maximumDaysCheck, "days").format("DD/MM/YYYY");
        }
      }
    });
  }

  totalInstallments() {
    return this.installments.reduce((acc, cur) => {
      return acc + cur.value;
    }, 0);
  }

  dateTextChange($event, index) {
    let previousValue = this.installments[index].dateString;
    this.installments[index].dateString = $event.target.value;
    if (index == 0) {
      this.fDateB = true;
    }else{
      this.index = index;
      this.calculateDateP1();
      this.fDateB = false;
    }
  }

  selectedReservationCodeValue() {
    return parseFloat(
      this.reserveList.find((reserve: Reserve) => {
        return (
          reserve.reservationCode == this.transactionRequest.reservationCode
        );
      }).totalValue
    );
  }

  transactionCheck() {
    if (
      this.transactionRequest.transactionType &&
      CONSTANTS.TRANSACTIONS.CHECK.UP_TO_DATE
    ) {
      (window as any).gtag("event", "cheque-dia-transaccion", {
        event_category: "transaccion-indiv",
        event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.userInfo.email}`,
      });
    } else {
      (window as any).gtag("event", "cheque-pos-transaccion", {
        event_category: "transaccion-indiv",
        event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.userInfo.email}`,
      });
    }
    const bank = this.banks.find(
      (currentBank) => currentBank.id == this.transactionRequest.bank
    );
    const payload = {
      name: this.userAvailableSpace.buyerSpace.names,
      bank: bank.value,
      accountNumber: this.transactionRequest.acount,
      postdated:
        this.transactionRequest.transactionType ==
        CONSTANTS.TRANSACTIONS.CHECK.POSTDATED_ID,
      checkTotalValue: this.transactionRequest.transactionValue,
    };

    if (
      this.transactionRequest.transactionType ==
      CONSTANTS.TRANSACTIONS.CHECK.POSTDATED_ID
    ) {
      payload["checks"] = this.installments.map((installment) => {
        return {
          checkValue: installment.value,
          checkDueDate: installment.dateString,
        };
      });
    }

    this.modal.show("transactionCheckConfirm", payload);
  }

  radioSelectedItem(value: any) {}

  getTotal(i) {
    // if (i === 0) {
    const prevSum = this.installments.reduce((acc, cur, idx) => {
      return acc + (idx <= i ? cur.value : 0);
    }, 0);
    const totalValue = this.transactionRequest.transactionValue;
    const newValue =
      (totalValue - prevSum) / (this.installments.length - (i + 1));
    this.installments.forEach((installment: TransactionDue, index: number) => {
      if (index > i) {
        this.installments[index].value = newValue < 0 ? 0 : newValue;
      }
    });
    // }
  }

  setItemValue(index: number, $event) {
    this.installments[index].value = this.helper.currencyInputChanged(
      $event.target.textContent
    );
    this.moveCursorToEnd($event.target);
  }

  moveCursorToEnd(el) {
    if (el.innerText && document.createRange) {
      window.setTimeout(() => {
        let selection = document.getSelection();
        let range = document.createRange();

        range.setStart(el.childNodes[0], el.innerText.length);
        range.collapse(true);
        selection.removeAllRanges();
        selection.addRange(range);
      }, 1);
    }
  }

  // dateTextInitChange($event) {
  //   this.initDate =
  //     $event.target.value.length != 10
  //       ? moment().toDate()
  //       : moment($event.target.value, 'DD/MM/YYYY').toDate();
  //   this.selectedMoments = [this.initDate, this.finalDate];
  // }

  // maskDate(index: number, $event) {
  //   let v = $event;
  //   if (v.match(/^\d{2}$/) !== null) {
  //     this.installments[index].dateString = v + '/';
  //   } else if (v.match(/^\d{2}\/\d{2}$/) !== null) {
  //     this.installments[index].dateString = v + '/';
  //   }

  //   if ($event.length >= 10) {
  //     this.zone.run(
  //       () => (this.installments[index].dateString = $event.substring(0, 10))
  //     );
  //   }
  // }

  clipboardEventHandler($event) {
    // Chrome , Firefox
    if ($event.target.textContent.length > 10 && $event.keyCode !== 8) {
      $event.preventDefault();
    }
  }

  sendTransaction() {
    if (
      this.transactionRequest.transactionType &&
      CONSTANTS.TRANSACTIONS.CHECK.UP_TO_DATE
    ) {
      (window as any).gtag("event", "cheque-dia-confirmacion", {
        event_category: "transaccion-indiv",
        event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.userInfo.email}`,
      });
    } else {
      (window as any).gtag("event", "cheque-pos-confirmacion", {
        event_category: "transaccion-indiv",
        event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.userInfo.email}`,
      });
    }
    this.transactionRequest.sellerDocumentNumber =
      this.transactionUser.sellerDocumentNumber;
    this.transactionRequest.sellerDocumentType =
      this.transactionUser.sellerDocumentType;
    this.transactionRequest.documentNumber =
      this.transactionUser.documentNumber;
    this.transactionRequest.documentType = this.transactionUser.documentType;
    this.transactionRequest.queryCode = this.transactionUser.queryCode;
    let transactionDues: Array<TransactionDue> = [];
    if (
      this.transactionRequest.transactionType ==
      CONSTANTS.TRANSACTIONS.CHECK.UP_TO_DATE_ID
    ) {
      transactionDues = [
        {
          date: this.transactionRequest.expirationDate,
          dateString: "",
          title: this.transactionRequest.titleValue,
          value: this.transactionRequest.transactionValue,
          check: false,
        },
      ];
    } else {
      this.transactionRequest.titleValue = this.installments[0].title;
      transactionDues = this.installments;
      this.transactionRequest.expirationDate = moment(
        transactionDues[0].dateString,
        "DD/MM/YYYY"
      ).toDate();
      for (let index = 0; index < transactionDues.length; index++) {
        transactionDues[index].date = moment(
          transactionDues[index].dateString,
          "DD/MM/YYYY"
        ).toDate();
      }
    }

    this.transactionRequest.transactionDues = transactionDues;
    this.spinner.show();
    this.transactionService
      .createTransaction(this.transactionRequest)
      .subscribe(
        (response: any) => {
          this.spinner.hide();
          if (response.error) {
            this.errorMessage = response.message;
            this.modal.show("transactionDenied");
          } else {
            if (
              this.transactionRequest.transactionType &&
              CONSTANTS.TRANSACTIONS.CHECK.UP_TO_DATE
            ) {
              (window as any).gtag("event", "cheque-dia-trans-exitosa", {
                event_category: "transaccion-indiv",
                event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.userInfo.email}`,
              });
            } else {
              (window as any).gtag("event", "cheque-pos-trans-exitosa", {
                event_category: "transaccion-indiv",
                event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.userInfo.email}`,
              });
            }
            this.approvalCode = response.approvalCode;
            this.modal.show("transactionSuccess");
          }
        },
        (err) => {
          this.spinner.hide();
        }
      );
  }
}
