import { Injectable } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { DocumentType } from '@models/documentType.model';
import { Bank } from '@models/bank.model';
import { Department } from '@models/department.model';
import { City } from '@models/city.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: "root",
})
export class HelperService {
  public documentTypes: Array<DocumentType>;
  public departments: Array<Department>;
  public citiesDepartment: Array<Department>;
  public scolarship: Array<Department>;
  public banks: Array<Bank>;
  private urlApi: string;

  constructor(
    private toastr: ToastrService,
    private router: Router,
    private http: HttpClient
  ) {
    this.urlApi = environment.urlApi;
    if (localStorage.getItem("documents")) {
      this.getDocumentTypes();
      this.getBanks();
      this.getDepartments();
      this.getCitiesDepartment();
      this.getScholarship();
    }
  }

  getDocumentTypes() {
    let documentTypes: Array<DocumentType> = [];
    JSON.parse(localStorage.getItem("documents")).TIPOS_DOCUMENTOS.forEach(
      (documentType) => {
        documentTypes.push(DocumentType.fromResponse(documentType));
      }
    );
    return (this.documentTypes = documentTypes);
  }

  getBanks() {
    let banks: Array<Bank> = [];
    JSON.parse(localStorage.getItem("documents")).BANCOS.forEach((bank) => {
      banks.push(Bank.fromResponse(bank));
    });
    return (this.banks = banks);
  }

  getDepartments() {
    let departments: Array<Department> = [];
    JSON.parse(localStorage.getItem("documents")).DEPARTAMENTOS.forEach(
      (department) => {
        departments.push(Department.fromResponse(department));
      }
    );
    return (this.departments = departments);
  }

  getDocumentName(documentId: String) {
    return this.documentTypes.find((documentType: DocumentType) => {
      return documentType.id == documentId;
    }).value;
  }

  getCities(departmentId: string) {
    return this.http
      .get(`${this.urlApi}/municipios?id_departamento=${departmentId}`)
      .pipe(
        map((resp: any) => {
          let cities: Array<City> = [];
          cities = resp["MUNICIPIOS"]
            ? resp["MUNICIPIOS"].map((city: City) => City.fromResponse(city))
            : [];
          return cities;
        })
      );
  }

  getCitiesDepartment() {
    let citiesDeparment: Array<Department> = [];
    JSON.parse(localStorage.getItem("documents"))[
      "CIUDAD-DEPARTAMENTO"
    ].forEach((cityDepartment) => {
      citiesDeparment.push(Department.fromResponse(cityDepartment));
    });
    return (this.citiesDepartment = citiesDeparment);
  }

  getScholarship() {
    let scolarship: Array<Department> = [];
    JSON.parse(localStorage.getItem("documents")).ESCOLARIDAD.forEach(
      (scolar) => {
        scolarship.push(Department.fromResponse(scolar));
      }
    );
    return (this.scolarship = scolarship);
  }

  createArray(cantElements: number) {
    return Array.from(new Array(cantElements), (x, i) => i + 1);
  }

  currencyInputChanged(value: any) {
    if (!value) {
      return;
    }
    const num = value.replace(/[\$,\. ]/g, "");
    return Number(num);
  }

  dateInputChanged(value: any) {
    return value;
  }

  logOut() {
    localStorage.clear();
    window.location.reload();
  }

  markForm(form?: NgForm, toast?: boolean, time?: number, textToast?: string) {
    const timeOut = time || 12000;
    const markForm = form.form.controls;
    const message =
      textToast || "Los campos marcados con rojo son obligatorios.";

    setTimeout(() => {
      Object.keys(markForm).forEach((control, index) => {
        form.form.controls[control].markAsTouched();
      });
      if (toast) {
        this.toastr.warning(message, null, {
          timeOut,
          positionClass: "toast-bottom-right",
        });
      }
    }, 500);
  }

  onlyNumber($event: any) {
    const arrayCodes = [8, 9, 37, 39, 91, 86];
    if (arrayCodes.includes($event.keyCode)) {
      return;
    } else if (
      arrayCodes.indexOf($event.keyCode) === -1 &&
      $event.key.search(/\d\b/) === -1
    ) {
      $event.preventDefault();
    }
  }

  onlyNumberAmount($event) {
    const arrayCodes = [8, 9, 37, 39, 91, 86];
    if (arrayCodes.includes($event.keyCode)) {
      return;
    } else if (
      arrayCodes.indexOf($event.keyCode) === -1 &&
      $event.key.search(/^\d{1,3}(\.\d{1,3})?$/) === -1
    ) {
      $event.preventDefault();
    }
  }

  pasteOnlyNumber($event) {
    let clipboardData = $event.clipboardData;
    let pastedText = clipboardData.getData("text");
    $event.preventDefault();
    return pastedText.replace(/[^0-9]*/g, "");
  }

  onlyText($event) {
    if ($event.key.search(/^[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]*$/) === -1) {
      $event.preventDefault();
    }
  }

  pasteOnlyText($event: any) {
    let clipboardData = $event.clipboardData;
    let pastedText = clipboardData.getData("text");
    
    $event.preventDefault();
    return pastedText.replace(/[^a-zA-ZñÑáéíóúÁÉÍÓÚ\s]*$/g, "");
  }

  maxLength(event, maxLength) {
    const value = event.target["value"];
    if (value.length + 1 > maxLength) {
      {
        if (
          event.which !== 46 &&
          event.which !== 8 &&
          event.which !== 37 &&
          event.which !== 39 &&
          event.which !== 9
        ) {
          event.preventDefault();
        }
      }
    }
  }

  maskDate($event) {
    let dateText = $event.target.value;
    let v = dateText.replace(/\D/g, "");
    if (v.length >= 5) {
      v = `${v.slice(0, 2)}/${v.slice(2, 4)}/${v.slice(4)}`;
    } else if (v.length >= 3) {
      v = `${v.slice(0, 2)}/${v.slice(2)}`;
    }
    $event.target.value = v.slice(0, 10);
    // return v.slice(0, 10);
  }

  getAmountOfPages(registers: any, pageLimit: number) {
    return registers.length > 0
      ? Math.ceil(parseInt(registers[0].numberRecords || 1) / pageLimit)
      : 1;
  }

  amountOfPages(arrayJ: any, pageLimit: number) {
    return arrayJ.length > 0
      ? Math.ceil(parseInt(arrayJ.length || 1) / pageLimit)
      : 1;
  }

  base64ToBinary(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  refresh(actualComponent: string) {
    this.router
      .navigateByUrl("/RefreshComponent", { skipLocationChange: true })
      .then(() => {
        this.router.navigate([actualComponent]);
      });
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  validatePatternDocument(documentType: string): RegExp {
    switch (documentType) {
      case "E":
        return new RegExp("^(\\d{0,8})$");
      case "N":
        return new RegExp("^(\\d{0,9}|\\d{10})$");
      default:
        return new RegExp("^(\\d{0,8}|\\d{10})$");
    }
  }
}
