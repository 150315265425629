export const CONSTANTS = {
  TRANSACTIONS: {
    RECIDIVIST: 'REINCIDENTE',
    DATAAGAIN: 'DEBE INGRESAR DATOS NUEVAMENTE',
    ACTIVE: 'ACTIVA',
    YES: 'SI',
    INVALID_CODE: 'código inválido',
    RETIRED_CODE: 'código retirado',
    SUSPENDED_CODE: 'código suspendido',
    ACTIVE_CODE: 'código activo',
    ALLOW_WITHOUT_QUOTE: 'PERMITIR SIN CUPO',
    ENABLED: 'HABILITADO',
    LOCKED: 'BLOQUEADO',
    OPTION_AGREEMENT: 'OPCION ACUERDO',
    WITH_AGREEMENT: 'CON ACUERDO',
    CHECK: {
      UP_TO_DATE: 'Al día',
      POSTDATED: 'Posfechado',
      UP_TO_DATE_ID: 'D',
      POSTDATED_ID: 'P',
    },
    DENIED: 'Negada',
    IN_PROGRESS: 'En Proceso',
    NOTIFICATION_SUCCESSFUL: 'Aviso No Pago Existoso',
  },
  EMAIL_TRANSACTION: {
    HELP: 'help',
    EMPTY_STATE: 'empty-state',
  },
  DOCUMENTS: {
    BULK_UPLOAD_FORMAT: 'Formato Carga Masiva',
    BULK_UPLOAD_RELEASE_FORMAT: 'Formato Carga Masiva',
    BULK_UPLOAD_EXONERATION_FORMAT: 'Formato Carga Masiva Exoneracion',
    BULK_UPLOAD_NONPAYMENT_FORMAT: 'Formato Carga Masiva Aviso no pago',
  },
  CLIENT_STUDY: {
    OTHER: 'Otra',
    SOCIETY: 'SOCIEDAD',
    LEGAL: 'legal',
    NIT: 'N',
    BUYER_WITH_ASSIGNED_SPACE: 'COMPRADOR CON CUPO ASIGNADO',
  },
  OPERATIONS: {
    UP_TO_DATE: 'Al día',
    EXONERATED: 'Exonerada',
    NOTICE_NOT_PAYMENT: 'Aviso no pago',
    CHECK: 'CHEQUE',
  },
  PERMISSIONS: {
    ACTIVE: 'A',
    HIDDEN: 'O',
    DISABLED: 'I',
    NO: 'NO',
  },
  ROUTES: {
    PAGES: 'pages',
    TRANSACTIONS: 'transacciones',
    INDIVIDUAL_TRANSACTION: 'transaccion-individual',
    CLIENT_STUDY: 'estudio-de-cliente',
    OPERATIONS: 'operaciones',
    CLAIMS: 'reclamaciones',
    REFUNDS: 'reintegros',
    PAYMENT_AGREEMENTS: 'acuerdos-de-pago',
    REPORTS: 'reportes',
    ADMINISTRATION: 'administracion',
    MY_PAYMENTS: 'mis-pagos',
    USERS: 'users',
    DOCUMENTS: 'documentos',
    PROFILE: 'perfil',
  },
  REPORTS: {
    NATURAL: 'NATURAL',
    LEGAL: 'JURIDICA',
  },
  USERS: {
    CREATE: 'CREAR',
    RECOVER: 'RECUPERAR',
  },
  REGEX: {
    CHECK_ONE_NUMBER_EXIST: '.*[0-9].*',
    EMAIL: '[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+[\.]+[A-Za-z]{2,4}$',
  },
};

export enum Tabs {
  TransaccionIndividual = '2',
  TransaccionMasiva = '6',
  ListadoDeReservas = '7',
  OperacionesIndividuales = '10',
  OperacionesMasivas = '11',
  Reportes = '18',
  ReporteDeTransacciones = '20',
  ReporteDeVendedores = '22',
  FacturaPorPagar = '27',
  HistorialDeFacturas = '28',
  Documentos = '29',
  Videos = '30'
}
