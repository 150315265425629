import { DocumentResponse } from "@models/documentResponse.model";
import { Component, Input, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ModalService } from "@app/services/modal.service";
import { AuthService } from "@services/auth.service";

@Component({
  selector: "app-tabs-videos",
  templateUrl: "./tabs-videos.component.html",
  styleUrls: ["./tabs-videos.component.scss"],
})
export class TabsvideosComponent implements OnInit {
  public isValid = false;

  @Input() listaVideos: Array<DocumentResponse> = [];
  // Radio
  public radioSel: any;
  public radioSelected = "transaccion_individual";

  // public listavideos: any = [
  //   // {
  //   //   id: "01",
  //   //   titulo: "Tutorial de ingreso a la plataforma",
  //   //   description:
  //   //     "Aquí podrá conocer paso a paso cómo ingresar a la plataforma de Afiliados Covifactura.",
  //   //   url: "https://secureservercdn.net/198.71.233.153/291.41f.myftpupload.com/wp-content/uploads/2021/10/Ingreso-a-plataforma.mp4",
  //   // },
  //   // {
  //   //   id: "02",
  //   //   titulo: "Tutorial de transacciones individuales",
  //   //   description: "Conozca el paso a paso para registrar transacciones individuales y reservas de cupo.",
  //   //   url: "https://secureservercdn.net/198.71.233.153/291.41f.myftpupload.com/wp-content/uploads/2021/10/Transacciones-individuales.mp4",
  //   // },
  //   // {
  //   //   id: "03",
  //   //   titulo: "Tutorial de transacciones masivas",
  //   //   description: "Conozca el paso a paso para cargar transacciones masivas.",
  //   //   url: "https://secureservercdn.net/198.71.233.153/291.41f.myftpupload.com/wp-content/uploads/2021/10/Transacciones-masivas.mp4",
  //   // },
  //   // {
  //   //   id: "04",
  //   //   titulo: "Tutorial de solicitud estudio cliente",
  //   //   description:"Conozca el paso a paso para solicitar el cupo en línea para sus compradores.",
  //   //   url: "https://secureservercdn.net/198.71.233.153/291.41f.myftpupload.com/wp-content/uploads/2021/10/Estudio-Cliente.mp4",
  //   // },
  //   // {
  //   //   id: "05",
  //   //   titulo: "Tutorial de operaciones individuales",
  //   //   description: "Conozca el paso a paso para marcar avisos de no pago, exoneraciones parciales, exoneraciones totales y desistimientos.",
  //   //   url: "https://secureservercdn.net/198.71.233.153/291.41f.myftpupload.com/wp-content/uploads/2021/10/Operaciones_Individuales.mp4",
  //   // },
  //   // {
  //   //   id: "06",
  //   //   titulo: "Tutorial de operaciones masivas",
  //   //   description: "Conozca el paso a paso para cargar avisos de no pago, exoneraciones parciales y exoneraciones totales masivas.",
  //   //   url: "https://secureservercdn.net/198.71.233.153/291.41f.myftpupload.com/wp-content/uploads/2021/10/Operaciones-masivas.mp4",
  //   // },
  //   // {
  //   //   id: "07",
  //   //   titulo: "Tutorial de reportes",
  //   //   description: "Conozca el paso a paso para acceder al reporte de cupos, reporte de transacciones y reporte de vendedores.",
  //   //   url: "https://secureservercdn.net/198.71.233.153/291.41f.myftpupload.com/wp-content/uploads/2021/10/Reportes.mp4",
  //   // },
  //   // {
  //   //   id: "08",
  //   //   titulo: "Tutorial de mis pagos",
  //   //   description: "Conozca el paso a paso para consultar sus facturas por pagar, realizar pagos en línea y acceder a su historial de facturación.",
  //   //   url: "https://secureservercdn.net/198.71.233.153/291.41f.myftpupload.com/wp-content/uploads/2021/10/Mis-pagos.mp4",
  //   // },
  //   // {
  //   //   id: "09",
  //   //   titulo: "Tutorial de administración de usuarios",
  //   //   description: "Conozca los roles de usuario disponibles para la plataforma y el paso a paso para crear, modificar, inactivar, activar y eliminar.",
  //   //   url: "https://secureservercdn.net/198.71.233.153/291.41f.myftpupload.com/wp-content/uploads/2021/10/Administración-de-usuarios_1.mp4",
  //   // },
  // ];

  constructor(
    private auth: AuthService,
    public spinner: NgxSpinnerService,
    public modal: ModalService,
  ) {}

  ngOnInit() {}

  viewDocument(url: string) {
    this.spinner.show();
    window.open(url, "_blank");
    setTimeout(() => {
      this.spinner.hide();
    }, 1000);
  }



}
