import { Component, OnDestroy } from '@angular/core';
import { ModalService } from '@services/modal.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-modal-available-quota',
  templateUrl: './modal-available-quota.component.html',
  styleUrls: ['./modal-available-quota.component.scss']
})
export class ModalAvailableQuotaComponent implements OnDestroy {

  constructor(public modal: ModalService,
              private router: Router) { }

  ngOnDestroy() {
     this.modal.close();
  }

  goToStart() {
     this.modal.close();
     this.router.navigateByUrl('/pages/transacciones');
  }

}
