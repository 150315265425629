import { Component, OnInit } from '@angular/core';
import { ModalService } from '@app/services/modal.service';

@Component({
  selector: 'app-modal-upload-mail-error',
  templateUrl: './modal-upload-mail-error.component.html',
  styleUrls: ['./modal-upload-mail-error.component.scss']
})
export class ModalUploadMailErrorComponent implements OnInit {

  constructor(
    public modalService: ModalService
  ) { }

  ngOnInit() {
  }

}
