import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '../services/auth.service';
import { PermissionModel } from '../models/permission.model';
import { CONSTANTS } from '../config/constants';

@Injectable({
  providedIn: 'root',
})
export class RolGuard implements CanActivate {
  constructor(private router: Router, private auth: AuthService) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const userActions: Array<PermissionModel> = this.auth.permissions;
    const actions = userActions
      .filter(
        (permission: PermissionModel) =>
          permission.status === CONSTANTS.PERMISSIONS.ACTIVE
      )
      .map((permission: PermissionModel) => parseInt(permission.idOption));

    const pages = [
      { path: CONSTANTS.ROUTES.TRANSACTIONS, idOption: 1 },
      { path: CONSTANTS.ROUTES.INDIVIDUAL_TRANSACTION, idOption: 1 },
      { path: CONSTANTS.ROUTES.CLIENT_STUDY, idOption: 8 },
      { path: CONSTANTS.ROUTES.OPERATIONS, idOption: 9 },
      { path: CONSTANTS.ROUTES.CLAIMS, idOption: 1 },
      { path: CONSTANTS.ROUTES.REFUNDS, idOption: 14 },
      { path: CONSTANTS.ROUTES.PAYMENT_AGREEMENTS, idOption: 16 },
      { path: CONSTANTS.ROUTES.REPORTS, idOption: 17 },
      { path: CONSTANTS.ROUTES.MY_PAYMENTS, idOption: 26 },
      { path: CONSTANTS.ROUTES.USERS, idOption: 29 },
      { path: CONSTANTS.ROUTES.DOCUMENTS, idOption: 32 },
      { path: CONSTANTS.ROUTES.PROFILE, idOption: 31 },
    ];

    const routeUrl = state.url;
    const routeSelected = routeUrl.split('/')[2];

    const findRoute = pages
      .filter((page) => page.path === routeSelected)
      .map((page) => page.idOption)
      .join();
    const permissionIndex = actions.findIndex(
      (action) => action === parseInt(findRoute)
    );

    if (permissionIndex > -1) {
      return true;
    } else {
      // return false;
      return true;
    }
  }
}
