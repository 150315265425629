import { ModalConfigureProfileImgComponent } from '@components/modals/modal-configure-profile-img/modal-configure-profile-img.component';
import { Output, EventEmitter } from '@angular/core';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { SubjectService } from '@services/subject.service';
import { ModalService } from '@services/modal.service';

@Component({
  selector: 'app-charge-profile-img',
  templateUrl: './charge-profile-img.component.html',
  styleUrls: ['./charge-profile-img.component.scss']
})
export class ChargeProfileImgComponent {

  public message = 'Cambiar foto';
  @ViewChild ('inputFile') inputFileValue: any;
  @ViewChild ('modalConfigProfile') modalConfigProfile: ModalConfigureProfileImgComponent;
  public file: File;
  public fileName: string;
  public imgToCropp: any;
  @Input() selectedCroppedImg: any;
  @Input() userPhoto: string;
  @Output() photo: EventEmitter<any> = new EventEmitter<any>();
  @Output() base64: EventEmitter<any> = new EventEmitter<any>();

  constructor(private subject: SubjectService,
              public modal: ModalService) { }

  uploadImg(file?: any, imgToCropp?: any) {
    this.file = file;
    this.imgToCropp = imgToCropp;
    this.fileName = file['name'];
    this.modal.show('modalConfigureProfileImg');
    this.modalConfigProfile.init();
  }

  deleteFile(id: number) {
    this.inputFileValue.nativeElement.value = '';
    this.file = undefined;
    this.fileName = '';
  }

  changeProfileImage(img: any) {
    const { file, base64 } = img;
    this.selectedCroppedImg = base64;
    file.name = this.fileName;
    this.photo.emit(file);
    this.base64.emit(this.selectedCroppedImg);
  }

  // Tooltip icon change password
  enter() {
    this.subject.sendText(this.message);
  }

  leave() {
    this.subject.sendText('');
  }

}
