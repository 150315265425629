import { Component, Output, OnDestroy, EventEmitter } from '@angular/core';
import { ModalService } from '@services/modal.service';

@Component({
  selector: 'app-modal-user-delete-success',
  templateUrl: './modal-user-delete-success.component.html',
  styleUrls: ['./modal-user-delete-success.component.scss']
})
export class ModalUserDeleteSuccessComponent implements OnDestroy {
  @Output() confirm: EventEmitter<object> = new EventEmitter();
  constructor(public modal: ModalService) {}

  ngOnDestroy() {
    this.modal.close();
  }

  accept() {
    this.confirm.emit();
    this.modal.close();
  }
}