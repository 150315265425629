import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponseBase } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UserModel } from '@app/models/user.model';
import { Observable } from 'rxjs';
import { ModelEmailLoadDocumentsRequest} from '@app/models/emailLoadDocuments.model';
import { ModelDocumentsLoadFlagRequest,ReturnDocumentsLoadFlag } from '@app/models/flagLoadDocuments.model';

interface FileData {
  id: any,
  source: string,
  user: string,
  documentType: any,
  document: any,
  originalName: string,
  creationDate: any,
  category: string,
  infoFile: string,
  originalPath: any,
  originalFinalName: any,
  resourceId: any
}
export interface ReturnFileDataMail{
  ok?: boolean,
  message?: string,
  data?: null,
  status?: number
}

export interface ReturnFileDataInfoUser{
  ok?: boolean,
  message?: string,
  data?: ReturnFileData,
  status?: number
}
export interface ReturnFileData{
  radicacion?: number,
  documentoComprador?: number, // documentoComprador
  tipoDocComprador?: string, // tipoDocComprador
  nombreComprador?: string //nombreComprador
  emailComprador?: string 
  emailAfiliado?: string 
}
@Injectable({
  providedIn: 'root'
})
export class UploadDocumentsService {
  public urlApiNit: string;
  public userToken: any;
  public identity: UserModel;
  public headers: any;

  constructor(private http: HttpClient) {
    this.urlApiNit = environment.urlApiNit;
    this.readToken();
    this.getIdentity();
  }

  readToken() {
    if (localStorage.getItem('tokenCF')) {
      this.userToken = localStorage.getItem('tokenCF');
    } else {
      this.userToken = '';
    }
    return this.userToken;
  }

  getIdentity() {
    return (this.identity = UserModel.fromResponse(
      JSON.parse(localStorage.getItem('user'))
    ));
  }
  
  sendDocuments(body) {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Basic ' + btoa('CLIENT_APP-210116ARtVSfJ:aVPtuE22145')
    });
    const options = {
      headers,
    };
    return this.http.post(`${environment.urlApiNit}/coviresource/CoviResourceServices/servicios/covifactura/saveImage`, body, options)
  }

  sendMails(body: ModelEmailLoadDocumentsRequest): Observable<ReturnFileDataMail> {
    const headers = new HttpHeaders().set('Authorization', 'Basic Q0xJRU5UX0FQUC1kb2FuZWlvbjEwOTI6c2tqZG9xajEyM0BBZlY=')
    return this.http.post<ReturnFileDataMail>(`${environment.urlApiDev}/NewCoviFactura/CoviFactura/cargueDocumentos/enviarCorreo`, body, {headers})
  }

  sendInfoUser(code): Observable<ReturnFileDataInfoUser> {
    const headers = new HttpHeaders().set('Authorization', 'Basic Q0xJRU5UX0FQUC0zMTIxMjhhdnJ1dDphVmVzcXQzMjVAbXFz')
    const params = new HttpParams().append('radicado', code)
    return this.http.get<ReturnFileDataInfoUser>(`${environment.urlApiNit}/buyerInt/creditoRadicacion/findByNumeroRadicado?`, {headers, params})
  }

  checkLoad(code): Observable<ReturnFileDataInfoUser> {
    const headers = new HttpHeaders().set('Authorization', 'Basic Q0xJRU5UX0FQUC0zMTIxMjhhdnJ1dDphVmVzcXQzMjVAbXFz')
    const params = new HttpParams().append('radicado', code)
    return this.http.get<ReturnFileDataInfoUser>(`${environment.urlApiNit}/buyerInt/recursoImagenes/verificarCargaDocumento?`, {headers, params})
  }

  saveDocumentUploadFlag(flagBody: ModelDocumentsLoadFlagRequest): Observable<ReturnDocumentsLoadFlag> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Basic ' + btoa('CLIENT_APP-312128avrut:aVesqt325@mqs')
    });
    const options = {
      headers,
    };
    return this.http.post<ReturnDocumentsLoadFlag>(`${environment.urlApiNit}/buyerInt/cargueDocumentosFlag/guardarFlag`, flagBody, options)
  }
}