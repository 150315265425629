import * as _moment from 'moment';
const moment = (_moment as any).default ? (_moment as any).default : _moment;

export class BirthdayRequest {
  idContry: string = '1';
  user: string;
  documentType: string = '';
  documentNumber: string = '';
  userDate: Date;
  cellPhone: string = '';

  public static toRequest(birthdayRequest: BirthdayRequest): any {
    console.log('birthdayRequest.userDate', birthdayRequest.userDate);

    return {
      id_pais: birthdayRequest.idContry,
      usuario: birthdayRequest.user,
      tipo_documento: birthdayRequest.documentType,
      documento: birthdayRequest.documentNumber,
      fecha_usuario:
        birthdayRequest.userDate && moment(birthdayRequest.userDate).isValid()
          ? moment(birthdayRequest.userDate).format('DDMMYYYY')
          : '',
      celular_usuario: birthdayRequest.cellPhone,
    };
  }
}
