import { UserInfoModel } from './userInfo.model';

export class UserModel {
    token: string;
    username: string;
    userInfo: UserInfoModel;

    public static fromResponse(json: any): UserModel {
        if (!json) return;
        const loginModel: UserModel = {
            token: json['token'],
            username: json['userEmail'],
            userInfo: UserInfoModel.fromResponse(json['userInfo']),
        }
        return loginModel;
    }

    public static toResponse(loginModel: UserModel): any {
        return {
            'token': loginModel.token,
            'userEmail': loginModel.username,
            'userInfo': loginModel.userInfo,
            'EMPRESA': UserInfoModel.toResponse(loginModel.userInfo),
        };
    }
}
