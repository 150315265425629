import { Component, OnInit, ViewChild, Output,EventEmitter} from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { HelperService } from '@services/helper.service';
import { ModalService } from '@services/modal.service';
import { TransactionService } from '@services/transaction.service';
import { DocumentType } from '@models/documentType.model';
import { UserAvailableSpaceRequest } from '@models/userAvailableSpaceRequest.model';
import { UserAvailableSpaceResponse } from '@models/userAvailableSpaceResponse.model';
import { CONSTANTS } from '@config/constants';
import { AuthService } from '@services/auth.service';
/* import { EventEmitter } from 'events'; */

@Component({
  selector: 'app-tab-individual-transaction',
  templateUrl: './tab-individual-transaction.component.html',
  styleUrls: ['./tab-individual-transaction.component.scss'],
})
export class TabIndividualTransactionComponent implements OnInit {
  @ViewChild('formCheck') formCheck: NgForm;

  public documentTypes: Array<DocumentType>;
  public documentTypesSelector: Array<any> = [];
  public showError = false;
  public showErrorMsg = '';
  public showThreePaymentsError = false;
  public showSixPaymentsError = false;
  public showThreePaymentsErrorDocumentType = false;
  public errorType = '';
  public userName = '';
  public names = '';
  public errorDocument = '';
  public transactionUser: UserAvailableSpaceRequest = {
    idContry: '1',
    sellerDocumentType: '',
    sellerDocumentNumber: '',
    documentType: '',
    documentNumber: '',
    queryCode: '',
  };
  public userAvailableSpaceResponse: UserAvailableSpaceResponse;

  public validPattern: boolean = false;

  activeButton:boolean = false;

  constructor(
    private auth: AuthService,
    public helper: HelperService,
    public modal: ModalService,
    public transaction: TransactionService,
    private spinner: NgxSpinnerService,
    public router: Router
  ) { }

  ngOnInit() {
    // this.helper.markForm(this.formCheck);
    this.documentTypes = this.helper.documentTypes;
    this.documentTypes.forEach((item)=> {
      this.documentTypesSelector.push({
        ID: item.id,
        VALUE: this.converterToSelect(item.value)
      })
    })
  }

  get threeOrSixPayments() : boolean {
    return (this.userAvailableSpaceResponse.threePaymentsCode === CONSTANTS.TRANSACTIONS.YES || 
              this.userAvailableSpaceResponse.sixPaymentsCode === CONSTANTS.TRANSACTIONS.YES) ? true : false;
  }

  getDocumentSelector(document:any){
    this.validPattern = document.patternValid;
    if(document && document.documentType && document.document){
      this.transactionUser.sellerDocumentNumber = document.patternValid ? document.document : '';
      this.transactionUser.sellerDocumentType = document.documentType;
    }
  }

  converterToSelect(value: string): string {
    switch(value) {
      case "CEDULA DE CIUDADANIA":
        return "C.C. CÉDULA DE CIUDADANIA";
      case "CEDULA DE EXTRANJERIA":
        return "C.E. CÉDULA DE EXTRANJERIA";
      case "NIT":
        return "NIT NÚMERO IDENFICACIÓN TRIBUTARIA";
      default:
        return value;
    }
  }
  cleanErrors() {
    this.showError = false;
    this.showThreePaymentsError = false;
    this.showSixPaymentsError = false;
    this.showThreePaymentsErrorDocumentType = false;
    this.errorType = '';
  }

  codeChange($event) {
    this.cleanErrors();
  }

  documentTypeChange($event) {
    this.cleanErrors();
    this.transactionUser.sellerDocumentType = $event;
  }

  saveInteraction(response: "success" | "error"){
    const data = {
      "plataforma": "COVIFACTURA WEB",
      "categoria": response === "success" ? "CONSULTAR_CUPO": "ERROR_CONSULTAR_CUPO",
      "subcategoria":  this.threeOrSixPayments ? "TRES_PAGOS" : "COVIFACTURA",
      "tipoDocumentoAfiliado": this.auth.identity.userInfo.documentType,
      "numeroDocumentoAfiliado": this.auth.identity.userInfo.document,
      "tipoDocumentoComprador": this.transactionUser.sellerDocumentType,
      "numeroDocumentoComprador": this.transactionUser.sellerDocumentNumber,
      "usuario_web": this.auth.identity.userInfo.user
    }
    this.auth.saveInteractionDate( data ).subscribe({
      next: () => {
      },
      error: () => {
        console.log('Verifique los datos');      
      },
    });
  }

  checkQuota() {
    (window as any).gtag('event', 'consulta-cupo', {
      event_category: 'transaccion-indiv',
      event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
    });
    this.showError = false;
    this.showThreePaymentsError = false;
    this.showSixPaymentsError = false;
    this.showThreePaymentsErrorDocumentType = false;
    this.errorType = '';
    this.spinner.show();
    // if (form && form.invalid) { return; }
    this.transaction.checkQuota(this.transactionUser).subscribe(
      (userAvailableSpaceResponse: UserAvailableSpaceResponse) => {
        this.userAvailableSpaceResponse = userAvailableSpaceResponse;
        this.spinner.hide();
        
        console.log(userAvailableSpaceResponse);

        const sixPaymentsCode =
          userAvailableSpaceResponse.sixPaymentsCode ===
          CONSTANTS.TRANSACTIONS.YES;
        const sixPaymentsQuota =
          userAvailableSpaceResponse.sixPaymentsQuota ===
          CONSTANTS.TRANSACTIONS.YES;


        if (sixPaymentsCode) {
          // if (
          //   sixPaymentsCode &&
          //   this.transactionUser.sellerDocumentType === 'E'
          // ) {
          //   (window as any).gtag('event', 'tipo-documento-n/a', {
          //     event_category: 'transaccion-3pagos',
          //     // tslint:disable-next-line:max-line-length
          //     event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.userInfo.email}`,
          //   });
          //   this.showThreePaymentsErrorDocumentType = true;
          // } else {
            if (sixPaymentsCode && !sixPaymentsQuota) {
                this.router.navigateByUrl(
                  // tslint:disable-next-line:max-line-length
                  `/pages/estudio-de-cliente?sixpayments=${this.transactionUser.queryCode}&documentType=${this.transactionUser.sellerDocumentType}&documentNumber=${this.transactionUser.sellerDocumentNumber}`
                );
            } else if (
              sixPaymentsCode &&
              sixPaymentsQuota &&
              userAvailableSpaceResponse.sixPaymentsStatus ===
              CONSTANTS.TRANSACTIONS.RECIDIVIST
            ) {
              this.router.navigateByUrl(
                // tslint:disable-next-line:max-line-length
                `/pages/transacciones/transaccion-individual/${this.transactionUser.sellerDocumentType}/${this.transactionUser.sellerDocumentNumber}/${this.transactionUser.queryCode}?sixpayments=true`
              );
            } else if (
              sixPaymentsCode &&
              sixPaymentsQuota &&
              userAvailableSpaceResponse.sixPaymentsStatus ===
              CONSTANTS.TRANSACTIONS.ACTIVE
            ) {
              (window as any).gtag('event', 'comprador-fact-activa', {
                event_category: 'transaccion-3pagos',
                // tslint:disable-next-line:max-line-length
                event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.userInfo.email}`,
              });
              this.names = `${this.transactionUser.sellerDocumentType} ${this.transactionUser.sellerDocumentNumber}`;
              setTimeout(() => {
                this.modal.show('requestThrepayments');
              }, 100);
            } else if (
              (userAvailableSpaceResponse.statusCode ==
                CONSTANTS.TRANSACTIONS.ACTIVE_CODE &&
                userAvailableSpaceResponse.buyerSpace) ||
              (userAvailableSpaceResponse.buyerSpace &&
                userAvailableSpaceResponse.buyerSpace.alert ==
                CONSTANTS.TRANSACTIONS.OPTION_AGREEMENT)
            ) {
              if (
                userAvailableSpaceResponse.buyerSpace.status ==
                CONSTANTS.TRANSACTIONS.ENABLED ||
                userAvailableSpaceResponse.buyerSpace.status ==
                CONSTANTS.TRANSACTIONS.ALLOW_WITHOUT_QUOTE ||
                userAvailableSpaceResponse.buyerSpace.alert ==
                CONSTANTS.TRANSACTIONS.OPTION_AGREEMENT
              ) {
                this.router.navigateByUrl(
                  // tslint:disable-next-line:max-line-length
                  `/pages/transacciones/transaccion-individual/${this.transactionUser.sellerDocumentType}/${this.transactionUser.sellerDocumentNumber}/${this.transactionUser.queryCode}`
                );
              } else if (
                userAvailableSpaceResponse.buyerSpace.status ===
                CONSTANTS.TRANSACTIONS.LOCKED
              ) {
                (window as any).gtag('event', 'comprador-bloqueado', {
                  event_category: 'transaccion-indiv',
                  // tslint:disable-next-line:max-line-length
                  event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.userInfo.email}`,
                });
                this.modal.show('transactionLocked');
              } else {
                (window as any).gtag('event', 'comprador-suspendido', {
                  event_category: 'transaccion-indiv',
                  // tslint:disable-next-line:max-line-length
                  event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.userInfo.email}`,
                });
                this.modal.show('requestSuspendedStatus');
              }
            } else if (
              userAvailableSpaceResponse.statusCode ==
              CONSTANTS.TRANSACTIONS.ACTIVE_CODE &&
              !userAvailableSpaceResponse.buyerSpace
            ) {
              // this.modal.show('notRegistered');
              (window as any).gtag('event', 'comprador-no-existe', {
                event_category: 'transaccion-indiv',
                event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
              });
              this.errorType = 'error';
              this.errorDocument = `${this.transactionUser.sellerDocumentType} ${this.transactionUser.sellerDocumentNumber}`;
            } else if (
              userAvailableSpaceResponse.statusCode ==
              CONSTANTS.TRANSACTIONS.INVALID_CODE ||
              userAvailableSpaceResponse.statusCode ==
              CONSTANTS.TRANSACTIONS.RETIRED_CODE ||
              userAvailableSpaceResponse.statusCode ==
              CONSTANTS.TRANSACTIONS.SUSPENDED_CODE
            ) {
              (window as any).gtag('event', 'codig-cons-invalido', {
                event_category: 'transaccion-indiv',
                event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
              });
              switch (userAvailableSpaceResponse.statusCode) {
                case CONSTANTS.TRANSACTIONS.INVALID_CODE:
                  this.showErrorMsg =
                    'Código inválido, verifique y vuelva a intentar.';
                  break;
                case CONSTANTS.TRANSACTIONS.RETIRED_CODE:
                  this.showErrorMsg =
                    'Código retirado, verifique y vuelva a intentar.';
                  break;
                case CONSTANTS.TRANSACTIONS.SUSPENDED_CODE:
                  this.showErrorMsg =
                    'Código suspendido, verifique y vuelva a intentar.';
                  break;
  
                default:
                  this.showErrorMsg =
                    'Código inválido, verifique y vuelva a intentar.';
                  break;
              }
              this.showError = true;
            } else {
              this.modal.show('requestSuspendedStatus');
            }
          // }
        } else if (!sixPaymentsCode){
            if (
              (userAvailableSpaceResponse.statusCode ==
                CONSTANTS.TRANSACTIONS.ACTIVE_CODE &&
                userAvailableSpaceResponse.buyerSpace) ||
              (userAvailableSpaceResponse.buyerSpace &&
                userAvailableSpaceResponse.buyerSpace.alert ==
                CONSTANTS.TRANSACTIONS.OPTION_AGREEMENT)
            ) {
              if (
                userAvailableSpaceResponse.buyerSpace.status ==
                CONSTANTS.TRANSACTIONS.ENABLED ||
                userAvailableSpaceResponse.buyerSpace.status ==
                CONSTANTS.TRANSACTIONS.ALLOW_WITHOUT_QUOTE ||
                userAvailableSpaceResponse.buyerSpace.alert ==
                CONSTANTS.TRANSACTIONS.OPTION_AGREEMENT
              ) {
                this.saveInteraction("success");
                this.router.navigateByUrl(
                  // tslint:disable-next-line:max-line-length
                  `/pages/transacciones/transaccion-individual/${this.transactionUser.sellerDocumentType}/${this.transactionUser.sellerDocumentNumber}/${this.transactionUser.queryCode}`
                );
              } else if (
                userAvailableSpaceResponse.buyerSpace.status ===
                CONSTANTS.TRANSACTIONS.LOCKED
              ) {
                (window as any).gtag('event', 'comprador-bloqueado', {
                  event_category: 'transaccion-indiv',
                  // tslint:disable-next-line:max-line-length
                  event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.userInfo.email}`,
                });
                this.saveInteraction("success");
                this.modal.show('transactionLocked');
              } else {
                (window as any).gtag('event', 'comprador-suspendido', {
                  event_category: 'transaccion-indiv',
                  // tslint:disable-next-line:max-line-length
                  event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.userInfo.email}`,
                });
                this.saveInteraction("success");
                this.modal.show('requestSuspendedStatus');
              }
            } else if (
              userAvailableSpaceResponse.statusCode ==
              CONSTANTS.TRANSACTIONS.ACTIVE_CODE &&
              !userAvailableSpaceResponse.buyerSpace
            ) {
              // this.modal.show('notRegistered');
              (window as any).gtag('event', 'comprador-no-existe', {
                event_category: 'transaccion-indiv',
                // tslint:disable-next-line:max-line-length
                event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.userInfo.email}`,
              });
              this.saveInteraction("error");
              this.errorType = 'error';
              this.errorDocument = `${this.transactionUser.sellerDocumentType} ${this.transactionUser.sellerDocumentNumber}`;
            } else if (
              userAvailableSpaceResponse.statusCode ==
              CONSTANTS.TRANSACTIONS.INVALID_CODE ||
              userAvailableSpaceResponse.statusCode ==
              CONSTANTS.TRANSACTIONS.RETIRED_CODE ||
              userAvailableSpaceResponse.statusCode ==
              CONSTANTS.TRANSACTIONS.SUSPENDED_CODE
            ) {
              (window as any).gtag('event', 'codig-cons-invalido', {
                event_category: 'transaccion-indiv',
                // tslint:disable-next-line:max-line-length
                event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.userInfo.email}`,
              });
              this.saveInteraction("error");
              switch (userAvailableSpaceResponse.statusCode) {
                case CONSTANTS.TRANSACTIONS.INVALID_CODE:
                  this.showErrorMsg =
                    'Código inválido, verifique y vuelva a intentar.';
                  break;
                case CONSTANTS.TRANSACTIONS.RETIRED_CODE:
                  this.showErrorMsg =
                    'Código retirado, verifique y vuelva a intentar.';
                  break;
                case CONSTANTS.TRANSACTIONS.SUSPENDED_CODE:
                  this.showErrorMsg =
                    'Código suspendido, verifique y vuelva a intentar.';
                  break;
  
                default:
                  this.showErrorMsg =
                    'Código inválido, verifique y vuelva a intentar.';
                  break;
              }
              this.showError = true;
            } else {
              this.saveInteraction("success");
              this.modal.show('requestSuspendedStatus');
            }
        }
        // const threePaymentsStatus = nextProps.userQuota
        //   ? nextProps.userQuota.TRANSACCION_TRES_PAGOS
        //   : '';
      },
      (err) => {
        this.saveInteraction("error");
        this.spinner.hide();
      }
    );
  }

  enableBtn($event){
    this.activeButton = $event
  }

}
