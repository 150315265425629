import {
  Component,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { HelperService } from "@services/helper.service";
import { ClientStudyRequest } from "@models/clientStudyRequest.model";
import { Department } from "@models/department.model";
import { City } from "@models/city.model";
import * as moment from "moment";
import { CONSTANTS } from "@app/config/constants";
import { ActivatedRoute } from '@angular/router';
import { ClientStudyResponse } from "@app/models/clientStudyResponse.model";
import { ModalService } from "@app/services/modal.service";
@Component({
  selector: "app-display-natural-person",
  templateUrl: "./display-natural-person.component.html",
  styleUrls: ["./display-natural-person.component.scss"],
})
export class DisplayNaturalPersonComponent {
  @ViewChild("formCheck") formCheck;
  @Input() departments: Array<Department>;
  @Input() initialDateFields: Array<string>;
  @Input() addressFields: Array<string>;
  @Input() documentTypesOptions: Array<any>;
  @Input() companyTypeFields: Array<any>;
  @Input() expeditionCities: Array<City>;
  @Input() homeCities: Array<City>;
  @Input() threePayments: boolean;
  @Input() lastSaveDate: string;
  @Input() scolarships: Array<Department>;
  @Input() clientStudyResponse: ClientStudyResponse;
  // Form
  @Input() clientStudyForm: ClientStudyRequest;
  @Output()
  requestClientStudy: EventEmitter<object> = new EventEmitter<object>();
  @Output()
  requestClientStudy2: EventEmitter<object> = new EventEmitter<object>();
  @Output()
  valueChanges: EventEmitter<object> = new EventEmitter<object>();
  @Output()
  validateChange: EventEmitter<object> = new EventEmitter<object>();
  /* @Output() objFileTypes: EventEmitter<object> = new EventEmitter<object>(); */

  public isRef1Valid = false;
  public isRef2Valid = false;
  public isAddressValid = false;
  public isTermAccepted = false;
  public deptRequired = false;
  public habilitar: boolean = false;
  public max = new Date();
  public maxBirthDate = moment().subtract(18, "years").toDate();
  public constants = CONSTANTS;
  types: string='C';
  public activeContent: string;
  modalUploadFile:boolean = false;
  objFileTypes = [
    {name: "Declaración de renta último año fiscal", montMin:20000000, },
    {name: "Estados financieros del último periodo", montMin:50000000, },
    {name: "Estados financieros de los últimos 2 años", montMin:100000000, montMax:100000001, },
  ]
  totalFiles: Array<File[]> = [];
  message = "Los campos marcados con * son obligatorios, diligéncielos antes de enviar el formulario.";
  messagecup = 'Ingrese un valor en "Monto de cupo a solicitar"';
  messageProductType = 'El tipo de producto es obligatorio, seleccione una opción (Covifactura - 3Pagos)';
  public option = { description: "pointsOfSale", };
  public validPattern: boolean = false;

  constructor(public helper: HelperService, private route: ActivatedRoute, private modal: ModalService) {
    }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.types = params['documentType'];
    });
  }

  onGetFiles(event) {
    this.totalFiles[event.idx]= event.event;
    console.log('onGetFiles: ', this.totalFiles, this.totalFiles.length)
  }

  removeItem(event){
    //this.totalFiles.sort()
    //this.totalFiles.splice(event.idx, 1);
    this.totalFiles[event.idx] = null;
    console.log('Después',this.totalFiles);
    console.log(this.totalFiles);
  }
  setHabilitar(): void{
    this.habilitar = (this.habilitar == true) ? true: true
  }
  setDesabilitar(): void{
    this.habilitar = (this.habilitar == false) ? false: false
  }

  onBlur($event) {
    $event.currentTarget.value = $event.currentTarget.value.trim();
    this.valueChanges.emit();
  }

  onBlurValidate($event) {
    this.validateChange.emit();
  }

  emitChanges($event) {
    this.valueChanges.emit();
  }

  setRef1Valid(value: boolean) {
    this.isRef1Valid = value;
  }

  setRef2Valid(value: boolean) {
    this.isRef2Valid = value;
  }

  setAddressValid(value: boolean) {
    this.isAddressValid = value;
  }

  setAcceptTerms(value: boolean) {
    this.isTermAccepted = value;
  }

  handleSubmit() {
    this.requestClientStudy.emit();
    this.requestClientStudy2.emit();
  }

  updateDeptRequired($event) {
    this.deptRequired = true ? $event == "C" : false;
  }

  getDocumentSelector(document:any){
    this.validPattern = document.patternValid;
    if(document && document.documentType && document.document){
      this.clientStudyForm.sellerDocumentNumber = document.patternValid ? document.document : '';
      this.clientStudyForm.sellerDocumentType = document.documentType;
    }
  }

  get isLowerAmount() {
    return this.clientStudyForm.quotaValue as any < 1;
  }

  openModal(){
    this.modalUploadFile = true;
  }
  closeModal(){
    this.modalUploadFile = false;
  }
  back() {
    this.activeContent = '';
    this.refresh();
  }
  refresh() {
    this.helper.refresh('/pages/administracion/usuarios');
  }
}
