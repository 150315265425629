import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ReportsService } from '@services/reports.service';
import {
  ReportResponse,
  ReportAffiliateResponse,
  YearReportResponse,
} from '@models/reports.model';
import { ModalService } from '@services/modal.service';
import * as moment from 'moment';
import { UsersService } from '@services/users.service';
import { PermissionModel } from '@models/permission.model';
import { CONSTANTS } from '@config/constants';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'app-tab-reports',
  templateUrl: './tab-reports.component.html',
  styleUrls: ['./tab-reports.component.scss'],
})
export class TabReportsComponent implements OnInit {
  public reportInfo: ReportResponse;
  public quotaAssigned: number = 0;
  public quotaUsed: number = 0;
  public quotaAvailable: number = 0;

  public quotaValueAssigned: number = 0;
  public quotaValueAvailable: number = 0;
  public quotaValueUsed: number = 0;

  public reportAffiliateResponse: ReportAffiliateResponse;
  public affiliateTransactionTotal: number = 0;
  public affiliateTransactionLegal: number = 0;
  public affiliateTransactionNatural: number = 0;

  public affiliateGuaranteeTotal: number = 0;
  public affiliateGuaranteeLegal: number = 0;
  public affiliateGuaranteeNatural: number = 0;

  public yearReportResponse: YearReportResponse;

  public buyersPrev: number;
  public buyersCurrent: number;
  public guaranteePrev: number;
  public guaranteeCurrent: number;
  public transactionsPrev: number;
  public transactionsCurrent: number;

  public prevYear: number;
  public currentYear: number;
  public permissions: Array<PermissionModel>;
  public constants = CONSTANTS;
  public donwloadQuotaReport = 'A';

  public dataUsersCards = [
    {
      img: 'assets/img/reports/img-1.png',
      name: 'Manuel Alvarez Camargo',
      doc_type: 'CC',
      doc: 123456,
      consult: 123,
      transactions: 345,
      amount: 123123,
    },
    {
      img: 'assets/img/reports/img-2.png',
      name: 'Daniela Castro Alvarez',
      doc_type: 'CC',
      doc: 123456,
      consult: 123,
      transactions: 345,
      amount: 123123,
    },
    {
      img: 'assets/img/reports/img-3.png',
      name: 'Roberto Díaz Gonzalez',
      doc_type: 'CC',
      doc: 123456,
      consult: 123,
      transactions: 345,
      amount: 123123,
    },
  ];

  constructor(
    private auth: AuthService,
    public spinner: NgxSpinnerService,
    private reportsService: ReportsService,
    public modal: ModalService,
    private usersService: UsersService
  ) {}

  ngOnInit() {
    this.permissions = this.usersService.permissions;
    const permissionStatus = this.permissions.filter(
      (permission) => permission.idOption === '19'
    );
    if (permissionStatus.length > 0) {
      this.donwloadQuotaReport = permissionStatus[0].status;
    }

    this.getReportInfo();
    this.getAffiliatesReport();
    this.prevYear = moment().subtract(1, 'year').year();
    this.currentYear = moment().year();
    this.getYearReport();
  }

  getReportInfo() {
    this.spinner.show();
    this.reportsService.getReport().subscribe(
      (reportInfo: ReportResponse) => {
        this.reportInfo = reportInfo;
        this.quotaAssigned = reportInfo.numberOfAllocatedSpaces;
        this.quotaUsed = reportInfo.quantityOfSpacesUsed;
        this.quotaAvailable = reportInfo.numberOfUnusedSpaces;

        this.quotaValueAvailable = reportInfo.unusedSpacesValue;
        this.quotaValueUsed = reportInfo.availableValueUsed;
        this.quotaValueAssigned = reportInfo.valueOfAssignedSpaces;

        this.spinner.hide();
      },
      (err: any) => {
        this.spinner.hide();
        console.error(err);
      }
    );
  }

  getAffiliatesReport() {
    this.spinner.show();
    this.reportsService.getAffiliatesReport().subscribe(
      (affiliatesReportInfo: ReportAffiliateResponse) => {
        this.reportAffiliateResponse = affiliatesReportInfo;
        this.affiliateTransactionTotal =
          affiliatesReportInfo.affiliateTransactionTotal;
        this.affiliateTransactionLegal =
          affiliatesReportInfo.affiliateTransactionLegal;
        this.affiliateTransactionNatural =
          affiliatesReportInfo.affiliateTransactionNatural;

        this.affiliateGuaranteeTotal =
          affiliatesReportInfo.affiliateGuaranteeTotal;
        this.affiliateGuaranteeLegal =
          affiliatesReportInfo.affiliateGuaranteeLegal;
        this.affiliateGuaranteeNatural =
          affiliatesReportInfo.affiliateGuaranteeNatural;

        this.spinner.hide();
      },
      (err: any) => {
        this.spinner.hide();
        console.error(err);
      }
    );
  }

  getYearReport() {
    this.spinner.show();
    this.reportsService
      .getYearReport(this.prevYear, this.currentYear)
      .subscribe(
        (yearReportInfo: YearReportResponse) => {
          this.yearReportResponse = yearReportInfo;

          this.buyersPrev = yearReportInfo ? yearReportInfo.buyersPrev : 0;
          this.buyersCurrent = yearReportInfo
            ? yearReportInfo.buyersCurrent
            : 0;
          this.guaranteePrev = yearReportInfo
            ? yearReportInfo.guaranteePrev
            : 0;
          this.guaranteeCurrent = yearReportInfo
            ? yearReportInfo.guaranteeCurrent
            : 0;
          this.transactionsPrev = yearReportInfo
            ? yearReportInfo.transactionsPrev
            : 0;
          this.transactionsCurrent = yearReportInfo
            ? yearReportInfo.transactionsCurrent
            : 0;

          this.spinner.hide();
        },
        (err: any) => {
          this.spinner.hide();
          console.error(err);
        }
      );
  }

  downloadReportExcel() {
    (window as any).gtag('event', 'descarga-reporte-cupos', {
      event_category: 'reportes-graficos',
      event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
    });
    this.spinner.show();
    this.reportsService.exportReport().subscribe(
      (res: any) => {
        const blob = new Blob([res], {
          type: 'application/ms-excel',
        });
        saveAs(blob, `reporteCupos.xlsx`);
        this.spinner.hide();
      },
      (err: any) => {
        this.spinner.hide();
        this.modal.show('error', {
          title: `No se pudo realizar la descarga.`,
          message: 'Por favor intente más tarde',
        });
      }
    );
  }
}
