import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { Owner } from '@models/owner.model';
import { HelperService } from '@services/helper.service';
import { ClientStudyRequest } from '@models/clientStudyRequest.model';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-owner-information-form',
  templateUrl: './owner-information-form.component.html',
  styleUrls: ['./owner-information-form.component.scss'],
})
export class OwnerInformationFormComponent implements OnInit {
  @Input() clientStudyForm: ClientStudyRequest;
  @Input() documentTypesOptions: Array<any>;
  @Output()
  valueChanges: EventEmitter<object> = new EventEmitter<object>();
  @Output() formValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('formReference') formReference: NgForm;

  public validatePattern: RegExp[] = [];

  constructor(public helper: HelperService) {}

  copyInfo() {
    this.clientStudyForm.owners[0].documentType =
      this.clientStudyForm.legalRepresentativeDocumentType;
    this.clientStudyForm.owners[0].documentNumber =
      this.clientStudyForm.legalRepresentativeDocumentNumber;
    this.clientStudyForm.owners[0].firstLastName =
      this.clientStudyForm.legalRepresentativeFistLastName;
    this.clientStudyForm.owners[0].firstName =
      this.clientStudyForm.legalRepresentativeFirstName;
    this.clientStudyForm.owners[0].secondName =
      this.clientStudyForm.legalRepresentativeSecondName;
    this.clientStudyForm.owners[0].secondLastName =
      this.clientStudyForm.legalRepresentativeSecondLastName;
  }

  onBlur($event) {
    $event.currentTarget.value = $event.currentTarget.value.trim();
    this.valueChanges.emit();
  }

  add() {
    const owner: Owner = {
      documentType: '',
      documentNumber: '',
      firstName: '',
      secondName: '',
      firstLastName: '',
      secondLastName: '',
    };
    this.clientStudyForm.owners.push(owner);
  }

  delete(i: number) {
    this.clientStudyForm.owners.splice(i, 1);
    this.valueChanges.emit();
  }

  ngOnInit() {
    if (this.clientStudyForm.owners.length === 0) {
      this.add();
    }
  }

  ngDoCheck() {
    this.formValidate();
  }

  formValidate() {
    const isFormValid = this.formReference.valid;
    this.formValid.emit(isFormValid);
  }

  valueDataChange(documentType: string, idx:number) {
    this.validatePattern[idx] = this.helper.validatePatternDocument(documentType);
  }
}
