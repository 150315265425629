import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Input,
} from "@angular/core";
import { NgModel } from "@angular/forms";
@Directive({
  selector: "[appSelectLabel]",
})
export class SelectLabelDirective {
  @Input() value: any;

  constructor(private el: ElementRef, public model: NgModel) {}

  @HostListener("click", ["$event.target"]) onClick(event) {
    let options = this.el.nativeElement.options.length;
    for (let i = 0; i < options; i++) {
      this.el.nativeElement.options[i].style["fontSize"] = "10px";
      this.el.nativeElement.options[i].label = this.changeLabel(
        this.el.nativeElement.options[i].label
      );
    }
    // const initalValue = this.el.nativeElement.value;
    // const val = initalValue.replace(/[^0-9]*/g, '');
    // this.el.nativeElement.value = val;
    // if (initalValue !== this.el.nativeElement.value) {
    //   event.stopPropagation();
    // }
    // this.model.viewToModelUpdate(val);
  }

  changeLabel(label: String): String {
    switch (label) {
      case "C. CIUDADANIA":
        return "C.C.";
      case "C. EXTRANJERIA":
        return "C.E.";
      case "C.C.":
        return "C. CIUDADANIA";
      case "C.E.":
        return "C. EXTRANJERIA";
      default:
        return label;
    }
  }
}
