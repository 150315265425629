import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ClientStudyRequest } from '@app/models/clientStudyRequest.model';
import { ClientStudyResponse } from '@app/models/clientStudyResponse.model';
import { UploadDocumentsService } from '@app/services/upload-documents.service';
import { ModelEmailLoadDocumentsRequest, tagsMailsModel } from '@app/models/emailLoadDocuments.model';
import { ReturnDocumentsLoadFlag } from '@app/models/flagLoadDocuments.model';
import { ModalService } from '@services/modal.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { __spreadArrays } from 'tslib';

interface FileData {
  id?: any,
  source?: string,
  user?: string,
  documentType?: any,
  document?: any,
  originalName?: string,
  creationDate?: any,
  category?: string,
  infoFile?: string,
  originalPath?: any,
  originalFinalName?: any,
  resourceId?: any
}
@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnChanges {

  public files: File[] = [];
  public stateFiles: File[] = [];
  public mb = 1024 * 1024;
  public maxFileSize = 10 * this.mb;
  public fileSelected: File[];
  public totalFiles: Array<File[]>
  public actualDate = new Date();
  
  @Input() objFileTypes: Array<any>;
  @Input() response: any;
  @Input() enableButton: boolean = false;
  @Input() quotaValue: number = 0;
  @Input() fileParams: Array<any>;
  @Input() indexElement: number;
  @Input('clientStudy') clientStudyForm: ClientStudyRequest;
  @Input() clientStudyResponse: ClientStudyResponse;
  @Output() chargeResult: EventEmitter<File[]> = new EventEmitter();
  @Output() numberDocuments: EventEmitter<number> = new EventEmitter();
  @Output() close: EventEmitter<File[]> = new EventEmitter();
  @Output() removeFileEvent: EventEmitter<number> = new EventEmitter();
  @Output() fileArray: EventEmitter<boolean[]> = new EventEmitter();
  @Output() confirm: EventEmitter<object> = new EventEmitter();
  
  fileData: FileData;
  fileDataMail: ModelEmailLoadDocumentsRequest;
  promiseList = [];
  dataFileList = [];
  filesUp: boolean[] = Array(1).fill(false);
  opc = 0;
  infoFile: any;
  checked: boolean = false;
  numberFiles: boolean = false;
  count = 0;
  uploadedDocs = 0;

  constructor(
    public modalService: ModalService,
    public toastr: ToastrService,
    public spinner: NgxSpinnerService,
    private uploadDocumentsService: UploadDocumentsService
  ) {}

  get getName() : string {
    const { companyName, clientType, legalRepresentativeFirstName, legalRepresentativeFistLastName} = this.clientStudyForm;
    return clientType === "legal" ? companyName.toUpperCase() : legalRepresentativeFirstName ? `${legalRepresentativeFirstName.toUpperCase()} ${legalRepresentativeFistLastName.toUpperCase()}` : 'N/A'
  }

  chargeDoc(event) {
    this.chargeResult.emit(event);
  }

  createArrayFile(objectFile: any, idx: number) {
    this.uploadedDocs += 1;
    this.numberFiles = true
    this.promiseList[idx] = (this.toBase64(objectFile.params))
    this.dataFileList[idx] = (objectFile.params)
    this.uploadedFile(idx)
  }

  onRemove(event, idx) {
    this.removeFileEvent.emit(idx)
    let position = this.promiseList.indexOf(event)

    if (this.promiseList.length === 0) return 
    else {
      if (position == -1) {
        this.promiseList.splice(idx, 1, '');
        this.dataFileList.splice(idx, 1, '');
      } else {
        this.promiseList.splice(position, idx, '');
        this.dataFileList.splice(position, idx, '');
      }
    }
    this.uploadedDocs -= 1;
  }

  async sendInfo(event?): Promise<void> {
    let longitud = [];
    for (let index = 0; index < this.objFileTypes.length; index++) {
      if (
        "MENSAJE_INTERNO" in this.response &&
        this.response["MENSAJE_INTERNO "].length >=
        this.objFileTypes[index].lengthMsg
      ) {
        longitud.push(this.objFileTypes[index].name);
      }
    }
    let csf = this.clientStudyForm;
    this.spinner.show();
    if (!this.checked) {
      if (this.promiseList.length === 0) return
      else {
        Promise.all(this.promiseList).then((filesBase64) => {
          filesBase64.forEach((file, index) => {
            if (this.opc === 0) {
              let fileData = {
                id: null,
                source: "CovifacturaWeb",
                documentType: csf.clientType === "legal" ? "N" : csf.sellerDocumentType,
                document: csf.sellerDocumentNumber,
                originalPath: null,
                originalFinalName: this.dataFileList[index].name,
                resourceId: this.clientStudyResponse.code,
                user:
                  csf.clientType === "legal"
                    ? csf.companyName
                    : csf.legalRepresentativeFirstName +
                      " " +
                      csf.legalRepresentativeFistLastName,
                infoFile: file.split(",")[1],
                category: "SOLICITUD CUPO",
                originalName: this.dataFileList[index].name.split('.')[0],
              }
              this.uploadDocumentsService.sendDocuments(fileData).subscribe({
                next: (response: any) => {
                  console.log(response);
                },
                complete: () => {
                  this.count = this.count + 1;
                  if (this.lengthDocuments() == this.count) {
                    this.sendUploadDocumentsFlag();
                  }
                },
                error: (err) => {
                  this.spinner.hide();
                  this.toastr.error(err.error.message, "", {
                    timeOut: 4000,
                  });
                },
              });
            } else if (this.opc == 1) window.location.reload();
          });
        });
      }
    } else {
      this.sendInfoMail();
    }
  }

  sendInfoMail() {
    let longitud = [];

    for (let index = 0; index < this.objFileTypes.length; index++) {
      if (
        this.response["MENSAJE_INTERNO "].length >=
        this.objFileTypes[index].lengthMsg
      ) {
        longitud.push(this.objFileTypes[index].name);
      }
      else if (this.opc == 0) this.opc = 2;
    }
    
    //TODO: nuevo ajuste
    
    const fileDataMail: ModelEmailLoadDocumentsRequest = new ModelEmailLoadDocumentsRequest();

    fileDataMail.asunto = "Cargue de documentos";
    fileDataMail.destinatarios = ["jeison.munoz@covinoc.com", "elver.acosta@covinoc.com", this.clientStudyForm.email];
    //fileDataMail.destinatarios = [this.clientStudyForm.email, "claudia.grau@covinoc.com", "juan.rodriguezc@covinoc.com"];
    fileDataMail.idPlantilla = 69418;
    fileDataMail.tagsCorreo = [
      new tagsMailsModel("Nombre", this.getName),
      new tagsMailsModel("DOC1", longitud.join(' <br> ')),
      new tagsMailsModel("ID_RECURSO", 'http://desarrollo.covinoc.com:9097/#/upload-file/' + this.clientStudyResponse.code + '/' + longitud.length),
      //new tagsMailsModel("ID_RECURSO", 'https://clientes.covifactura.com/#/upload-file/' + this.clientStudyResponse.code + '/' + longitud.length),
    ]

    this.useEmailService(fileDataMail, 2);
  }

  ngOnDestroy() {
    this.modalService.close();
  }

  uploadedFile(idx) {
    this.numberDocuments.emit(idx)
  }

  accept() {
    this.confirm.emit();
    this.close.emit();
    window.location.reload();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.quotaValue && changes.quotaValue !== undefined) {
      try {
        if (changes.quotaValue.previousValue != changes.quotaValue.currentValue) {
          if (this.clientStudyForm.quotaValue > 20000000 && this.clientStudyForm.quotaValue <= 50000000) {
            this.filesUp = Array(1).fill(false);
          } else if (this.clientStudyForm.quotaValue > 50000000 && this.clientStudyForm.quotaValue <= 100000000) {
            this.filesUp = Array(2).fill(false);
          } else if (this.clientStudyForm.quotaValue > 100000000) {
            this.filesUp = Array(3).fill(false);
          }
          this.fileArray.emit(this.filesUp)
        }
      } catch (error) { 
        console.error(error);
      }
    }
  }

  async toBase64(file: File) {
    const base64 = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
    return base64;
  }

 /*  decodeUTF8(string: string) {
    return string ? decodeURIComponent(escape(string)) : "";
  } */

  useEmailService(dataEmail: ModelEmailLoadDocumentsRequest, opc: number) {
    this.spinner.show();
    this.uploadDocumentsService.sendMails(dataEmail).subscribe({
      next: (response: any) => {
        console.log(response);
      },
      complete: () => {
        this.spinner.hide();
        this.opc = opc;
      },
      error: (err) => {
        this.spinner.hide();
        console.error(err);
        this.toastr.error(err.error.message, "", {
          timeOut: 4000,
        });
      },
    });
  }

  lengthDocuments(): number {
    let numberOfDocs: number = 0;
    this.objFileTypes.forEach((file) => {
      if (this.response["MENSAJE_INTERNO "].length >= file.lengthMsg) {
        numberOfDocs += 1;
      }
    });
    return numberOfDocs;
  }

  sendUploadDocumentsFlag() {
    const flagBody = {
      radicado: this.clientStudyResponse.code,
      numeroDocumentos: this.lengthDocuments(),
    };

    this.uploadDocumentsService.saveDocumentUploadFlag(flagBody).subscribe({
      next: (response: ReturnDocumentsLoadFlag) => {
        console.log(response);
      },
      complete: () => {
        this.spinner.hide();
        this.opc = 1;
      },
      error: (err) => {
        this.spinner.hide();
        console.error(err);
      }
    });
  }
}
