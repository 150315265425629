import {
  TransactionsReportRequest,
  TransactionsReportResponse,
} from '@models/transactionsReport.model';
import { ReportsService } from '@services/reports.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { HelperService } from '@services/helper.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalService } from '@services/modal.service';
import { PaginationComponent } from '@components/layout/pagination/pagination.component';
import * as moment from 'moment';
import { UsersService } from '@services/users.service';
import { PermissionModel } from '@models/permission.model';
import { CONSTANTS } from '@config/constants';
import { AuthService } from '@services/auth.service';
@Component({
  selector: 'app-tab-transactions-report',
  templateUrl: './tab-transactions-report.component.html',
  styleUrls: ['./tab-transactions-report.component.scss'],
})
export class TabTransactionsReportComponent implements OnInit {
  @ViewChild('paginationComponent') paginationComponent: PaginationComponent;
  public totalPages = 1;
  public pageLimit = 6;
  public minInput: Date = moment().startOf('year').subtract(1, 'year').toDate();
  public reports: Array<TransactionsReportResponse>;
  public reportsPaginated: Array<TransactionsReportResponse>;
  public currentPageA = 0;

  public reportRequest: TransactionsReportRequest = {
    endDate: moment().toDate(),
    startDate: moment().subtract(7, 'days').toDate(),
  };

  public permissions: Array<PermissionModel>;
  public constants = CONSTANTS;
  public donwloadReport = 'A';

  constructor(
    private auth: AuthService,
    public helper: HelperService,
    private spinner: NgxSpinnerService,
    private reportsService: ReportsService,
    public modal: ModalService,
    private usersService: UsersService
  ) {}

  ngOnInit() {
    this.transactionsReport(1);
    this.permissions = this.usersService.permissions;
    const permissionStatus = this.permissions.filter(
      (permission) => permission.idOption === '21'
    );
    if (permissionStatus.length > 0) {
      this.donwloadReport = permissionStatus[0].status;
    }
  }

  pageSelected({ currentPage }) {
    this.transactionsReport(currentPage, true);
    this.currentPageA = currentPage - 1;
  }

  search(dateRange: any) {
    if (dateRange[0]) {
      this.reportRequest.startDate = dateRange[0];
    }
    if (dateRange[1]) {
      this.reportRequest.endDate = dateRange[1];
    }
    if (dateRange[0] && dateRange[1]) {
      this.transactionsReport(1);
    }
  }

  onSearch(documentNumber: string) {
    (window as any).gtag('event', 'busqueda-de-compradores', {
      event_category: 'reportes-transacciones',
      event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
    });
    if (documentNumber && documentNumber.length < 1) {
      this.reportRequest.buyerDocumentNumber = documentNumber || '0';
      this.transactionsReport(1);
      return;
    }
    this.reportRequest.buyerDocumentNumber = documentNumber;
    this.transactionsReport(1);
  }

  transactionsReport(currentPage: number, isPaginating: boolean = false) {
    this.spinner.show();
    this.reportsPaginated = [];
    if (isPaginating) {
      this.reportsPaginated = this.reports.slice(currentPage * this.pageLimit - this.pageLimit, currentPage * this.pageLimit);
      this.spinner.hide();
    } else {
      this.reportsService.getTransactionsReport(this.reportRequest).subscribe(
        (report: Array<TransactionsReportResponse>) => {
          this.reports = report;
          // this.totalPages =
          //   this.reports.length > 0
          //     ? Math.ceil(
          //         parseInt(this.reports[0].numberRecords, 10) / this.pageLimit
          //       )
          //     : 1;
          this.totalPages = this.helper.getAmountOfPages(this.reports, this.pageLimit);
          this.reportsPaginated = this.reports.slice(currentPage * this.pageLimit - this.pageLimit, currentPage * this.pageLimit);
          this.spinner.hide();
        },
        (err: any) => {
          console.error(err);
          this.spinner.hide();
        }
      );
    }
  }

  downloadTransactionsExcel() {
    (window as any).gtag('event', 'descarga-reporte-transacciones', {
      event_category: 'reportes-transacciones',
      event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
    });
    this.spinner.show();
    this.reportsService.exportTransactions(this.reportRequest).subscribe(
      (res: any) => {
        const blob = new Blob([res], {
          type: 'application/ms-excel',
        });
        saveAs(blob, `reporteTransacciones.xlsx`);
        this.spinner.hide();
      },
      (err: any) => {
        this.spinner.hide();
        this.modal.show('error', {
          title: `No se pudo realizar la descarga.`,
          message: 'Por favor intente más tarde',
        });
      }
    );
  }

  noPay() {
    this.modal.show('nopayConfirm');
  }

  downloadExcel() {
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 2000);
  }
}
