export class City {
  id: string;
  value: string;

  public static fromResponse(json: any): City {
    const city: City = {
      id: json['ID'],
      value: json['LABEL'],
    };
    return city;
  }
}
