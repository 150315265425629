export class ClientStudyResponse {
  responseType: number;
  approvedQuota?: number;
  errorModalText?: string;
  code?: string;
  dates?: string;
  consecutive?: string;

  public static fromResponse(json: any): ClientStudyResponse {
    const clientStudyResponse: ClientStudyResponse = {
      responseType: 'RAZON ' in json ? parseInt(json['RAZON ']) : 3,
      approvedQuota: json['ESTUDIO_CUPO '] ? parseFloat(json['CUPO']) : 0,
      errorModalText: json['MENSAJE_EXTERNO '] || '',
      code: json['ESTUDIO_CUPO '] || '',
      dates: json['FECHAS_CUOTAS '] || '',
      consecutive: json['VALOR_CUOTAS '] || '',
    };
    return clientStudyResponse;
  }
}
