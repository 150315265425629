import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'app-experience',
  templateUrl: './experience.component.html',
  styleUrls: ['./experience.component.scss'],
})
export class ExperienceComponent implements OnInit {

  public closeX: boolean = true;
  public isTextWhite: boolean = false;
  public valueDataModal : boolean = false;
  @Output() opened: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private router: Router, private auth: AuthService) {}

  ngOnInit() {
    this.isTextWhite = (this.router.url.includes('login') || this.router.url.includes('contrasena')) ? true : false
  }
  closeModal(){
    this.valueDataModal = !this.valueDataModal;
    this.closeX = true;
    this.opened.emit(true);
  }
  emitLastModal(event : boolean){
    this.closeX = event;
  }
}
