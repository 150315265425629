import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ModalService {
  public modal = {
    transaction: false,
    transactionConfirm: false,
    transactionCheckConfirm: false,
    reserveConfirm: false,
    transactionSuccess: false,
    transactionSuccessThreepayments: false,
    transactionDenied: false,
    transactionLocked: false,
    requestStatus: false,
    requestSuspendedStatus: false,
    requestThrepayments: false,
    availableQuota: false,
    logOut: false,
    fileUpload: false,
    modalUploadFile: false,
    modalUploadMailFile: false,
    // Client study
    requestApproved: false,
    requestDenied: false,
    requestSettled: false,
    // Operations
    operationsConfirm: false,
    operationsSuccess: false,
    operationsDenied: false,
    modalLogoutErrorComponent: false,
    // Claims
    claimConfirm: false,
    claimSuccess: false,
    // Agreements
    paymentAgreementConfirm: false,
    paymentAgreementSuccess: false,
    paymentSuccess: false,
    paymentConfirm: false,
    // Administration
    pdfBill: false,
    modalConfigureProfileImg: false,
    // Users
    userDisable: false,
    userEnable: false,
    userDelete: false,
    userDisableSuccess: false,
    userEnableSuccess: false,
    userDeleteSuccess: false,
    userUpdateSuccess: false,
    userAddSuccess: false,
    // Profile
    profileChangePassword: false,
    profileChangePasswordSuccess: false,
    profileUpdateSuccess: false,
    // Others
    error: false,
    reservationSuccess: false,
    nopayConfirm: false,
    notRegistered: false,
    invalidCode: false,
    // Login
    forceUpdatePassword: false
  };

  public payload: any;

  get propModal() {
    return this.modal;
  }

  get payloadModal() {
    return this.payload;
  }

  show(modalName?: string, payload?: any) {
    if (!modalName) {
      return;
    }
    this.modal[modalName] = true;
    this.payload = payload;
  }

  close() {
    for (const property in this.modal) {
      if (property) {
        this.modal[property] = false;
      }
    }
  }
}
