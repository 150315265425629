import { SubjectService } from "@services/subject.service";
import { NgForm } from "@angular/forms";
import { ChangePasswordRequest } from "@models/changePassword.model";
import { NgxSpinnerService } from "ngx-spinner";
import { HelperService } from "@services/helper.service";
import { ToastrService } from "ngx-toastr";
import { ModalService } from "@services/modal.service";
import { UserPhotoRequest, UserPhotoResponse } from "@models/userPhoto.model";
import { Component, OnInit, ViewChild, ChangeDetectorRef } from "@angular/core";
import { ProfileService } from "@services/profile.service";
import { SellersRequest } from "@models/sellersRequest.model";
import { SellersResponse } from "@models/sellersResponse.model";
import { BirthdayRequest } from "@models/birthday.model";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "@services/auth.service";

import * as _moment from "moment";
import { CONSTANTS } from "@app/config/constants";
const moment = (_moment as any).default ? (_moment as any).default : _moment;

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit {
  public sellersRequest: SellersRequest = {
    idContry: "1",
    documentType: "",
    documentNumber: "",
    search: "",
    page: 1,
    registers: 3,
  };

  @ViewChild("formProfile") formProfile: NgForm;

  public userBirthday: BirthdayRequest = new BirthdayRequest();
  public uploadPhotoRequest: UserPhotoRequest = new UserPhotoRequest();
  public sellersResponse: SellersResponse = new SellersResponse();

  public birthdayFromService: Date;
  public profileImgBase64: string;
  public min = new Date(1930, 1, 1);
  public max = new Date();
  public isChangePassword = false;
  public constants = CONSTANTS;

  constructor(
    private auth: AuthService,
    private profileService: ProfileService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public helper: HelperService,
    private cdRef: ChangeDetectorRef,
    private subject: SubjectService,
    private modal: ModalService,
    public activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.isChangePassword =
      !!this.activatedRoute.snapshot.queryParamMap.get("changePassword");
    if (this.isChangePassword) {
      this.showModalChangePassword();
    }
    this.sellers();
  }

  dateTextChange($event) {
    this.sellersResponse.birthday = moment(
      $event.target.value,
      "DD/MM/YYYY"
    ).toDate();
  }

  sellers() {
    this.profileService.getSellers(this.sellersRequest).subscribe(
      (profile: SellersResponse) => {
        const birthday = moment(profile.birthday, "DD/MM/YYYY").format(
          "MM-DD-YYYY"
        );
        profile.birthday = new Date(birthday);
        this.birthdayFromService = new Date(birthday);
        this.sellersResponse = profile;
        this.subject.sendProfileImg(this.sellersResponse.userPhoto);
      },
      (err: any) => {
        console.error();
      }
    );
  }

  updateUser() {
    (window as any).gtag('event', 'guardar-cambios', {
      event_category: 'actualizacion-perfil',
      event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
    });
    if (
      !moment(this.birthdayFromService).isSame(this.sellersResponse.birthday)
    ) {
      this.updateUserBirthday();
    }
    if (this.uploadPhotoRequest.photo) {
      this.uploadUserPhoto();
    }
  }

  updateUserBirthday() {
    this.spinner.show();
    const { email, cellphone, birthday } = this.sellersResponse;
    this.userBirthday.userDate = birthday;
    this.userBirthday.cellPhone = cellphone;
    if (this.userBirthday.cellPhone && this.userBirthday.cellPhone.length >= 3 && this.userBirthday.cellPhone.length <=10  ) {
      this.profileService.userBirthday(this.userBirthday).subscribe(
        (resp: any) => {
          this.toastr.success("Perfil actualizado.", "", {
            timeOut: 3000,
          });
        },
        (err: any) => {
          console.error(err);
          this.toastr.error("No se pudo actualizar.", "", {
            timeOut: 3000,
          });
        },
        () => {
          this.spinner.hide();
        }
      );
    }
    else{
      this.toastr.error("Campo incompleto.", "", {
        timeOut: 3000,
      });
      this.spinner.hide();
    }
  }

  selectedProfileImg(userPhoto: File) {
    this.uploadPhotoRequest.photo = userPhoto;
    this.cdRef.detectChanges();
  }

  base64Img(base64: any) {
    (window as any).gtag('event', 'cambio-imagen', {
      event_category: 'actualizacion-perfil',
      event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
    });
    this.profileImgBase64 = base64;
  }

  uploadUserPhoto() {
    this.spinner.show();
    this.profileService.uploadUserPhoto(this.uploadPhotoRequest).subscribe(
      (url: UserPhotoResponse) => {
        if (url) {
          this.modal.show("profileUpdateSuccess");
          this.subject.sendProfileImg(this.profileImgBase64);
        }
      },
      (err: any) => {
        console.error(err);
        this.sellers();
        this.modal.show("error", {
          title: "No se pudo cargar la imagen.",
          message: "Por favor intente más tarde.",
        });
        this.helper.refresh("/pages/administracion/perfil");
      },
      () => {
        this.spinner.hide();
      }
    );
  }

  showModalChangePassword() {
    (window as any).gtag('event', 'cambiar-contrasena', {
      event_category: 'actualizacion-perfil',
      event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
    });
    this.modal.show("profileChangePassword");
  }

  changePassword(changePassRequest: ChangePasswordRequest) {
    (window as any).gtag('event', 'nueva-contrasena', {
      event_category: 'actualizacion-perfil',
      event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
    });
    this.profileService.changePassword(changePassRequest).subscribe(
      (resp: any) => {
        if (this.isChangePassword) {
          this.router.navigateByUrl("/pages/transacciones");
        } else {
          (window as any).gtag('event', 'contrasena-actualizada', {
            event_category: 'actualizacion-perfil',
            event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
          });
          this.modal.show("profileChangePasswordSuccess");
        }
      },
      (err: any) => {
        console.error(err);
        this.modal.show("error", {
          title: "No se pudo cambiar la contraseña.",
          message: "Por favor intente más tarde.",
        });
      }
    );
  }

  refresh() {
    this.uploadPhotoRequest.photo = "";
    this.formProfile.form.markAsPristine();
  }
}
