import { Component, OnInit, ViewChild } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { HelperService } from "@services/helper.service";
import { ModalService } from "@services/modal.service";
import { NgForm } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { SubjectService } from "@app/services/subject.service";
import { MultimediaService } from "@app/services/multimedia.service";

@Component({
  selector: "app-transactions",
  templateUrl: "./transactions.component.html",
  styleUrls: ["./transactions.component.scss"],
})
export class TransactionsComponent implements OnInit {
  @ViewChild("content") content: any;
  public displayStyle = "none";
  public userName: string;
  public numberTransactions: number;
  public afifiliateName: string;
  public cityOffice: string;
  public documentType: string = "N";
  public money = 123456789;

  public DataSelectFake = [
    { value: 1, description: "Opción 1" },
    { value: 2, description: "Opción 2" },
    { value: 3, description: "Opción 3" },
  ];

  public data1 = {
    tipo_documento: "",
    documento: "",
    codigo: "",
  };
  image: any;
  responseData: any;
  imageData:any;

  // Forms
  // @ViewChild('formCheck') formCheck: NgForm;

  constructor(
    private spinner: NgxSpinnerService,
    public helper: HelperService,
    private router: Router,
    public toastr: ToastrService,
    private modal: ModalService,
    public subject: SubjectService,
    private multimediaService: MultimediaService
  ) {
    this.ngOnInit();
  }
  validatePopUp() {
    this.subject.headerCollapse(true);
    let popUpChristhmast = localStorage.getItem("popUpChristhmas");
    if (popUpChristhmast && this.responseData && this.imageData != null) {
      this.displayStyle = "block"
    } 
/*     console.log('soy chir',popUpChristhmast )
    console.log('soy activepop',this.responseData )
    console.log('soy imageData',this.imageData ) */

    localStorage.removeItem("popUpChristhmas");
  }

  ngOnInit() {
    this.multimediaService.getPopUp().subscribe({
      next: (response: Response) => {
        let attr = response["data"][0].attributes
        this.image =
          this.multimediaService.urlApi +
          attr["imgPopup1"]["data"]["attributes"]["url"];
        this.responseData = attr["activePopup1"];
        /* console.log('soy activepop', this.responseData) */
        this.imageData = attr["imgPopup1"].data; 
        this.validatePopUp();
      },
      complete: () => {},
      error: () => {},
    });
  }

  closePopup() {
    this.displayStyle = "none";
  }

  ngOnDestroy() {
    this.subject.headerCollapse(false);
  }

  transaction() {
    this.modal.show("transactionSuccess");
  }
}
