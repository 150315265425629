import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appSpecialCharac]'
})
export class OnlySpecialCharacDirective {
  constructor(private el: ElementRef) { }


  @HostListener('input', ['$event']) onInputChange(event) {
    const initalValue = this.el.nativeElement.value;
    this.el.nativeElement.value = initalValue.replace(/[ ^]/g, "");
    if (initalValue !== this.el.nativeElement.value) {
      event.stopPropagation();
    }
    this.el.nativeElement.value = this.el.nativeElement.value;
  }
}
