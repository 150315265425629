import { Component, OnDestroy, Output, EventEmitter } from '@angular/core';
import { ModalService } from '@services/modal.service';

@Component({
  selector: 'app-modal-request-approved',
  templateUrl: './modal-request-approved.component.html',
  styleUrls: ['./modal-request-approved.component.scss'],
})
export class ModalRequestApprovedComponent implements OnDestroy {
  @Output() finish: EventEmitter<object> = new EventEmitter<object>();

  constructor(public modal: ModalService) {}

  ngOnDestroy() {
    this.modal.close();
  }

  accept() {
    this.modal.close();
    this.finish.emit();
  }
}
