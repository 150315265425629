import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserAvailableSpaceRequest } from '@models/userAvailableSpaceRequest.model';
import { UserModel } from '@models/user.model';
import { UserAvailableSpaceResponse } from '@models/userAvailableSpaceResponse.model';
import { TransactionRequest } from '@models/transactionRequest.model';
import { EmailMassiveTransactionRequest } from '@models/emailMassiveTransactionRequest.model';
import { MassiveTransactionRequest } from '@models/massiveTransactionRequest.model';
import { MassiveExportTransactionRequest } from '@models/massiveExportTransactionRequest.model';
import { MassiveRequest } from '@models/massiveRequest.model';
import { MassiveRecordResponse } from '@models/massiveRecordResponse.model';
import { DocumentFile } from '@models/documentFile.model';
import { ClientStudyRequest } from '@models/clientStudyRequest.model';
import { ClientStudyResponse } from '@models/clientStudyResponse.model';
import { TransactionDue } from '@models/transactionDue.model';
import { Owner } from '@models/owner.model';
import { CONSTANTS } from '@config/constants';
import { CounterpartKnowledgeRequest } from '@app/models/counterpartKnowledgeRequest.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TransactionService {
  private urlApi: string;
  public userToken: any;
  public identity: UserModel;
  private urlApiDSRL: string

  constructor(private http: HttpClient) {
    this.urlApi = environment.urlApi;
    // this.urlApiDSRL = environment.urlApiDSRL;
    this.readToken();
    this.getIdentity();
  }

  readToken() {
    if (localStorage.getItem('tokenCF')) {
      this.userToken = localStorage.getItem('tokenCF');
    } else {
      this.userToken = '';
    }
    return this.userToken;
  }

  getIdentity() {
    return (this.identity = UserModel.fromResponse(
      JSON.parse(localStorage.getItem('user'))
    ));
  }

  checkQuota(userAvailableSpaceRequets: UserAvailableSpaceRequest) {
    userAvailableSpaceRequets.documentNumber = this.identity.userInfo.document;
    userAvailableSpaceRequets.documentType =
      this.identity.userInfo.documentType;
    const headers = new HttpHeaders().set('token', this.userToken);
    const options = {
      params: UserAvailableSpaceRequest.toRequest(userAvailableSpaceRequets),
      headers,
    };

    return this.http.get(`${this.urlApi}/pr_cupo_v8`, options).pipe(
      map((resp: any) => {
        return UserAvailableSpaceResponse.fromResponse(resp);
      })
    );
  }

  createTransaction(transactionRequest: TransactionRequest) {
    transactionRequest.username = this.identity.username;
    const headers = new HttpHeaders().set('token', this.userToken);
    const options = {
      params: TransactionRequest.toRequest(transactionRequest),
      headers,
    };
    const transactionDues = JSON.stringify(
      transactionRequest.transactionDues.map((transactionDue: TransactionDue) =>
        TransactionDue.toRequest(transactionDue)
      )
    );
    return this.http
      .post(
        `${this.urlApi}/pr_transaccion_v3?StrJArray=${transactionDues}`,
        {},
        options
      )
      .pipe(
        map((resp: any) => {
          if (resp['RAZON'] !== 0) {
            return {
              error: true,
              message: resp['MENSAJE'],
            };
          }
          return { error: false, approvalCode: resp['CLAVE'], message: resp['MENSAJE'] };
        })
      );
  }

  sendThreePaymentsTransaction(transactionRequest: TransactionRequest) {
    transactionRequest.username = this.identity.username;
    const headers = new HttpHeaders().set('token', this.userToken);
    const options = {
      params: TransactionRequest.toThreePaymentsRequest(transactionRequest),
      headers,
    };
    return this.http.get(`${this.urlApi}/pr_solicitud_trespagos`, options).pipe(
      map((resp: any) => {
        const clientStudyResponse: ClientStudyResponse =
          ClientStudyResponse.fromResponse(resp);
        return clientStudyResponse;
      })
    );
  }

  requestClientStudy(clientStudyRequest: ClientStudyRequest) {
    clientStudyRequest.documentNumber = this.identity.userInfo.document;
    clientStudyRequest.documentType = this.identity.userInfo.documentType;
    clientStudyRequest.userEmail = this.identity.username;
    const headers = new HttpHeaders().set('token', this.userToken);
    const options = {
      params: ClientStudyRequest.toRequest(clientStudyRequest),
      headers,
    };
    const ownerArray = JSON.stringify(
      clientStudyRequest.owners.map((owner: Owner) => Owner.toRequest(owner))
    );
    const parsedArray =
      clientStudyRequest.clientType === CONSTANTS.CLIENT_STUDY.LEGAL &&
      clientStudyRequest.companyType === CONSTANTS.CLIENT_STUDY.SOCIETY
        ? `?StrJArray=${ownerArray}`
        : '';
    return this.http
      .post(`${this.urlApi}/pr_solicitud_cupo${parsedArray}`, {}, options)
      .pipe(
        map((resp: any) => {
          const clientStudyResponse: ClientStudyResponse =
            ClientStudyResponse.fromResponse(resp);
          return {clientStudyResponse:clientStudyResponse, response:resp};
        })
      );
  }
  requestClientStudy2(clientStudyRequest: ClientStudyRequest) {
    clientStudyRequest.documentNumber = this.identity.userInfo.document;
    clientStudyRequest.documentType = this.identity.userInfo.documentType;
    clientStudyRequest.userEmail = this.identity.username; 
    const headers = new HttpHeaders().set('token', this.userToken);
    const options = {
      params: ClientStudyRequest.toRequest(clientStudyRequest),
      headers,
    };
    const ownerArray = JSON.stringify(
      clientStudyRequest.owners.map((owner: Owner) => Owner.toRequest(owner))
    );
    const parsedArray =
      clientStudyRequest.clientType === CONSTANTS.CLIENT_STUDY.LEGAL &&
      clientStudyRequest.companyType === CONSTANTS.CLIENT_STUDY.SOCIETY
        ? `?StrJArray=${ownerArray}`
        : '';
    return this.http
      .get(`${this.urlApi}/pr_solicitud_cupo${parsedArray}`)
      .pipe(
        map((resp: any) => {
          const clientStudyResponse: ClientStudyResponse =
            ClientStudyResponse.fromResponse(resp);
          return clientStudyResponse;
        })
      );
  }



  requestCounterpartKnowledge(
    documentType: string,
    document: number,
    counterpartKnowledgeRequest: CounterpartKnowledgeRequest,
    countryId: number = 1) {

    let params = {
      tipo_documento: documentType,
      documento: document + '',
      idPais: countryId + '',
    }
    const headers = new HttpHeaders().set('token', this.userToken);
    const options = {
      params: params,
      headers,
    };
    return this.http
      .post(`${this.urlApi}/pr_conocimiento_contraparte`, CounterpartKnowledgeRequest.toRequest(counterpartKnowledgeRequest), options)
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  requestThreePayments(clientStudyRequest: ClientStudyRequest) {
    clientStudyRequest.documentNumber = this.identity.userInfo.document;
    clientStudyRequest.documentType = this.identity.userInfo.documentType;
    clientStudyRequest.userEmail = this.identity.username;
    const headers = new HttpHeaders().set('token', this.userToken);
    const options = {
      params: ClientStudyRequest.toThreePaymentsRequest(clientStudyRequest),
      headers,
    };
    const ownerArray = JSON.stringify(
      clientStudyRequest.owners.map((owner: Owner) => Owner.toRequest(owner))
    );
    const parsedArray =
      clientStudyRequest.clientType === CONSTANTS.CLIENT_STUDY.LEGAL &&
      clientStudyRequest.companyType === CONSTANTS.CLIENT_STUDY.SOCIETY
        ? `?StrJArray=${ownerArray}`
        : '';
    return this.http
      .get(`${this.urlApi}/pr_solicitud_trespagos${parsedArray}`, options)
      .pipe(
        map((resp: any) => {
          const clientStudyResponse: ClientStudyResponse =
            ClientStudyResponse.fromResponse(resp);
          return clientStudyResponse;
        })
      );
  }

  validateForm(clientStudyRequest: ClientStudyRequest) : Observable<boolean>{
    clientStudyRequest.documentNumber = this.identity.userInfo.document;
    clientStudyRequest.documentType = this.identity.userInfo.documentType;
    clientStudyRequest.userEmail = this.identity.username;
    const headers = new HttpHeaders().set('token', this.userToken);
    const options = {
      params: ClientStudyRequest.toValidateRequest(clientStudyRequest),
      headers,
    };
    return this.http
      .post<boolean>(`${this.urlApi.replace("DSRL","ST")}/pr_validar_solicitud`, {}, options)
      .pipe(
        map((resp: any) => {
          let error = false;
          if (
            'EXISTE_COMPRADOR' in resp &&
            resp['EXISTE_COMPRADOR'] ===
              CONSTANTS.CLIENT_STUDY.BUYER_WITH_ASSIGNED_SPACE
          ) {
            error = true;
          }
          if (resp['RADICADO']) {
            clientStudyRequest.filingNumber = resp['RADICADO'];
          }
          if ('FORMULARIO' in resp) {
            ClientStudyRequest.saveFormToClientStudyRequest(
              clientStudyRequest,
              resp['FORMULARIO'][0],
              resp['FORMULARIO_SOCIOS'],
              resp['RADICADO']
            );
          } else {
            ClientStudyRequest.resetForm(clientStudyRequest);
          }
          return error;
        })
      );
  }

  saveForm(clientStudyRequest: ClientStudyRequest) {
    clientStudyRequest.documentNumber = this.identity.userInfo.document;
    clientStudyRequest.documentType = this.identity.userInfo.documentType;
    clientStudyRequest.userEmail = this.identity.username;
    const headers = new HttpHeaders().set('token', this.userToken);
    const options = {
      params: ClientStudyRequest.toSaveForm(clientStudyRequest),
      headers,
    };
    const ownerArray = JSON.stringify(
      clientStudyRequest.owners.map((owner: Owner) => Owner.toRequest(owner))
    );
    const parsedArray =
      clientStudyRequest.clientType === CONSTANTS.CLIENT_STUDY.LEGAL &&
      clientStudyRequest.companyType === CONSTANTS.CLIENT_STUDY.SOCIETY
        ? `?StrJArray=${ownerArray}`
        : '';
    return this.http
      .post(`${this.urlApi.replace("DSRL","ST")}/pr_almacenar_solicitud${parsedArray}`, {}, options)
      .pipe(
        map((resp: any) => {
          return resp.RADICADO;
        })
      );
  }

  emailMassiveUpload(file: File) {
    const emailMassiveTransactionRequest: EmailMassiveTransactionRequest = {
      idContry: '1',
      documentType: this.identity.userInfo.documentType,
      documentNumber: this.identity.userInfo.document,
      userName: this.identity.username,
    };
    const headers = new HttpHeaders().set('token', this.userToken);
    const options = {
      params: EmailMassiveTransactionRequest.toRequest(
        emailMassiveTransactionRequest
      ),
      headers,
    };

    const formData = new FormData();
    formData.append('file', file);

    return this.http
      .post(`${this.urlApi}/pr_carga_masiva_correo`, formData, options)
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  massiveUpload(file: File) {
    const massiveTransactionRequest: MassiveTransactionRequest = {
      idContry: '1',
      documentType: this.identity.userInfo.documentType,
      documentNumber: this.identity.userInfo.document,
      userName: this.identity.username,
      check: '1',
    };
    const headers = new HttpHeaders().set('token', this.userToken);
    const options = {
      params: MassiveTransactionRequest.toRequest(massiveTransactionRequest),
      headers,
    };

    const formData = new FormData();
    formData.append('file', file);

    return this.http
      .put(`${this.urlApi}/pr_carga_masiva`, formData, options)
      .pipe(
        map((resp: any) => {
          const massiveRequets: MassiveRequest = MassiveRequest.fromResponse(
            resp.CARGA
          );
          return massiveRequets;
        })
      );
  }

  massiveVerify(massiveRegistersRequest: MassiveRequest, check: Boolean) {
    const massiveTransactionRequest: MassiveTransactionRequest = {
      idContry: '1',
      documentType: this.identity.userInfo.documentType,
      documentNumber: this.identity.userInfo.document,
      userName: this.identity.username,
      check: check ? '1' : '0',
    };
    const headers = new HttpHeaders().set('token', this.userToken);
    headers.set('Content-Type', 'application/json');
    const options = {
      params: MassiveTransactionRequest.toRequest(massiveTransactionRequest),
      headers,
    };
    const body = check
      ? MassiveRequest.toVerify(massiveRegistersRequest)
      : MassiveRequest.toSend(massiveRegistersRequest);

    return this.http.put(`${this.urlApi}/pr_carga_masiva`, body, options).pipe(
      map((resp: any) => {
        if (check) {
          const massiveRequets: MassiveRequest = MassiveRequest.fromResponse(
            resp.CARGA
          );
          return massiveRequets;
        } else {
          const massiveRequets: Array<MassiveRecordResponse> = resp['CARGA']
            ? resp['CARGA'].map((correctRecord) =>
                MassiveRecordResponse.fromResponse(correctRecord)
              )
            : [];
          return massiveRequets;
        }
      })
    );
  }

  exportMassiveResult(settled: string, status: string) {
    const massiveExportTransactionRequest: MassiveExportTransactionRequest = {
      idContry: '1',
      documentType: this.identity.userInfo.documentType,
      documentNumber: this.identity.userInfo.document,
      settled,
      status,
    };
    const headers = new HttpHeaders().set('token', this.userToken);
    const options = {
      params: MassiveExportTransactionRequest.toRequest(
        massiveExportTransactionRequest
      ),
      headers,
    };

    return this.http.get(`${this.urlApi}/pr_exportar_carga_masiva`, {
      headers: options.headers,
      params: options.params,
      responseType: 'blob',
    });
  }

  //   public downloadFile(response: any, fileName?: string) {
  //     const blob = new Blob([response.body], { type: response.headers.get('content-type') });
  //     fileName = fileName || response.headers.get('content-disposition').split(';')[0];
  //     const file = new File([blob], fileName, { type: response.headers.get('content-type') });
  //     saveAs(file);
  // }

  isDocumentFiles() {
    let documentFilesString = JSON.parse(localStorage.getItem('documentFiles'));
    if (documentFilesString) {
      let documentFiles: Array<DocumentFile> = [];
      documentFilesString.forEach((documentFile) => {
        documentFiles.push(DocumentFile.fromResponse(documentFile));
      });
      return documentFiles;
    }
    return null;
  }

  getDocumentFiles() {
    this.getIdentity();
    const headers = new HttpHeaders().set('token', this.userToken);
    const options = { headers };
    return this.http.get(`${this.urlApi}/listado_documentos`, options).pipe(
      map((resp: any) => {
        localStorage.setItem('documentFiles', JSON.stringify(resp.DOCUMENTOS));
        return resp.DOCUMENTOS;
      })
    );
  }

  getProductsByUser(): any {
    let user = JSON.parse(localStorage.getItem("user"));
    const headers = new HttpHeaders().set('token', this.userToken);
    headers.set('Content-Type', 'application/json');
    const options = {
      params: {
        tipo_documento: user.userInfo.TIPO_DOCUMENTO,
        documento: user.userInfo.DOCUMENTO,
        id_pais: '1'
      },
      headers,
    };

    return this.http.get(`${this.urlApi.replace("DSRL","ST")}/pr_productos_usuario`, options).pipe(
      map((resp: any) => {
        const response = resp.PRODUCTOS;
        return response;
      })
    );
  }
}
