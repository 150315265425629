import { Injectable } from '@angular/core';
import { UserModel } from '@models/user.model';
import { PermissionModel } from '@models/permission.model';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { UsersRequest } from '@models/usersRequest.model';
import { UsersResponse } from '@models/usersResponse.model';
import { AddUserRequest } from '@models/addUserRequest.model';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private urlApi: string;
  public userToken: any;
  public identity: UserModel;
  public permissions: Array<PermissionModel>;
  public quotaStudy: boolean;
  public quotaReport: boolean;

  constructor(private http: HttpClient) {
    this.urlApi = environment.urlApi;
    this.readToken();
    this.getIdentity();
    this.getPermisions();
  }

  readToken() {
    if (localStorage.getItem('tokenCF')) {
      this.userToken = localStorage.getItem('tokenCF');
    } else {
      this.userToken = '';
    }
    return this.userToken;
  }

  getIdentity() {
    return (this.identity = UserModel.fromResponse(
      JSON.parse(localStorage.getItem('user'))
    ));
  }

  getPermisions() {
    const permissions = localStorage.getItem('permissions');
    if (permissions) {
      const parsedPermissions = JSON.parse(permissions);
      this.permissions = parsedPermissions.MENU.map((permission) =>
        PermissionModel.fromResponse(permission)
      );
      this.quotaStudy =
        parsedPermissions.ESTUDIO_CUPO &&
        parsedPermissions.ESTUDIO_CUPO === 'SI';
      this.quotaReport =
        parsedPermissions.REPORTE_CUPOS &&
        parsedPermissions.REPORTE_CUPOS === 'SI';
    }
  }

  getUsers(usersRequest: UsersRequest) {
    const { userInfo, username } = this.identity;
    usersRequest.documentNumber = userInfo.document;
    usersRequest.documentType = userInfo.documentType;
    const headers = new HttpHeaders().set('token', this.userToken);
    const options = {
      params: UsersRequest.toRequest(usersRequest),
      headers,
    };

    return this.http.get(`${this.urlApi}/pr_usuarios_v3`, options).pipe(
      map((resp: any) => {
        let users: Array<UsersResponse> = [];
        users =
          resp['USUARIOS'] && resp['USUARIOS'].length > 0
            ? resp['USUARIOS'].map((user: UsersResponse) =>
                UsersResponse.fromResponse(user)
              )
            : [];
        return users;
      })
    );
  }

  adminUser(addUserRequest: AddUserRequest) {
    const { userInfo, username } = this.identity;
    addUserRequest.documentNumber = userInfo.document;
    addUserRequest.documentType = userInfo.documentType;
    addUserRequest.cellphone = addUserRequest.cellphone || 0;
    addUserRequest.newPass = addUserRequest.newPass || '';
    // if(!addUserRequest.newPass) {
    //   delete addUserRequest.newPass;
    // }
    addUserRequest.user = username;
    const headers = new HttpHeaders().set('token', this.userToken);
    const options = {
      params: AddUserRequest.toRequest(addUserRequest),
      headers,
    };

    return this.http
      .post(`${this.urlApi}/pr_administrar_usuario_v4`, {}, options)
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }
}
