export class Question {
    questionId: number;
    questionDescription: string;
    starQuestion: boolean;
    emojiQuestion: boolean;
    openedQuestion: boolean;
    obligatory: boolean;
    uniqueResponse: boolean;
    questionsOrder: number;
    activeQuestion: boolean;
}
  