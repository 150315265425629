import { Component, OnInit, Input } from '@angular/core';
import { SubjectService } from '@services/subject.service';

@Component({
  selector: 'app-tooltip-icon',
  templateUrl: './tooltip-icon.component.html',
  styleUrls: ['./tooltip-icon.component.scss']
})
export class TooltipIconComponent implements OnInit {

  @Input() message: string;
  @Input() displayIcon = true;

  constructor(private subject: SubjectService) { }

  ngOnInit() {
  }

  enter() {
    this.subject.sendText(this.message);
  }

  leave() {
    this.subject.sendText('');
  }
}

