import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
})
export class HelpComponent implements OnInit {

  public isTextWhite: boolean = false;

  constructor(private router: Router, private auth: AuthService) {}

  ngOnInit() {
    this.isTextWhite = (this.router.url.includes('login') || this.router.url.includes('contrasena')) ? true : false;
  }

  get userName() : string {
    if ('identity' in this.auth) {
      const {documentType, document} = this.auth.identity.userInfo;
      return `${documentType}:${document}:1;${this.auth.identity.username}`;
    }
    return '';
  }

  openWhatsapp() {
    (window as any).gtag('event', 'ayuda-sac', {
      event_category: 'con-login',
      event_label: this.userName,
    });
    window.open(
      'https://covinoc.com/w/?telefono=573187116639&utm_source=COVIFACTURA&utm_medium=WEB&texto=&utm_content=boton_web&plantilla=WEB&utm_term=COVIFACTURA&utm_campaign=COVIFACTURA_WEB&campaign=COVIFACTURA_WEB',
      '_newtab'
    );
  }
}
