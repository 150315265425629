import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import { ModalService } from '@services/modal.service';

@Component({
  selector: 'app-modal-user-add-success',
  templateUrl: './modal-user-add-success.component.html',
  styleUrls: ['./modal-user-add-success.component.scss'],
})
export class ModalUserAddSuccessComponent implements OnDestroy {
  @Output() confirm: EventEmitter<object> = new EventEmitter();

  constructor(public modal: ModalService) {}

  ngOnDestroy() {
    this.modal.close();
  }

  accept() {
    this.confirm.emit();
    this.modal.close();
  }
}
