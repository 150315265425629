import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthNitService {
  public urlApiNit: string
  constructor(private http: HttpClient) {
    this.urlApiNit = environment.urlApiNit;
  }

  validatedNit(number?: any) {
    return this.http.get(`${this.urlApiNit}/covi-utils/generals/verificationNumber?nit=${number}`);
  }
}
