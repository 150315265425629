import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MassiveRecordResponse } from '@models/massiveRecordResponse.model';
import { CONSTANTS } from '@config/constants';
import { NoticeService } from '@services/notice.service';
import { PubSubService } from '@services/pubsub.service';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'app-massive-nopay-status',
  templateUrl: './massive-nopay-status.component.html',
  styleUrls: ['./massive-nopay-status.component.scss'],
})
export class MassiveNopayStatusComponent implements OnInit {
  @Output() operationType: EventEmitter<string> = new EventEmitter<string>();
  @Input() massiveResponse: Array<MassiveRecordResponse>;
  public massiveSucces: Array<MassiveRecordResponse>;
  public massiveError: Array<MassiveRecordResponse>;

  constructor(
    private auth: AuthService,
    public router: Router,
    public spinner: NgxSpinnerService,
    public notice: NoticeService,
    private pubSubService: PubSubService
  ) {}

  ngOnInit() {
    this.pubSubService.sendMessage(true);
    this.massiveError = this.massiveResponse.filter(
      (massiveRecord: MassiveRecordResponse) =>
        massiveRecord.observation !=
        CONSTANTS.TRANSACTIONS.NOTIFICATION_SUCCESSFUL
    );
    this.massiveSucces = this.massiveResponse.filter(
      (massiveRecord: MassiveRecordResponse) =>
        massiveRecord.observation ==
        CONSTANTS.TRANSACTIONS.NOTIFICATION_SUCCESSFUL
    );
  }

  back() {
    this.operationType.emit('');
  }

  downloadTransactions(status: string) {
    if(status === 'Negada') {
      (window as any).gtag('event', 'descarga-negados', {
        event_category: 'aviso-masivo',
        event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
      });
    } else {
      (window as any).gtag('event', 'descarga-trans-exitosa', {
        event_category: 'aviso-masivo',
        event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
      });
    }
    const settled =
      this.massiveSucces.length > 0
        ? this.massiveSucces[0].radication
        : this.massiveError.length > 0
        ? this.massiveError[0].radication
        : '0';
    this.spinner.show();
    this.notice.exportMassiveResult(settled, status).subscribe(
      (res: any) => {
        const blob = new Blob([res], {
          type: 'application/ms-excel',
        });
        saveAs(blob, `${settled}-${status}` + '.xlsx');
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }
}
