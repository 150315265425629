import { CONSTANTS } from '@config/constants';
export class ReportRequest {
  documentType: string;
  documentNumber: string;

  public static toRequest(reportRequest: ReportRequest): any {
    return {
      id_pais: 1,
      tipo_documento: reportRequest.documentType,
      documento: reportRequest.documentNumber,
    };
  }
}

export class ReportResponse {
  numberOfAllocatedSpaces: number;
  numberOfUnusedSpaces: number;
  quantityOfSpacesUsed: number;
  valueOfAssignedSpaces: number;
  unusedSpacesValue: number;
  valueAvailableForUse: number;
  availableValueUsed: number;

  public static fromResponse(json: any): ReportResponse {
    const reportResponse: ReportResponse = {
      numberOfAllocatedSpaces: parseInt(json['CANTIDAD_CUPOS_ASIGNADOS']),
      numberOfUnusedSpaces: parseInt(json['CANTIDAD_CUPOS_SIN_UTILIZAR']),
      quantityOfSpacesUsed: parseInt(json['CANTIDAD_CUPOS_UTILIZADOS']),
      valueOfAssignedSpaces: parseInt(json['VALOR_CUPOS_ASIGNADOS']),
      unusedSpacesValue: parseInt(json['VALOR_CUPOS_SIN_UTILIZAR']),
      valueAvailableForUse: parseInt(json['VALOR_DISPONIBLE_POR_UTILIZAR']),
      availableValueUsed: parseInt(json['VALOR_DISPONIBLE_UTILIZADO']),
    };
    return reportResponse;
  }
}
export class YearReportResponse {
  buyersPrev: number;
  buyersCurrent: number;
  guaranteePrev: number;
  guaranteeCurrent: number;
  transactionsPrev: number;
  transactionsCurrent: number;

  public static fromResponse(
    json: any,
    prevYear: number,
    currentYear: number
  ): YearReportResponse {
    const reportResponse: YearReportResponse = {
      buyersPrev: parseInt(json[`COMPRADORES_${prevYear}`]),
      buyersCurrent: parseInt(json[`COMPRADORES_${currentYear}`]),
      guaranteePrev: parseInt(json[`GARANTIA_${prevYear}`]),
      guaranteeCurrent: parseInt(json[`GARANTIA_${currentYear}`]),
      transactionsPrev: parseInt(json[`TRANSACCIONES_${prevYear}`]),
      transactionsCurrent: parseInt(json[`TRANSACCIONES_${currentYear}`]),
    };
    return reportResponse;
  }
}
export class ReportAffiliateResponse {
  affiliateTransactionTotal: number;
  affiliateTransactionLegal: number;
  affiliateTransactionNatural: number;
  affiliateGuaranteeTotal: number;
  affiliateGuaranteeLegal: number;
  affiliateGuaranteeNatural: number;

  public static fromResponse(json: any): ReportAffiliateResponse {
    const legalValue = json.find((arrayValue) => {
      return (
        arrayValue.TIPO_PERSONA &&
        arrayValue.TIPO_PERSONA === CONSTANTS.REPORTS.LEGAL
      );
    });
    const naturalValue = json.find((arrayValue) => {
      return (
        arrayValue.TIPO_PERSONA &&
        arrayValue.TIPO_PERSONA === CONSTANTS.REPORTS.NATURAL
      );
    });
    const totalValue = json.find((arrayValue) => {
      return !arrayValue.TIPO_PERSONA;
    });
    let affiliateTransactionTotal = parseInt(totalValue['TOTAL_TRANSACCIONES']);
    let affiliateTransactionLegal = parseInt(legalValue['TRANSACCIONES']);
    let affiliateTransactionNatural = parseInt(naturalValue['TRANSACCIONES']);
    let affiliateGuaranteeTotal = parseInt(totalValue['TOTAL_GARANTIAS']);
    let affiliateGuaranteeLegal = parseInt(legalValue['GARANTIAS']);
    let affiliateGuaranteeNatural = parseInt(naturalValue['GARANTIAS']);
    const reportAffiliateResponse: ReportAffiliateResponse = {
      affiliateTransactionTotal,
      affiliateTransactionLegal,
      affiliateTransactionNatural,
      affiliateGuaranteeTotal,
      affiliateGuaranteeLegal,
      affiliateGuaranteeNatural,
    };
    return reportAffiliateResponse;
  }
}
