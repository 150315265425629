export class CounterpartKnowledgeRequest {
  buyerDocumentType: string;
  buyerDocument: number;
  settlement: number;
  publicResources: boolean;
  politicalRecognition: boolean;
  publicPower: boolean;
  administratorsPEP: boolean;
  linksPEP: boolean;
  foreignTransactions: {
    operationType: string;
    description: {
      entityName: string;
      productType: string;
      averageAmount: number;
      coin: string;
      city: string;
      country: string;
    }
  }
    public static toRequest(
        counterpartKnowledgeRequest: CounterpartKnowledgeRequest
        ): any {
      return {
        tipo_documento_comprador: counterpartKnowledgeRequest.buyerDocumentType,
        documento_comprador : counterpartKnowledgeRequest.buyerDocument,
        radicacion: counterpartKnowledgeRequest.settlement,
        recursos_publicos: counterpartKnowledgeRequest.publicResources,
        reconocimiento_politico: counterpartKnowledgeRequest.politicalRecognition,
        poder_publico: counterpartKnowledgeRequest.publicPower,
        administradores_PEP: counterpartKnowledgeRequest.administratorsPEP,
        vinculos_PEP: counterpartKnowledgeRequest.linksPEP,
        transacciones_extranjeria: {
            tipo_operacion: counterpartKnowledgeRequest.foreignTransactions.operationType,
            descripcion: {
                nombre_entidad: counterpartKnowledgeRequest.foreignTransactions.description.entityName,
                tipo_producto: counterpartKnowledgeRequest.foreignTransactions.description.productType,
                monto_promedio: counterpartKnowledgeRequest.foreignTransactions.description.averageAmount,
                moneda: counterpartKnowledgeRequest.foreignTransactions.description.coin,
                ciudad: counterpartKnowledgeRequest.foreignTransactions.description.city,
                pais: counterpartKnowledgeRequest.foreignTransactions.description.country,
                }
            }
        }
    };
}