import * as moment from 'moment';
import { Owner } from './owner.model';
import { CONSTANTS } from '@config/constants';
export class ClientStudyRequest {
  userEmail?: string;
  documentType?: string;
  documentNumber?: string;
  clientType: string;
  ref1TypCompany: string;
  ref2TypCompany: string;
  ref1TypPerson: string;
  ref2TypPerson: string;
  pointsOfSale: boolean;
  modeWP: boolean;
  modeEmail: boolean;
  modeSMS: boolean;
  modePhone: boolean;
  expeditionDate: Date;
  addressType: string;
  addressFirst: string;
  addressSecond: string;
  addressThird: string;
  addressOther: string;
  sellerDocumentType: string;
  sellerDocumentNumber: string;
  checkDigit: string;
  expeditionDepartment: string;
  expeditionCity: string;
  companyType: string;
  firstRefFirstName: string;
  firstRefSecondName: string;
  firstRefFistLastName: string;
  firstRefSecondLastName: string;
  firstRefDepartment: string;
  firstRefCity: string;
  firstRefPhone: string;
  firstRefEmail: string;
  secondRefFirstName: string;
  secondRefSecondName: string;
  secondRefFistLastName: string;
  secondRefSecondLastName: string;
  secondRefDepartment: string;
  secondRefCity: string;
  secondRefPhone: string;
  secondRefEmail: string;
  legalRepresentativeFirstName: string;
  legalRepresentativeSecondName: string;
  legalRepresentativeFistLastName: string;
  legalRepresentativeSecondLastName: string;
  homeDepartment: string;
  homeCity: string;
  cellular: string;
  phone: string;
  email: string;
  birthDate: Date;
  economicActivity: string;
  quotaValue: any;
  companyName: string;
  legalRepresentativeDocumentType: string;
  legalRepresentativeDocumentNumber: string;
  contactPosition: string;
  contactFirstName: string;
  contactSecondName: string;
  contactFirstLastName: string;
  contactSecondLastName: string;
  contactCellular: string;
  contactEmail: string;
  owners?: Array<Owner>;
  contractNumber: string;
  queryCode: string;
  initialDate: string;
  filingNumber?: string;
  scolarShip: string;
  occupationProfess: string;
  productType: String;

  public static saveFormToClientStudyRequest(
    clientStudyRequest: ClientStudyRequest,
    saveForm: any,
    ownersArray: Array<any>,
    filingNumber: string
  ) {
    let expeditionDepartment =
      saveForm.CODIGO_DANE_EXPEDICION && saveForm.CODIGO_DANE_EXPEDICION !== '0'
        ? saveForm.CODIGO_DANE_EXPEDICION.substring(0, 2)
        : '';
    let homeDepartment =
      saveForm.CODIGO_DANE && saveForm.CODIGO_DANE !== '0'
        ? saveForm.CODIGO_DANE
        : '';
    let firstRefDepartment =
      saveForm.CODIGO_DANE_REF_1 && saveForm.CODIGO_DANE_REF_1 !== '0'
        ? saveForm.CODIGO_DANE_REF_1
        : '';
    let secondRefDepartment =
      saveForm.CODIGO_DANE_REF_2 && saveForm.CODIGO_DANE_REF_2 !== '0'
        ? saveForm.CODIGO_DANE_REF_2
        : '';

    if (expeditionDepartment) {
      if (saveForm.CODIGO_DANE_EXPEDICION.length < 5) {
        expeditionDepartment =
          '0' + saveForm.CODIGO_DANE_EXPEDICION.substring(0, 1);
      }
    }
    if (homeDepartment) {
      if (saveForm.CODIGO_DANE.length < 5) {
        homeDepartment = '0' + saveForm.CODIGO_DANE;
      }
    }

    if (firstRefDepartment) {
      if (saveForm.CODIGO_DANE_REF_1.length < 5) {
        firstRefDepartment = saveForm.CODIGO_DANE_REF_1;
      }
    }
    if (secondRefDepartment) {
      if (saveForm.CODIGO_DANE_REF_2.length < 5) {
        secondRefDepartment = saveForm.CODIGO_DANE_REF_2;
      }
    }

    if (saveForm.COMPRADOR_TIPO_DOCUMENTO) {
      clientStudyRequest.clientType =
        saveForm.COMPRADOR_TIPO_DOCUMENTO !== 'N' ? 'natural' : 'legal';
    }
    if (saveForm.I_PUNTO_VENTA) {
      clientStudyRequest.pointsOfSale = saveForm.I_PUNTO_VENTA === 'S';
    }
    if (saveForm.I_PREFIERE_WHATSAPP) {
      clientStudyRequest.modeWP = saveForm.I_PREFIERE_WHATSAPP === 'S';
    }
    if (saveForm.I_PREFIERE_EMAIL) {
      clientStudyRequest.modeEmail = saveForm.I_PREFIERE_EMAIL === 'S';
    }
    if (saveForm.I_PREFIERE_SMS) {
      clientStudyRequest.modeSMS = saveForm.I_PREFIERE_SMS === 'S';
    }
    if (saveForm.I_PREFIERE_LLAMADA) {
      clientStudyRequest.modePhone = saveForm.I_PREFIERE_LLAMADA === 'S';
    }
    if (saveForm.FECHA_EXPEDICION) {
      clientStudyRequest.expeditionDate = moment(
        saveForm.FECHA_EXPEDICION
      ).toDate();
    }
    if (saveForm.TIPO_EMPRESA) {
      clientStudyRequest.companyType = saveForm.TIPO_EMPRESA;
    }
    if (expeditionDepartment) {
      clientStudyRequest.expeditionDepartment = expeditionDepartment;
    }
    if (saveForm.CODIGO_DANE_EXPEDICION) {
      clientStudyRequest.expeditionCity = saveForm.CODIGO_DANE_EXPEDICION;
    }
    if (saveForm.NOMBRE_1_REF_1) {
      clientStudyRequest.firstRefFirstName = saveForm.NOMBRE_1_REF_1;
    }
    if (saveForm.NOMBRE_2_REF_1) {
      clientStudyRequest.firstRefSecondName = saveForm.NOMBRE_2_REF_1;
    }
    if (saveForm.APELLIDO_1_REF_1) {
      clientStudyRequest.firstRefFistLastName = saveForm.APELLIDO_1_REF_1;
    }
    if (saveForm.APELLIDO_2_REF_1) {
      clientStudyRequest.firstRefSecondLastName = saveForm.APELLIDO_2_REF_1;
    }
    if (saveForm.APELLIDO_2_REF_1) {
      clientStudyRequest.firstRefSecondLastName = saveForm.APELLIDO_2_REF_1;
    }
    if (firstRefDepartment) {
      clientStudyRequest.firstRefDepartment = firstRefDepartment;
    }
    if (saveForm.CODIGO_DANE_REF_1) {
      clientStudyRequest.firstRefCity = saveForm.CODIGO_DANE_REF_1;
    }
    if (saveForm.TELEFONO_REF_1 && saveForm.TELEFONO_REF_1 !== '0') {
      clientStudyRequest.firstRefPhone = saveForm.TELEFONO_REF_1;
    }
    if (saveForm.EMAIL_REF_1) {
      clientStudyRequest.firstRefEmail = saveForm.EMAIL_REF_1;
    }
    if (saveForm.NOMBRE_1_REF_2) {
      clientStudyRequest.secondRefFirstName = saveForm.NOMBRE_1_REF_2;
    }
    if (saveForm.NOMBRE_2_REF_2) {
      clientStudyRequest.secondRefSecondName = saveForm.NOMBRE_2_REF_2;
    }
    if (saveForm.APELLIDO_1_REF_2) {
      clientStudyRequest.secondRefFistLastName = saveForm.APELLIDO_1_REF_2;
    }
    if (saveForm.APELLIDO_2_REF_2) {
      clientStudyRequest.secondRefSecondLastName = saveForm.APELLIDO_2_REF_2;
    }
    if (secondRefDepartment) {
      clientStudyRequest.secondRefDepartment = secondRefDepartment;
    }
    if (saveForm.CODIGO_DANE_REF_2) {
      clientStudyRequest.secondRefCity = saveForm.CODIGO_DANE_REF_2;
    }
    if (saveForm.TELEFONO_REF_2 && saveForm.TELEFONO_REF_2 !== '0') {
      clientStudyRequest.secondRefPhone = saveForm.TELEFONO_REF_2;
    }
    if (saveForm.EMAIL_REF_2) {
      clientStudyRequest.secondRefEmail = saveForm.EMAIL_REF_2;
    }
    if (saveForm.NOMBRE_1) {
      clientStudyRequest.legalRepresentativeFirstName = saveForm.NOMBRE_1;
    }
    if (saveForm.NOMBRE_2) {
      clientStudyRequest.legalRepresentativeSecondName = saveForm.NOMBRE_2;
    }
    if (saveForm.APELLIDO_1) {
      clientStudyRequest.legalRepresentativeFistLastName = saveForm.APELLIDO_1;
    }
    if (saveForm.APELLIDO_2) {
      clientStudyRequest.legalRepresentativeSecondLastName =
        saveForm.APELLIDO_2;
    }
    if (homeDepartment) {
      clientStudyRequest.homeDepartment = homeDepartment;
    }
    if (saveForm.CODIGO_DANE) {
      clientStudyRequest.homeCity = saveForm.CODIGO_DANE;
    }
    if (saveForm.CELULAR && saveForm.CELULAR !== '0') {
      clientStudyRequest.cellular = saveForm.CELULAR;
    }
    if (saveForm.TELEFONO && saveForm.TELEFONO !== '0') {
      clientStudyRequest.phone = saveForm.TELEFONO;
    }
    if (saveForm.CORREO) {
      clientStudyRequest.email = saveForm.CORREO;
    }
    if (saveForm.FECHA_NACIMIENTO) {
      clientStudyRequest.birthDate = moment(saveForm.FECHA_NACIMIENTO).toDate();
    }
    if (saveForm.ACTIVIDAD_ECONOMICA) {
      clientStudyRequest.economicActivity = saveForm.ACTIVIDAD_ECONOMICA;
    }
    if (saveForm.CUPO_SOLICITADO && saveForm.CUPO_SOLICITADO !== '1') {
      clientStudyRequest.quotaValue = saveForm.CUPO_SOLICITADO;
    }
    if (saveForm.RAZON_SOCIAL) {
      clientStudyRequest.companyName = saveForm.RAZON_SOCIAL.toUpperCase();
    }
    if (saveForm.TIPO_DOCUMENTO_REPRESENTATE) {
      clientStudyRequest.legalRepresentativeDocumentType =
        saveForm.TIPO_DOCUMENTO_REPRESENTATE;
    }
    if (saveForm.DOCUMENTO_REPRESENTANTE) {
      clientStudyRequest.legalRepresentativeDocumentNumber =
        saveForm.DOCUMENTO_REPRESENTANTE;
    }
    if (saveForm.CARGO_CONTACTO) {
      clientStudyRequest.contactPosition = saveForm.CARGO_CONTACTO;
    }
    if (saveForm.NOMBRE_1_CONTACTO) {
      clientStudyRequest.contactFirstName = saveForm.NOMBRE_1_CONTACTO.toUpperCase();
    }
    if (saveForm.NOMBRE_2_CONTACTO) {
      clientStudyRequest.contactSecondName = saveForm.NOMBRE_2_CONTACTO.toUpperCase();
    }
    if (saveForm.APELLIDO_1_CONTACTO) {
      clientStudyRequest.contactFirstLastName = saveForm.APELLIDO_1_CONTACTO.toUpperCase();
    }
    if (saveForm.APELLIDO_2_CONTACTO) {
      clientStudyRequest.contactSecondLastName = saveForm.APELLIDO_2_CONTACTO.toUpperCase();
    }
    if (saveForm.TELEFONO_CONTACTO && saveForm.TELEFONO_CONTACTO !== '0') {
      clientStudyRequest.contactCellular = saveForm.TELEFONO_CONTACTO;
    }
    if (saveForm.CORREO_CONTACTO) {
      clientStudyRequest.contactEmail = saveForm.CORREO_CONTACTO.toUpperCase();
    }
    if (saveForm.INICIO_ACTIVIDAD) {
      clientStudyRequest.initialDate = saveForm.INICIO_ACTIVIDAD.toUpperCase();
    }
    if (saveForm.TIPO_REF_1) {
      clientStudyRequest.ref1TypPerson =
        saveForm.TIPO_REF_1 === 'COMERCIAL' ? 'legal' : 'natural';
    }
    if (saveForm.TIPO_REF_2) {
      clientStudyRequest.ref2TypPerson =
        saveForm.TIPO_REF_2 === 'COMERCIAL' ? 'legal' : 'natural';
    }
    if (saveForm.TIPO_REF_1) {
      clientStudyRequest.ref1TypCompany =
        saveForm.TIPO_REF_1 === 'COMERCIAL' ? 'legal' : 'natural';
    }
    if (saveForm.TIPO_REF_2) {
      clientStudyRequest.ref2TypCompany =
        saveForm.TIPO_REF_2 === 'COMERCIAL' ? 'legal' : 'natural';
    }
    if (ownersArray) {
      const partnersArray: Array<Owner> = [];
      const partnersForm = ownersArray;
      for (const key in partnersForm) {
        if (partnersForm.hasOwnProperty(key)) {
          const partner = partnersForm[key];
          partnersArray.push({
            documentType: partner.TIPO_DOCUMENTO_SOCIO,
            documentNumber: partner.DOCUMENTO_SOCIO,
            firstName: partner.NOMBRE_1_SOCIO,
            secondName: partner.NOMBRE_2_SOCIO,
            firstLastName: partner.APELLIDO_1_SOCIO,
            secondLastName: partner.APELLIDO_2_SOCIO,
          });
        }
      }
      clientStudyRequest.owners = partnersArray;
    }
    if (filingNumber) {
      clientStudyRequest.filingNumber = filingNumber;
    }
  }

  public static resetForm(clientStudyRequest: ClientStudyRequest) {
    const keysExclude = ['productType', 'clientType','userEmail','documentType','documentNumber','ref1TypPerson','ref2TypPerson', 'sellerDocumentNumber','sellerDocumentType'];
    const keysArray = ['owners'];
    
    for(const key in clientStudyRequest) {
      if (!keysExclude.includes(key) && !keysArray.includes(key)) clientStudyRequest[key] = '';
      if (keysArray.includes(key)) clientStudyRequest[key] = [];
    }
    console.log(clientStudyRequest);
  }

  public static toValidateRequest(clientStudyRequest: ClientStudyRequest): any {
    return {
      id_pais: '1',
      comprador_tipo_documento:
        clientStudyRequest.clientType !== CONSTANTS.CLIENT_STUDY.LEGAL
          ? clientStudyRequest.sellerDocumentType
          : CONSTANTS.CLIENT_STUDY.NIT,
      comprador_documento: clientStudyRequest.sellerDocumentNumber,
      usuario: clientStudyRequest.userEmail,
      tipo_documento: clientStudyRequest.documentType,
      documento: clientStudyRequest.documentNumber,
      tipo_producto: "F",
      pagina: 1,
      registros: 100,
    };
  }
  public static toRequest(clientStudyRequest: ClientStudyRequest): any {
    const isRef2Type = !!clientStudyRequest.secondRefFirstName;
    const clientRequest = {
      usuario: clientStudyRequest.userEmail,
      id_pais: '1',
      tipo_producto: clientStudyRequest.productType ? clientStudyRequest.productType : "F",
      tipo_documento: clientStudyRequest.documentType,
      documento: clientStudyRequest.documentNumber,
      comprador_tipo_documento:
        clientStudyRequest.clientType !== CONSTANTS.CLIENT_STUDY.LEGAL
          ? clientStudyRequest.sellerDocumentType
          : CONSTANTS.CLIENT_STUDY.NIT,
      comprador_documento: clientStudyRequest.sellerDocumentNumber,
      fecha_creacion: '19900101',
      punto_venta: clientStudyRequest.pointsOfSale ? 'S' : 'N',
      canal_whatsapp: clientStudyRequest.modeWP ? 'S' : 'N',
      canal_correo: clientStudyRequest.modeEmail ? 'S' : 'N',
      canal_sms: clientStudyRequest.modeSMS ? 'S' : 'N',
      canal_llamada: clientStudyRequest.modePhone ? 'S' : 'N',
      tipo_empresa: clientStudyRequest.companyType,
      tipo_ref_1:
        clientStudyRequest.clientType !== CONSTANTS.CLIENT_STUDY.LEGAL
          ? clientStudyRequest.ref1TypPerson !== CONSTANTS.CLIENT_STUDY.LEGAL
            ? 'PERSONAL'
            : 'COMERCIAL'
          : clientStudyRequest.ref1TypCompany !== CONSTANTS.CLIENT_STUDY.LEGAL
          ? 'PERSONAL'
          : 'COMERCIAL',
      nombre1_ref_1: clientStudyRequest.firstRefFirstName,
      nombre2_ref_1: clientStudyRequest.firstRefSecondName,
      apellido1_ref_1: clientStudyRequest.firstRefFistLastName,
      apellido2_ref_1: clientStudyRequest.firstRefSecondLastName,
      telefono_ref_1: clientStudyRequest.firstRefPhone,
      correo_ref_1: clientStudyRequest.firstRefEmail,
      lista_cuidad_ref_1: clientStudyRequest.firstRefDepartment,
      nombre1_ref_2: clientStudyRequest.secondRefFirstName,
      nombre2_ref_2: clientStudyRequest.secondRefSecondName,
      apellido1_ref_2: clientStudyRequest.secondRefFistLastName,
      apellido2_ref_2: clientStudyRequest.secondRefSecondLastName,
      nom1_contacto: clientStudyRequest.contactFirstName,
      nom2_contacto: clientStudyRequest.contactSecondName,
      apel1_contacto: clientStudyRequest.contactFirstLastName,
      cel_contacto: clientStudyRequest.contactCellular,
      lista_municipio: clientStudyRequest.homeDepartment,
      telefono_ref_2: clientStudyRequest.secondRefPhone,
      correo_ref_2: clientStudyRequest.secondRefEmail,
      lista_cuidad_ref_2: clientStudyRequest.secondRefCity,
      nombre_empresa: clientStudyRequest.companyName,
      tipo_ref_2: isRef2Type
        ? clientStudyRequest.clientType !== CONSTANTS.CLIENT_STUDY.LEGAL
          ? clientStudyRequest.ref2TypPerson !== CONSTANTS.CLIENT_STUDY.LEGAL
            ? 'PERSONAL'
            : 'COMERCIAL'
          : clientStudyRequest.ref2TypCompany !== CONSTANTS.CLIENT_STUDY.LEGAL
          ? 'PERSONAL'
          : 'COMERCIAL'
        : '',
    };

    if (clientStudyRequest.contactSecondLastName) {
      clientRequest['apel2_contacto'] =
        clientStudyRequest.contactSecondLastName;
    }
    if (clientStudyRequest.contactSecondLastName) {
      clientRequest['apel2_contacto'] =
        clientStudyRequest.contactSecondLastName;
    }
    if (clientStudyRequest.addressType) {
      clientRequest['direccion'] =
        clientStudyRequest.addressType === 'Otra'
          ? clientStudyRequest.addressOther
          : `${clientStudyRequest.addressType} ${clientStudyRequest.addressFirst} ${clientStudyRequest.addressSecond} - ${clientStudyRequest.addressThird}`;
    }
    if (clientStudyRequest.phone) {
      clientRequest['telefono'] = clientStudyRequest.phone;
    }
    if (clientStudyRequest.quotaValue) {
      clientRequest['valor_cupo'] = clientStudyRequest.quotaValue;
    }
    if (clientStudyRequest.expeditionDate) {
      clientRequest['fecha_expedicion'] = moment(
        clientStudyRequest.expeditionDate
      ).format('YYYYMMDD');
    }
    if (clientStudyRequest.expeditionCity) {
      clientRequest['lista_municipio_expedicion'] =
        clientStudyRequest.expeditionCity;
    }
    if (clientStudyRequest.cellular) {
      clientRequest['celular'] = clientStudyRequest.cellular;
    }
    if (clientStudyRequest.email) {
      clientRequest['correo'] = clientStudyRequest.email.toUpperCase();
    }
    if (clientStudyRequest.birthDate) {
      clientRequest['fecha_nacimiento'] = moment(
        clientStudyRequest.birthDate
      ).format('YYYYMMDD');
    }
    if (clientStudyRequest.economicActivity) {
      clientRequest['act_economica'] = clientStudyRequest.economicActivity;
    }
    if (clientStudyRequest.legalRepresentativeDocumentType) {
      clientRequest['tipo_doc_rep'] =
        clientStudyRequest.legalRepresentativeDocumentType;
    }
    if (clientStudyRequest.legalRepresentativeDocumentNumber) {
      clientRequest['doc_rep'] =
        clientStudyRequest.legalRepresentativeDocumentNumber;
    }
    if (clientStudyRequest.legalRepresentativeFirstName) {
      clientRequest['nombres_rep'] = `${
        clientStudyRequest.legalRepresentativeFirstName.replace(/[^a-zA-Z ñÑ\ ]*/g, '').toUpperCase()
      }${
        clientStudyRequest.legalRepresentativeSecondName
          ? ' ' + clientStudyRequest.legalRepresentativeSecondName.replace(/[^a-zA-Z ñÑ\ ]*/g, '').toUpperCase()
          : ''
      }`;
    }
    if (clientStudyRequest.legalRepresentativeFistLastName) {
      clientRequest['apel1_rep'] =
        clientStudyRequest.legalRepresentativeFistLastName.replace(/[^a-zA-Z ñÑ\ ]*/g, '').toUpperCase();
    }
    if (clientStudyRequest.legalRepresentativeSecondLastName) {
      clientRequest['apel2_rep'] =
        clientStudyRequest.legalRepresentativeSecondLastName.replace(/[^a-zA-Z ñÑ\ ]*/g, '').toUpperCase();
    }
    if (clientStudyRequest.contactPosition) {
      clientRequest['cargo_contacto'] = clientStudyRequest.contactPosition.toUpperCase();
    }
    if (clientStudyRequest.contactEmail) {
      clientRequest['correo_contacto'] = clientStudyRequest.contactEmail.toUpperCase();
    }
    if (clientStudyRequest.initialDate) {
      clientRequest['inicio_actividad'] = clientStudyRequest.initialDate;
    }
    if (clientStudyRequest.filingNumber) {
      clientRequest['radicado'] = clientStudyRequest.filingNumber;
    }
    return clientRequest;
  }

  public static toThreePaymentsRequest(
    clientStudyRequest: ClientStudyRequest
  ): any {
    const isRef2Type = !!clientStudyRequest.secondRefFirstName;
    let clientRequest = {
      tipo_transaccion: 1,
      codigo_consulta: clientStudyRequest.queryCode,
      usuario: clientStudyRequest.userEmail,
      id_pais: '1',
      tipo_documento: clientStudyRequest.documentType,
      documento: clientStudyRequest.documentNumber,
      comprador_tipo_documento:
        clientStudyRequest.clientType !== CONSTANTS.CLIENT_STUDY.LEGAL
          ? clientStudyRequest.sellerDocumentType
          : CONSTANTS.CLIENT_STUDY.NIT,
      comprador_documento: clientStudyRequest.sellerDocumentNumber,
      fecha_creacion: '19900101',
      punto_venta: clientStudyRequest.pointsOfSale ? 'S' : 'N',
      canal_whatsapp: clientStudyRequest.modeWP ? 'S' : 'N',
      canal_correo: clientStudyRequest.modeEmail ? 'S' : 'N',
      canal_sms: clientStudyRequest.modeSMS ? 'S' : 'N',
      canal_llamada: clientStudyRequest.modePhone ? 'S' : 'N',
      tipo_empresa: clientStudyRequest.companyType,
      tipo_ref_1:
        clientStudyRequest.clientType !== CONSTANTS.CLIENT_STUDY.LEGAL
          ? clientStudyRequest.ref1TypPerson !== CONSTANTS.CLIENT_STUDY.LEGAL
            ? 'PERSONAL'
            : 'COMERCIAL'
          : clientStudyRequest.ref1TypCompany !== CONSTANTS.CLIENT_STUDY.LEGAL
          ? 'PERSONAL'
          : 'COMERCIAL',
      nombre1_ref_1: clientStudyRequest.firstRefFirstName,
      nombre2_ref_1: clientStudyRequest.firstRefSecondName,
      apellido1_ref_1: clientStudyRequest.firstRefFistLastName,
      apellido2_ref_1: clientStudyRequest.firstRefSecondLastName,
      telefono_ref_1: clientStudyRequest.firstRefPhone,
      correo_ref_1: clientStudyRequest.firstRefEmail,
      lista_cuidad_ref_1: clientStudyRequest.firstRefDepartment,
      nombre1_ref_2: clientStudyRequest.secondRefFirstName ? clientStudyRequest.secondRefFirstName.replace(/[^a-zA-Z ñÑ\ ]*/g, '') : clientStudyRequest.secondRefFirstName,
      nombre2_ref_2: clientStudyRequest.secondRefSecondName ? clientStudyRequest.secondRefSecondName.replace(/[^a-zA-Z ñÑ\ ]*/g, '') : clientStudyRequest.secondRefSecondName,
      apellido1_ref_2: clientStudyRequest.secondRefFistLastName ? clientStudyRequest.secondRefFistLastName.replace(/[^a-zA-Z ñÑ\ ]*/g, '') : clientStudyRequest.secondRefFistLastName,
      apellido2_ref_2: clientStudyRequest.secondRefSecondLastName ? clientStudyRequest.secondRefSecondLastName.replace(/[^a-zA-Z ñÑ\ ]*/g, '') : clientStudyRequest.secondRefSecondLastName,
      nom1_contacto: clientStudyRequest.contactFirstName ? clientStudyRequest.contactFirstName.replace(/[^a-zA-Z ñÑ\ ]*/g, '') : clientStudyRequest.contactFirstName,
      nom2_contacto: clientStudyRequest.contactSecondName ? clientStudyRequest.contactSecondName.replace(/[^a-zA-Z ñÑ\ ]*/g, '') : clientStudyRequest.contactSecondName,
      apel1_contacto: clientStudyRequest.contactFirstLastName ? clientStudyRequest.contactFirstLastName.replace(/[^a-zA-Z ñÑ\ ]*/g, '') : clientStudyRequest.contactFirstLastName,
      cel_contacto: clientStudyRequest.contactCellular,
      lista_municipio: clientStudyRequest.homeDepartment,
      telefono_ref_2: clientStudyRequest.secondRefPhone,
      correo_ref_2: clientStudyRequest.secondRefEmail,
      lista_cuidad_ref_2: clientStudyRequest.secondRefCity,
      nombre_empresa: clientStudyRequest.companyName,
      tipo_ref_2: isRef2Type
        ? clientStudyRequest.clientType !== CONSTANTS.CLIENT_STUDY.LEGAL
          ? clientStudyRequest.ref2TypPerson !== CONSTANTS.CLIENT_STUDY.LEGAL
            ? 'PERSONAL'
            : 'COMERCIAL'
          : clientStudyRequest.ref2TypCompany !== CONSTANTS.CLIENT_STUDY.LEGAL
          ? 'PERSONAL'
          : 'COMERCIAL'
        : '',
    };

    if (clientStudyRequest.contactSecondLastName) {
      clientRequest['apel2_contacto'] =
        clientStudyRequest.contactSecondLastName;
    }
    if (clientStudyRequest.contactSecondLastName) {
      clientRequest['apel2_contacto'] =
        clientStudyRequest.contactSecondLastName;
    }
    if (clientStudyRequest.addressType) {
      clientRequest['direccion'] =
        clientStudyRequest.addressType === 'Otra'
          ? clientStudyRequest.addressOther
          : `${clientStudyRequest.addressType} ${clientStudyRequest.addressFirst} ${clientStudyRequest.addressSecond} - ${clientStudyRequest.addressThird}`;
    }
    if (clientStudyRequest.phone) {
      clientRequest['telefono'] = clientStudyRequest.phone;
    }
    if (clientStudyRequest.quotaValue) {
      clientRequest['valor_cupo'] = clientStudyRequest.quotaValue;
    }
    if (clientStudyRequest.expeditionDate) {
      clientRequest['fecha_expedicion'] = moment(
        clientStudyRequest.expeditionDate
      ).format('YYYYMMDD');
    }
    if (clientStudyRequest.expeditionCity) {
      clientRequest['lista_municipio_expedicion'] =
        clientStudyRequest.expeditionCity;
    }
    if (clientStudyRequest.cellular) {
      clientRequest['celular'] = clientStudyRequest.cellular;
    }
    if (clientStudyRequest.email) {
      clientRequest['correo'] = clientStudyRequest.email;
    }
    if (clientStudyRequest.birthDate) {
      clientRequest['fecha_nacimiento'] = moment(
        clientStudyRequest.birthDate
      ).format('YYYYMMDD');
    }
    if (clientStudyRequest.economicActivity) {
      clientRequest['act_economica'] = clientStudyRequest.economicActivity;
    }
    if (clientStudyRequest.legalRepresentativeDocumentType) {
      clientRequest['tipo_doc_rep'] =
        clientStudyRequest.legalRepresentativeDocumentType;
    }
    if (clientStudyRequest.legalRepresentativeDocumentNumber) {
      clientRequest['doc_rep'] =
        clientStudyRequest.legalRepresentativeDocumentNumber;
    }
    if (clientStudyRequest.legalRepresentativeFirstName) {
      clientRequest['nombres_rep'] = `${
        clientStudyRequest.legalRepresentativeFirstName.replace(/[^a-zA-Z ñÑ\ ]*/g, '').toUpperCase()
      }${
        clientStudyRequest.legalRepresentativeSecondName
          ? ' ' + clientStudyRequest.legalRepresentativeSecondName.replace(/[^a-zA-Z ñÑ\ ]*/g, '').toUpperCase()
          : ''
      }`;
    }
    if (clientStudyRequest.legalRepresentativeFistLastName) {
      clientRequest['apel1_rep'] =
        clientStudyRequest.legalRepresentativeFistLastName.replace(/[^a-zA-Z ñÑ\ ]*/g, '');
    }
    if (clientStudyRequest.legalRepresentativeSecondLastName) {
      clientRequest['apel2_rep'] =
        clientStudyRequest.legalRepresentativeSecondLastName.replace(/[^a-zA-Z ñÑ\ ]*/g, '');
    }
    if (clientStudyRequest.contactPosition) {
      clientRequest['cargo_contacto'] = clientStudyRequest.contactPosition;
    }
    if (clientStudyRequest.contactEmail) {
      clientRequest['correo_contacto'] = clientStudyRequest.contactEmail;
    }
    if (clientStudyRequest.initialDate) {
      clientRequest['inicio_actividad'] = clientStudyRequest.initialDate;
    }
    if (clientStudyRequest.filingNumber) {
      clientRequest['radicado'] = clientStudyRequest.filingNumber;
    }
    return clientRequest;
  }

  public static toSaveForm(clientStudyRequest: ClientStudyRequest): any {
    let clientRequest = {
      usuario: clientStudyRequest.userEmail,
      id_pais: '1',
      tipo_documento: clientStudyRequest.documentType,
      documento: clientStudyRequest.documentNumber,
      comprador_tipo_documento:
        clientStudyRequest.clientType !== CONSTANTS.CLIENT_STUDY.LEGAL
          ? clientStudyRequest.sellerDocumentType
          : CONSTANTS.CLIENT_STUDY.NIT,
      comprador_documento: clientStudyRequest.sellerDocumentNumber,
      radicado: clientStudyRequest.filingNumber || 0,

      fecha_creacion: '19900101',
      punto_venta: clientStudyRequest.pointsOfSale ? 'S' : 'N',
      canal_whatsapp: clientStudyRequest.modeWP ? 'S' : 'N',
      canal_correo: clientStudyRequest.modeEmail ? 'S' : 'N',
      canal_sms: clientStudyRequest.modeSMS ? 'S' : 'N',
      canal_llamada: clientStudyRequest.modePhone ? 'S' : 'N',
      tipo_empresa: clientStudyRequest.companyType,
      tipo_ref_1:
        clientStudyRequest.clientType !== CONSTANTS.CLIENT_STUDY.LEGAL
          ? clientStudyRequest.ref1TypPerson !== CONSTANTS.CLIENT_STUDY.LEGAL
            ? 'PERSONAL'
            : 'COMERCIAL'
          : clientStudyRequest.ref1TypCompany !== CONSTANTS.CLIENT_STUDY.LEGAL
          ? 'PERSONAL'
          : 'COMERCIAL',
      nombre1_ref_1: clientStudyRequest.firstRefFirstName,
      nombre2_ref_1: clientStudyRequest.firstRefSecondName,
      apellido1_ref_1: clientStudyRequest.firstRefFistLastName,
      apellido2_ref_1: clientStudyRequest.firstRefSecondLastName,
      telefono_ref_1: clientStudyRequest.firstRefPhone,
      correo_ref_1: clientStudyRequest.firstRefEmail,
      lista_cuidad_ref_1: clientStudyRequest.firstRefDepartment,
      nombre1_ref_2: clientStudyRequest.secondRefFirstName,
      nombre2_ref_2: clientStudyRequest.secondRefSecondName,
      apellido1_ref_2: clientStudyRequest.secondRefFistLastName,
      apellido2_ref_2: clientStudyRequest.secondRefSecondLastName,
      nom2_contacto: clientStudyRequest.contactSecondName,
      telefono_ref_2: clientStudyRequest.secondRefPhone,
      correo_ref_2: clientStudyRequest.secondRefEmail,
      lista_cuidad_ref_2: clientStudyRequest.secondRefCity,
      tipo_producto: "F",
    };

    if (clientStudyRequest.contactSecondLastName) {
      clientRequest['apel2_contacto'] =
        clientStudyRequest.contactSecondLastName.replace(/[^a-zA-Z ñÑ\ ]*/g, '');
    }
    if (clientStudyRequest.contactFirstName) {
      clientRequest['nom1_contacto'] = clientStudyRequest.contactFirstName.replace(/[^a-zA-Z ñÑ\ ]*/g, '');
    }
    if (clientStudyRequest.contactFirstLastName) {
      clientRequest['apel1_contacto'] = clientStudyRequest.contactFirstLastName.replace(/[^a-zA-Z ñÑ\ ]*/g, '');
    }
    if (clientStudyRequest.contactCellular) {
      clientRequest['cel_contacto'] = clientStudyRequest.contactCellular;
    }
    if (clientStudyRequest.homeCity) {
      clientRequest['lista_municipio'] = clientStudyRequest.homeCity;
    }
    if (clientStudyRequest.companyName) {
      clientRequest['nombre_empresa'] = clientStudyRequest.companyName;
    }
    if (clientStudyRequest.secondRefCity) {
      clientRequest['tipo_ref_2'] =
        clientStudyRequest.clientType !== CONSTANTS.CLIENT_STUDY.LEGAL
          ? clientStudyRequest.ref2TypPerson !== CONSTANTS.CLIENT_STUDY.LEGAL
            ? 'PERSONAL'
            : 'COMERCIAL'
          : clientStudyRequest.ref2TypCompany !== CONSTANTS.CLIENT_STUDY.LEGAL
          ? 'PERSONAL'
          : 'COMERCIAL';
    }
    if (clientStudyRequest.addressType) {
      clientRequest['direccion'] =
        clientStudyRequest.addressType === 'Otra'
          ? clientStudyRequest.addressOther
          : `${clientStudyRequest.addressType} ${clientStudyRequest.addressFirst} ${clientStudyRequest.addressSecond} - ${clientStudyRequest.addressThird}`;
    }
    if (clientStudyRequest.phone) {
      clientRequest['telefono'] = clientStudyRequest.phone;
    }
    if (clientStudyRequest.quotaValue) {
      clientRequest['valor_cupo'] = clientStudyRequest.quotaValue;
    }
    if (clientStudyRequest.expeditionDate) {
      clientRequest['fecha_expedicion'] = moment(
        clientStudyRequest.expeditionDate
      ).format('YYYYMMDD');
    }
    if (clientStudyRequest.expeditionCity) {
      clientRequest['lista_municipio_expedicion'] =
        clientStudyRequest.expeditionCity;
    }
    if (clientStudyRequest.cellular) {
      clientRequest['celular'] = clientStudyRequest.cellular;
    }
    if (clientStudyRequest.email) {
      clientRequest['correo'] = clientStudyRequest.email;
    }
    if (clientStudyRequest.birthDate) {
      clientRequest['fecha_nacimiento'] = moment(
        clientStudyRequest.birthDate
      ).format('YYYYMMDD');
    }
    if (clientStudyRequest.economicActivity) {
      clientRequest['act_economica'] = clientStudyRequest.economicActivity;
    }
    if (clientStudyRequest.legalRepresentativeDocumentType) {
      clientRequest['tipo_doc_rep'] =
        clientStudyRequest.legalRepresentativeDocumentType;
    }
    if (clientStudyRequest.legalRepresentativeDocumentNumber) {
      clientRequest['doc_rep'] =
        clientStudyRequest.legalRepresentativeDocumentNumber;
    }
    if (clientStudyRequest.legalRepresentativeFirstName) {
      clientRequest['nom1_rep'] =
        clientStudyRequest.legalRepresentativeFirstName.replace(/[^a-zA-Z ñÑ\ ]*/g, '');
    }
    if (clientStudyRequest.legalRepresentativeSecondName) {
      clientRequest['nom2_rep'] =
        clientStudyRequest.legalRepresentativeSecondName.replace(/[^a-zA-Z ñÑ\ ]*/g, '');
    }
    if (clientStudyRequest.legalRepresentativeFistLastName) {
      clientRequest['apel1_rep'] =
        clientStudyRequest.legalRepresentativeFistLastName.replace(/[^a-zA-Z ñÑ\ ]*/g, '');
    }
    if (clientStudyRequest.legalRepresentativeSecondLastName) {
      clientRequest['apel2_rep'] =
        clientStudyRequest.legalRepresentativeSecondLastName.replace(/[^a-zA-Z ñÑ\ ]*/g, '');
    }
    if (clientStudyRequest.contactPosition) {
      clientRequest['cargo_contacto'] = clientStudyRequest.contactPosition;
    }
    if (clientStudyRequest.contactEmail) {
      clientRequest['correo_contacto'] = clientStudyRequest.contactEmail;
    }
    if (clientStudyRequest.initialDate) {
      clientRequest['inicio_actividad'] = clientStudyRequest.initialDate;
    }
    if (clientStudyRequest.filingNumber) {
      clientRequest['radicado'] = clientStudyRequest.filingNumber;
    }
    return clientRequest;
  }
}
