import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalService } from '@app/services/modal.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss']
})
export class FileComponent implements OnInit {

  public files: File[] = [];
  public stateFiles: File[] = [];
  public mb = 1024 * 1024;
  public maxFileSize = 10 * this.mb;
  public fileSelected: File[];
  public opc = 0;
  public filename: string=''
  public fileList: any[] = [];
  public formatValid = false
  public fileForm:FormGroup;
  public allowedExtensions = ["png", "jpeg", "jpg" ,"pdf", "docx","doc"];

  @ViewChild('fileInput') fileInput: any;
  @Input() nameDocument: string = "";
  @Input() idx: number = 0;
  @Output() chargeResult: EventEmitter<any> = new EventEmitter();
  @Output() removeFile: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<File[]> = new EventEmitter();
  @Output() removeFileEvent: EventEmitter<any> = new EventEmitter();
  @Output() confirm: EventEmitter<object> = new EventEmitter();
  @Output() isValid: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private fb:FormBuilder,
    public modalService: ModalService,
    public toastr: ToastrService) {
    this.fileForm = this.fb.group({
      fileUpload:[null, Validators.required],
    })
  }

  ngOnInit(): void {
    this.fileForm.get('fileUpload').valueChanges.subscribe((selectedFile) => {
    })
  }

  onFileChange(event,idx) {
    this.filename = event.srcElement.value.replace(/C:\\fakepath\\/i, '');
    if (this.allowedExtensions.includes(this.filename.split('.')[1].toLowerCase())) {
      this.formatValid = true
      this.isValid.emit(event)
      this.chargeResult.emit({params:event.target.files[0], idx:0});
    }else{
      this.formatValid = false
    }
    console.log(this.formatValid)
  }

  onRemove(event,idx) {
    if (this.allowedExtensions.includes(this.filename.split('.')[1])) {
      this.removeFileEvent.emit(event)
    }else{
      this.formatValid = false
    }
    this.filename =''
  }

  sendInfo() {
    if (this.opc == 0) this.opc = 1;
    else if (this.opc == 1) this.opc = 2;
  }

  accept() {
    this.confirm.emit();
    this.close.emit();
    this.modalService.close();
  }
}

