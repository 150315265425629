import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MassiveRecordResponse } from '@models/massiveRecordResponse.model';
import { CONSTANTS } from '@config/constants';
import { TransactionService } from '@services/transaction.service';
import { saveAs } from 'file-saver';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'app-transactions-status',
  templateUrl: './transactions-status.component.html',
  styleUrls: ['./transactions-status.component.scss'],
})
export class TransactionsStatusComponent implements OnInit {
  @Input() massiveResponse: Array<MassiveRecordResponse>;
  public massiveSucces: Array<MassiveRecordResponse>;
  public massiveError: Array<MassiveRecordResponse>;

  constructor(
    private auth: AuthService,
    public router: Router,
    public spinner: NgxSpinnerService,
    public transaction: TransactionService
  ) {}

  ngOnInit() {
    this.massiveError = this.massiveResponse.filter(
      (massiveRecord: MassiveRecordResponse) =>
        massiveRecord.observation == CONSTANTS.TRANSACTIONS.DENIED ||
        massiveRecord.observation == CONSTANTS.TRANSACTIONS.IN_PROGRESS
    );
    this.massiveSucces = this.massiveResponse.filter(
      (massiveRecord: MassiveRecordResponse) =>
        !(
          massiveRecord.observation == CONSTANTS.TRANSACTIONS.DENIED ||
          massiveRecord.observation == CONSTANTS.TRANSACTIONS.IN_PROGRESS
        )
    );
  }

  back() {
    window.location.reload();
  }

  downloadTransactions(status: string) {
    if (status === 'Negado') {
      (window as any).gtag('event', 'descarga-negados', {
        event_category: 'transaccion-masiv',
        event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
      });
    } else {
      (window as any).gtag('event', 'descarga-trans-exitosa', {
        event_category: 'transaccion-masiv',
        event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
      });
    }
    const settled =
      this.massiveSucces.length > 0
        ? this.massiveSucces[0].radication
        : this.massiveError.length > 0
        ? this.massiveError[0].radication
        : '0';
    this.spinner.show();
    this.transaction.exportMassiveResult(settled, status).subscribe(
      (res: any) => {
        const blob = new Blob([res], {
          type: 'application/ms-excel',
        });
        saveAs(blob, `${settled}-${status}` + '.xlsx');
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }
}
