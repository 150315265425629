import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
})
export class PasswordComponent implements OnInit {
  // Properties
  formPass: FormGroup;
  userForm = {
    username: '',
  };

  public adminEmail: String = '';
  public userEmail: String = '';

  // Toggles
  public toggleFormPass = true;

  constructor(
    private auth: AuthService,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {
    this.setForm();
  }

  ngOnInit() {
    (window as any).gtag('event', 'olvide-contraseña', {
      event_category: 'sin-login',
      event_label: 'olvide-contraseña-inicio',
    });
  }

  setForm() {
    this.formPass = new FormGroup({
      username: new FormControl('', [
        Validators.required,
        Validators.minLength(4),
      ]),
    });
    this.formPass.controls['username'].setValidators([Validators.required]);

    this.formPass.setValue(this.userForm);
  }

  recordBackEvent() {
    (window as any).gtag('event', 'volver-inicio', {
      event_category: 'olvide-contrasena',
      event_label: 'codigo-enviado-para-recuperara',
    });
  }

  recoverPassword() {
    (window as any).gtag('event', 'recuperar-contrasena', {
      event_category: 'olvide-contrasena',
      event_label: 'recuperacion-contrasena',
    });
    this.spinner.show();

    this.auth.recoverPassword(this.formPass.value.username).subscribe(
      (resp: any) => {
        if ('CORREO_ADMINISTRADOR' in resp) {
          this.adminEmail = resp['CORREO_ADMINISTRADOR'];
        }
        if ('CORREO_USUARIO' in resp) {
          this.userEmail = resp['CORREO_USUARIO'];
        }
        this.spinner.hide();
        this.toggleFormPass = false;
      },
      (err) => {
        this.spinner.hide();
        Swal.fire({
          type: 'error',
          title: 'Error:',
          text: 'Intente mas tarde',
        });
      }
    );
  }

  pass() {
    this.router.navigateByUrl('pages/transacciones');
  }

  // Swal.fire({
  //   type: 'error',
  //   title: 'Error al cambiar contraseña',
  //   text: 'Error de contraseña'
  //   });
}
