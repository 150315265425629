import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { onErrorResumeNextStatic } from 'rxjs/internal/operators/onErrorResumeNext';

@Directive({
    selector: '[appOnlyAlpha]'
})
export class OnlyAlphaDirective {
    constructor(private el: ElementRef) { }
    @HostListener('input', ['$event']) onInputChange(event) {
        const initalValue = this.el.nativeElement.value;
        const val = initalValue.replace(/[^a-zA-Z ñÑ\ ]*/g, '');
        this.el.nativeElement.value = val;
        //console.log(this.el.nativeElement.value);
        if (initalValue !== this.el.nativeElement.value) {
            event.stopPropagation();
        }
    }
}
