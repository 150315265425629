export class ChangePasswordRequest {
    idCountry: number = 1;
    user: string;
    documentType?: string;
    documentNumber?: string;
    actualPass: string;
    newPass: string;
    confirmNewPass: string;

    public static toRequest(changePassword: ChangePasswordRequest): any {
      return {
        id_pais: changePassword.idCountry,
        usuario: changePassword.user,
        tipo_documento: changePassword.documentType,
        documento: changePassword.documentNumber,
        actualcontrasena: changePassword.actualPass,
        nuevacontrasena: changePassword.newPass,
        confirmarcontrasena: changePassword.confirmNewPass
      };
    }
  }