export class LoginModel {
  username: string;
  password: string;
  idContry?: string = '1';

  public static fromResponse(json: any): LoginModel {
    const loginModel: LoginModel = {
      username: json['usuario'],
      password: json['contrasena'],
      idContry: '1',
    };
    return loginModel;
  }

  public static toResponse(loginModel: LoginModel): any {
    return {
      usuario: loginModel.username,
      contrasena: loginModel.password,
      id_pais: '1',
    };
  }
}

export class RecoverPasswordModel {
  username: string;

  public static toResponse(
    recoverPassword: RecoverPasswordModel,
    option: string
  ): any {
    return {
      usuario: recoverPassword.username,
      pagina: 'cambiar-contrasena',
      opcion: option,
    };
  }
}

export class LoginRequestUpdatePassword {
  public user:           string = '';
  public newPassword:           string = '';
  public confirmNewPass?:    string = '';
  public accessCode:        string = '';
}

export class LoginResponseOnUpdatePassword {
   public ok: boolean;
   public data: any;
}
