export class ModelEmailLoadDocumentsRequest {
    public asunto: string;
    public destinatarios: string [];
    public idPlantilla: number;
    public tagsCorreo: tagsMailsModel [];
}

export class tagsMailsModel {
    public nombre: string;
    public valor: string;

    constructor (name: string, value: string) {
        this.nombre = name;
        this.valor = value;
    }
}