import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { ClientStudyRequest } from "@app/models/clientStudyRequest.model";
import { ClientStudyResponse } from "@app/models/clientStudyResponse.model";
import { ReturnDocumentsLoadFlag } from "@app/models/flagLoadDocuments.model";
import { UploadDocumentsService } from "@app/services/upload-documents.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ModalService } from "@services/modal.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { __spreadArrays } from "tslib";

interface FileData {
  id?: any;
  source?: string;
  user?: string;
  documentType?: any;
  document?: any;
  originalName?: string;
  creationDate?: any;
  category?: string;
  infoFile?: string;
  originalPath?: any;
  originalFinalName?: any;
  resourceId?: any;
}
@Component({
  selector: "app-modal-upload-mail-file",
  templateUrl: "./modal-upload-mail-file.component.html",
  styleUrls: ["./modal-upload-mail-file.component.scss"],
})
export class ModalUploadMailFileComponent implements OnChanges {

  public files: File[] = [];
  public stateFiles: File[] = [];
  public mb = 1024 * 1024;
  public maxFileSize = 10 * this.mb;
  public fileSelected: File[];
  public totalFiles: Array<File[]>;
  public actualDate = new Date();

  @Input() response: any;
  @Input() enableButton: boolean = false;
  @Input() quotaValue: number = 0;
  @Input() fileParams: Array<any>;
  @Input() indexElement: number;
  @Input("clientStudy") clientStudyForm: ClientStudyRequest;
  @Input() clientStudyResponse: ClientStudyResponse;
  @Output() chargeResult: EventEmitter<File[]> = new EventEmitter();
  @Output() numberDocuments: EventEmitter<number> = new EventEmitter();
  @Output() close: EventEmitter<File[]> = new EventEmitter();
  @Output() removeFileEvent: EventEmitter<number> = new EventEmitter();
  @Output() fileArray: EventEmitter<boolean[]> = new EventEmitter();
  @Output() confirm: EventEmitter<object> = new EventEmitter();

  fileData: FileData;
  promiseList = [];
  dataFileList = [];
  filesUp: boolean[] = Array(1).fill(false);
  opc = 0;
  infoFile: any;
  idRecurso: string | null;
  numberDoc: string;
  numEntero: number;
  count = 0;
  uploadedDocs = 0;

  constructor(
    public modalService: ModalService,
    private route: ActivatedRoute,
    public spinner: NgxSpinnerService,
    public toastr: ToastrService,
    private uploadDocumentsService: UploadDocumentsService,
  ) {}

  objFileTypes = [
    {
      name: "Declaración de renta último año fiscal",
      number: 0,
      montMin: 20000000,
    },
    {
      name: "Estados financieros del último periodo",
      number: 1,
      montMin: 50000000,
    },
    {
      name: "Estados financieros de los últimos 2 años",
      number: 2,
      montMin: 100000000,
      montMax: 100000001,
    },
  ];

  ngOnInit(): void {
    this.idRecurso = this.route.snapshot.paramMap.get("idRecurso");
    this.numberDoc = this.route.snapshot.paramMap.get("numberDoc");
    this.numEntero = parseInt(this.numberDoc);
  }

  createArrayFile(objectFile: any, idx: number) {
    console.log(objectFile)
    this.uploadedDocs += 1;
    this.promiseList[idx] = this.toBase64(objectFile.params);
    this.dataFileList[idx] = objectFile.params;
    this.uploadedFile(idx);

    if (this.uploadedDocs == this.numEntero) {
      this.enableButton = true;
    }
    console.log(this.uploadedDocs)
  }

  onRemove(event, idx) {
    this.removeFileEvent.emit(idx);
    this.enableButton = false;
    let position = this.promiseList.indexOf(event);
    if (this.promiseList.length === 0) return;
    else {
      if (position == -1) {
        this.promiseList.splice(idx, 1, '');
        this.dataFileList.splice(idx, 1, '');
      } else {
        this.promiseList.splice(position, idx, '');
        this.dataFileList.splice(position, idx, '');
      }
    }
    this.uploadedDocs -= 1;
    console.log(this.uploadedDocs)
  }

  verifyDocumentsUpload(): void {
    this.uploadDocumentsService.checkLoad(this.idRecurso).subscribe({
      complete: () => {
        this.sendInfo();
      },
      error: (err) => {
        this.toastr.error(err.error.message, "", {
          timeOut: 3000,
        });
        setTimeout(() => {
          window.location.reload();   
        }, 3000);
      },
    });
  }

  async sendInfo(event?): Promise<void> {
    let fileData;
    this.spinner.show();
    if (this.promiseList.length === 0) return;
    else {
      Promise.all(this.promiseList).then((filesBase64) => {
        filesBase64.forEach((file, index) => {
          if (this.opc === 0) {
            this.uploadDocumentsService.sendInfoUser(this.idRecurso).subscribe({
              next: (response: any) => {
                localStorage.setItem("response", JSON.stringify(response.data));
                fileData = {
                  id: null,
                  source: "CovifacturaWeb",
                  documentType: response.data.tipoDocComprador,
                  document: response.data.documentoComprador,
                  originalPath: null,
                  originalFinalName: this.dataFileList[index].name,
                  resourceId: this.idRecurso,
                  user: response.data.nombreComprador,
                  infoFile: file.split(",")[1],
                  category: "SOLICITUD CUPO",
                  originalName: this.dataFileList[index].name.split(".")[0],
                };
              },
              complete: () => {
                this.uploadDocumentsService.sendDocuments(fileData).subscribe({
                  next: () => {},
                  complete: () => {
                    this.count = this.count + 1;
  
                    if (Number(this.numberDoc) == this.count) {
                      this.sendUploadDocumentsFlag();
                    }
                  },
                  error: (err) => {
                    this.spinner.hide();
                    this.toastr.error(err.error.message, "", {
                      timeOut: 4000,
                    });
                  },
                });
              },
              error: (err) => {
                this.spinner.hide();
                this.toastr.error(err.error.message, "", {
                  timeOut: 3000,
                });
              },
            });
          } else if (this.opc == 1) {
            this.opc = 2;
          }
        });
      });
    }
  }

  ngOnDestroy() {
    this.modalService.close();
  }

  uploadedFile(idx) {
    this.numberDocuments.emit(idx);
  }

  accept() {
    this.confirm.emit();
    this.close.emit();
    window.location.reload();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.quotaValue) {
      try {
        if (
          changes.quotaValue.previousValue != changes.quotaValue.currentValue
        ) {
          if (
            this.clientStudyForm.quotaValue > 20000000 &&
            this.clientStudyForm.quotaValue <= 50000000
          ) {
            this.filesUp = Array(1).fill(false);
          } else if (
            this.clientStudyForm.quotaValue > 50000000 &&
            this.clientStudyForm.quotaValue <= 100000000
          ) {
            this.filesUp = Array(2).fill(false);
          } else if (this.clientStudyForm.quotaValue > 100000000) {
            this.filesUp = Array(3).fill(false);
          }
          this.fileArray.emit(this.filesUp);
        }
      } catch (error) {}
    }
  }

  async toBase64(file: File) {
    const base64 = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
    return base64;
  }

  sendUploadDocumentsFlag() {
    const flagBody = {
      radicado: this.idRecurso,
      numeroDocumentos: this.numEntero,
    };

    this.uploadDocumentsService.saveDocumentUploadFlag(flagBody).subscribe({
      next: (response: ReturnDocumentsLoadFlag) => {
        console.log(response);
      },
      complete: () => {
        this.spinner.hide();
        this.opc = 1;
      },
      error: (err) => {
        this.spinner.hide();
        console.error(err);
      },
    });
  }
}
