import { SelectRequest } from '@models/selectRequest.model';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserModel } from '@models/user.model';
import { UserRoles } from '@models/userRolesResponse.model';

@Injectable({
  providedIn: 'root'
})
export class SelectsService {
  public urlApi: string;
  public userToken: string;
  public identity: UserModel;

  constructor(private http: HttpClient) {
    this.urlApi = environment.urlApi;
    this.readToken();
    this.getIdentity();
  }

  readToken() {
    if (localStorage.getItem("tokenCF")) {
      this.userToken = localStorage.getItem("tokenCF");
    } else {
      this.userToken = "";
    }
    return this.userToken;
  }

  getIdentity() {
    return (this.identity = UserModel.fromResponse(
      JSON.parse(localStorage.getItem("user"))
    ));
  }

  getUserRoles() {
    let selectRequest: SelectRequest = new SelectRequest();
    const { userInfo, username } = this.identity;
    selectRequest.documentNumber = userInfo.document;
    selectRequest.documentType = userInfo.documentType;
    const headers = new HttpHeaders().set("token", this.userToken);
    const options = {
      params: SelectRequest.toRequest(selectRequest),
      headers
    };
    return this.http.get(`${this.urlApi}/pr_rol_usuario`, options).pipe(
      map((resp) => {
        let userRoles: Array<UserRoles> = [];
        userRoles = resp["ROL_USUARIO"] && resp["ROL_USUARIO"].length > 0
              ? resp["ROL_USUARIO"].map((user: UserRoles) => UserRoles.fromResponse(user)) 
              : [];
        return userRoles;
      })
    )
  }

}
