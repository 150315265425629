import { Component, Output, OnDestroy, EventEmitter } from '@angular/core';
import { ModalService } from '@services/modal.service';

@Component({
  selector: 'app-modal-payment-agreement-confirm',
  templateUrl: './modal-payment-agreement-confirm.component.html',
  styleUrls: ['./modal-payment-agreement-confirm.component.scss'],
})
export class ModalPaymentAgreementConfirmComponent implements OnDestroy {
  @Output() finish: EventEmitter<object> = new EventEmitter<object>();

  constructor(public modal: ModalService) {}

  ngOnDestroy() {
    this.modal.close();
  }

  accept() {
    this.modal.close();
    this.finish.emit();
  }
}
