import * as moment from 'moment';
import { TransactionDue } from './transactionDue.model';

export class TransactionRequest {
  idContry: string;
  sellerDocumentType: string;
  sellerDocumentNumber: string;
  documentType: string;
  documentNumber: string;
  queryCode: string;
  reservationCode: string;
  transactionType: string;
  titleValue: string;
  username: string;
  expirationDate: Date;
  transactionValue: number;
  bank: string;
  acount: string;
  municipality: string;
  phone: string;
  celPhone: string;
  addres: string;
  transactionDues: Array<TransactionDue>;

  public static toRequest(transactionRequest: TransactionRequest): any {
    return {
      id_pais: transactionRequest.idContry,
      comprador_tipo_documento: transactionRequest.sellerDocumentType,
      tipo_documento: transactionRequest.documentType,
      documento: transactionRequest.documentNumber,
      comprador_documento: transactionRequest.sellerDocumentNumber,
      usuario: transactionRequest.username,
      tipo_transaccion: transactionRequest.transactionType,
      num_titulovalor: transactionRequest.titleValue,
      fechavence: moment(transactionRequest.expirationDate).format('YYYYMMDD'),
      valor_transaccion: transactionRequest.transactionValue,
      banco: transactionRequest.bank,
      cuenta: transactionRequest.acount,
      lista_municipio: transactionRequest.municipality,
      telefono: transactionRequest.phone,
      celular: transactionRequest.celPhone,
      direccion: transactionRequest.addres,
      codigo_consulta: transactionRequest.queryCode,
      orden_compra: transactionRequest.reservationCode,
    };
  }

  public static toThreePaymentsRequest(
    transactionRequest: TransactionRequest
  ): any {
    return {
      id_pais: transactionRequest.idContry,
      comprador_tipo_documento: transactionRequest.sellerDocumentType,
      tipo_documento: transactionRequest.documentType,
      documento: transactionRequest.documentNumber,
      comprador_documento: transactionRequest.sellerDocumentNumber,
      usuario: transactionRequest.username,
      tipo_transaccion: 2,
      valor_cupo: transactionRequest.transactionValue,
      celular: transactionRequest.phone,
      codigo_consulta: transactionRequest.queryCode,
    };
  }
}
