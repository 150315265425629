import { Component, OnDestroy, OnInit } from '@angular/core';
import { HelperService } from '@services/helper.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UsersService } from '@services/users.service';
import { PermissionModel } from '@models/permission.model';
import { CONSTANTS, Tabs } from '@config/constants';
import { AuthService } from '@services/auth.service';
import { SubjectService } from '@app/services/subject.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent implements OnInit, OnDestroy {
  // public selectedTabId: number;
  public permissions: Array<PermissionModel>;
  // showReports = false;
  // showTransactionsReport = false;
  // showSellersReport = false;
  Tabs = Tabs;
  currentTab: Tabs;

  public navItemTabs = [
    {
      href: '#reports',
      description: 'Reportes',
      icon: 'icon-tab-reports.svg',
      idOption: Tabs.Reportes,
      // id: 0,
    },
    {
      href: '#transactionsReport',
      description: 'Reporte de transacciones',
      icon: 'icon-tab-transactions-report.svg',
      idOption: Tabs.ReporteDeTransacciones,
      // id: 1,
    },
    {
      href: '#sellersReport',
      description: 'Reporte de vendedores',
      icon: 'icon-tab-sellers-report.svg',
      idOption: Tabs.ReporteDeVendedores,
      // id: 2,
    },
  ];

  constructor(
    private auth: AuthService,
    public helper: HelperService,
    public spinner: NgxSpinnerService,
    private usersService: UsersService,
    public subject: SubjectService
  ) {}

  setCurrentTab(item) {
    //console.log('item', item);
    this.currentTab = item.idOption;

    switch (item.idOption) {
      case Tabs.Reportes: {
        (window as any).gtag('event', 'ver-reporte', {
          event_category: 'reportes-graficos',
          event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
        });
        break;
      }
      case Tabs.ReporteDeTransacciones: {
        (window as any).gtag('event', 'ver-reporte', {
          event_category: 'reportes-transacciones',
          event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
        });
        break;
      }
      default: {
        (window as any).gtag('event', 'ver-reporte', {
          event_category: 'reportes-vendedores',
          event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
        });
        break;
      }
    }
    // this.selectedTab(item.id);
  }

  // showTab(idOption) {
  //   let showItem = false;
  //   switch (idOption) {
  //     case Tabs.Reportes: {
  //       (window as any).gtag('event', 'ver-reporte', {
  //         event_category: 'reportes-graficos',
  //         event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.userInfo.email}`,
  //       });
  //       // showItem = this.showReports;
  //       break;
  //     }
  //     case Tabs.ReporteDeTransacciones: {
  //       (window as any).gtag('event', 'ver-reporte', {
  //         event_category: 'reportes-transacciones',
  //         event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.userInfo.email}`,
  //       });
  //       // showItem = !this.showReports && this.showTransactionsReport;
  //       break;
  //     }
  //     default: {
  //       (window as any).gtag('event', 'ver-reporte', {
  //         event_category: 'reportes-vendedores',
  //         event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.userInfo.email}`,
  //       });
  //       // showItem =
  //       //   !this.showReports &&
  //       //   !this.showTransactionsReport &&
  //       //   this.showSellersReport;
  //       break;
  //     }
  //   }

  //   return showItem;
  // }

  ngOnInit() {
    this.subject.headerCollapse(true);
    this.permissions = this.usersService.permissions;
    this.navItemTabs = this.navItemTabs
      .filter((navItemTab) => {
        const permissionStatus = this.permissions.filter(
          (permission) => permission.idOption == navItemTab.idOption
        );
        const showItem =
          permissionStatus.length > 0 &&
          permissionStatus[0].status === CONSTANTS.PERMISSIONS.ACTIVE;

        // switch (navItemTab.idOption) {
        //   case Tabs.Reportes:
        //     this.showReports = showItem;
        //     break;
        //   case Tabs.ReporteDeTransacciones:
        //     this.showTransactionsReport = showItem;
        //     break;
        //   default:
        //     this.showSellersReport = showItem;
        //     break;
        // }
        return showItem;
      })
      .map((navItemTab) => {
        const permissionStatus = this.permissions.filter(
          (permission) => permission.idOption == navItemTab.idOption
        );
        if (permissionStatus.length > 0) {
          navItemTab['status'] = permissionStatus[0].status;
        }
        return navItemTab;
      });
    this.currentTab = this.navItemTabs[0]['idOption'];

    // if (this.showReports) {
    //   this.selectedTabId = 0;
    // }
    // if (!this.showReports && this.showTransactionsReport) {
    //   this.selectedTabId = 1;
    // }
    // if (
    //   !this.showReports &&
    //   !this.showTransactionsReport &&
    //   this.showSellersReport
    // ) {
    //   this.selectedTabId = 2;
    // }
  }

  ngOnDestroy() {
    this.subject.headerCollapse(false);
  }

  // selectedTab(i: number) {
  //   console.log('i', i);
  //   this.selectedTabId = i;
  // }
}
