import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  NgZone,
} from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalService } from '@services/modal.service';
import { ToastrService } from 'ngx-toastr';
import { SubjectService } from '@services/subject.service';
import { MassiveRequest } from '@models/massiveRequest.model';
import { MassiveRecord } from '@models/massiveRecord.model';
import { MassiveRecordResponse } from '@models/massiveRecordResponse.model';
import { ExonerationService } from '@services/exoneration.service';
import { HelperService } from '@services/helper.service';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'app-massive-exoneration',
  templateUrl: './massive-exoneration.component.html',
  styleUrls: ['./massive-exoneration.component.scss'],
})
export class MassiveExonerationComponent implements OnInit {
  @Output() massiveResponseEvent: EventEmitter<Array<MassiveRecordResponse>> =
    new EventEmitter<Array<MassiveRecordResponse>>();
  @Input() massiveRecivedRequet: MassiveRequest;

  public errorTitlesIndex: any = {
    'Tipo doc/to': 'documentType',
    'Número documento': 'documentNumber',
    'Número factura': 'valueTitle',
    'Valor deuda': 'value',
    'Fecha vencimiento': 'date',
  };

  public errorTitlesIndexType: any = {
    'Tipo doc/to': 'string',
    'Número documento': 'number',
    'Número factura': 'string',
    'Valor deuda': 'number',
    'Fecha vencimiento': 'date',
  };

  public errorTitles: any = [
    'Tipo doc/to',
    'Número documento',
    'Número factura',
    'Valor deuda',
    'Fecha vencimiento',
  ];

  public correctTitlesIndex: any = {
    'Tipo doc/to': 'documentType',
    'Número documento': 'documentNumber',
    'Número factura': 'valueTitle',
    'Valor deuda': 'value',
    'Fecha vencimiento': 'date',
  };
  public correctTitles: any = [
    'Tipo doc/to',
    'Número documento',
    'Número factura',
    'Valor deuda',
    'Fecha vencimiento',
  ];

  public massiveRequest: MassiveRequest;
  public massiveResponse: Array<MassiveRecordResponse>;

  public togglePopOver = false;
  public messageTooltip: string;

  // Effects
  public hoverEffect = false;
  public hoverDiv: number;

  public activeContent = true;

  constructor(
    private auth: AuthService,
    public helper: HelperService,
    public spinner: NgxSpinnerService,
    public modal: ModalService,
    private toastr: ToastrService,
    public subject: SubjectService,
    public exoneration: ExonerationService,
    public zone: NgZone
  ) {}

  ngOnInit() {
    this.massiveRequest = this.massiveRecivedRequet;
  }

  maskInput(index: number, errorTitle: string, $event) {
    switch (this.errorTitlesIndexType[errorTitle]) {
      case 'date':
        this.maskDate(index, errorTitle, $event.replace(/\D/g, ''));
        break;
      case 'number':
        this.massiveRequest.errorRecords[index][
          this.errorTitlesIndex[errorTitle]
        ] = $event.replace(/\D/g, '');
        break;

      default:
        this.massiveRequest.errorRecords[index][
          this.errorTitlesIndex[errorTitle]
        ] = $event;
        break;
    }
  }

  isOnlyNumber(errorTitle) {
    return (
      this.errorTitlesIndexType[errorTitle] === 'date' ||
      this.errorTitlesIndexType[errorTitle] === 'number'
    );
  }

  maskDate(index: number, errorTitle: string, $event) {
    let v = $event;
    if (v.length >= 5) {
      v = `${v.slice(0, 2)}/${v.slice(2, 4)}/${v.slice(4)}`;
    } else if (v.length >= 3) {
      v = `${v.slice(0, 2)}/${v.slice(2)}`;
    }
    this.massiveRequest.errorRecords[index][this.errorTitlesIndex[errorTitle]] =
      v.slice(0, 10);
  }

  downloadExcel() {}

  validateRegisters(event?: any, row?: any, id?: any, type?: string) {}

  mouseEnter(index: any) {
    this.hoverEffect = true;
    this.hoverDiv = index;
  }

  mouseLeave(index: any) {
    this.hoverEffect = false;
    this.hoverDiv = index;
  }

  deleteRow(id: number) {
    this.massiveRequest.errorRecords.splice(id, 1);
  }

  deleteRows() {
    this.massiveRequest.errorRecords = [];
  }

  save() {
    this.spinner.show();
    this.exoneration.massiveVerify(this.massiveRequest, true).subscribe(
      (massiveRequests: MassiveRequest) => {
        this.massiveRequest = massiveRequests;
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  charge() {
    (window as any).gtag('event', 'hacer-exoneracion-masiva', {
      event_category: 'exoneracion-masiva',
      event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
    });
    this.spinner.show();
    this.exoneration.massiveVerify(this.massiveRequest, false).subscribe(
      (massiveRequests: Array<MassiveRecordResponse>) => {
        this.massiveResponse = massiveRequests;
        this.spinner.hide();
        this.massiveResponseEvent.emit(this.massiveResponse);
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  enterTd(item: MassiveRecord, title: string, event: any) {
    if (item.message[this.errorTitlesIndex[title]] !== undefined) {
      this.messageTooltip = item.message[this.errorTitlesIndex[title]];
      this.subject.sendText(this.messageTooltip);
    } else {
      this.messageTooltip = '';
      this.subject.sendText(this.messageTooltip);
    }
  }

  leaveTd() {
    this.messageTooltip = '';
    this.subject.sendText(this.messageTooltip);
  }
}
