import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalService } from '@services/modal.service';
import { HelperService } from '@services/helper.service';
import { UserAvailableSpaceRequest } from '@models/userAvailableSpaceRequest.model';
import { UserAvailableSpaceResponse } from '@models/userAvailableSpaceResponse.model';
import { TransactionService } from '@services/transaction.service';
import { DocumentType } from '@models/documentType.model';
import { CONSTANTS } from '@config/constants';
import { ReserveService } from '@services/reserve.service';
import { Reserve } from '@models/reserve.model';

@Component({
  selector: 'app-individual-transaction',
  templateUrl: './individual-transaction.component.html',
  styleUrls: ['./individual-transaction.component.scss'],
})
export class IndividualTransactionComponent implements OnInit {
  public documentTypes: Array<DocumentType>;
  public documentName: String;
  public constants: any = CONSTANTS;
  public reserveList: Array<Reserve> = [];
  public threePayments: boolean = false;
  public threePaymentsimg: boolean = false;
  public sixPayments: boolean = false;

  public transactionUser: UserAvailableSpaceRequest = {
    idContry: '1',
    sellerDocumentType: '',
    sellerDocumentNumber: '',
    documentType: '',
    documentNumber: '',
    queryCode: '',
  };

  public userAvailableSpace: UserAvailableSpaceResponse =
    new UserAvailableSpaceResponse();

  public navItemTabs = [
    {
      href: '#bill-transaction',
      description: 'Factura',
      icon: 'icon-bill.svg',
      width: '20px',
    },
    {
      href: '#check-transaction',
      description: 'Cheque',
      icon: 'icon-check.svg',
      width: '40px',
    },
  ];

  // Radio
  public radioSel: any;
  public reservationCode: string;
  public radioSelected = '';
  showInvoice = false;
  showCheck = false;
  invoiceRol = false;
  checkRol = false;
  permissions = null;

  public radio = [];

  constructor(
    public modal: ModalService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public helper: HelperService,
    private spinner: NgxSpinnerService,
    public transaction: TransactionService,
    private reserveService: ReserveService
  ) {}

  ngOnInit() {
    this.permissions = localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : null;
    if (this.permissions) {
      this.invoiceRol = this.permissions['MENU'].find(permission => {
        return permission.DESCRIPCION == "Transaccion indv factura";
      }).ESTADO == "A" ? true : false;

      this.checkRol = this.permissions['MENU'].find(permission => {
        return permission.DESCRIPCION == "Transaccion indv cheque";
      }).ESTADO == "A" ? true : false;

      if (this.permissions['MENU'].find(permission => {
        return permission.DESCRIPCION == "Reservar cupo";
      }).ESTADO == "A") {
        this.radio.push({ name: 'Reserva de cupo', value: 'reserva_cupo' });
      }
    }
    this.navItemTabs = [];
    this.documentTypes = this.helper.documentTypes;
    const isSixpayments =
      this.activatedRoute.snapshot.queryParamMap.get('sixpayments');
    
    this.activatedRoute.params.subscribe((params) => {
      this.transactionUser.sellerDocumentType = params['documentType'];
      this.transactionUser.sellerDocumentNumber = params['documentNumber'];
      this.transactionUser.queryCode = params['queryCode'];
      this.transactionUser.reservationCode = params['reservationCode'];
      this.documentName = this.helper.getDocumentName(
        this.transactionUser.sellerDocumentType
      );
      this.checkQuota();
    });

    if (isSixpayments) {
      this.threePayments = true;
      this.sixPayments = true;

      this.navItemTabs.push({
        href: '#bill-transaction',
        description: 'Factura',
        icon: 'icon-bill.svg',
        width: '20px',
      });
      this.showInvoice = true;
    }

  }

  showTab(tab) {
    let showItem = false;
    switch (tab) {
      case 'invoice':
        showItem = this.showInvoice;
        break;
      default:
        showItem = !this.showInvoice && this.showCheck;
        break;
    }

    return showItem;
  }

  checkQuota() {
    this.spinner.show();
    // if (form && form.invalid) { return; }
    this.transaction.checkQuota(this.transactionUser).subscribe(
      (userAvailableSpaceResponse: UserAvailableSpaceResponse) => {
        this.radio = [];
        if (this.invoiceRol) {
          if ((userAvailableSpaceResponse.invoice !== undefined 
            && userAvailableSpaceResponse.invoice !== CONSTANTS.PERMISSIONS.NO)
            || userAvailableSpaceResponse.threePaymentsCode === CONSTANTS.TRANSACTIONS.YES) {
            this.navItemTabs.push({
              href: '#bill-transaction',
              description: 'Factura',
              icon: 'icon-bill.svg',
              width: '20px',
            });
            this.showInvoice = true;
          }
        }
        if (this.checkRol) {
          if (userAvailableSpaceResponse.invoice !== undefined
            && userAvailableSpaceResponse.check !== CONSTANTS.PERMISSIONS.NO) {
            this.navItemTabs.push({
              href: '#check-transaction',
              description: 'Cheque',
              icon: 'icon-check.svg',
              width: '40px',
            });
            this.showCheck = true;
          }
        }
        if (this.invoiceRol || this.checkRol) {
          if (
            userAvailableSpaceResponse.invoice !== CONSTANTS.PERMISSIONS.NO ||
            userAvailableSpaceResponse.threePaymentsCode === CONSTANTS.TRANSACTIONS.YES ||
            userAvailableSpaceResponse.check !== CONSTANTS.PERMISSIONS.NO
          ) {
            this.radio.push({
              name: 'Transacción individual',
              value: 'transaccion_individual',
            });
            this.radioSelected = 'transaccion_individual';
          }
        }
        if (this.permissions && this.permissions['MENU'].find(permission => {
          return permission.DESCRIPCION == "Reservar cupo";
        }).ESTADO == "A")
        if (
          userAvailableSpaceResponse.reservation !== CONSTANTS.PERMISSIONS.NO
        ) {
          this.radio.push({ name: 'Reserva de cupo', value: 'reserva_cupo' });
          this.radioSelected = this.radioSelected == 'transaccion_individual' ? this.radioSelected : 'reserva_cupo';
        }

        this.userAvailableSpace = userAvailableSpaceResponse;
        this.spinner.hide();
        this.getReserves();
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  getReserves() {
    this.spinner.show();
    // if (form && form.invalid) { return; }
    this.reserveService
      .getLegacyReserves(
        '1',
        '10',
        this.transactionUser.sellerDocumentNumber,
        this.transactionUser.sellerDocumentType
      )
      .subscribe(
        (reserves: Array<Reserve>) => {
          this.reserveList = reserves;
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
        }
      );
  }

  radioSelectedItem() {
    const radioSel = this.radio.find(
      (item) => item.value === this.radioSelected
    );
    return radioSel ? radioSel.value : null;
  }

  transactionSuccess(event?: any) {
    this.modal.show('transactionSuccess');
  }
}
