import { Component, OnInit } from '@angular/core';
import { HelperService } from '@services/helper.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalService } from '@services/modal.service';

@Component({
  selector: 'app-tab-agreements-history',
  templateUrl: './tab-agreements-history.component.html',
  styleUrls: ['./tab-agreements-history.component.scss']
})
export class TabAgreementsHistoryComponent implements OnInit {

  public dataTableFake = [
    { documento: 'N', numero_documento: 123456, nombre: 'Felipe Manchego', total_titulos: 1,
      total_acuerdo: 3500000, fecha_acuerdo: '01/Ene/2021', estado: 'vencido'},
    { documento: 'N', numero_documento: 123456, nombre: 'Felipe Manchego', total_titulos: 2,
      total_acuerdo: 3500000, fecha_acuerdo: '01/Ene/2021', estado: 'pagado'},
    { documento: 'N', numero_documento: 123456, nombre: 'Felipe Manchego', total_titulos: 3,
      total_acuerdo: 3500000, fecha_acuerdo: '01/Ene/2021', estado: 'vencido'},
    { documento: 'N', numero_documento: 123456, nombre: 'Felipe Manchego', total_titulos: 4,
      total_acuerdo: 3500000, fecha_acuerdo: '01/Ene/2021', estado: 'pagado'},
  ];

constructor(public helper: HelperService,
            private spinner: NgxSpinnerService,
            public modal: ModalService) { }

    ngOnInit() {
      // this.modal.show('paymentAgreementConfirm', { 
      //   title: 'Confirmar acuerdo de pago',
      //   name: 'Industrias Universal SAS',
      //   buyerTitle: '',
      //   agreementTitle: '',
      //   agreementTotal: '',
      //   cantTitle: '',
      // });
      // this.modal.show('paymentAgreementSuccess');
      // this.modal.show('paymentSuccess');
       this.modal.show('paymentConfirm', { 
          title: 'Confirmar abono',
          name: 'Industrias Universal SAS',
          buyerTitle: '',
          agreementTitle: '',
          agreementTotal: '',
          cantTitle: '',
       });
    }

    search(dateRange: object) {
    }

    noPay() {
      this.modal.show('nopayConfirm');
    }

    downloadExcel() {
      this.spinner.show();
      setTimeout(() => {
        this.spinner.hide();
      }, 2000);
    }
}

