import { DocumentsService } from "@services/documents.service";
import { DocumentResponse } from "@models/documentResponse.model";
import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ModalService } from "@app/services/modal.service";
import { AuthService } from "@services/auth.service";
import { SubjectService } from "@app/services/subject.service";
import { HelperService } from "@app/services/helper.service";
import { UsersService } from "@app/services/users.service";
import { CONSTANTS, Tabs } from "@config/constants";

@Component({
  selector: "app-tab-principal",
  templateUrl: "./tab-principal.component.html",
  styleUrls: ["./tab-principal.component.scss"],
})
export class TabprincipalComponent implements OnInit {
  Tabs = Tabs;
  currentTab: Tabs = null;

  public navItemTabs = [];
  public documents = [];
  public tutorials = [];

  constructor(
    private auth: AuthService,
    public helper: HelperService,
    public spinner: NgxSpinnerService,
    private documentService: DocumentsService,
    public subject: SubjectService,
    public modal: ModalService,
  ) {}

  ngOnInit() {
    this.documentService.getDocuments().subscribe(
      (documents: Array<DocumentResponse>) => {
        documents.forEach(element => {
          if (!element.document.includes("Tutorial")) {
            if (!this.navItemTabs.find(ele => {
              return ele.href == "#Documentos";
            })) {
              this.navItemTabs.push(
                {
                  href: "#Documentos",
                  description: "Documentos",
                  icon: "icon-documents.svg",
                  idOption: Tabs.Documentos,
                  size: '25px'
                  // id: 0,
                }
                );
                this.currentTab = Tabs.Documentos;
            }
            this.documents.push(element);
          } else if (element.document.includes("Tutorial")) {
            if (!this.navItemTabs.find(ele => {
              return ele.href == "#Videos";
            })) {
              this.navItemTabs.push(
                {
                href: "#Videos",
                description: "Tutoriales",
                icon: "VIDEO_C.svg",
                idOption: Tabs.Videos,
                size: '35px'
                // id: 1,
              });
              this.currentTab = this.currentTab == null ? Tabs.Videos : this.currentTab;
            }
            let links = element.link.split("https");
            element.link = "https" + links[links.length -1];
            this.tutorials.push(element);
          }
        });
        (err: any) => {
          console.error(err);
          this.modal.show('error', {
            title: `No hay documentos para mostrar.`,
            message: 'Por favor intente más tarde',
          });
        }
      }
    )
    this.subject.headerCollapse(true);
  }

  setCurrentTab(item) {
    this.currentTab = item.idOption;
    // this.selectedTab(item.id);
  }

  ngOnDestroy() {
    this.subject.headerCollapse(false);
  }
}
