import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import { NgForm } from '@angular/forms';
import { ClientStudyRequest } from '@models/clientStudyRequest.model';
import { Department } from '@models/department.model';
import { City } from '@models/city.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { HelperService } from '@services/helper.service';
import { CONSTANTS } from '@app/config/constants';

@Component({
  selector: 'app-commercial-reference',
  templateUrl: './commercial-reference.component.html',
  styleUrls: ['./commercial-reference.component.scss'],
})
export class CommercialReferenceComponent implements OnInit {
  @Input() clientStudyForm: ClientStudyRequest;
  @Input() departments: Array<Department>;
  @Input() required = true;

  public firstRefCities: Array<City> = [];
  public secondRefCities: Array<City> = [];
  public constants = CONSTANTS;

  // Radio
  private radioSelected = 'legal_person';
  private radio = [
    { name: 'Persona Jurídica', value: 'legal' },
    { name: 'Persona Natural', value: 'natural' },
  ];

  @Input() id: number;
  @Output() formValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  valueChanges: EventEmitter<object> = new EventEmitter<object>();
  @ViewChild('formReference') formReference: NgForm;
  public cant = [1, 2];

  // Form
  public dataLegal = [
    {
      razon_social: '',
      departamento_residencia: '',
      telefono: '',
      correo: '',
    },
    {
      razon_social: '',
      departamento_residencia: '',
      telefono: '',
      correo: '',
    },
  ];

  public dataNatural = [
    {
      primer_nombre: '',
      segundo_nombre: '',
      primar_apellido: '',
      segundo_apellido: '',
      departamento_residencia: '',
      telefono: '',
      correo: '',
    },
    {
      primer_nombre: '',
      segundo_nombre: '',
      primar_apellido: '',
      segundo_apellido: '',
      departamento_residencia: '',
      telefono: '',
      correo: '',
    },
  ];

  public DataSelectFake = [
    { value: 1, description: 'Opción 1' },
    { value: 2, description: 'Opción 2' },
    { value: 3, description: 'Opción 3' },
  ];

  constructor(
    private spinner: NgxSpinnerService,
    public helper: HelperService
  ) {}

  ngOnInit() {
  }

  get pattern() : RegExp {
    return this.validateLengthNumber === 10 ? /^[3]\d{9}$/ : /^[1-9]\d*$/;
  }

  get validateLengthNumber() : number {
    return this.formReference.value['flexRadioDefault'] === 'celular' ? 10 : 7 ;
  }

  onBlur($event) {
    $event.currentTarget.value = $event.currentTarget.value.trim();
    this.valueChanges.emit();
  }

  changeTypeNumber() {
    const keyPhone = String(`${this.id}phone`);
    this.formReference.setValue({... this.formReference.value , [keyPhone]: ''});
  }

  ngModelValue(name) {
    switch (name) {
      case 'firstName':
        return this.clientStudyForm[
          this.id == 1 ? 'firstRefFirstName' : 'secondRefFirstName'
        ];
      case 'secondName':
        return this.clientStudyForm[
          this.id == 1 ? 'firstRefSecondName' : 'secondRefSecondName'
        ];
      case 'fistLastName':
        return this.clientStudyForm[
          this.id == 1 ? 'firstRefFistLastName' : 'secondRefFistLastName'
        ];
      case 'secondLastName':
        return this.clientStudyForm[
          this.id == 1 ? 'firstRefSecondLastName' : 'secondRefSecondLastName'
        ];
      case 'department':
        return this.clientStudyForm[
          this.id == 1 ? 'firstRefDepartment' : 'secondRefDepartment'
        ];
      case 'phone':
        return this.clientStudyForm[
          this.id == 1 ? 'firstRefPhone' : 'secondRefPhone'
        ];
      case 'email':
        return this.clientStudyForm[
          this.id == 1 ? 'firstRefEmail' : 'secondRefEmail'
        ];
      default:
        return '';
    }
  }

  ngModelChange($event, name) {
    switch (name) {
      case 'firstName':
        this.clientStudyForm[
          this.id == 1 ? 'firstRefFirstName' : 'secondRefFirstName'
        ] = $event;
        break;
      case 'secondName':
        this.clientStudyForm[
          this.id == 1 ? 'firstRefSecondName' : 'secondRefSecondName'
        ] = $event;
        break;
      case 'fistLastName':
        this.clientStudyForm[
          this.id == 1 ? 'firstRefFistLastName' : 'secondRefFistLastName'
        ] = $event;
        break;
      case 'secondLastName':
        this.clientStudyForm[
          this.id == 1 ? 'firstRefSecondLastName' : 'secondRefSecondLastName'
        ] = $event;
        break;
      case 'department': {
        this.clientStudyForm[
          this.id == 1 ? 'firstRefDepartment' : 'secondRefDepartment'
        ] = $event;
        break;
      }
      case 'phone':
        this.clientStudyForm[
          this.id == 1 ? 'firstRefPhone' : 'secondRefPhone'
        ] = $event;
        break;
      case 'email':
        this.clientStudyForm[
          this.id == 1 ? 'firstRefEmail' : 'secondRefEmail'
        ] = $event;
        break;

      default:
        break;
    }
  }

  ngDoCheck() {
    this.formValidate();
  }

  formValidate() {
    const isFormValid = this.formReference.valid;
    this.formValid.emit(isFormValid);
  }

  radioSelectedItem() {
    const radioSel = this.radio.find(
      (Item) => Item.value === this.radioSelected
    );
    return radioSel.value;
  }

  firstRefDepartmentChanged(firstRefDepartmentId) {
    this.spinner.show();
    this.helper.getCities(firstRefDepartmentId).subscribe(
      (cities: Array<City>) => {
        this.firstRefCities = cities;
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  secondRefDepartmentChanged(secondRefDepartmentId) {
    this.spinner.show();
    this.helper.getCities(secondRefDepartmentId).subscribe(
      (cities: Array<City>) => {
        this.secondRefCities = cities;
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

}
