import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ModalService } from '@services/modal.service';

@Component({
  selector: 'app-modal-user-update-success',
  templateUrl: './modal-user-update-success.component.html',
  styleUrls: ['./modal-user-update-success.component.scss']
})
export class ModalUserUpdateSuccessComponent implements OnDestroy {

  @Output() confirm: EventEmitter<object> = new EventEmitter();
  constructor(public modal: ModalService) { }

  ngOnDestroy() {
    this.modal.close();
  }

  accept() {
    this.confirm.emit();
    this.modal.close();
  }
}
