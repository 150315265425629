import {
  Component,
  Input,
  OnDestroy,
  EventEmitter,
  Output,
} from '@angular/core';
import { ModalService } from '@services/modal.service';

@Component({
  selector: 'app-modal-reserve-confirm',
  templateUrl: './modal-reserve-confirm.component.html',
  styleUrls: ['./modal-reserve-confirm.component.scss'],
})
export class ModalReserveConfirmComponent implements OnDestroy {
  @Input() name: string;
  @Input() spaceAvailable: string;
  @Input() reservationValue: string;
  @Input() reserveDays = 5;
  @Output() confirm: EventEmitter<object> = new EventEmitter();
  constructor(public modal: ModalService) {}

  ngOnDestroy() {
    this.modal.close();
  }

  close() {
    this.modal.close();
  }

  accept() {
    this.modal.close();
    this.confirm.emit();
  }
}
