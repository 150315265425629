import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UploadDocumentsService } from '@app/services/upload-documents.service';


@Component({
  selector: 'app-upload-mail-file',
  templateUrl: './upload-mail-file.component.html',
  styleUrls: ['./upload-mail-file.component.scss']
})
export class UploadMailFileComponent implements OnInit{
  
  public idRecurso: any;
  public numberDoc: any;
  public numEntero: number;
  public showModal: boolean;
  public isActive: boolean;
  
  constructor(
    protected router:Router,
    protected activeRouter:ActivatedRoute,
    protected uploadDocumentsService: UploadDocumentsService
  ) {}

  recordSocialEvent() {
    (window as any).gtag('event', 'social', {
      event_category: 'sin-login',
      event_label: 'boton-socialmedia',
    });
  }

  ngOnInit() {
    this.idRecurso = this.activeRouter.snapshot.paramMap.get("idRecurso");
    this.numberDoc = this.activeRouter.snapshot.paramMap.get("numberDoc");
    this.numEntero = parseInt(this.numberDoc);

    // Habilitar para validar carga de documentos segun Radicado.
    this.uploadDocumentsService.checkLoad(this.idRecurso).subscribe({
      next: () => {
        this.isActive = true;
      },
      complete: () => {
        this.showModal = true;
      },
      error: (err) => {
        this.showModal = true;
        if (err.error.data && err.error.data.timeExpired) {
          this.isActive = false;
        } else {
          this.router.navigate(["/not-found"]);
        }
      },
    });
  }
}

