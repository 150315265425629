import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';
import { HelperService } from '@services/helper.service';
import { ModalService } from '@services/modal.service';
import { TransactionRequest } from '@models/transactionRequest.model';
import { Reserve } from '@models/reserve.model';
import { TransactionDue } from '@models/transactionDue.model';
import { UserAvailableSpaceRequest } from '@models/userAvailableSpaceRequest.model';
import { UserAvailableSpaceResponse } from '@models/userAvailableSpaceResponse.model';
import { TransactionService } from '@services/transaction.service';
import { ClientStudyResponse } from '@models/clientStudyResponse.model';
import { Router } from '@angular/router';
import { AuthService } from '@services/auth.service';

import { CONSTANTS } from '@config/constants';

@Component({
  selector: 'app-tab-bill',
  templateUrl: './tab-bill.component.html',
  styleUrls: ['./tab-bill.component.scss'],
})
export class TabBillComponent implements OnInit {
  @Input() threePayments: boolean;
  @Input() sixPayments: boolean;
  @Input() transactionUser: UserAvailableSpaceRequest;
  @Input() userAvailableSpace: UserAvailableSpaceResponse;
  @Input() reserveList: Array<Reserve>;

  public constants: any = CONSTANTS;
  public userAvailable = 0;
  public allowWithoutQuote = false;
  public paymentMethod3 = false;
  public options = [];

  ngOnInit() {
    if (
      this.userAvailableSpace.buyerSpace &&
      this.userAvailableSpace.buyerSpace.status ==
        CONSTANTS.TRANSACTIONS.ALLOW_WITHOUT_QUOTE
    ) {
      this.allowWithoutQuote = true;
    } else if (
      this.userAvailableSpace.buyerSpace &&
      (this.userAvailableSpace.buyerSpace.alert ==
        CONSTANTS.TRANSACTIONS.OPTION_AGREEMENT ||
        this.userAvailableSpace.buyerSpace.alert ==
          CONSTANTS.TRANSACTIONS.WITH_AGREEMENT)
    ) {
      this.userAvailable = parseFloat(
        this.userAvailableSpace.buyerSpace.spaceAvailableAgreement
      );
    } else if (
      this.userAvailableSpace.buyerSpace &&
      this.userAvailableSpace.buyerSpace.status !=
        CONSTANTS.TRANSACTIONS.ALLOW_WITHOUT_QUOTE &&
      this.userAvailableSpace.buyerSpace.alert !=
        CONSTANTS.TRANSACTIONS.OPTION_AGREEMENT &&
      this.userAvailableSpace.buyerSpace.alert !=
        CONSTANTS.TRANSACTIONS.WITH_AGREEMENT
    ) {
      this.userAvailable = parseFloat(
        this.userAvailableSpace.buyerSpace.spaceAvailable
      );
    }
    this.transactionRequest.reservationCode =
      this.transactionUser.reservationCode || '';
    this.maxDate = moment(new Date())
      .add(this.userAvailableSpace.maximumDaysInvoice, 'days')
      .toDate();
      if (this.userAvailableSpace.invoice == "SI") {
        this.transactionRequest.transactionType = "F";
        this.paymentMethod3 = false;
        this.options.push({
          id: "F",
          value: "F"
        })
        this.minDate = moment(new Date()).toDate();
        this.maxDate = moment(new Date()).add(this.userAvailableSpace.maximumDaysInvoice, 'days').toDate();
      }
      if (this.userAvailableSpace.threePaymentsCode == "SI") {
        this.transactionRequest.transactionType = "3";
        this.paymentMethod3 = true;
        this.options.push({
          id: "3",
          value: "3"
        })
        this.maxDate = moment(new Date()).toDate();
        this.minDate = moment(new Date()).add(-25, 'days').toDate();
      }
      
      this.selectedSubCategories();
    }


  onBlur($event) {
    $event.currentTarget.value = $event.currentTarget.value.trim();
  }

  // Properties
  public transactionRequest: TransactionRequest = {
    idContry: '1',
    sellerDocumentType: '',
    sellerDocumentNumber: '',
    documentType: '',
    documentNumber: '',
    reservationCode: '',
    queryCode: '',
    transactionType: '',
    titleValue: '',
    username: '',
    expirationDate: null,
    transactionValue: null,
    bank: '0',
    acount: '20201219',
    municipality: '0',
    phone: '0',
    celPhone: '0',
    addres: '.',
    transactionDues: [],
  };

  selectedReservationCodeValue() {
    return parseFloat(
      this.reserveList.find((reserve: Reserve) => {
        return (
          reserve.reservationCode == this.transactionRequest.reservationCode
        );
      }).totalValue
    );
  }

  paymentMethodChange(event: any) {
    if(event == 3){
      this.paymentMethod3 = true;
      this.maxDate = moment(new Date()).toDate();
      this.minDate = moment(new Date()).add(-25, 'days').toDate();
    }else{
      this.paymentMethod3 = false;
      this.minDate = moment(new Date()).toDate();
      this.maxDate = moment(new Date()).add(60, 'days').toDate();
    }
  }
  public minDate = moment(new Date()).toDate();
  public maxDate = moment(new Date()).add(60, 'days').toDate();

  public approvalCode: string;
  public errorMessage: string;
  public individualTransaction = true;

  constructor(
    private auth: AuthService,
    public helper: HelperService,
    public modal: ModalService,
    private spinner: NgxSpinnerService,
    private transactionService: TransactionService,
    private router: Router
  ) {}

  goToStart() {
    this.router.navigateByUrl('/pages/transacciones');
  }

  transactionBill() {
    if (this.threePayments) {
      (window as any).gtag('event', 'solicitud-recurrentev', {
        event_category: 'transaccion-3pagos',
        event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
      });
    }
    if (this.transactionUser.reservationCode) {
      (window as any).gtag('event', 'factura-transaccion-de-reserva', {
        event_category: 'listado-reservas',
        event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
      });
    } else if (this.transactionRequest.reservationCode) {
      (window as any).gtag('event', 'factura-trx-con-reserva', {
        event_category: 'transaccion-indiv',
        event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
      });
    } else {
      (window as any).gtag('event', 'factura-transaccion', {
        event_category: 'transaccion-indiv',
        event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
      });
    }
    const payload = {"methodPayments": this.paymentMethod3}
    this.modal.show('transactionConfirm',payload );
   
  }

  dateTextChange($event) {
    this.transactionRequest.expirationDate = moment(
      $event.target.value,
      'DD/MM/YYYY'
    ).toDate();
  }

  sendTransaction($event) {
    if (this.threePayments) {
      (window as any).gtag('event', 'conrmacion-recurrentev', {
        event_category: 'transaccion-3pagos',
        event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
      });
      this.requestThreePayments();
    } else {
      if (this.transactionUser.reservationCode) {
        (window as any).gtag('event', 'factura-confirm-trans', {
          event_category: 'listado-reservas',
          event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
        });
      } else if (this.transactionRequest.reservationCode) {
        (window as any).gtag('event', 'fact-conf-trx-con-reserva', {
          event_category: 'transaccion-indiv',
          event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
        });
      } else {
        (window as any).gtag('event', 'factura-conrmacion', {
          event_category: 'transaccion-indiv',
          event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
        });
      }
      this.sendSaveTransaction();
    }
  }

  requestThreePayments() {
    this.transactionRequest.sellerDocumentNumber =
      this.transactionUser.sellerDocumentNumber;
    this.transactionRequest.sellerDocumentType =
      this.transactionUser.sellerDocumentType;
    this.transactionRequest.documentNumber =
      this.transactionUser.documentNumber;
    this.transactionRequest.documentType = this.transactionUser.documentType;
    this.transactionRequest.queryCode = this.transactionUser.queryCode;
    this.spinner.show();
    this.transactionService
      .sendThreePaymentsTransaction(this.transactionRequest)
      .subscribe(
        (clientStudyResponse: ClientStudyResponse) => {
          const name = this.userAvailableSpace.names;

          this.spinner.hide();
          switch (clientStudyResponse.responseType) {
            case 0: {
              const datesArray = clientStudyResponse.dates.split('~');
              const consecutiveArray =
                clientStudyResponse.consecutive.split('~');
              const date1 = moment(datesArray[0], 'YYYYMMDD').format(
                'DD/MM/YYYY'
              );
              const date2 = moment(datesArray[1], 'YYYYMMDD').format(
                'DD/MM/YYYY'
              );
              const date3 = moment(datesArray[2], 'YYYYMMDD').format(
                'DD/MM/YYYY'
              );
              const consecutive = consecutiveArray[3];

              let modalCall = {
                title: '¡Transacción exitosa!',
                threePayments: true,
                name,
                date1,
                date2,
                date3,
                consecutive,
                documentNumber: this.transactionRequest.sellerDocumentNumber,
                billNumber: this.transactionRequest.transactionValue,
                billValue: clientStudyResponse.approvedQuota,
                date: moment().format('DD/MM/YYYY'),
                code: clientStudyResponse.code,
              };
              if (this.sixPayments) {
                modalCall.consecutive = consecutiveArray[6];
                modalCall['date4'] = moment(datesArray[3], 'YYYYMMDD').format(
                  'DD/MM/YYYY'
                );
                modalCall['date5'] = moment(datesArray[4], 'YYYYMMDD').format(
                  'DD/MM/YYYY'
                );
                modalCall['date6'] = moment(datesArray[5], 'YYYYMMDD').format(
                  'DD/MM/YYYY'
                );
              }

              (window as any).gtag('event', 'transaccion-exitosa', {
                event_category: 'transaccion-3pagos',
                event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.userInfo.email}`,
              });
              // aprobada
              this.modal.show('requestApproved', modalCall);
              break;
            }
            case 1: {
              (window as any).gtag('event', 'transaccion-negada', {
                event_category: 'transaccion-3pagos',
                event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
              });
              // negado
              this.modal.show('requestDenied', {
                title: '¡Transacción negada!',
                threePayments: true,
                name,
                documentNumber: this.transactionRequest.sellerDocumentNumber,
                billNumber: this.transactionRequest.transactionValue,
                date: moment().format('DD/MM/YYYY'),
                code: clientStudyResponse.code,
              });
              break;
            }
            case 2: {
              (window as any).gtag('event', 'transaccion-radicada', {
                event_category: 'transaccion-3pagos',
                event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
              });
              // radicada
              this.modal.show('requestSettled', {
                title: '¡Transacción radicada!',
                threePayments: true,
                name,
                documentNumber: this.transactionRequest.sellerDocumentNumber,
                billNumber: this.transactionRequest.transactionValue,
                date: moment().format('DD/MM/YYYY'),
                code: clientStudyResponse.code,
                hours: `${
                  this.transactionRequest.transactionValue
                    ? this.transactionRequest.transactionValue <= 20000000
                      ? 2
                      : 24
                    : 2
                }`,
              });
              break;
            }
            case 3:
              // error
              this.modal.show('error', {
                title: 'Error',
                message: clientStudyResponse.errorModalText,
              });
              break;

            default:
              break;
          }
        },
        (err) => {
          this.spinner.hide();
        }
      );
  }

  sendSaveTransaction() {
    this.transactionRequest.expirationDate = this.transactionRequest.expirationDate;
    this.transactionRequest.sellerDocumentNumber =
      this.transactionUser.sellerDocumentNumber;
    this.transactionRequest.sellerDocumentType =
      this.transactionUser.sellerDocumentType;
    this.transactionRequest.documentNumber =
      this.transactionUser.documentNumber;
    this.transactionRequest.documentType = this.transactionUser.documentType;
    this.transactionRequest.queryCode = this.transactionUser.queryCode;
    const transactionDues: Array<TransactionDue> = [
      {
        date: this.transactionRequest.expirationDate,
        dateString: '',
        title: this.transactionRequest.titleValue,
        value: this.transactionRequest.transactionValue,
        check: false,
      },
    ];
    this.transactionRequest.transactionDues = transactionDues;
    this.spinner.show();
    this.transactionService
      .createTransaction(this.transactionRequest)
      .subscribe(
        (response: any) => {
          this.spinner.hide();
          if (response.error) {
            this.saveInteraction("error");
            this.errorMessage = response.message;
            this.modal.show('transactionDenied');
          } else {
            this.saveInteraction("success");
            if (this.transactionUser.reservationCode) {
              (window as any).gtag('event', 'factura-trans-exitosa', {
                event_category: 'listado-reservas',
                event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
              });
            } else if (this.transactionRequest.reservationCode) {
              (window as any).gtag('event', 'fact-trx-exitosa-con-reserva', {
                event_category: 'transaccion-indiv',
                event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
              });
            } else {
              (window as any).gtag('event', 'factura-trans-exitosa', {
                event_category: 'transaccion-indiv',
                event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
              });
            }
            this.approvalCode = response.approvalCode;
            if (this.transactionRequest.transactionType == "3" && response.message) {
              const messageDatesValues = response.message.split('~');
              const messageCode = messageDatesValues[0].split(" ");
              this.approvalCode = messageCode[messageCode.length - 1];
              const payment1 = {
                date: moment(messageDatesValues[1], "YYYYMMDD").format(
                  "DD/MM/YYYY"
                ),
                value: messageDatesValues[4],
              };
              const payment2 = {
                date: moment(messageDatesValues[2], "YYYYMMDD").format(
                  "DD/MM/YYYY"
                ),
                value: messageDatesValues[5],
              };
              const payment3 = {
                date: moment(messageDatesValues[3], "YYYYMMDD").format(
                  "DD/MM/YYYY"
                ),
                value: messageDatesValues[6],
              };

              const payments = [payment1,payment2,payment3];

              let modalCall = {
                threePayments: true,
                payments,
              };
              // aprobada
              this.modal.show('transactionSuccessThreepayments', modalCall);
            } else {
              this.modal.show('transactionSuccess');
            }
          }
        },
        (err) => {
          this.spinner.hide();
          this.saveInteraction("error");
          this.modal.show('transactionDenied');
        }
      );
  }
  saveInteraction(response: "success" | "error"){
    const data = {
      "plataforma": "COVIFACTURA WEB",
      "categoria": response === "success" ? "RESPALDAR_FACTURA": "ERROR_RESPALDAR_FACTURA",
      "subcategoria": this.threePayments || this.sixPayments ? "TRES_PAGOS" : "COVIFACTURA",
      "tipoDocumentoAfiliado": this.auth.identity.userInfo.documentType,
      "numeroDocumentoAfiliado": this.auth.identity.userInfo.document,
      "tipoDocumentoComprador": this.transactionUser.sellerDocumentType,
      "numeroDocumentoComprador":this.transactionUser.sellerDocumentNumber,
      "usuario_web": this.auth.identity.userInfo.user
    }
  
    this.auth.saveInteractionDate( data ).subscribe({
      next: () => {
        console.log(data)
      },
      error: () => {
        console.log('Verifique los datos');      
      },
    });
  }
  selectedSubCategories(){
    this.options.forEach((item)=> {

      console.log("Aquí",item.id)
      
      });
  }
}
