import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { Response } from "@app/models/util/response.model";
import { Question } from "@app/models/question.model";
import { ResponseQuestion } from "@app/models/responseQuestion.model";
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: "root",
})
export class SatisfactionService {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  // properties
  private urlApi: string;
  private token : string;

  constructor(public http: HttpClient, ) {
    this.token = environment.interactionToken;
    this.urlApi = environment.urlApiDev;
  }

  getQuestions(idUsurio: number): Observable<Response<Question>> {
    let headers = new HttpHeaders();
    headers = headers.set("Content-Type", "application/x-www-form-urlencoded");
    headers = headers.set(
      "Authorization",
      "Basic " + btoa(this.token)
    );

    const params = {
      idUsuario: idUsurio + "",
    };
    const options = { params, headers };
    return this.http
      .get(`${this.urlApi}/NewCoviFactura/CoviFactura/preguntasSatisfaccion/obtenerPreguntas`, options)
      .pipe(
        map((response: Response<Question>) => {
          return response;
        })
      );
  }

  postResponseQuestion(
    responseQuestion: ResponseQuestion
  ): Observable<Response<ResponseQuestion>> {
    let headers = new HttpHeaders();
    headers = headers.set(
      "Authorization",
      "Basic " + btoa(this.token)
    );

    const options = { headers };
    return this.http
      .post(
        `${this.urlApi}/NewCoviFactura/CoviFactura/respuestasSatisfaccion/guardarRespuesta`,
        responseQuestion,
        options
      )
      .pipe(
        map((response: Response<ResponseQuestion>) => {
          return response;
        })
      );
  }
}
