import * as moment from 'moment';

export class RefundsListRequest {
  documentType?: string;
  documentNumber?: string;
  page: Number;
  records?: string;
  sellerDocumentType?: string;
  search?: string;
  startDate: Date;
  endDate: Date;

  public static toRequest(refundsListRequest: RefundsListRequest): any {
    return {
      id_pais: 1,
      tipo_documento: refundsListRequest.documentType,
      documento: refundsListRequest.documentNumber,
      busqueda: refundsListRequest.search || 0,
      pagina: refundsListRequest.page,
      registros: 5,
      fecha_inicial: refundsListRequest.startDate
        ? moment(refundsListRequest.startDate).format('DD/MM/YYYY')
        : '01/01/2015',
      fecha_final: refundsListRequest.endDate
        ? moment(refundsListRequest.endDate).format('DD/MM/YYYY')
        : moment(new Date()).format('DD/MM/YYYY'),
    };
  }
}
