import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserModel } from '@models/user.model';
import { MassiveTransactionRequest } from '@models/massiveTransactionRequest.model';
import { MassiveExportTransactionRequest } from '@models/massiveExportTransactionRequest.model';
import { MassiveRequest } from '@models/massiveRequest.model';
import { MassiveRecordResponse } from '@models/massiveRecordResponse.model';
import { ExonerateGuaranteRequest } from '@models/exonerateGuaranteRequest.model';
import { OperationItem } from '@models/operationItem.model';

@Injectable({
  providedIn: 'root',
})
export class ExonerationService {
  private urlApi: string;
  public userToken: any;
  public identity: UserModel;

  constructor(private http: HttpClient) {
    this.urlApi = environment.urlApi;
    this.readToken();
    this.getIdentity();
  }

  readToken() {
    if (localStorage.getItem('tokenCF')) {
      this.userToken = localStorage.getItem('tokenCF');
    } else {
      this.userToken = '';
    }
    return this.userToken;
  }

  getIdentity() {
    return (this.identity = UserModel.fromResponse(
      JSON.parse(localStorage.getItem('user'))
    ));
  }

  massiveUpload(file: File) {
    const massiveTransactionRequest: MassiveTransactionRequest = {
      idContry: '1',
      documentType: this.identity.userInfo.documentType,
      documentNumber: this.identity.userInfo.document,
      userName: this.identity.username,
      check: '1',
    };
    const headers = new HttpHeaders().set('token', this.userToken);
    const options = {
      params: MassiveTransactionRequest.toRequest(massiveTransactionRequest),
      headers,
    };

    const formData = new FormData();
    formData.append('file', file);

    return this.http
      .put(`${this.urlApi}/pr_exoneraciones_masiva`, formData, options)
      .pipe(
        map((resp: any) => {
          const massiveRequets: MassiveRequest = MassiveRequest.fromResponse(
            resp.CARGA
          );
          return massiveRequets;
        })
      );
  }

  massiveVerify(massiveRegistersRequest: MassiveRequest, check: Boolean) {
    const massiveTransactionRequest: MassiveTransactionRequest = {
      idContry: '1',
      documentType: this.identity.userInfo.documentType,
      documentNumber: this.identity.userInfo.document,
      userName: this.identity.username,
      check: check ? '1' : '0',
    };
    const headers = new HttpHeaders().set('token', this.userToken);
    headers.set('Content-Type', 'application/json');
    const options = {
      params: MassiveTransactionRequest.toRequest(massiveTransactionRequest),
      headers,
    };
    const body = check
      ? MassiveRequest.toVerify(massiveRegistersRequest, true)
      : MassiveRequest.toSend(massiveRegistersRequest, true);

    return this.http
      .put(`${this.urlApi}/pr_exoneraciones_masiva`, body, options)
      .pipe(
        map((resp: any) => {
          if (check) {
            const massiveRequets: MassiveRequest = MassiveRequest.fromResponse(
              resp.CARGA
            );
            return massiveRequets;
          } else {
            const massiveRequets: Array<MassiveRecordResponse> = resp['CARGA']
              ? resp['CARGA'].map((correctRecord) =>
                  MassiveRecordResponse.fromResponse(correctRecord)
                )
              : [];

            return massiveRequets;
          }
        })
      );
  }

  exportMassiveResult(settled: string, status: string) {
    const massiveExportTransactionRequest: MassiveExportTransactionRequest = {
      idContry: '1',
      documentType: this.identity.userInfo.documentType,
      documentNumber: this.identity.userInfo.document,
      settled,
      status,
    };
    const headers = new HttpHeaders().set('token', this.userToken);
    const options = {
      params: MassiveExportTransactionRequest.toRequest(
        massiveExportTransactionRequest
      ),
      headers,
    };

    return this.http.get(`${this.urlApi}/pr_exportar_exoneracion_masiva`, {
      headers: options.headers,
      params: options.params,
      responseType: 'blob',
    });
  }

  exonerateGuarante(exonerateGuaranteRequest: ExonerateGuaranteRequest) {
    exonerateGuaranteRequest.documentNumber = this.identity.userInfo.document;
    exonerateGuaranteRequest.documentType = this.identity.userInfo.documentType;
    exonerateGuaranteRequest.userEmail = this.identity.username;
    const headers = new HttpHeaders().set('token', this.userToken);
    const options = {
      params: ExonerateGuaranteRequest.toRequest(exonerateGuaranteRequest),
      headers,
    };
    const exonerateArray = JSON.stringify(
      exonerateGuaranteRequest.exonerateArray.map(
        (exonerateItem: OperationItem) =>
          OperationItem.toExonerate(exonerateItem)
      )
    );
    return this.http
      .post(
        `${this.urlApi}/pr_exonerar?StrJArray=${exonerateArray}`,
        {},
        options
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }
}
