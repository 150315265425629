import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { HelperService } from '@services/helper.service';
import { ModalService } from '@services/modal.service';
import { UserAvailableSpaceRequest } from '@models/userAvailableSpaceRequest.model';
import { UserAvailableSpaceResponse } from '@models/userAvailableSpaceResponse.model';
import { ReserveService } from '@services/reserve.service';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'app-quota-reserve',
  templateUrl: './quota-reserve.component.html',
  styleUrls: ['./quota-reserve.component.scss'],
})
export class QuotaReserveComponent implements OnInit {
  @Input() transactionUser: UserAvailableSpaceRequest;
  @Input() userAvailableSpace: UserAvailableSpaceResponse;
  @Input() reserveDays = 5;
  public todayText: string = moment(new Date()).format('DD/MM/YYYY');
  public reservationValue: number;
  public approvalCode: string;

  constructor(
    private auth: AuthService,
    public helper: HelperService,
    public modal: ModalService,
    public reserveService: ReserveService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {}

  confirmReservation() {
    (window as any).gtag('event', 'reserva-monto', {
      event_category: 'reserva-cupo',
      event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
    });
    this.modal.show('reserveConfirm');
  }

  sendReservation($event) {
    (window as any).gtag('event', 'reserva-confirmacion', {
      event_category: 'reserva-cupo',
      event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
    });
    this.spinner.show();
    this.reserveService
      .createReserve(this.transactionUser, this.reservationValue)
      .subscribe(
        (approvalCode: string) => {
          (window as any).gtag('event', 'reserva-exitosa', {
            event_category: 'reserva-cupo',
            event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
          });
          this.approvalCode = approvalCode;
          this.spinner.hide();
          this.modal.show('reservationSuccess');
        },
        (err) => {
          this.spinner.hide();
        }
      );
  }
}
