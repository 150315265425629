import { Component, OnDestroy } from '@angular/core';
import { ModalService } from '@services/modal.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-modal-operations-success',
  templateUrl: './modal-operations-success.component.html',
  styleUrls: ['./modal-operations-success.component.scss'],
})
export class ModalOperationsSuccessComponent implements OnDestroy {
  constructor(public modal: ModalService, private router: Router) {}

  ngOnDestroy() {
    this.modal.close();
  }

  goToStart() {
    this.modal.close();
  }
}
