import { Question } from "./question.model";

export class ResponseQuestion {
    openedResponse: string;
    starResponse: number;
    emojiResponse: string;
    questionId: Question;
    userId: string;
    userDocumentType: string;
    userDocument: number;
    responseDate: Date;
    responseComents: string;
}
  