export class UserInfoModel {
  document: string;
  userDocument: string;
  email: string;
  company: string;
  quotaStudy: string;
  enterDate: string;
  userPhoto: string;
  ip: string;
  companyLogo: string;
  message: string;
  birth: string;
  name: string;
  quotaReport: string;
  useRoler: string;
  userBranch: string;
  documentType: string;
  userDocumentType: string;
  phone: string;
  token: string;
  user?: string;

  public static fromResponse(json: any): UserInfoModel {
    const loginModel: UserInfoModel = {
      document: json['DOCUMENTO'],
      userDocument: json['DOCUMENTO_USUARIO'],
      email: json['EMAIL'],
      company: json['EMPRESA'],
      quotaStudy: json['ESTUDIO_CUPO'],
      enterDate: json['FECHA_INGRESO'],
      userPhoto: json['FOTO_USUARIO'],
      ip: json['IP'],
      companyLogo: json['LOGO_EMPRESA'],
      message: json['MENSAJE'],
      birth: json['NACIMIENTO'],
      name: json['NOMBRE'],
      quotaReport: json['REPORTE_CUPOS'],
      useRoler: json['ROL_USUARIO'],
      userBranch: json['SUCURSAL_USUARIO'],
      documentType: json['TIPO_DOCUMENTO'],
      userDocumentType: json['TIPO_DOCUMENTO_USUARIO'],
      phone: json['CELULAR'],
      token: json['TOKEN'],
      user: json['USUARIO'],
    };
    return loginModel;
  }

  public static toResponse(loginModel: UserInfoModel): any {
    return {
      DOCUMENTO: loginModel.document,
      DOCUMENTO_USUARIO: loginModel.userDocument,
      EMAIL: loginModel.email,
      EMPRESA: loginModel.company,
      ESTUDIO_CUPO: loginModel.quotaStudy,
      FECHA_INGRESO: loginModel.enterDate,
      FOTO_USUARIO: loginModel.userPhoto,
      IP: loginModel.ip,
      LOGO_EMPRESA: loginModel.companyLogo,
      MENSAJE: loginModel.message,
      NACIMIENTO: loginModel.birth,
      NOMBRE: loginModel.name,
      REPORTE_CUPOS: loginModel.quotaReport,
      ROL_USUARIO: loginModel.useRoler,
      SUCURSAL_USUARIO: loginModel.userBranch,
      TIPO_DOCUMENTO: loginModel.documentType,
      TIPO_DOCUMENTO_USUARIO: loginModel.userDocumentType,
      TOKEN: loginModel.token,
    };
  }
}
