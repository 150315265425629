import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule, LocationStrategy, HashLocationStrategy } from '@angular/common';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { AppComponent } from './app.component';
import { ToastrModule } from 'ngx-toastr';

import { ChartsModule } from 'ng2-charts';
import { HttpClientModule } from '@angular/common/http';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { AuthService } from './services/auth.service';
import { AppRoutingModule } from './app-routing.module';


// Directives
import { } from '@angular/cdk/keycodes';
import { ColorPickerModule } from 'ngx-color-picker';
import { ChartStateComponent } from './components/layout/chart-state/chart-state.component';
import { HelpComponent } from './components/help/help.component';
import { ExperienceComponent } from './components/experienceRating/experience.component';
import { QuestionsComponent } from './components/experienceRating/questions-component/questions.component';
import { BgUrlPipe } from './pipes/bg-url.pipe';
import { DocumentTypePipe } from './pipes/document-type.pipe';
import { PagesComponent } from './pages/pages.component';
import { PagesModule } from './pages/pages.module';
import { SharedModule } from './shared/shared.module';
import { AuthComponent } from './auth/auth.component';
import { LoginComponent } from './auth/login/login.component';
import { PasswordComponent } from './auth/password/password.component';
import { RolByIdPipe } from './pipes/rol-by-id.pipe';
import { ComponentsModule } from './components/components.module';

@NgModule({
  declarations: [
    AppComponent,
    ChartStateComponent,
    HelpComponent,
    ExperienceComponent,
    QuestionsComponent,
    PagesComponent,
    AuthComponent,
    LoginComponent,
    PasswordComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    PagesModule,
    SharedModule,
    ChartsModule,
    ScrollingModule,
    Ng2SearchPipeModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ColorPickerModule,
    ComponentsModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      progressBar: true,
      progressAnimation: 'decreasing',
      maxOpened: 1,
      autoDismiss: true,
      closeButton: true
    })
  ],
  providers: [
    AuthService,
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  exports: [
    BgUrlPipe,
    DocumentTypePipe,
    RolByIdPipe,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
