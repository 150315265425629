import {
  Component,
  OnInit,
  HostListener,
  ChangeDetectorRef,
  DoCheck,
  AfterViewChecked,
  NgZone,
} from "@angular/core";
import { ModalService } from "../services/modal.service";
import { UsersService } from "../services/users.service";
import { PermissionModel } from "@models/permission.model";
import { CONSTANTS } from "@config/constants";
import { SubjectService } from "@app/services/subject.service";
import { Observable } from "rxjs";
import { AuthService } from "@services/auth.service";

@Component({
  selector: "app-pages",
  templateUrl: "./pages.component.html",
  styleUrls: ["./pages.component.scss"],
})
export class PagesComponent implements OnInit, AfterViewChecked {
  public navigation = [
    {
      idOption: "1",
      alt: "Transacciones",
      img: "icon-transactions.svg",
      path: "/pages/transacciones",
    },
    {
      idOption: "8",
      alt: "Solicitar cupo",
      img: "icon-client-study.svg",
      path: "/pages/estudio-de-cliente",
    },
    {
      idOption: "9",
      alt: "Operaciones",
      img: "icon-operations.svg",
      path: "/pages/operaciones",
    },
    // {
    //   alt: 'Reclamaciones',
    //   img: 'icon-claims.svg',
    //   path: '/pages/reclamaciones',
    // },
    {
      idOption: "14",
      alt: "Reintegros",
      img: "icon-claims.svg",
      path: "/pages/reintegros",
    },
    {
      idOption: "16",
      alt: "Acuerdos de pago",
      img: "icon-agreements.svg",
      path: "/pages/acuerdos-de-pago",
    },
    {
      idOption: "17",
      alt: "Reportes",
      img: "icon-reports.svg",
      path: "/pages/reportes",
    },
    {
      idOption: null,
      alt: "Administración",
      img: "icon-administration.svg",
      path: "/pages/administracion/mis-pagos",
      subItems: [
        {
          idOption: "26",
          alt: "Mis pagos",
          img: "icon-my-payments.svg",
          path: "/pages/administracion/mis-pagos",
        },
        {
          idOption: "29",
          alt: "Usuarios",
          img: "icon-users.svg",
          path: "/pages/administracion/usuarios",
        },
        {
          idOption: "32",
          alt: "Documentos",
          img: "icon-documents.svg",
          path: "/pages/administracion/principal",
        },
      ],
    },
  ];

  public collapseMenu: Observable<boolean> = this.subject.stateMenu$;
  public collapseHeader: Observable<boolean> = this.subject.stateHeader$;
  public showSubMenu: boolean = false;
  public permissions: Array<PermissionModel>;
  public quotaStudy: boolean;
  public constants = CONSTANTS;
  public innerWidth: number;
  public onLaptopBreakpoint: boolean = true;
  public onMobileBreakpoint: boolean = false;
  public showMenu: boolean = false;
  public showHeader: boolean = false;
  public count: number = 0;
  public valHidenSubMenu: string = "";
  public modalOpened : boolean = false;

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    this.updatedResize();
  }

  constructor(
    private auth: AuthService,
    private modal: ModalService,
    private usersService: UsersService,
    public subject: SubjectService,
    private cdRef: ChangeDetectorRef,
    public zone: NgZone
  ) {}

  ngOnInit() {
    this.collapseHeader.subscribe((show: boolean) => {
      this.zone.run(() => (this.showHeader = show));
    });
    this.updatedResize();
    this.handlePermissions();
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  updatedResize() {
    this.innerWidth = window.innerWidth;
    this.onLaptopBreakpoint =
      this.innerWidth > 200 && this.innerWidth < 1600 ? true : false;
    this.onMobileBreakpoint =
      this.innerWidth > 0 && this.innerWidth < 768 ? true : false;
    this.subject.menuCollapse(this.onLaptopBreakpoint);
    this.subject.isMobile(this.onMobileBreakpoint);
  }

  handlePermissions() {
    this.permissions = this.usersService.permissions;
    this.quotaStudy = this.usersService.quotaStudy;
    this.navigation = this.navigation.map((page) => {
      const { idOption, subItems, ...rest } = page;
      const permissionStatus = this.permissions.filter(
        (permission) => permission.idOption === idOption
      );
      let status;
      if (page.idOption === "8") {
        status = !this.quotaStudy
          ? CONSTANTS.PERMISSIONS.DISABLED
          : permissionStatus.length > 0
          ? permissionStatus[0].status
          : CONSTANTS.PERMISSIONS.ACTIVE;
      } else {
        status =
          permissionStatus.length > 0
            ? permissionStatus[0].status
            : CONSTANTS.PERMISSIONS.ACTIVE;
      }
      return {
        idOption,
        status,
        subItems: subItems
          ? subItems.map((item) => {
              const permissionStatus = this.permissions.filter(
                (permission) => permission.idOption === item.idOption
              );

              const { idOption, ...rest } = item;
              return {
                idOption,
                status:
                  permissionStatus.length > 0
                    ? permissionStatus[0].status
                    : CONSTANTS.PERMISSIONS.ACTIVE,
                ...rest,
              };
            })
          : [],
        ...rest,
      };
    });
  }

  handleDropdown(item?: string, subItems?: any) {
    this.showSubMenu = item === "Administración" ? true : false;

    if (this.showSubMenu) {
      if (this.count === 1) {
        this.count = 0;
        this.valHidenSubMenu = "collapse";
      }else{
        this.valHidenSubMenu = "visible";
        this.count++;
      }
    }

    if (
      (this.onMobileBreakpoint && !this.showSubMenu) ||
      (this.onMobileBreakpoint && subItems)
    ) {
      this.collapseSidebar();
    }
  }

  validaHidden(): string {
    return this.valHidenSubMenu;
  }

  enter(path: string) {
    this.collapseMenu.subscribe((show: boolean) => (this.showMenu = show));
    if (this.onLaptopBreakpoint && this.showMenu) {
      this.subject.sendText(path);
    }
  }

  leave() {
    if (this.onLaptopBreakpoint && this.showMenu) {
      this.subject.sendText("");
    }
  }

  showModalLogOut() {
    (window as any).gtag("event", "cerrar-sesion-sin-conrmar", {
      event_category: "cerrar-sesión",
      event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.userInfo.email}`,
    });
    this.modal.show("logOut");
  }

  collapseSidebar() {
    this.showMenu = !this.showMenu;
    this.subject.menuCollapse(this.showMenu);
  }

  openWhatsapp() {
    (window as any).gtag('event', 'ayuda-sac', {
      event_category: 'con-login',
      event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
    });
    window.open(
      'https://covinoc.com/w/?telefono=573187116639&utm_source=COVIFACTURA&utm_medium=WEB&texto=&utm_content=boton_web&plantilla=WEB&utm_term=COVIFACTURA&utm_campaign=COVIFACTURA_WEB&campaign=COVIFACTURA_WEB',
      '_newtab'
    );
  }
  
  openExperience(event: any) {
    this.modalOpened = !this.modalOpened;
  }
}
