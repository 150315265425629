import * as moment from 'moment';

export class SellersResponse {
  approvedTransactions: number = 0;
  amount: number = 0;
  userName: string;
  email: string;
  userPhoto: any = 'assets/img/administration/icon-change-img-profile.svg';
  birthday: Date;
  rol: string;
  cellphone: string;

  public static fromResponse(json: any): any {
    const sellersResponse: SellersResponse = {
      approvedTransactions: json['APROBADAS'],
      amount: json['MONTO'],
      userName: json['NOMBRE_USUARIO'],
      email: json['EMAIL'],
      userPhoto: json['FOTO_USUARIO'],
      birthday: moment(json['FECHA_CUMPLEANOS'], 'YYYY-MM-DD').toDate(),
      rol: json['ROL'],
      cellphone: json['CELULAR'],
    };
    return sellersResponse;
  }
}
