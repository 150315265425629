import { map, pluck } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import {
  MyPaymentsRequest,
  MyPaymentsResponse,
  MyPaymentExportRequest,
} from '@models/myPayments.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PermissionModel } from '../models/permission.model';
import { UserModel } from '../models/user.model';
import { GeneratePaymentRequest } from '../models/generatePayment.model';
import { NetsuiteRequest } from '../models/netsuiteBill.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MyPaymentsService {
  private urlApi: string;
  private urlApiPayzen: string;

  public userToken: any;
  public identity: UserModel;
  public permissions: Array<PermissionModel>;
  public xml: any;

  constructor(private http: HttpClient) {
    this.urlApi = environment.urlApi;
    this.urlApiPayzen = environment.urlApiPayzen;
    this.readToken();
    this.getIdentity();
  }

  readToken() {
    if (localStorage.getItem('tokenCF')) {
      this.userToken = localStorage.getItem('tokenCF');
    } else {
      this.userToken = '';
    }
    return this.userToken;
  }

  getIdentity() {
    return (this.identity = UserModel.fromResponse(
      JSON.parse(localStorage.getItem('user'))
    ));
  }

  getMyPayments(myPaymentsRequest: MyPaymentsRequest) {
    const { userInfo, username } = this.identity;
    myPaymentsRequest.documentNumber = userInfo.document;
    myPaymentsRequest.documentType = userInfo.documentType;
    const headers = new HttpHeaders().set('token', this.userToken);
    const options = {
      params: MyPaymentsRequest.toRequest(myPaymentsRequest),
      headers,
    };

    return this.http.get(`${this.urlApi}/pr_mispagos_v2`, options).pipe(
      map((paymentsResponse: Array<MyPaymentsResponse>) => {
        let payments: Array<MyPaymentsResponse> = [];
        payments =
          paymentsResponse['FACTURAS'] &&
          paymentsResponse['FACTURAS'].length > 0
            ? paymentsResponse['FACTURAS'].map((payment: MyPaymentsResponse) =>
                MyPaymentsResponse.fromResponse(payment)
              )
            : [];
        return payments;
      })
    );
  }

  exportMyPayment(payment: MyPaymentExportRequest) {
    const { userInfo, username } = this.identity;
    payment.documentNumber = userInfo.document;
    payment.documentType = userInfo.documentType;
    const headers = new HttpHeaders().set('token', this.userToken);
    const options = {
      params: MyPaymentExportRequest.toRequest(payment),
      headers,
    };

    return this.http.get(
      `${this.urlApi}/pr_exportar_mispagos_detalle_factura`,
      {
        headers: options.headers,
        params: options.params,
        responseType: 'blob',
      }
    );
  }

  generatePayment(payment: GeneratePaymentRequest) {
    const { userInfo, username } = this.identity;
    payment.documentNumber = userInfo.document;
    payment.documentType = userInfo.documentType;
    const headers = new HttpHeaders().set('token', this.userToken);
    const options = {
      params: GeneratePaymentRequest.toRequest(payment),
      headers,
    };

    return this.http
      .put(`${this.urlApi}/pr_generar_pago?`, null, options)
      .pipe(pluck('url'));
  }

  generatePayzenPayment(payment: MyPaymentsResponse) {
    const headers = new HttpHeaders().set('token', this.userToken);
    const options = {
      params: MyPaymentsResponse.toRequest(payment),
      headers,
    };

    return this.http
      .get(`${this.urlApiPayzen}/retornar_datos_pago_payzen_prod`, options)
      .pipe(
        map((resp: any) => {
          const payzenValues = Object.keys(resp).map((payzenKey) => {
            return {
              key: payzenKey,
              value: resp[payzenKey],
            };
          });
          return payzenValues;
        })
      );
  }

  getNetsuiteBill(netsuiteBill: NetsuiteRequest, selectedBill: string) {
    const { userInfo } = this.identity;
    netsuiteBill.documentNumber = userInfo.document;
    netsuiteBill.documentType = userInfo.documentType;
    netsuiteBill.urlEndpoint = environment.urlEndpoint;
    netsuiteBill.strService = 'consultarArchivos';
    netsuiteBill.xmlParams = this.xmlParams(selectedBill);

    const headers = new HttpHeaders().set('token', this.userToken);
    const options = {
      params: NetsuiteRequest.toRequest(netsuiteBill),
      headers,
    };

    return this.http
      .get(`${this.urlApi}/pr_NetsuiteFactura`, options)
      .pipe(
        pluck(
          'S:Envelope',
          'S:Body',
          'ns2:consultarArchivosResponse',
          'return',
          'listaArchivos',
          'streamFile'
        )
      );
  }

  xmlParams(bill: string) {
    const consecutive = bill.substring(3, bill.length);
    const prefix = bill.substring(0, 3);
    return (this.xml = `<!--Optional:--><consecutivo>${consecutive}</consecutivo><!--Optional:--><contrasenia>PwC1v8n9cS0S</contrasenia><!--Optional:--><idEmpresa>1084</idEmpresa><!--Optional:--><prefijo>${prefix}</prefijo><tipoArchivo>1</tipoArchivo><!--Optional:--><tipoDocumento>1</tipoDocumento><!--Optional:--><token>45b3616496c44518e8c23c008b87ceab37e5a5c0</token><!--Optional:--><usuario>UCovinocSAS</usuario><!--Optional:--><version>10</version>
      `);
  }
}
