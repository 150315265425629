import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalService } from '@services/modal.service';
import { Router } from '@angular/router';
import { DocumentFile } from '@models/documentFile.model';
import { CONSTANTS } from '@config/constants';
import { TransactionService } from '@services/transaction.service';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'app-download-upload-excel',
  templateUrl: './download-upload-excel.component.html',
  styleUrls: ['./download-upload-excel.component.scss'],
})
export class DownloadUploadExcelComponent implements OnInit {
  public activeContent = 'empty-state';
  public title: string;
  public emptyImg: string;
  public documentFiles: Array<DocumentFile>;

  @Input() type: string;

  constructor(
    private auth: AuthService,
    private spinner: NgxSpinnerService,
    public modal: ModalService,
    public router: Router,
    public transaction: TransactionService
  ) {}

  ngOnInit() {
    this.validateType();
    this.documentFiles = this.transaction.isDocumentFiles();
    if (!this.documentFiles) {
      this.transaction.getDocumentFiles().subscribe(
        (documentFiles: Array<DocumentFile>) => {
          this.documentFiles = documentFiles;
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
        }
      );
    }
  }

  validateType() {
    switch (this.type) {
      case 'noPay':
        this.title = 'Aviso de no pago masivo';
        this.emptyImg = 'assets/img/operations/icon-empty-massive-nopay.svg';
        break;

      case 'massiveExoneration':
        this.title = 'Exoneración masiva';
        this.emptyImg =
          'assets/img/operations/icon-empty-massive-exoneration.svg';
        break;

      default:
        this.title = 'Carga masiva';
        break;
    }
  }

  dowloadFileName() {
    switch (this.type) {
      case 'noPay':
        return CONSTANTS.DOCUMENTS.BULK_UPLOAD_NONPAYMENT_FORMAT;

      case 'massiveExoneration':
        return CONSTANTS.DOCUMENTS.BULK_UPLOAD_EXONERATION_FORMAT;

      default:
        return CONSTANTS.DOCUMENTS.BULK_UPLOAD_RELEASE_FORMAT;
    }
  }

  downloadExcel = () => {
    let documentName = this.dowloadFileName();
    const documentFile: DocumentFile = this.documentFiles.find(
      (documentFile: DocumentFile) => {
        return documentFile.documentName === documentName;
      }
    );
    window.open(documentFile.url, '_newtab');
  };

  openSelector() {
    if (this.type === 'noPay') {
      (window as any).gtag('event', 'cargue-archivo', {
        event_category: 'aviso-masiv',
        event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
      });
    } else {
      (window as any).gtag('event', 'exoneracion-total', {
        event_category: 'exoneracion-masiv',
        event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
      });
    }
    this.modal.show('fileUpload');
  }

  goToStart() {
    this.activeContent = 'empty-state';
  }
}
