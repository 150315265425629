import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Question } from '@app/models/question.model';
import { ResponseQuestion } from '@app/models/responseQuestion.model';
import { Response } from '@app/models/util/response.model';
import { SatisfactionService } from '@services/satisfaction.service';


@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss'],
})
export class QuestionsComponent implements OnInit {

  @Output() closeX: EventEmitter<boolean> = new EventEmitter();
  @Output() closeParentModal: EventEmitter<string> = new EventEmitter();

  public questionslist : Array<Question> = [];
  public question : Question;
  public emojiHappy : boolean = false;
  public emojiRegular : boolean = false;
  public emojiSad : boolean = false;
  public experienceBtn : boolean = false;
  public openResponse : string = null;

  public stars = [{
    id: 1,
    active: false
  },{
    id: 2,
    active: false
  },{
    id: 3,
    active: false
  },{
    id: 4,
    active: false
  },{
    id: 5,
    active: false
  }]

  private starResponse = null;
  public comments = "";
  private emojiResponse = null;
  private user;

  constructor(private router: Router, private satisfactionService: SatisfactionService ) {}

  ngOnInit() {
    this.user = localStorage.getItem("user")? JSON.parse(localStorage.getItem("user")) : {};
    this.getQuestions();
  }

  getQuestions() {
    this.satisfactionService.getQuestions(this.user.userInfo.USUARIO).subscribe((resp : Response<Question>) => {
        if(resp.data && resp.data.length > 0){
            this.questionslist = resp.data;
            this.question = resp.data[0];
            //CODIGO QUE SE VA A COMENTAR YA QUE SOLO ES PARA PROBRAR FLUJOS DE PANTALLA
            // this.question.emojiQuestion = true;
            // this.question.starQuestion = true;
        }
    });
  }

  activeStar(star: any){
    if(star.active) {
        this.starResponse = star.id -1;
        this.starResponse = this.starResponse == 0 ? null : this.starResponse;
        this.stars.forEach(item => {
            if(item.id >= star.id) item.active = false;  
        });
    } else if(!star.active) {
        this.starResponse = star.id;
        this.stars.forEach(item => {
            if(item.id <= star.id) item.active = true; 
        });
    }
  }
  
  emojiValue(emoji: string) {
    this.emojiResponse = emoji;
    this.emojiHappy = emoji == "Promotores" ? true : false
    this.emojiRegular = emoji == "Pasivos" ? true : false
    this.emojiSad = emoji == "Detractores" ? true : false
  }

  ClearValue(){
    this.emojiResponse = null;
    this.openResponse = null;
    this.starResponse = null;
    this.comments = null;
    this.stars = [{
      id: 1,
      active: false
    },{
      id: 2,
      active: false
    },{
      id: 3,
      active: false
    },{
      id: 4,
      active: false
    },{
      id: 5,
      active: false
    }]
  }

  closeModal(){
    this.experienceBtn = false;
    this.closeParentModal.emit("close");
  }

  saveResponse(){
    let responseQuestion : ResponseQuestion = new ResponseQuestion();
    if(this.question.emojiQuestion) responseQuestion.emojiResponse = this.emojiResponse;
    if(this.question.openedQuestion) responseQuestion.openedResponse = this.openResponse;
    if(this.question.starQuestion) responseQuestion.starResponse = this.starResponse;
    if(!this.question.openedQuestion) responseQuestion.responseComents = this.comments;
    responseQuestion.userDocument = this.user.userInfo.DOCUMENTO_USUARIO;
    responseQuestion.userId = this.user.userInfo.USUARIO;
    responseQuestion.questionId = this.question;
    responseQuestion.userDocumentType = this.user.userInfo.TIPO_DOCUMENTO_USUARIO;
    console.log(responseQuestion);
    this.satisfactionService.postResponseQuestion(responseQuestion).subscribe((resp: Response<ResponseQuestion>) => {
      this.ClearValue()
        if(resp.status == 201) {
          if (this.questionslist.length == 1) {
            this.question = new Question();
            this.experienceBtn = true;
            this.closeX.emit(false);
          }else{
            this.getQuestions();
          } 
        }
    });
  };
}