export class SellerReportRequest {
  idContry: string = '1';
  documentType: string = '';
  documentNumber: string = '';
  // userDocumentType: string = '';
  // userDocumentNumber: string;
  search?: string;
  page: number = 1;
  registers: number = 5;
  sellerUser: number = 0;

  public static toRequest(sellerReportRequest: SellerReportRequest): any {
    return {
      id_pais: sellerReportRequest.idContry,
      tipo_documento: sellerReportRequest.documentType,
      documento: sellerReportRequest.documentNumber,
      busqueda: sellerReportRequest.search || 0,
      pagina: sellerReportRequest.page,
      registros: sellerReportRequest.registers,
      vendedor_usuario: sellerReportRequest.sellerUser,
    };
  }

  public static toExportRequest(sellerReportRequest: SellerReportRequest): any {
    return {
      id_pais: sellerReportRequest.idContry,
      tipo_documento: sellerReportRequest.documentType,
      documento: sellerReportRequest.documentNumber,
      busqueda: sellerReportRequest.search || 0,
      vendedor_usuario: sellerReportRequest.sellerUser,
    };
  }
}

export class SellerReportResponse {
  name: string;
  userPhoto: string;
  approved: number;
  prQuota: number;
  amount: number;
  userDocumentType: string;
  userDocument: number;
  numberOfRecords: string;

  public static fromResponse(json: any): SellerReportResponse {
    const sellerReportResponse: SellerReportResponse = {
      name: json['NOMBRE_USUARIO'],
      userPhoto: json['FOTO_USUARIO'],
      approved: json['APROBADAS'],
      prQuota: json['CONSULTA_PR_CUPO'],
      amount: json['VALOR_GARANTIAS'],
      userDocumentType: json['TIPO_DOCUMENTO_USUARIO'],
      userDocument: json['DOCUMENTO_USUARIO'],
      numberOfRecords: json['N_REGISTROS'],
    };
    return sellerReportResponse;
  }
}
