import { OperationItem } from './operationItem.model';

export class ExonerateGuaranteRequest {
  documentType?: string;
  documentNumber?: string;
  userEmail?: string;
  exonerateArray: Array<OperationItem>;

  public static toRequest(
    exonerateGuaranteRequest: ExonerateGuaranteRequest
  ): any {
    return {
      id_pais: 1,
      tipo_documento: exonerateGuaranteRequest.documentType,
      documento: exonerateGuaranteRequest.documentNumber,
      usuario: exonerateGuaranteRequest.userEmail,
    };
  }
}
