export class EstadoGestionModel {
 mensajes?: number;
 posibleAcuerdo?: number;
 ilocalizado?: number;
 promesa?: number;
 renuente?: number;
 reclamo?: number;
 pagoParcial?: number;
 pagoTotal?: number;
 otros?: number;
}
