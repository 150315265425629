import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit {
  public showVideoModal: boolean = false;

  constructor() {}

  ngOnInit() {}

  showModal() {
    (window as any).gtag('event', 'ver-video', {
      event_category: 'sin-login',
      event_label: `conozca-mas`,
    });
    this.showVideoModal = !this.showVideoModal;
  }

  recordSocialEvent() {
    (window as any).gtag('event', 'social', {
      event_category: 'sin-login',
      event_label: 'boton-socialmedia',
    });
  }
}
