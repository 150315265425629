import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { HelperService } from "@services/helper.service";
import { AuthNitService } from "@app/services/auth-nit.service";
import { debounceTime } from "rxjs/operators";
@Component({
  selector: "app-select-input",
  templateUrl: "./select-input.component.html",
  styleUrls: ["./select-input.component.scss"],
})
export class SelectInputComponent implements OnInit {
  public type;
  public valueData;
  public validatePattern;
  public errorFormatDocument;
  cumple: boolean = false;
  responseData: any;

  @Input() threePayments: boolean = false;
  @Input() document: number;
  @Input() types: Array<any>;
  @Input() typeSelected: string='C';
  @Output() selectValue: EventEmitter<any> = new EventEmitter<any>();
  @Output() blurEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() checkValidNit: EventEmitter<any> = new EventEmitter();
  
  @Output() checkValid: EventEmitter<any> = new EventEmitter();
  constructor(public helper: HelperService, private authNitService: AuthNitService) {
  }

  ngOnInit() {
    if (this.typeSelected) {
      this.type = this.typeSelected
      if (this.document) {
        this.valueData = this.document;
        this.threePayments = true;
        this.valueDataChange();
      }
    }
  }

  valueDataChange(event?: any) {
    this.validatePattern = this.helper.validatePatternDocument(this.type);
    this.errorFormatDocument = this.validatePattern.test(this.valueData);
    this.selectValue.emit({
      documentType: this.type,
      document: this.valueData,
      patternValid: this.validatePattern.test(this.valueData),
    });
    if(this.type == "N"){
      /* this.valueDataNumberChange();  */
    }
    else{
      this.checkValid.emit(true);
    }
  }

  valueDataNumberChange() {
    this.cumple = false;
    this.checkValidNit.emit(false);
    let str = this.valueData;
    let nit = str.split("");
    let lastDigit = nit[nit.length - 1];
    console.log(lastDigit);
    nit.pop();
    var shortNit = nit.join("");
    this.authNitService
      .validatedNit(shortNit)
      .pipe(debounceTime(700))
      .subscribe({
        next: (response) => {
          if (!response["ok"]) return;
          else this.responseData = response;
          if (
            this.responseData["data"] &&
            this.responseData["data"] == lastDigit
          ){ 
            this.cumple = true;
            this.checkValidNit.emit(true);
          }
          else {
            this.cumple = false;
                      this.checkValidNit.emit(false);
          }
        },
        complete: () => {
        },
        error: (error) => {
          //console.log("este es un error", error);
        },
      });
  }

  onBlur($event) {
    this.blurEvent.emit();
  }
}
