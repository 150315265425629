import { Component, OnInit } from '@angular/core';
import { NoticeService } from '@services/notice.service';
import { ExonerationService } from '@services/exoneration.service';
import { HelperService } from '@services/helper.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MassiveRequest } from '@models/massiveRequest.model';
import { MassiveRecordResponse } from '@models/massiveRecordResponse.model';
import { CONSTANTS, Tabs } from '@config/constants';
import { UsersService } from '@services/users.service';
import { PermissionModel } from '@models/permission.model';
import { SubjectService } from '@app/services/subject.service';
import { PubSubService } from '@services/pubsub.service';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'app-operations-tabs',
  templateUrl: './operations-tabs.component.html',
  styleUrls: ['./operations-tabs.component.scss'],
})
export class OperationsTabsComponent implements OnInit {
  public navItemTabs = [
    {
      href: '#individual',
      description: 'Operaciones individuales',
      icon: 'icon-tab-individual-operation.svg',
      idOption: Tabs.OperacionesIndividuales,
    },
    {
      href: '#massive',
      description: 'Operaciones masivas',
      icon: 'icon-tab-massive-operation.svg',
      idOption: Tabs.OperacionesMasivas,
    },
  ];

  public type: string;
  public jsonFromExcelExonerations: any;
  public jsonFromExcelNoPay: any;
  public massiveRecivedRequetNoPay: MassiveRequest;
  public massiveRecivedRequetExoneration: MassiveRequest;
  public massiveResponseNoPay: Array<MassiveRecordResponse>;
  public massiveResponseExoneration: Array<MassiveRecordResponse>;
  public permissions: Array<PermissionModel>;
  // showMassive = false;
  // showIndividual = false;
  Tabs = Tabs;
  currentTab: Tabs;

  constructor(
    private auth: AuthService,
    public notice: NoticeService,
    public exoneration: ExonerationService,
    public helper: HelperService,
    private spinner: NgxSpinnerService,
    private subject: SubjectService,
    private pubSubService: PubSubService,
    private usersService: UsersService
  ) {}

  ngOnInit() {
    this.permissions = this.usersService.permissions;
    this.navItemTabs = this.navItemTabs
      .filter((navItemTab) => {
        const permissionStatus = this.permissions.filter(
          (permission) => permission.idOption == navItemTab.idOption
        );
        const showItem =
          permissionStatus.length > 0 &&
          permissionStatus[0].status === CONSTANTS.PERMISSIONS.ACTIVE;

        // switch (navItemTab.idOption) {
        //   case Tabs.OperacionesIndividuales:
        //     this.showIndividual = showItem;
        //     break;
        //   default:
        //     this.showMassive = showItem;
        //     break;
        // }
        return showItem;
      })
      .map((navItemTab) => {
        const permissionStatus = this.permissions.filter(
          (permission) => permission.idOption == navItemTab.idOption
        );
        if (permissionStatus.length > 0) {
          navItemTab['status'] = permissionStatus[0].status;
        }
        return navItemTab;
      });
    this.currentTab = this.navItemTabs[0]['idOption'];
  }

  setCurrentTab(item) {
    //console.log('item', item);
    this.currentTab = item.idOption;
  }

  // showTab(idOption) {
  //   let showItem = false;
  //   switch (idOption) {
  //     case Tabs.OperacionesIndividuales:
  //       showItem = this.showIndividual;
  //       break;
  //     default:
  //       showItem = !this.showIndividual && this.showMassive;
  //       break;
  //   }

  //   return showItem;
  // }

  massiveOperationType(type: string) {
    this.type = type;
    return this.type;
  }

  massiveNoPayResponseEvent(
    massiveResponseEvent: Array<MassiveRecordResponse>
  ) {
    this.massiveResponseNoPay = massiveResponseEvent;
    this.type = 'noPayStatus';
  }

  massiveExonerationResponseEvent(
    massiveResponseEvent: Array<MassiveRecordResponse>
  ) {
    this.massiveResponseExoneration = massiveResponseEvent;
    this.type = 'exonerationStatus';
  }

  uploadExcel(file: File) {
    if (this.type == 'noPay') {
      (window as any).gtag('event', 'seleccion archivo', {
        event_category: 'aviso-masivo',
        event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
      });
      this.spinner.show();
      this.notice.massiveUpload(file).subscribe(
        (massiveRequets: MassiveRequest) => {
          this.massiveRecivedRequetNoPay = massiveRequets;
          this.spinner.hide();
          this.type = 'massiveNoPay';
        },
        (err) => {
          this.spinner.hide();
        }
      );
    } else {
      (window as any).gtag('event', 'seleccion archivo', {
        event_category: 'exoneracion-masiva',
        event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
      });
      this.spinner.show();
      this.exoneration.massiveUpload(file).subscribe(
        (massiveRequets: MassiveRequest) => {
          this.massiveRecivedRequetExoneration = massiveRequets;
          this.spinner.hide();
          this.type = 'massiveExoneration';
        },
        (err) => {
          this.spinner.hide();
        }
      );
    }
  }

  back() {
    this.type = '';
    this.subject.removeFiles();
  }

  refresh(item) {
    if (item.idOption == Tabs.OperacionesIndividuales) {
      this.pubSubService.sendMessage(true);
    } else {
      this.type = '';
      this.subject.removeFiles();
    }
  }

  // excelUploadComplete(jsonFromExcel: any) {
  //   // Obtener la data del servicio y redireccionar al componente
  //   switch (this.type) {
  //     case 'exoneration':
  //       this.jsonFromExcelExonerations = jsonFromExcel;
  //       this.type = 'massiveExoneration';
  //       break;
  //     case 'noPay':
  //       this.jsonFromExcelNoPay = jsonFromExcel;
  //       this.type = 'massiveNoPay';
  //       break;
  //     case 'massiveExoneration':
  //       this.type = 'exonerationStatus';
  //       break;
  //     case 'massiveNoPay':
  //       this.type = 'noPayStatus';
  //       break;
  //     case 'exonerationStatus':
  //       this.type = '';
  //       break;
  //     case 'noPayStatus':
  //       this.type = '';
  //       break;

  //     default:
  //       this.type = '';
  //       break;
  //   }
  // }
}
