export class UsersRequest {
    idContry: string;
    documentType: string;
    documentNumber: string;
    page: number;
    registers: number;
    search?: string;
  
    public static toRequest(usersRequest: UsersRequest): any {
      return {
        id_pais: usersRequest.idContry,
        tipo_documento: usersRequest.documentType,
        documento: usersRequest.documentNumber,
        pagina: usersRequest.page,
        registros: usersRequest.registers,
        busqueda: usersRequest.search
      };
    }
  }
  