import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from '@services/modal.service';
import { HelperService } from '@services/helper.service';
import { SubjectService } from '@app/services/subject.service';

@Component({
  selector: 'app-operations',
  templateUrl: './operations.component.html',
  styleUrls: ['./operations.component.scss'],
})
export class OperationsComponent implements OnInit {
  public data1 = {
    tipo_documento: '',
    documento: '',
    codigo: '',
  };

  public activeEmptyState = true;

  public navItemTabs = [
    {
      href: '#individual',
      description: 'Operaciones individuales',
      icon: 'icon-tab-individual-operation.svg',
    },
    {
      href: '#massive',
      description: 'Operaciones masivas',
      icon: 'icon-tab-massive-operation.svg',
    },
  ];

  public DataSelectFake = [
    { value: 1, description: 'Opción 1' },
    { value: 2, description: 'Opción 2' },
    { value: 3, description: 'Opción 3' },
  ];

  public dataFromService = [{ prop1: 1, prop2: 2, prop3: 3, prop4: 4 }];

  constructor(
    private router: Router,
    public modal: ModalService,
    public helper: HelperService,
    public subject: SubjectService
  ) {}

  ngOnInit() {
    this.subject.headerCollapse(true);
  }

  ngOnDestroy() {
    this.subject.headerCollapse(false);
  }

  transaction2() {
    this.router.navigateByUrl('/pages/transaccion-individual');
  }

  excelUpload() {
    this.activeEmptyState = false;
  }

  showModalStatus() {
    this.modal.show('operationsDenied', { title: 'Aviso de no pago negado' });
  }
}
