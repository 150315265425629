import { Component, OnInit, OnDestroy } from '@angular/core';
import { ModalService } from '@services/modal.service';
import { Router } from '@angular/router';
import { HelperService } from '@services/helper.service';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'app-modal-logout',
  templateUrl: './modal-logout.component.html',
  styleUrls: ['./modal-logout.component.scss'],
})
export class ModalLogoutComponent implements OnInit, OnDestroy {
  constructor(
    private auth: AuthService,
    public modal: ModalService,
    public helper: HelperService,
    private router: Router
  ) {}

  ngOnInit() {}

  ngOnDestroy() {
    this.modal.close();
  }

  logOut() {
    (window as any).gtag('event', 'cerrar-sesion-conrmada', {
      event_category: 'cerrar-sesión',
      event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
    });
    this.helper.logOut();
  }
}
