import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { ModalService } from '@services/modal.service';

@Component({
  selector: 'app-modal-user-delete',
  templateUrl: './modal-user-delete.component.html',
  styleUrls: ['./modal-user-delete.component.scss']
})
export class ModalUserDeleteComponent implements OnDestroy {
 
  @Output() confirm: EventEmitter<object> = new EventEmitter();
  constructor(public modal: ModalService) {  }

    ngOnDestroy() {
      this.modal.close();
    }

    accept() {
      this.modal.close();
      this.confirm.emit();
    }

}

