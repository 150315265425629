import {
  Component,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { ModalService } from "@services/modal.service";
import { HelperService } from "@services/helper.service";
import { ClientStudyRequest } from "@models/clientStudyRequest.model";
import { Department } from "@models/department.model";
import { City } from "@models/city.model";
import { CONSTANTS } from "@config/constants";
import * as moment from "moment";
import { AuthNitService } from "@app/services/auth-nit.service";
import { debounceTime } from "rxjs/operators";

@Component({
  selector: "app-display-legal-person",
  templateUrl: "./display-legal-person.component.html",
  styleUrls: ["./display-legal-person.component.scss"],
})
export class DisplayLegalPersonComponent {
  @ViewChild("formCheck") formCheck;
  @Input() departments: Array<Department>;
  @Input() initialDateFields: Array<string>;
  @Input() addressFields: Array<string>;
  @Input() documentTypesOptions: Array<any>;
  @Input() companyTypeFields: Array<any>;
  @Input() expeditionCities: Array<City>;
  @Input() homeCities: Array<City>;
  @Input() lastSaveDate: string;
  @Input() threePayments: boolean;

  // Form
  @Input() clientStudyForm: ClientStudyRequest;
  @Output()
  requestClientStudy: EventEmitter<object> = new EventEmitter<object>();
  @Output()
  valueChanges: EventEmitter<object> = new EventEmitter<object>();
  @Output()
  validateChange: EventEmitter<object> = new EventEmitter<object>();

  public isRef1Valid = false;
  public isRef2Valid = false;
  public isAddressValid = false;
  public isOwnersValid = false;
  public isTermAccepted = false;
  public habilitar: boolean = false;
  public cumple: boolean = false;
  public constants = CONSTANTS;
  public max = new Date();
  public maxBirthDate = moment().subtract(18, "years").toDate();
  message =
    "Los campos marcados con * son obligatorios, diligéncielos antes de enviar el formulario.";
  messagecup = 'Ingrese un valor en "Monto de cupo a solicitar"';
  messageProductType = 'El tipo de producto es obligatorio, seleccione una opción (Covifactura - 3Pagos)';
  responseData: any;

  public option = {
    description: "pointsOfSale",
  };

  public validPattern: boolean = false;

  constructor(
    public helper: HelperService,
    public modal: ModalService,
    private authNitService: AuthNitService
  ) {}

  ngOnInit() {
    this.valueDataNumberChange();
  }
  valueDataNumberChange() {
    this.cumple = false;
    let str = this.clientStudyForm.sellerDocumentNumber;
    //console.log(str)
    let nit = str.split("");
    let lastDigit = nit[nit.length - 1];
    //console.log(lastDigit);
    nit.pop();
    var shortNit = nit.join("");
    if (shortNit.length >= 8) {
      this.authNitService
      .validatedNit(shortNit)
      .subscribe({
        next: (response) => {
          if (!response["ok"]) return;
          else this.responseData = response;
          // console.log("nit corto" + shortNit);
          if (
            this.responseData["data"] &&
            this.responseData["data"] == lastDigit
          )
            this.cumple = true;
          else this.cumple = false;
        },
        complete: () => {},
        error: (error) => {
          // console.log("este es un error", error);
        },
      });
    } else {
      this.cumple = false;
    }
  }
  setHabilitar(): void {
    this.habilitar = this.habilitar == true ? true : true;
  }
  setDesabilitar(): void {
    this.habilitar = this.habilitar == false ? false : false;
  }

  onBlur($event) {
    $event.currentTarget.value = $event.currentTarget.value.trim();
    this.valueChanges.emit();
  }

  onBlurValidate($event) {
    this.validateChange.emit();
    /* event.preventDefault(); */
    /* console.log('onblur');
     */
  }

  emitChanges($event) {
    this.valueChanges.emit();
  }

  setRef1Valid(value: boolean) {
    this.isRef1Valid = value;
  }

  setRef2Valid(value: boolean) {
    this.isRef2Valid = value;
  }

  setAddressValid(value: boolean) {
    this.isAddressValid = value;
  }

  setOwnersValid(value: boolean) {
    this.isOwnersValid = value;
  }

  setAcceptTerms(value: boolean) {
    this.isTermAccepted = value;
  }

  copyInfo() {
    this.clientStudyForm.contactCellular = this.clientStudyForm.cellular;
    this.clientStudyForm.contactEmail = this.clientStudyForm.email;
    this.clientStudyForm.contactFirstName =
      this.clientStudyForm.legalRepresentativeFirstName;
    this.clientStudyForm.contactSecondName =
      this.clientStudyForm.legalRepresentativeSecondName;
    this.clientStudyForm.contactFirstLastName =
      this.clientStudyForm.legalRepresentativeFistLastName;
    this.clientStudyForm.contactSecondLastName =
      this.clientStudyForm.legalRepresentativeSecondLastName;
  }

  getDocumentSelector(document: any) {
    this.validPattern = document.patternValid;
    if (document && document.documentType && document.document) {
      this.clientStudyForm.legalRepresentativeDocumentNumber =
        document.patternValid ? document.document : "";
      this.clientStudyForm.legalRepresentativeDocumentType =
        document.documentType;
    }
  }

  handleSubmit() {
    this.requestClientStudy.emit();
  }

}
