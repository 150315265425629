import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class MultimediaService {
  public urlApi: string ='https://admindigital.c0v.co';
  constructor(private http: HttpClient) {
  }

  getPopUp() {
    return this.http.get(`${this.urlApi}/api/popup-imgs/?populate=*`);
  }
}
