import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalService } from '@services/modal.service';
import { Router } from '@angular/router';
import { TransactionService } from '@services/transaction.service';
import { DocumentFile } from '@models/documentFile.model';
import { CONSTANTS } from '@config/constants';
import { MassiveRequest } from '@models/massiveRequest.model';
import { AuthService } from '@services/auth.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-tab-massive-transaction',
  templateUrl: './tab-massive-transaction.component.html',
  styleUrls: ['./tab-massive-transaction.component.scss'],
})
export class TabMassiveTransactionComponent implements OnInit {
  @Input() selectedTab: Observable<number>;
  @Output() changePage: EventEmitter<MassiveRequest> = new EventEmitter();

  public activeContent = CONSTANTS.EMAIL_TRANSACTION.EMPTY_STATE;
  public documentFiles: Array<DocumentFile>;
  public constants = CONSTANTS;

  constructor(
    private auth: AuthService,
    private spinner: NgxSpinnerService,
    public modal: ModalService,
    public transaction: TransactionService,
    public router: Router
  ) { }

  ngOnInit() {
    this.documentFiles = this.transaction.isDocumentFiles();
    if (!this.documentFiles) {
      this.transaction.getDocumentFiles().subscribe(
        (documentFiles: Array<DocumentFile>) => {
          this.documentFiles = documentFiles;
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
        }
      );
    }
  }

  downloadExcel = () => {
    const documentFile: DocumentFile = this.documentFiles.find(
      (documentFile: DocumentFile) => {
        return (
          documentFile.documentName === CONSTANTS.DOCUMENTS.BULK_UPLOAD_FORMAT
        );
      }
    );
    window.open(documentFile.url, '_newtab');
  }

  openSelector() {
    (window as any).gtag('event', 'cargue-archivo', {
      event_category: 'transaccion-masiv',
      event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
    });
    this.modal.show('fileUpload');
  }

  openHelp() {
    (window as any).gtag('event', 'cargue-archivo-asistido', {
      event_category: 'transaccion-masiv',
      event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
    });
    this.activeContent = 'help';
  }

  uploadExcel(file: File) {
    if (this.activeContent === CONSTANTS.EMAIL_TRANSACTION.HELP) {
      this.spinner.show();
      this.transaction.emailMassiveUpload(file).subscribe(
        (resp: any) => {
          this.modal.show('requestStatus');
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
        }
      );
    } else {
      (window as any).gtag('event', 'seleccion-archivo', {
        event_category: 'transaccion-masiv',
        event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
      });
      this.spinner.show();
      this.transaction.massiveUpload(file).subscribe(
        (massiveRequets: MassiveRequest) => {
          this.changePage.emit(massiveRequets);
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
        }
      );
    }
  }

  goToStart() {
    (window as any).gtag('event', 'archivo-asistido-cargue-exito', {
      event_category: 'transaccion-masiv',
      event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
    });
    this.activeContent = CONSTANTS.EMAIL_TRANSACTION.EMPTY_STATE;
  }
}
