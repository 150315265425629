export class ReserveListRequest {
  idContry: string;
  documentType: string;
  documentNumber: string;
  sellerDocumentType?: string;
  sellerDocumentNumber?: string;
  page: string;
  records: string;
  searchTerm: string;

  public static toRequest(reserveListRequest: ReserveListRequest): any {
    return {
      id_pais: reserveListRequest.idContry,
      busqueda: reserveListRequest.searchTerm,
      tipo_documento: reserveListRequest.documentType,
      documento: reserveListRequest.documentNumber,
      pagina: reserveListRequest.page,
      registros: reserveListRequest.records,
    };
  }

  public static toLegacyRequest(reserveListRequest: ReserveListRequest): any {
    return {
      id_pais: reserveListRequest.idContry,
      tipo_documento: reserveListRequest.documentType,
      documento: reserveListRequest.documentNumber,
      comprador_tipo_documento: reserveListRequest.sellerDocumentType,
      comprador_documento: reserveListRequest.sellerDocumentNumber,
      pagina: reserveListRequest.page,
      registros: reserveListRequest.records,
    };
  }
}
