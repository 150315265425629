import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { PermissionModel } from '../models/permission.model';
import { LoginModel, LoginRequestUpdatePassword, RecoverPasswordModel } from '@models/login.model';
import { UserModel } from '@models/user.model';
import { CONSTANTS } from '@app/config/constants';
import { Observable } from 'rxjs';
import { UserInfoModel } from '@app/models/userInfo.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // properties
  private urlApi: string;
  private urlLogin: string;
  public headers: HttpHeaders;
  public identity: UserModel;
  public userToken: any;
  private userTokenAlt: any;
  public permissions: Array<PermissionModel>;
  public quotaStudy: boolean;
  public quotaReport: boolean;
  private urlInteraction: string;
  private interactionToken: string;

  constructor(private http: HttpClient) {  
    this.urlApi = environment.urlApi;
    this.urlLogin = environment.urlApiDev;
    this.urlInteraction = environment.urlApiDev;
    this.interactionToken = environment.interactionToken;
    this.readToken();
    this.setPermisions();
    this.getIdentity();
  }

  migrateUser(user : LoginModel){
    //console.log("entro");
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set(
      "Authorization",
      "Basic " + btoa("hola:mundo")
    );
    const options = {headers};
    const body = {
      usuario: user.username,
      contrasenaPlana : user.password
    }
    return this.http.patch(`${this.urlLogin}/NewLogin/migration/migrateuser`, body, options).pipe(
      map((resp: any) => {
        console.log(resp);
        return;
  }))
  }

  login(user: LoginModel) {
    this.isAutenticated();
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const options = { params: LoginModel.toResponse(user), headers };

    return this.http.get(`${this.urlApi}/acceso_v3`, options).pipe(
      map((resp: any) => {
        // Expires
        const data = resp;
        let expiraToken = moment(new Date());
        expiraToken = expiraToken.add(1,"hours");
        localStorage.setItem('expiresCF', expiraToken.toDate().getTime().toString());
        localStorage.setItem(
          'user',
          JSON.stringify({
            userEmail: user.username,
            token: data['TOKEN'],
            userInfo: data,
          })
        );
        this.saveToken(data['TOKEN']);
        this.saveTokenAlterno(data['TOKEN_ALTERNO']);
        return data;
      })
    );
  }

  recoverPassword(username: string, option: string = CONSTANTS.USERS.RECOVER) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');/*.set(
      "Authorization","Basic " + btoa("hola:mundo")
    );*/
    const recoverPassword: RecoverPasswordModel = {
      username,
    };
    const options = {
      params: RecoverPasswordModel.toResponse(recoverPassword, option),
      headers,
    };

    return this.http.get(`${this.urlApi}/envio_olvide_contrasena`, options);
  }

  getFamilyList() {
    return this.http.get(`${this.urlApi}/listado_parentesco`).pipe(
      map((resp: any) => {
        localStorage.setItem('familyList', JSON.stringify(resp.PARENTESCO));
        return resp;
      })
    );
  }

  getCareersList() {
    return this.http.get(`${this.urlApi}/listado_carreras`).pipe(
      map((resp: any) => {
        localStorage.setItem('careerList', JSON.stringify(resp.CARRERAS));
        return resp;
      })
    );
  }

  getPermissions() {
    this.getIdentity();
    const roleParams: any = {
      id_pais: 1,
      tipo_documento: this.identity.userInfo.documentType,
      documento: this.identity.userInfo.document,
      idrol: this.identity.userInfo.useRoler,
    };
    const headers = new HttpHeaders().set('token', this.userToken);
    const options = { params: roleParams, headers };
    return this.http.get(`${this.urlApi}/pr_rol_opciones_v2`, options).pipe(
      map((resp: any) => {
        localStorage.setItem('permissions', JSON.stringify(resp));
        this.permissions = resp.MENU.map((permission) =>
          PermissionModel.fromResponse(permission)
        );
        this.quotaStudy = resp.ESTUDIO_CUPO && resp.ESTUDIO_CUPO === 'SI';
        this.quotaReport = resp.REPORTE_CUPOS && resp.REPORTE_CUPOS === 'SI';
        return resp;
      })
    );
  }

  setPermisions() {
    const permissions = localStorage.getItem('permissions');
    if (permissions) {
      const parsedPermissions = JSON.parse(permissions);
      if ("MENU" in parsedPermissions) {
        this.permissions = parsedPermissions.MENU.map((permission) =>
          PermissionModel.fromResponse(permission)
        );
        this.quotaStudy =
          parsedPermissions.ESTUDIO_CUPO &&
          parsedPermissions.ESTUDIO_CUPO === 'SI';
        this.quotaReport =
          parsedPermissions.REPORTE_CUPOS &&
          parsedPermissions.REPORTE_CUPOS === 'SI';
      }
    }
  }

  getGlobalData() {
    return this.http.get(`${this.urlApi}/inicializar`).pipe(
      map((resp: any) => {
        localStorage.setItem('documents', JSON.stringify(resp));
        return resp;
      })
    );
  }

  private saveToken(token: string) {
    this.userToken = token;
    localStorage.setItem('tokenCF', token);
    localStorage.setItem('tokenDate', new Date().toDateString());
  }
  private saveTokenAlterno(token: string) {
    this.userTokenAlt = token;
    localStorage.setItem('tokenALT', token);
  }


  readToken() {
    if (localStorage.getItem('tokenCF')) {
      this.userToken = localStorage.getItem('tokenCF');
    } else {
      this.userToken = '';
    }
    return this.userToken;
  }

  

  logout() {
    localStorage.removeItem('tokenCF');
    localStorage.removeItem('tokenALT');
  }

  getIdentity() {
    if (!localStorage.getItem('user')) {
      return;
    }
    return (this.identity = UserModel.fromResponse(
      JSON.parse(localStorage.getItem('user'))
    ));
  }

  isAutenticated(): boolean {
    const today = new Date().getTime();
    const expira = Number(localStorage.getItem('expiresCF'));
    if (
      localStorage.getItem('tokenCF') === undefined ||
      localStorage.getItem('tokenCF') === null ||
      expira < today
    ) {
      return false;
    } else {
      return this.userToken;
    }
  }
  

  // Examples:
  post(user: any) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const options = { params: user, headers };
    const body = { test: 123 };
    this.http.post('url', body, options);
  }

  put(user: any) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const options = { params: user, headers };
    const body = { test: 123 };
    this.http.post('url', body, options);
  }

  saveInteractionDate(body: any) {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic ' +  btoa(this.interactionToken)
   });
   const options = {
      headers,
   };

  return this.http.post(`${this.urlInteraction}/NewCoviFactura/CoviFactura/interaccion/guardar_interaccion`, body, options)
  }

  forceUpdatePassword(forceUpdateRequest: LoginRequestUpdatePassword, user: LoginModel): Observable<any> {
   
   forceUpdateRequest.user = user.username;
   let headers = new HttpHeaders().set('Content-Type', 'application/json');
   const options = { headers };
   return this.http.post(`${this.urlLogin}/NewLogin/migration/migrate_new_password`, forceUpdateRequest, options).pipe(
    map((resp: any) => {
      // Expires
      const data = resp.data;
      let expiraToken = moment(new Date());
      expiraToken = expiraToken.add(1,"hours");
      localStorage.setItem('expiresCF', expiraToken.toDate().getTime().toString());
      localStorage.setItem(
        'user',
        JSON.stringify({
          userEmail: user.username,
          token: data['TOKEN'],
          userInfo: data,
        })
      );
      this.saveToken(data['TOKEN']);
      this.saveTokenAlterno(data['TOKEN_ALTERNO']);
      return data;
    })
  );
  }

}
