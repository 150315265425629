import { Component, OnInit, Input } from '@angular/core';
import { ChartType } from 'chart.js';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { MultiDataSet, Label, Color, Colors } from 'ng2-charts';

@Component({
  selector: 'app-chart-report',
  templateUrl: './chart-report.component.html',
  styleUrls: ['./chart-report.component.scss'],
})
export class ChartReportComponent implements OnInit {
  // text and values
  @Input() title: string;
  @Input() valueTitle: number;
  @Input() label1: string;
  @Input() label2: string;
  @Input() valueLabel1: number;
  @Input() valueLabel2: number;
  @Input() isMoney: boolean;

  // Chart
  public doughnutChartLabels: Label[] = [];
  public doughnutChartData: MultiDataSet = [[], []];

  public doughnutChartOptions: ChartOptions & { annotation: any } = {
    responsive: true,
    maintainAspectRatio: false,
    annotation: {
      annotations: [{}],
    },
    legend: {
      display: false,
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          return ` ${data.labels[tooltipItem.index]}: ${
            this.isMoney ? '$ ' : ''
          }${data.datasets[0].data[tooltipItem.index].toLocaleString('es-CO', {
            minimumFractionDigits: 0,
          })}`;
        },
      },
    },
    plugins: {
      datalabels: {
        formatter: (value) => {
          return value.toLocaleString('en-US', {
            minimumFractionDigits: 2,
          });
        },
        color: 'transparent',
        anchor: 'end',
        align: 'left',
        font: {
          size: 12,
        },
      },
    },
  };

  public doughnutChartColors: Color[] = [
    {
      backgroundColor: ['rgba(19, 97, 158, 1', 'rgba(28, 176, 211, 1)'],
      hoverBackgroundColor: [
        'rgba(165, 165, 165, 1)',
        'rgba(165, 165, 165, 1)',
      ],
      borderColor: 'white',
    },
  ];
  public doughnutChartLegend = false;

  public doughnutChartType: ChartType = 'doughnut';

  constructor() {}

  ngOnInit() {
    this.doughnutChartLabels = [this.label1, this.label2];
    this.doughnutChartData = [[this.valueLabel1, this.valueLabel2], []];
  }
}
