import {
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import * as moment from "moment";
import { NgxSpinnerService } from "ngx-spinner";
import { ModalService } from "@services/modal.service";
import { HelperService } from "@services/helper.service";
import { Department } from "@models/department.model";
import { TransactionService } from "@services/transaction.service";
import { ClientStudyRequest } from "@models/clientStudyRequest.model";
import { ClientStudyResponse } from "@models/clientStudyResponse.model";
import { City } from "@models/city.model";
import { CONSTANTS } from "@config/constants";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from "@services/auth.service";
import { SubjectService } from "@app/services/subject.service";

export interface BuyerFau {
  radicado: string;
  comprador: string;
  destino: string;
}

@Component({
  selector: "app-client-study",
  templateUrl: "./client-study.component.html",
  styleUrls: ["./client-study.component.scss"],
})
export class ClientStudyComponent implements OnInit, OnDestroy {
  public departments: Array<Department>;
  public scolarships: Array<Department>;
  public modalSuccessfulFau: boolean = false;

  // Radio
  private radioSelected = "legal_person";
  public expeditionCities: Array<City> = [];
  public homeCities: Array<City> = [];
  public lastSaveDate = "";
  public resetError = false;
  public threePayments = false;
  public sixPayments = false;
  public queryCode = "";
  enableButton: boolean = false;
  totalFiles: Array<File[]> = [];
  filesUp = [];
  objFileTypes = [
    { name: "Declaración de renta último año fiscal", lengthMsg:56, montMin: 20000000, numberDoc:1 },
    { name: "Estados financieros del último periodo", lengthMsg:101, montMin: 50000000, numberDoc:2 },
    { name: "Estados financieros de los últimos 2 años", lengthMsg:118, montMin: 100000000, montMax: 100000001, numberDoc:3 },
  ];

  //public updateRefCities: Subject<boolean> = new Subject();
  private radio = [
    { name: "Persona Jurídica", value: "legal_person" },
    { name: "Persona Natural", value: "natural_person" },
  ];

  public initialDateFields = [
    "Menos de 1 año",
    "1 a 5 años",
    "5 a 10 años",
    "Más de 10 años",
  ];

  public addressFields = [
    "Calle",
    "Carrera",
    "Avenida",
    "Avenida Carrera",
    "Avenida Calle",
    "Circular",
    "Circunvalar",
    "Diagonal",
    "Manzana",
    "Transversal",
    "Vía",
    "Otra",
  ];

  public documentTypesOptions = [
    {
      LABEL: "CEDULA DE CIUDADANIA",
      ID: "C",
      VALUE: "C.C. CÉDULA DE CIUDADANIA",
    },
    {
      LABEL: "CEDULA DE EXTRANJERIA",
      ID: "E",
      VALUE: "C.E. CÉDULA DE EXTRANJERIA",
    },
  ];

  public companyTypeFields = [
    { LABEL: "Unipersonal", ID: "UNIPERSONAL" },
    { LABEL: "Sociedad", ID: "SOCIEDAD" },
  ];

  productTypeTemp;

  public clientStudyFormTest: ClientStudyRequest = {
    ref1TypCompany: "legal",
    ref2TypCompany: "legal",
    ref1TypPerson: "legal",
    ref2TypPerson: "legal",
    addressType: "Calle",
    addressFirst: "cl 24 a norte",
    addressSecond: "18",
    addressThird: "20",
    addressOther: "cl 24 a norte 18 20 conjunto alcalá casa 47",
    firstRefFirstName: "Alberto",
    firstRefSecondName: "",
    firstRefFistLastName: "Ramirez",
    firstRefSecondLastName: "",
    firstRefDepartment: "91",
    firstRefCity: "91263",
    firstRefPhone: "3113457684",
    firstRefEmail: "Alberto@gmail.com",
    secondRefFirstName: "Raul",
    secondRefSecondName: "",
    secondRefFistLastName: "Hernandez",
    secondRefSecondLastName: "",
    secondRefDepartment: "91",
    secondRefCity: "91263",
    secondRefPhone: "3243456786",
    secondRefEmail: "raul@gmail.com",
    clientType: "legal",
    pointsOfSale: true,
    modeWP: false,
    modeEmail: false,
    modeSMS: false,
    modePhone: false,
    expeditionDate: moment("24/10/2019", "DD/MM/YYYY").toDate(),
    sellerDocumentType: "C",
    sellerDocumentNumber: "860222222",
    checkDigit: "1",
    expeditionDepartment: "91",
    expeditionCity: "85263",
    companyType: "UNIPERSONAL",
    homeDepartment: "91",
    homeCity: "91263",
    cellular: "3002101010",
    phone: "3420011",
    email: "prueba@prueba.com",
    birthDate: moment("28/10/2019", "DD/MM/YYYY").toDate(),
    economicActivity: "mercadeo",
    quotaValue: 30000000,
    companyName: "prueba platafomra",
    legalRepresentativeDocumentType: "C",
    legalRepresentativeDocumentNumber: "52100100",
    legalRepresentativeFirstName: "Laura",
    legalRepresentativeSecondName: "",
    legalRepresentativeFistLastName: "Perez",
    legalRepresentativeSecondLastName: "",
    contactPosition: "mercadeo",
    contactFirstName: "Claudia",
    contactSecondName: "",
    contactFirstLastName: "Perez",
    contactSecondLastName: "",
    contactCellular: "3002202020",
    contactEmail: "prueba@prueba.com",
    owners: [
      {
        documentType: "C",
        documentNumber: "52101101",
        firstName: "Patricia",
        secondName: "",
        firstLastName: "Cespedes",
        secondLastName: "Martinez",
      },
    ],
    contractNumber: '',
    initialDate: 'Menos de 1 año',
    queryCode: '',
    scolarShip: '',
    occupationProfess: '',
    productType: 'F'
  };

  public clientStudyFormReset: ClientStudyRequest = {
    clientType: "legal",
    ref1TypCompany: "legal",
    ref2TypCompany: "legal",
    ref1TypPerson: "legal",
    ref2TypPerson: "legal",
    pointsOfSale: false,
    modeWP: false,
    modeEmail: false,
    modeSMS: false,
    modePhone: false,
    expeditionDate: null,
    addressType: "",
    addressFirst: "",
    addressSecond: "",
    addressThird: "",
    addressOther: "",
    sellerDocumentType: "",
    sellerDocumentNumber: "",
    checkDigit: "",
    expeditionDepartment: "",
    expeditionCity: "",
    companyType: "",
    firstRefFirstName: "",
    firstRefSecondName: "",
    firstRefFistLastName: "",
    firstRefSecondLastName: "",
    firstRefDepartment: "",
    firstRefCity: "",
    firstRefPhone: "",
    firstRefEmail: "",
    secondRefFirstName: "",
    secondRefSecondName: "",
    secondRefFistLastName: "",
    secondRefSecondLastName: "",
    secondRefDepartment: "",
    secondRefCity: "",
    secondRefPhone: "",
    secondRefEmail: "",
    legalRepresentativeFirstName: "",
    legalRepresentativeSecondName: "",
    legalRepresentativeFistLastName: "",
    legalRepresentativeSecondLastName: "",
    homeDepartment: "",
    homeCity: "",
    cellular: "",
    phone: "",
    email: "",
    birthDate: null,
    economicActivity: "",
    quotaValue: 0,
    companyName: "",
    legalRepresentativeDocumentType: "",
    legalRepresentativeDocumentNumber: "",
    contactPosition: "",
    contactFirstName: "",
    contactSecondName: "",
    contactFirstLastName: "",
    contactSecondLastName: "",
    contactCellular: "",
    contactEmail: "",
    owners: [
      {
        documentType: "",
        documentNumber: "",
        firstName: "",
        secondName: "",
        firstLastName: "",
        secondLastName: "",
      },
    ],
    contractNumber: "",
    initialDate: null,
    queryCode: '',
    scolarShip: '',
    occupationProfess: '',
    productType : ''   
  };

  public clientStudyForm: ClientStudyRequest = Object.assign(
    {},
    this.clientStudyFormReset
  );
  clientStudyResponse: any;
  clientStudyResponseData:any={};
  constructor(
    private auth: AuthService,
    public helper: HelperService,
    private spinner: NgxSpinnerService,
    private transactionService: TransactionService,
    public modal: ModalService,
    private router: Router,
    public activatedRoute: ActivatedRoute,
    public subject: SubjectService
  ) {}

  public covifacturaByService: boolean = false;
  public threePaymentsByService: boolean = false;
  private products: any[] = [];
  public options: any[] = [];
  public buyerDigitalFau: BuyerFau;

  ngOnInit() {

    this.selectedSubCategories();
    this.subject.headerCollapse(true);
    this.departments = this.helper.citiesDepartment;
    this.scolarships = this.helper.scolarship;
    const isThreepayments =
      this.activatedRoute.snapshot.queryParamMap.get("threepayments");
    if (isThreepayments) {
      this.threePayments = true;
      this.clientStudyForm.sellerDocumentType =
        this.activatedRoute.snapshot.queryParamMap.get("documentType");
      this.clientStudyForm.queryCode =
        this.activatedRoute.snapshot.queryParamMap.get("threepayments");
      this.clientStudyForm.sellerDocumentNumber =
        this.activatedRoute.snapshot.queryParamMap.get("documentNumber");
      this.clientStudyForm.clientType =
        this.clientStudyForm.sellerDocumentType === "N" ? "legal" : "natural";
      this.validateForm();
    }
    const isSixpayments =
      this.activatedRoute.snapshot.queryParamMap.get("sixpayments");
    if (isSixpayments) {
      this.sixPayments = true;
      this.clientStudyForm.sellerDocumentType =
        this.activatedRoute.snapshot.queryParamMap.get("documentType");
      this.clientStudyForm.queryCode =
        this.activatedRoute.snapshot.queryParamMap.get("sixpayments");
      this.clientStudyForm.sellerDocumentNumber =
        this.activatedRoute.snapshot.queryParamMap.get("documentNumber");
      this.clientStudyForm.clientType =
        this.clientStudyForm.sellerDocumentType === "N" ? "legal" : "natural";
      this.validateForm();
    }
    this.transactionService.getProductsByUser().subscribe((resp: any) => {
      this.products = resp;
      if(this.products.length > 0) {
        this.products.forEach(product => {
          if(product.PRODUCTO.substring(0,3) == "GLO" || product.PRODUCTO.substring(0,3) == "SEP") {
            if(!this.options.find(item => {
              return item.id == 1;
            })){
              this.options.push(
                {
                  id: 1,
                  value: "F",
                });
            }
            this.covifacturaByService = true;
            this.clientStudyForm.productType = "F"; 
          }
          if(product.PRODUCTO == "TRESPG") {
            if(!this.options.find(item => {
              return item.id == 3;
            })){
              this.options.push(
                {
                  id: 3,
                  value: "3",
                })
            }
            this.threePaymentsByService = true;
            this.clientStudyForm.productType =!this.covifacturaByService ? "3": "";
          }
        })
      }
      if(this.options.length >= 2){
        this.clientStudyFormReset.productType = "F";
      }
    });
    
  }

  ngOnDestroy() {
    this.subject.headerCollapse(false);
  }

  closeModal() {
    this.modalSuccessfulFau = false;
    this.clientStudyForm = Object.assign({}, this.clientStudyFormReset);
  }

  changeClientType(clientType) {
    this.productTypeTemp=this.clientStudyForm.productType;
    this.clientStudyForm = Object.assign({}, this.clientStudyFormReset);
    this.clientStudyForm.productType=this.productTypeTemp;
    this.clientStudyForm.clientType = clientType;
  }

  goToStart(status) {
    if (this.clientStudyForm.clientType === 'legal') {
      window.location.reload();
    } else {
      this.clientStudyForm = Object.assign({}, this.clientStudyFormReset);
    }

    if (status === 'A') {
      (window as any).gtag('event', 'aprobado', {
        event_category: 'estudio-cliente-respuesta',
        event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
      });
    } else if (status === 'N') {
      (window as any).gtag('event', 'negado', {
        event_category: 'estudio-cliente-respuesta',
        event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
      });
    } else {
      (window as any).gtag(
        "event",
        parseInt(this.clientStudyForm.quotaValue, 10) <= 20000000
          ? "pendiente-2-hrs"
          : "pendiente-24-hrs",
        {
          event_category: 'estudio-cliente-respuesta',
          event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
        }
      );
    }
    // this.router.navigateByUrl('/pages/transacciones');
  }

  closeErrorModal() {
    if (this.resetError) {
      this.resetError = false;
      this.clientStudyForm = Object.assign({}, this.clientStudyFormReset);
    }
  }

  radioSelectedItem() {
    const radioSel = this.radio.find(
      (Item) => Item.value === this.radioSelected
    );
    return radioSel.value;
  }

  expeditionDepartmentChanged(expeditionDepartmentId) {
    this.spinner.show();
    this.helper.getCities(expeditionDepartmentId).subscribe(
      (cities: Array<City>) => {
        this.expeditionCities = cities;
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  homeDepartmentChanged(expeditionDepartmentId) {
    this.spinner.show();
    this.helper.getCities(expeditionDepartmentId).subscribe(
      (cities: Array<City>) => {
        this.homeCities = cities;
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  valueChanges(change) {
    this.saveForm();
  }

  validateChange(change) {
    this.productTypeTemp = this.clientStudyForm.productType;
    this.validateForm();
    this.clientStudyForm.productType = this.productTypeTemp;
  }

  validateForm() {
    if (
      this.clientStudyForm.sellerDocumentNumber &&
      (this.clientStudyForm.clientType === CONSTANTS.CLIENT_STUDY.LEGAL ||
        this.clientStudyForm.sellerDocumentType)
    ) {
      this.spinner.show();
      this.transactionService.validateForm(this.clientStudyForm).subscribe(
        (error: boolean) => {
          if (this.clientStudyForm.homeDepartment) {
            this.homeDepartmentChanged(this.clientStudyForm.homeDepartment);
          }
          if (this.clientStudyForm.expeditionDepartment) {
            this.expeditionDepartmentChanged(
              this.clientStudyForm.expeditionDepartment
            );
          }
          if (
            this.clientStudyForm.firstRefDepartment ||
            this.clientStudyForm.secondRefDepartment
          ) {
            //this.updateRefCities.next(true);
          }
          this.spinner.hide();
          if (error) {
            (window as any).gtag('event', 'comprador-con-cupo', {
              event_category: 'estudio-cliente-respuesta',
              event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
            });
            this.resetError = true;
            this.modal.show("error", {
              title: "Error",
              message: "Este comprador ya tiene un cupo asignado",
            });
          }
        },
        (err) => {
          this.spinner.hide();
        }
      );
    } else if (!this.clientStudyForm.sellerDocumentNumber) {
      const prevClientType: string = this.clientStudyForm.clientType;
      this.clientStudyForm = Object.assign({}, this.clientStudyFormReset);
      this.clientStudyForm.clientType = prevClientType;
    }
  }

  saveForm() {
    if (
      this.clientStudyForm.sellerDocumentNumber &&
      (this.clientStudyForm.clientType === CONSTANTS.CLIENT_STUDY.LEGAL ||
        this.clientStudyForm.sellerDocumentType)
    ) {
      this.lastSaveDate = moment().format("YYYY-MM-DD HH:mm:ss");
      this.transactionService.saveForm(this.clientStudyForm).subscribe(
        (filingNumber: string) => {
          this.clientStudyForm.filingNumber = filingNumber;
        },
        (err) => {}
      );
    }
  }

  requestClientStudy($event) {
    if (this.threePayments || this.sixPayments) {
      // funcion obsoleta
      if (this.clientStudyForm.clientType === CONSTANTS.CLIENT_STUDY.LEGAL) {
        (window as any).gtag('event', 'solicitud-juridica', {
          event_category: 'transaccion-3pagos',
          event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
        });
      } else {
        (window as any).gtag('event', 'solicitud-natural', {
          event_category: 'transaccion-3pagos',
          event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
        });
      }
      this.requestThreePayments();
    } else {
      if (this.clientStudyForm.clientType === CONSTANTS.CLIENT_STUDY.LEGAL) {
        (window as any).gtag('event', 'solicitud-juridica', {
          event_category: 'estudio-cliente',
          event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
        });
      } else {
        (window as any).gtag('event', 'solicitud-natural', {
          event_category: 'estudio-cliente',
          event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
        });
      }
      this.requestClientStudyForm();
      
    }
    // this.saveInteractionThreePayments();
  }

  selectedSubCategories(){
   
      if(this.options.length > 0) {
        this.options.forEach(item => {

          console.log("Probando el id", item)
          if(item.id === 3) {
            console.log("tres pagos")
            //this.saveInteractionThreePayments()
          }
          else{
            console.log("Covifactura")
            //this.saveInteractionCovi()
          }
          
    });
  }

  }

  saveInteractionThreePayments(response: "success" | "error"){
    const data = {
      "plataforma": "COVIFACTURA WEB",
      "categoria": response === "success" ? "SOLICITAR_CUPO": "ERROR_SOLICITAR_CUPO",
      "subcategoria": "TRES_PAGOS",
      "tipoDocumentoAfiliado": this.auth.identity.userInfo.documentType,
      "numeroDocumentoAfiliado": this.auth.identity.userInfo.document,
      "tipoDocumentoComprador": this.clientStudyForm.clientType === 'legal' ? 'N': this.clientStudyForm.sellerDocumentType,
      "numeroDocumentoComprador": this.clientStudyForm.sellerDocumentNumber,
      "usuario_web": this.auth.identity.userInfo.user
    }
  
    this.auth.saveInteractionDate( data ).subscribe({
      next: () => {
      },
      error: () => {
        console.log('Verifique los datos');      
      },
    });
  }

  saveInteractionCovi(response: "success" | "error"){
    const data = {
      "plataforma": "COVIFACTURA WEB",
      "categoria": response === "success" ? "SOLICITAR_CUPO": "ERROR_SOLICITAR_CUPO",
      "subcategoria": this.clientStudyForm.productType === "F" ? "COVIFACTURA":"TRES_PAGOS",
      "tipoDocumentoAfiliado": this.auth.identity.userInfo.documentType,
      "numeroDocumentoAfiliado": this.auth.identity.userInfo.document,
      "tipoDocumentoComprador": this.clientStudyForm.clientType === 'legal' ? 'N': this.clientStudyForm.sellerDocumentType,
      "numeroDocumentoComprador": this.clientStudyForm.sellerDocumentNumber,
      "usuario_web": this.auth.identity.userInfo.user
    }
  
    this.auth.saveInteractionDate( data ).subscribe({
      next: () => {
      },
      error: () => {
        console.log('Verifique los datos');      
      },
    });
  }

  requestThreePayments() {
    this.spinner.show();
    this.transactionService
      .requestThreePayments(this.clientStudyForm)
      .subscribe(
        (clientStudyResponse: ClientStudyResponse) => {
          const name =
            this.clientStudyForm.clientType === CONSTANTS.CLIENT_STUDY.LEGAL
              ? this.clientStudyForm.companyName
              : `${this.clientStudyForm.legalRepresentativeFirstName} ${this.clientStudyForm.legalRepresentativeFistLastName}`;
          // const documentNumber =
          //   this.clientStudyForm.clientType !== 'legal'
          //     ? this.clientStudyForm.documentNumber
          //     : `${this.clientStudyForm.documentNumber}${this.clientStudyForm.checkDigit}`;
          this.spinner.hide();
          switch (clientStudyResponse.responseType) {
            case 0: {
              // aprobada
              const datesArray = clientStudyResponse.dates.split("~");
              const consecutiveArray =
                clientStudyResponse.consecutive.split("~");
              const date1 = moment(datesArray[0], "YYYYMMDD").format(
                "DD/MM/YYYY"
              );
              const date2 = moment(datesArray[1], "YYYYMMDD").format(
                "DD/MM/YYYY"
              );
              const date3 = moment(datesArray[2], "YYYYMMDD").format(
                "DD/MM/YYYY"
              );
              this.saveInteractionThreePayments("success");
              const consecutive = consecutiveArray[3];
              let response = {
                title: "¡Transacción exitosa!",
                threePayments: true,
                name,
                date1,
                date2,
                date3,
                consecutive,
                documentNumber: this.clientStudyForm.sellerDocumentNumber,
                billNumber: this.clientStudyForm.quotaValue,
                billValue: clientStudyResponse.approvedQuota,
                date: moment().format("DD/MM/YYYY"),
                code: clientStudyResponse.code,
              };

              if (datesArray.length > 3) {
                const date4 = moment(datesArray[3], "YYYYMMDD").format(
                  "DD/MM/YYYY"
                );
                const date5 = moment(datesArray[4], "YYYYMMDD").format(
                  "DD/MM/YYYY"
                );
                const date6 = moment(datesArray[5], "YYYYMMDD").format(
                  "DD/MM/YYYY"
                );

                response["date4"] = date4;
                response["date5"] = date5;
                response["date6"] = date6;
                response.consecutive = consecutiveArray[6];
              }

              (window as any).gtag("event", "transaccion-exitosa", {
                event_category: "transaccion-3pagos",
                event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.userInfo.email}`,
              });
              this.modal.show('requestApproved', response);
              /* this.modal.modal.modalUploadFile = true */
              break;
            }
            case 1: {
              // negado
              (window as any).gtag('event', 'transaccion-negada', {
                event_category: 'transaccion-3pagos',
                event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
              });
              this.saveInteractionThreePayments("success");
              this.modal.show("requestDenied", {
                title: "¡Transacción negada!",
                threePayments: true,
                name,
                documentNumber: this.clientStudyForm.sellerDocumentNumber,
                billNumber: this.clientStudyForm.quotaValue,
                date: moment().format("DD/MM/YYYY"),
                code: clientStudyResponse.code,
              });
              break;
            }
            case 2: {
              // radicada
              (window as any).gtag('event', 'transaccion-radicada', {
                event_category: 'transaccion-3pagos',
                event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
              });
              this.saveInteractionThreePayments("success");
              this.modal.show("requestSettled", {
                title: "¡Transacción radicada!",
                threePayments: true,
                name,
                documentNumber: this.clientStudyForm.sellerDocumentNumber,
                billNumber: this.clientStudyForm.quotaValue,
                date: moment().format("DD/MM/YYYY"),
                code: clientStudyResponse.code,
                hours: `${this.clientStudyForm.quotaValue
                  ? parseInt(this.clientStudyForm.quotaValue, 10) <= 20000000
                    ? 2
                    : 24
                  : 2
                  }`,
              });
              break;
            }
            case 3:
              // error
              this.saveInteractionThreePayments("error");
              this.modal.show("error", {
                title: "Error",
                message: clientStudyResponse.errorModalText,
              });
              break;

            default:
              break;
          }
        },
        (err) => {
          this.spinner.hide();
          this.saveInteractionThreePayments("error");
          this.modal.show("error", {
            title: "Error",
            message: "Datos inválidos",
          });
        }
      );
  }

  /*  onGetUploadFileParams($event) {
    this.propiedadQueCreaste = $event.propiedadQueCreaste;
    this.propiedadQueCreaste = $event.propiedadQueCreaste;
    this.propiedadQueCreaste = $event.propiedadQueCreaste;
  } */

  requestClientStudyForm() {
    this.spinner.show();
    this.transactionService.requestClientStudy(this.clientStudyForm).subscribe(
      (clientStudyResponseService: ClientStudyResponse | any) => {
        const name =
          this.clientStudyForm.clientType === CONSTANTS.CLIENT_STUDY.LEGAL
            ? this.clientStudyForm.companyName
            : `${this.clientStudyForm.legalRepresentativeFirstName} ${this.clientStudyForm.legalRepresentativeFistLastName}`;
        this.spinner.hide();
        this.clientStudyResponse = clientStudyResponseService.clientStudyResponse;
        this.clientStudyResponseData = clientStudyResponseService.response;

        switch (this.clientStudyResponse.responseType) {
          case 0:
            // aprobada
            this.saveInteractionCovi("success");
            this.modal.show("requestApproved", {
              title: "¡Su solicitud ha sido aprobada!",
              name,
              documentNumber: this.clientStudyForm.sellerDocumentNumber,
              billNumber: this.clientStudyForm.quotaValue,
              billValue: this.clientStudyResponse.approvedQuota,
              date: moment().format("DD/MM/YYYY"),
              code: this.clientStudyResponse.code,
            });
            break;
          case 1:
            // negado
            this.saveInteractionCovi("success");
            this.modal.show("requestDenied", {
              title: "¡Su solicitud ha sido negada!",
              name,
              documentNumber: this.clientStudyForm.sellerDocumentNumber,
              billNumber: this.clientStudyForm.quotaValue,
              date: moment().format("DD/MM/YYYY"),
              code: this.clientStudyResponse.code,
            });
            break;
          case 2:
              // radicada
              this.saveInteractionCovi("success");
              if ("MENSAJE_INTERNO " in clientStudyResponseService.response && clientStudyResponseService.response['MENSAJE_INTERNO '].length > 40 && this.clientStudyForm.quotaValue > 20000000) {
                // this.clientStudyResponse = clientStudyResponseService.response;
                this.modal.modal.modalUploadFile = true;
                break ;
              }else {
                this.modal.show('requestSettled', {
                  title: '¡Su solicitud ha sido radicada!',
                  name,
                  documentNumber: this.clientStudyForm.sellerDocumentNumber,
                  billNumber: this.clientStudyForm.quotaValue,
                  date: moment().format('DD/MM/YYYY'),
                  code: this.clientStudyResponse.code,
                  hours: `${this.clientStudyForm.productType == "3" ? 20
                    : this.clientStudyForm.quotaValue
                    ? parseInt(this.clientStudyForm.quotaValue, 10) <= 20000000
                      ? 2
                      : 24
                    : 2
                    }`,
                });
                break;
              }
              // radicada 1073179608
            
          case 3: {
            // error
            (window as any).gtag('event', 'datos-invalidoso', {
              event_category: 'estudio-cliente-respuesta',
              event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
            });
            this.saveInteractionCovi("error");
            this.modal.show('error', {
              title: 'Error',
              message: this.clientStudyResponse.errorModalText,
            });
            break;
          }
          default:break;
        }
      },
      (err: any) => {
        this.spinner.hide();
        this.saveInteractionThreePayments("error");
        this.modal.show("error", {
          title: "Error",
          message: "Datos inválidos",
        });
      }
    );
    // }
  }

  validFiles(idx){
    this.filesUp[idx]=true;
    if (this.filesUp.every(el => el)) this.enableButton = true;
  }

  onGetFiles(event) {
    this.totalFiles[event.idx] = event.event;
    console.log(this.totalFiles[event.idx])
    console.log(JSON.stringify(this.totalFiles))
  }

  removeItem(idx) {
    this.filesUp[idx]=false;
    this.enableButton = false;
  }

}
