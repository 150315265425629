import { DocumentsService } from '@services/documents.service';
import { DocumentResponse } from '@models/documentResponse.model';
import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalService } from '@app/services/modal.service';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
})
export class DocumentsComponent implements OnInit {
  public isValid = false;

  // Radio
  public radioSel: any;
  public radioSelected = 'transaccion_individual';
  @Input() public documentsResponse: Array<DocumentResponse>;
  documentsList:any=[]
  position=[]

  constructor(
    private auth: AuthService,
    public spinner: NgxSpinnerService,
    public modal: ModalService
  ) {}

  ngOnInit() {
    this.documentsResponse.map(el => {
      if ((el.documentId != 21 && el.documentId != 22) && el.visible.trim() === 'S'  ) {
        this.documentsList.push(el)
      }
    })
    this.position[0] = this.documentsResponse.findIndex(el => el.documentId == 21)
    //console.log(this.position[0], JSON.stringify(this.documentsResponse[this.position[0]]))
    this.position[1] = this.documentsResponse.findIndex(el => el.documentId == 22)
    //console.log(this.position[1], JSON.stringify(this.documentsResponse[this.position[1]]))
    this.position[2] = this.documentsResponse.findIndex(el => el.documentId == 23)
    //console.log(this.position[2], JSON.stringify(this.documentsResponse[this.position[2]]))
    this.position[3] = this.documentsResponse.findIndex(el => el.documentId == 24)
    //console.log(this.position[3], JSON.stringify(this.documentsResponse[this.position[3]]))
  }
  viewDocument(url: string, document: any) {
    (window as any).gtag(
      'event',
      document.document.replace(/ /g, '-').toLowerCase(),
      {
        event_category: 'documentos',
        event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
      }
    );
    this.spinner.show();
    window.open(url, '_blank');
    setTimeout(() => {
      this.spinner.hide();
    }, 1000);
  }
}
