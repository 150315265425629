import { Component, Input, OnInit } from '@angular/core';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'app-error-validation',
  templateUrl: './error-validation.component.html',
  styleUrls: ['./error-validation.component.scss']
})
export class ErrorValidationComponent {
  @Input() model: NgModel;
  @Input() requiredText = 'Campo requerido';
  @Input() patternText = 'Campo inválido';
  @Input() inputName = 'Campo';

  constructor() { }
}
