export class SellersRequest {
  idContry: string;
  documentType: string;
  documentNumber: string;
  search?: string;
  page: number;
  registers: number;

  public static toRequest(sellersRequest: SellersRequest): any {
    return {
      id_pais: sellersRequest.idContry,
      busqueda: sellersRequest.search || 0,
      tipo_documento: sellersRequest.documentType,
      documento: sellersRequest.documentNumber,
      pagina: sellersRequest.page,
      registros: sellersRequest.registers,
    };
  }
}
