import { NgForm } from '@angular/forms';
import { ChangePasswordRequest } from './../../../models/changePassword.model';
import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { ModalService } from '@services/modal.service';
import { isBoolean } from 'util';

@Component({
  selector: 'app-modal-profile-change-password',
  templateUrl: './modal-profile-change-password.component.html',
  styleUrls: ['./modal-profile-change-password.component.scss'],
})
export class ModalProfileChangePasswordComponent implements OnDestroy {
  @ViewChild('formChangePass') formChangePass: NgForm;
  @Input() changePassword: boolean = false;

  public changePassRequest: ChangePasswordRequest = new ChangePasswordRequest();
  public message = `La contraseña debe ser mínimo de 8 caracteres y 
                    tener al menos una letra minúscula, una mayúscula y un número`;
  public message2 = `Las contraseñas no coinciden.`;

  public hideActualPass = false;
  public hideNewPass = false;
  public hideConfirmNewPass = false;

  @Output() confirm: EventEmitter<object> = new EventEmitter<object>();
  @Output() onSave: EventEmitter<ChangePasswordRequest> =
    new EventEmitter<ChangePasswordRequest>();
  public continue: boolean = false;
  public passAreEquals: boolean = false;
  public closePassword: boolean = false;

  constructor(public modal: ModalService) {}

  ngOnInit() {
    if (typeof (this.changePassword) === 'boolean')  {
      this.closePassword = true;
    } else {
      this.closePassword = false;
    }
  }
  ngOnDestroy() {
    this.modal.close();
  }

  togglePassword(passwordStep) {
    switch (passwordStep) {
      case 1:
        this.hideActualPass = !this.hideActualPass;

        break;
      case 2:
        this.hideNewPass = !this.hideNewPass;
        break;
      default:
        this.hideConfirmNewPass = !this.hideConfirmNewPass;
        break;
    }
  }

  accept() {
    this.modal.close();
    this.confirm.emit();
  }

  passwordIsEqual() {
    const pass1 = this.changePassRequest.confirmNewPass;
    const pass2 = this.changePassRequest.newPass;
    const pass1Isvalid = this.formChangePass.form.controls['newPass'].valid;
    const pass2IsValid =
      this.formChangePass.form.controls['confirmNewPass'].valid;

    if (!pass1Isvalid && !pass2IsValid) {
      this.continue = false;
      return;
    }

    if (pass1 === pass2 && this.formChangePass.form.valid) {
      this.continue = true;
      this.passAreEquals = true;
    } else if (pass1 !== pass2 && this.formChangePass.form.valid) {
      this.continue = false;
      this.passAreEquals = false;
    } else {
      this.continue = false;
    }
  }

  changePass() {
    this.onSave.emit(this.changePassRequest);
    this.formChangePass.reset();
    this.modal.close();
  }
}
