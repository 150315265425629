import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from '@services/modal.service';
import { HelperService } from '@services/helper.service';
import { SubjectService } from '@app/services/subject.service';

@Component({
  selector: 'app-claims',
  templateUrl: './claims.component.html',
  styleUrls: ['./claims.component.scss'],
})
export class ClaimsComponent implements OnInit, OnDestroy {
  public data1 = {
    tipo_documento: '',
    documento: '',
    codigo: '',
  };

  public activeEmptyState = true;

  public navItemTabs = [
    {
      href: '#refunds',
      description: 'Reintegros',
      icon: 'icon-tab-refunds.svg',
    },
    {
      href: '#claims',
      description: 'Reclamaciones',
      icon: 'icon-tab-claims.svg',
    },
  ];

  public DataSelectFake = [
    { value: 1, description: 'Opción 1' },
    { value: 2, description: 'Opción 2' },
    { value: 3, description: 'Opción 3' },
  ];

  constructor(
    private router: Router,
    public modal: ModalService,
    public helper: HelperService,
    public subject: SubjectService
  ) {}

  ngOnInit() {
    this.subject.headerCollapse(true);
  }

  ngOnDestroy() {
    this.subject.headerCollapse(false);
  }

  transaction2() {
    this.router.navigateByUrl('/pages/transaccion-individual');
  }
}
