import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { UserModel } from "@models/user.model";
import { IndividualOperationRequest } from "@models/individualOperationRequest.model";
import { OperationItem } from "@models/operationItem.model";

@Injectable({
  providedIn: "root",
})
export class OperationService {
  private urlApi: string;
  public userToken: any;
  private tokenAlt: any;
  public identity: UserModel;

  constructor(private http: HttpClient) {
    this.urlApi = environment.urlApi;
    this.readToken();
    this.readTokenAlt();
    this.getIdentity();
  }

  readToken() {
    if (localStorage.getItem("tokenCF")) {
      this.userToken = localStorage.getItem("tokenCF");
    } else {
      this.userToken = "";
    }
    return this.userToken;
  }

  readTokenAlt() {
    if (localStorage.getItem("tokenALT")) {
      this.tokenAlt = localStorage.getItem("tokenALT");
    } else {
      this.tokenAlt = "";
    }
    return this.tokenAlt;
  }

  getIdentity() {
    return (this.identity = UserModel.fromResponse(
      JSON.parse(localStorage.getItem("user"))
    ));
  }

  getOperationList(individualOperationRequest: IndividualOperationRequest) {
    individualOperationRequest.documentNumber = this.identity.userInfo.document;
    individualOperationRequest.documentType =
      this.identity.userInfo.documentType;

      const headers = new HttpHeaders().set("token", this.tokenAlt);
      // const headers = new HttpHeaders().set("token", this.userToken);
    const options = {
      params: IndividualOperationRequest.toRequest(individualOperationRequest),
      headers,
    };
    this.urlApi = "https://wsclifac.c0v.co/api/CoviFactura2022/servicios";
    return (
      this.http.get(`${this.urlApi}/consulta1`, options).pipe(
        map((resp: any) => {
          const operations: Array<OperationItem> = resp.OPERACIONES_INDIVIDUALES
            ? resp.OPERACIONES_INDIVIDUALES.map((operationItem) =>
                OperationItem.fromResponse(operationItem)
              )
            : [];
          return operations;
        })
      )
    );
  }
}
