export class PermissionModel {
  idrol: string;
  idOption: string;
  description: string;
  status: string;

  public static fromResponse(json: any): PermissionModel {
    const permissionModel: PermissionModel = {
      idrol: json['IDROL'],
      idOption: json['ID_OPCION'],
      description: json['DESCRIPCION'],
      status: json['ESTADO'],
    };
    return permissionModel;
  }
}
