import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalService } from '@services/modal.service';
import { Router } from '@angular/router';
import { TransactionService } from '@services/transaction.service';
import { DocumentFile } from '@models/documentFile.model';
import { CONSTANTS } from '@config/constants';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'app-load-support',
  templateUrl: './load-support.component.html',
  styleUrls: ['./load-support.component.scss'],
})
export class LoadSupportComponent implements OnInit {
  public documentFiles: Array<DocumentFile>;
  constructor(
    private auth: AuthService,
    public spinner: NgxSpinnerService,
    public modal: ModalService,
    public transaction: TransactionService,
    public router: Router
  ) {}

  ngOnInit() {
    this.documentFiles = this.transaction.isDocumentFiles();
    if (!this.documentFiles) {
      this.transaction.getDocumentFiles().subscribe(
        (documentFiles: Array<DocumentFile>) => {
          this.documentFiles = documentFiles;
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
        }
      );
    }
  }

  downloadExcel = () => {
    const documentFile: DocumentFile = this.documentFiles.find(
      (documentFile: DocumentFile) => {
        return (
          documentFile.documentName === CONSTANTS.DOCUMENTS.BULK_UPLOAD_FORMAT
        );
      }
    );
    window.open(documentFile.url, '_newtab');
  };

  openSelector() {
    this.modal.show('fileUpload');
  }

  // uploadExcel() {
  //   this.modal.show('requestStatus');
  // }
}
