import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ModalService } from '@services/modal.service';

@Component({
  selector: 'app-modal-pdf',
  templateUrl: './modal-pdf.component.html',
  styleUrls: ['./modal-pdf.component.scss']
})
export class ModalPdfComponent implements OnDestroy {

  constructor(public modal: ModalService) { }

    ngOnDestroy() {
      this.modal.close();
    }

    accept() {
      this.modal.close();
    }
}
