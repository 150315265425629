import { Component, OnInit, Input } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalService } from '@services/modal.service';
import { SubjectService } from '@services/subject.service';
import { ToastrService } from 'ngx-toastr';
import { TransactionService } from '@services/transaction.service';
import { HelperService } from '@services/helper.service';
import { MassiveRequest } from '@models/massiveRequest.model';
import { MassiveRecord } from '@models/massiveRecord.model';
import { MassiveRecordResponse } from '@models/massiveRecordResponse.model';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'app-massive-transaction',
  templateUrl: './massive-transaction.component.html',
  styleUrls: ['./massive-transaction.component.scss'],
})
export class MassiveTransactionComponent implements OnInit {
  @Input() process = 'Transacción masiva';
  @Input() massiveRecivedRequet: MassiveRequest;

  public errorTitlesIndex: any = {
    'Tipo doc/to': 'documentType',
    'Número documento': 'documentNumber',
    'Número factura': 'valueTitle',
    'Valor factura': 'value',
    'Fecha vencimiento': 'date',
    'Código consulta': 'queryCode'
  };

  public errorTitlesIndexType: any = {
    'Tipo doc/to': 'string',
    'Número documento': 'number',
    'Número factura': 'string',
    'Valor deuda': 'number',
    'Fecha vencimiento': 'date',
    'Código consulta': 'number'
  };

  public errorTitles: any = [
    'Tipo doc/to',
    'Número documento',
    'Número factura',
    'Valor factura',
    'Fecha vencimiento',
    'Código consulta'
  ];

  public correctTitlesIndex: any = {
    'Tipo doc/to': 'documentType',
    'Número documento': 'documentNumber',
    'Número factura': 'valueTitle',
    'Valor factura': 'value',
    'Fecha vencimiento': 'date',
    'Código consulta': 'queryCode'
  };
  public correctTitles: any = [
    'Tipo doc/to',
    'Número documento',
    'Número factura',
    'Valor factura',
    'Fecha vencimiento',
    'Código consulta'
  ];

  public togglePopOver = false;
  public messageTooltip: string;

  // Effects
  public hoverEffect = false;
  public hoverDiv: number;

  public activeContent = true;
  public massiveRequest: MassiveRequest;
  public massiveResponse: Array<MassiveRecordResponse>;

  public currentPage = 1;
  public currentPageA = 0;
  public perPage = 10;
  public totalPages = 1;
  public correctRecords : Array<MassiveRecord>;

  constructor(
    private auth: AuthService,
    public spinner: NgxSpinnerService,
    public modal: ModalService,
    private toastr: ToastrService,
    public transaction: TransactionService,
    public subject: SubjectService,
    public helper: HelperService
  ) {}

  ngOnInit() {
    this.massiveRequest = this.massiveRecivedRequet;
    this.correctRecords = this.massiveRequest.correctRecords;

    this.totalPages = this.helper.amountOfPages(this.correctRecords, this.perPage);
    console.log(this.totalPages)
    this.massiveRequest.correctRecords = this.massiveRequest.correctRecords.slice(this.currentPage * this.perPage - this.perPage, this.currentPage * this.perPage);
  }

  pageSelected({ currentPage }) {
    this.currentPage = currentPage;
    this.massiveRequest.correctRecords = this.correctRecords.slice(this.currentPage * this.perPage - this.perPage, this.currentPage * this.perPage);
    this.currentPageA = currentPage - 1;
  }

  maskInput(index: number, errorTitle: string, $event) {
    switch (this.errorTitlesIndexType[errorTitle]) {
      case 'date':
        this.maskDate(index, errorTitle, $event.replace(/\D/g, ''));
        break;
      case 'number':
        this.massiveRequest.errorRecords[index][
          this.errorTitlesIndex[errorTitle]
        ] = $event.replace(/\D/g, '');
        break;

      default:
        this.massiveRequest.errorRecords[index][
          this.errorTitlesIndex[errorTitle]
        ] = $event;
        break;
    }
  }

  isOnlyNumber(errorTitle) {
    return (
      this.errorTitlesIndexType[errorTitle] === 'date' ||
      this.errorTitlesIndexType[errorTitle] === 'number'
    );
  }

  maskDate(index: number, errorTitle: string, $event) {
    let v = $event;
    if (v.length >= 5) {
      v = `${v.slice(0, 2)}/${v.slice(2, 4)}/${v.slice(4)}`;
    } else if (v.length >= 3) {
      v = `${v.slice(0, 2)}/${v.slice(2)}`;
    }
    this.massiveRequest.errorRecords[index][this.errorTitlesIndex[errorTitle]] =
      v.slice(0, 10);
  }

  validateRegisters(event?: any, row?: any, id?: any, type?: string) {
    // this.spinner.show();
    // setTimeout(() => {
    //   this.spinner.hide();
    //   this.toastr.warning('mensaje personalizado', '', {
    //     timeOut: 3000,
    //   });
    // }, 300);
  }

  mouseEnter(index: any) {
    this.hoverEffect = true;
    this.hoverDiv = index;
  }

  mouseLeave(index: any) {
    this.hoverEffect = false;
    this.hoverDiv = index;
  }

  deleteRow(id: number) {
    this.massiveRequest.errorRecords.splice(id, 1);
  }

  deleteRows() {
    this.massiveRequest.errorRecords = [];
  }

  save() {
    this.spinner.show();
    this.transaction.massiveVerify(this.massiveRequest, true).subscribe(
      (massiveRequests: MassiveRequest) => {
        this.massiveRequest = massiveRequests;
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  charge() {
    (window as any).gtag('event', 'hacer-transaccion', {
      event_category: 'transaccion-masiv',
      event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
    });
    this.spinner.show();
    this.massiveRequest.correctRecords = this.correctRecords;
    this.transaction.massiveVerify(this.massiveRequest, false).subscribe(
      (massiveRequests: Array<MassiveRecordResponse>) => {
        this.massiveResponse = massiveRequests;
        this.spinner.hide();
        this.activeContent = false;
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  enterTd(item: MassiveRecord, title: string, event: any) {
    if (item.message[this.errorTitlesIndex[title]] !== undefined) {
      this.messageTooltip = item.message[this.errorTitlesIndex[title]];
      this.subject.sendText(this.messageTooltip);
    } else {
      this.messageTooltip = '';
      this.subject.sendText(this.messageTooltip);
    }
  }

  leaveTd() {
    this.messageTooltip = '';
    this.subject.sendText(this.messageTooltip);
  }
}
