import { SubjectService } from '@services/subject.service';
import { Subscription, Observable } from 'rxjs';
import { HostListener } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ProfileService } from '@services/profile.service';
import { SellersRequest } from '@models/sellersRequest.model';
import { SellersResponse } from '@models/sellersResponse.model';
import { UserModel } from '@models/user.model';
import { UserPhotoRequest } from '@models/userPhoto.model';
import { Router } from '@angular/router';
import { AuthService } from '@app/services/auth.service';
import { ModalService } from '@app/services/modal.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public showMenu: boolean = false;
  public subscribeProfileImg: Subscription;
  public identity: UserModel;
  public sellersRequest: SellersRequest = {
    idContry: '1',
    documentType: '',
    documentNumber: '',
    search: '',
    page: 1,
    registers: 3,
  };

  public uploadPhotoRequest: UserPhotoRequest = new UserPhotoRequest();
  public sellersResponse: SellersResponse = new SellersResponse();
  public collapseMenu: Observable<boolean> = this.subject.stateMenu$;
  public isMobile: Observable<boolean> = this.subject.stateIsMobile$;

  public innerWidth: number;

  constructor(
    private auth: AuthService,
    private modal: ModalService,
    private profileService: ProfileService,
    private subject: SubjectService,
    private router: Router
  ) {
    this.subscribeChangeProfileImg();
  }

  ngOnInit() {
    this.sellers();
    this.identity = this.profileService.getIdentity();
    this.collapseMenu.subscribe((show: boolean) => (this.showMenu = show));
  }

  sellers() {
    this.profileService.getSellers(this.sellersRequest).subscribe(
      (profile: SellersResponse) => {
        this.sellersResponse = profile;
      },
      (err: any) => {
        console.error();
      }
    );
  }

  subscribeChangeProfileImg() {
    this.subscribeProfileImg = this.subject.eventSelectedProfileImg$.subscribe(
      (img: string) => {
        this.sellersResponse.userPhoto = img;
      }
    );
  }

  collapseSidebar() {
    this.showMenu = !this.showMenu;
    this.subject.menuCollapse(this.showMenu);
  }

  showModalLogOut() {
    (window as any).gtag("event", "cerrar-sesion-sin-conrmar", {
      event_category: "cerrar-sesión",
      event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.userInfo.email}`,
    });
    this.modal.show("logOut");
  }

  goToProfile() {
    this.router.navigateByUrl('/pages/administracion/perfil');
  }
}
