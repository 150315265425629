export class SelectRequest {
    idContry?: string = '1';
    documentType?: string;
    documentNumber?: string;
  
    public static toRequest(selectRequest: SelectRequest): any {
      return {
        id_pais: selectRequest.idContry,
        tipo_documento: selectRequest.documentType,
        documento: selectRequest.documentNumber,
      };
    }
}