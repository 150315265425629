import { BuyerSpace } from './buyerSpace.model';

export class UserAvailableSpaceResponse {
  activity: string;
  check: string;
  statusCode: string;
  reserveDays: string;
  invoice: string;
  location: string;
  maximumDays: string;
  maximumDaysCheck: string;
  maximumDaysInvoice: string;
  maximumCheckQuotas: string;
  product: string;
  reservation: string;
  threePaymentsCode: string;
  sixPaymentsCode: string;
  sixPaymentsQuota: string;
  sixPaymentsStatus: string;
  names: string;
  buyerSpace: BuyerSpace;

  public static fromResponse(json: any): UserAvailableSpaceResponse {
    const userAvailableSpaceResponse: UserAvailableSpaceResponse = {
      activity: json['ACTIVIDAD'],
      check: json['CHEQUE'],
      statusCode: json['CODIGO_ESTADO'],
      reserveDays: json['DIAS_RESERVA'],
      invoice: json['FACTURA'],
      location: json['LOCALIZACION'],
      maximumDays: json['MAXIMO_DIAS'],
      maximumDaysCheck: json['DIAS_CHEQUE'],
      maximumDaysInvoice: json['DIAS_FACTURA'],
      maximumCheckQuotas: json['CUOTAS_CHEQUE'],
      threePaymentsCode: json['TRESPAGOS'],
      sixPaymentsCode: json['SEIS_PAGOS'],
      sixPaymentsQuota: json['CUPO_SEIS_PAGOS'],
      sixPaymentsStatus: json['TRANSACCION_SEIS_PAGOS'],
      names: json['NOMBRES'],
      product: json['PRODUCTO'],
      reservation: json['RESERVA'],
      buyerSpace:
        'CUPO_COMPRADOR' in json && json['CUPO_COMPRADOR'].length > 0
          ? BuyerSpace.fromResponse(json['CUPO_COMPRADOR'][0])
          : null,
    };
    return userAvailableSpaceResponse;
  }
}
