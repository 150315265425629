import { SelectsService } from '@services/selects.service';
import * as moment from 'moment';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ViewChild,
  DoCheck,
  ChangeDetectorRef,
  NgZone,
} from '@angular/core';
import { DocumentType } from '@models/documentType.model';
import { HelperService } from '@services/helper.service';
import { Department } from '@models/department.model';
import { City } from '@models/city.model';
import { AddUserRequest } from '@models/addUserRequest.model';
import { UsersService } from '@services/users.service';
import { ModalService } from '@services/modal.service';
import { NgForm, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserRoles } from '@models/userRolesResponse.model';
import { AuthService } from '@services/auth.service';
import { CONSTANTS } from '@app/config/constants';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss'],
})
export class AddUserComponent implements OnInit, DoCheck {
  public documentTypes: Array<DocumentType>;
  public departments: Array<Department>;
  public cities: Array<City>;
  public addUserRequest: AddUserRequest;
  public message = '';
  public showErrorMessage = false;
  public requireConfirmPass = false;
  public passEquals = false;
  public continue = true;
  public roles: Array<UserRoles>;

  @Output() back: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  submitUserForm: EventEmitter<AddUserRequest> = new EventEmitter<AddUserRequest>();
  @Input() userToEdit: AddUserRequest;
  @Input() action: string;
  @ViewChild('formAddUser') formAddUser: NgForm;

  dateForm: FormGroup;
  public passAreEquals = false;
  public hidePassword = false;
  public hideConfirmPassword = false;

  public constants = CONSTANTS;

  public validatePattern;

  constructor(
    private auth: AuthService,
    public helper: HelperService,
    public usersService: UsersService,
    public modal: ModalService,
    private selectsService: SelectsService,
    private cdRef: ChangeDetectorRef,
    private fb: FormBuilder,
    public zone: NgZone
  ) {
    this.dateForm = this.fb.group({
      inputname: ['', [Validators.required, Validators.minLength(2)]],
    });
  }
  // this.dateForm.controls['inputName'].value

  ngOnInit() {
    this.selects();
    this.initForm();
    this.userRoles();
  }

  ngDoCheck() {
    this.cdRef.detectChanges();
  }

  dateTextChange($event) {
    this.addUserRequest.birthday = $event.target.value
      ? moment($event.target.value, 'DD/MM/YYYY').toDate()
      : null;
  }

  initForm() {
    this.addUserRequest = new AddUserRequest();
    switch (this.action) {
      case 'add':
        this.formAdd();
        break;
      case 'edit':
        this.formEdit();
        break;
    }
  }

  userRoles() {
    this.selectsService.getUserRoles().subscribe(
      (roles: Array<UserRoles>) => {
        this.roles = roles;
      },
      (err: any) => {
        console.error(err);
      }
    );
  }

  passwordIsEqual() {
    const pass1 = this.addUserRequest.newPass || '';
    const pass2 = this.addUserRequest.confirmNewPass || '';
    const pass1Isvalid = this.formAddUser.form.controls['newPass'].valid;
    const pass2IsValid = this.formAddUser.form.controls['confirmNewPass'].valid;

    if (!pass1Isvalid && !pass2IsValid) {
      const errMessage = `La contraseña debe ser mínimo de 8 caracteres y tener al menos una letra minúscula, una mayúscula, un número y un caracter especial`;
      this.message = errMessage;
      this.continue = false;
      this.formAddUser.form.controls['newPass'].markAsTouched();
      this.formAddUser.form.controls['confirmNewPass'].markAsTouched();
      return;
    }

    if (pass1.length > 0) {
      this.requireConfirmPass = true;
      this.continue = false;
      this.formAddUser.form.controls['newPass'].markAsTouched();
      this.formAddUser.form.controls['confirmNewPass'].markAsTouched();
    } else {
      this.requireConfirmPass = false;
      this.continue = true;
      this.formAddUser.form.controls['newPass'].markAsUntouched();
      this.formAddUser.form.controls['confirmNewPass'].markAsUntouched();
      this.addUserRequest.confirmNewPass = '';
      return;
    }

    if (pass1 === pass2) {
      this.passEquals = true;
      this.continue = true;
    } else if (pass1 !== pass2) {
      this.formAddUser.form.controls['newPass'].markAsUntouched();
      this.formAddUser.form.controls['confirmNewPass'].markAsUntouched();
      this.continue = false;
      const errMessage = `La nueva contraseña no coincide, por favor revise los campos en rojo y vuelva a intentar.`;
      this.message = errMessage;
      this.passEquals = false;
    }
  }

  formAdd() {
    this.addUserRequest = new AddUserRequest();
    // this.addUserRequest = {
    //   idContry: '1',
    //   user: '',
    //   documentType: '',
    //   documentNumber: '',
    //   email: 'sara.rincon@covinoc.com',
    //   name1: 'Sara',
    //   name2: '',
    //   lastName1: 'Rincon',
    //   lastname2: '',
    //   birthday: moment('16/02/1997', 'DD/MM/YYYY').toDate(),
    //   adminAction: '',
    //   newPass: '',
    //   confirmNewPass: '',
    //   idRol: '2',
    //   sellerDocumentType: 'C',
    //   sellerDocumentNumber: '1088340902',
    //   cellphone: 0,
    //   department: '',
    //   municipalList: '',
    //   cityOffice: 'Bogotá',
    //   state: 'A',
    //   mustChangePass: '',
    // };
    this.validate();
  }

  formEdit() {
    this.addUserRequest = this.userToEdit;
    this.getCities(this.addUserRequest.department);
    this.validate();
  }

  validate() {
    const errMessage = `Los campos marcados con * son obligatorios,
                        diligencielos antes de enviar el formulario.`;
    this.message = errMessage;
  }

  selects() {
    this.documentTypes = this.helper.documentTypes;
    this.departments = this.helper.getDepartments();
  }

  getCities(department: string) {
    this.helper.getCities(department).subscribe(
      (cities: Array<City>) => {
        this.cities = cities;
        // console.log('formAddUser.form.invalid', this.formAddUser.form.invalid);
        // const invalid = [];
        // const controls = this.formAddUser.controls;
        // for (const name in controls) {
        //   if (controls[name].invalid) {
        //     invalid.push(name);
        //   }
        // }
        // console.log('invalid', invalid);
      },
      (err: any) => {
        console.error(err);
      }
    );
  }

  backToUsersList() {
    this.back.emit();
  }

  togglePassword() {
    this.hidePassword = !this.hidePassword;
  }
  
  toggleConfirmPassword() {
    this.hideConfirmPassword = !this.hideConfirmPassword;
  }

  submitForm() {
    switch (this.action) {
      case 'add': {
        (window as any).gtag('event', 'nuevo-usuario', {
          event_category: 'creacion-usuario',
          event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
        });
        this.addUser();
        break;
      }
      case 'edit': {
        (window as any).gtag('event', 'modicacion-usuario', {
          event_category: 'modicacion-usuario',
          event_label: `${this.auth.identity.userInfo.documentType}:${this.auth.identity.userInfo.document}:1;${this.auth.identity.username}`,
        });
        this.editUser();
        break;
      }
    }
  }

  editUser() {
    this.submitUserForm.emit(this.addUserRequest);
  }

  addUser() {
    this.submitUserForm.emit(this.addUserRequest);
  }

  valueDataChange(event: any) {
    this.validatePattern = this.helper.validatePatternDocument(this.addUserRequest.sellerDocumentType);
  }
}
