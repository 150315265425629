import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ModalService } from '@services/modal.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-modal-denied',
  templateUrl: './modal-denied.component.html',
  styleUrls: ['./modal-denied.component.scss'],
})
export class ModalDeniedComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() message: string;
  constructor(public modal: ModalService, private router: Router) {}

  ngOnInit() {}

  ngOnDestroy() {}

  close() {
    this.modal.close();
  }

  goToStart() {
    this.router.navigateByUrl('/pages/transacciones');
  }
}
