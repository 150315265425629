import { Component, OnInit, Input } from '@angular/core';
import { Chart } from 'chart.js';
import { ChartType } from 'chart.js';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { MultiDataSet, Label, Color, Colors } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import * as pluginAnnotations from 'chartjs-plugin-annotation';

import * as _moment from 'moment';
import { NgForm } from '@angular/forms';
import { EstadoGestionModel } from '@models/estadoGestion.model';
import { NgxSpinnerService } from 'ngx-spinner';
const moment = (_moment as any).default ? (_moment as any).default : _moment;
declare let $: any;


@Component({
  selector: 'app-chart-state',
  templateUrl: './chart-state.component.html',
  styleUrls: ['./chart-state.component.scss']
})
export class ChartStateComponent implements OnInit {

      // properties
      public DataClientesVigentes: any;

      // Models
      public EstadoGestion = new EstadoGestionModel();
      public PorcentajeEstadoGestion = new EstadoGestionModel();

      // Totales
      public totalObligaciones = 0;
      public cantidadObligaciones = 0;

      // Promedio Mora
      public DataPromedioMora = [];

      // Estado gestiones
      public DataEstadoGestion = [];

      // Chart
  public lineChartData: ChartDataSets[] = [
    { data: [], label: '', yAxisID: 'y-axis-1', lineTension: 0, fill: true, borderWidth: 1, }
  ];
  public lineChartLabels: Label[] = [];
  public lineChartOptions: (ChartOptions & { annotation: any }) = {
    responsive: true,
    elements: {
            point:
            {
              radius: 7,
              hitRadius: 5,
              hoverRadius: 10,
              hoverBorderWidth: 2
            }
          },
    scales: {
      // Dynamic theming.
      xAxes: [
        {  gridLines: {
           color: 'transparent',
           drawOnChartArea: true
        }, ticks: {
           display: true,
           beginAtZero: true
        }
      }],
      yAxes: [
        {
          id: 'y-axis-0',
          position: 'left',
          gridLines: {
            color: '#EDEDED',
            drawOnChartArea: true
          }, ticks: {
            display: false,
            beginAtZero: true
         }
        },
        {
          id: 'y-axis-1',
          position: 'right',
          gridLines: {
            color: 'transparent',
            drawOnChartArea: false
          },
          ticks: {
            fontColor: '#4A4A4A',
            display: true,
            // max: 5,
            min: 0,
            // stepSize: 10
          }
        }
      ]
    },
    annotation: {
      annotations: [
        {
          type: 'line',
          mode: 'vertical',
          scaleID: 'x-axis-0',
          value: 'March',
          borderColor: 'orange',
          borderWidth: 1,
          label: {
            enabled: true,
            fontColor: 'orange',
            content: 'LineAnno'
          }
        },
      ],
    },
    plugins: {
      datalabels: {
        color: 'transparent',
        anchor: 'end',
        align: 'left',
        font: {
          size: 12,
        }
      }
    }
  };
  public lineChartColors: Color[] = [
    { // Orange
      backgroundColor: 'transparent',
      borderColor: '#E5703A',
      pointBackgroundColor: '#E5703A',
      pointBorderColor: '#E5703A',
      pointHoverBackgroundColor: '#E5703A',
      pointHoverBorderColor: '#E5703A'
    },
  ];
  public lineChartLegend = false;
  public lineChartType: ChartType = 'line';
  public lineChartPlugins = [pluginAnnotations];
   // ./Chart

  constructor(private spinner: NgxSpinnerService) { }

  ngOnInit() {
  }

  EstadoGestiones(data: any) {
    // Cant
    this.DataClientesVigentes = data || [];

    const arrTemp  = this.DataClientesVigentes.map(({respuesta}) => respuesta);

    this.EstadoGestion = {
      renuente: arrTemp.filter((item: any) => item === 'Renuente').length || 0,
      reclamo: arrTemp.filter((item: any) => item === 'Reclamo').length || 0,
      promesa: arrTemp.filter((item: any) => item === 'Promesa').length || 0,
      posibleAcuerdo: arrTemp.filter((item: any) => item === 'Posible acuerdo').length || 0,
      pagoParcial: arrTemp.filter((item: any) => item === 'Pago Parcial').length || 0,
      ilocalizado: arrTemp.filter((item: any) => item === 'No contancto/Ilocalizado').length,
      mensajes: arrTemp.filter((item: any) => item === 'Mensajes').length || 0,
      pagoTotal: arrTemp.filter((item: any) => item === 'Pago Total').length || 0,
      otros: arrTemp.filter((item: any) => item === undefined).length || 0,
    };

    this.DataEstadoGestion = Object.values(this.EstadoGestion);
    this.lineChartData[0].data = this.DataEstadoGestion;

    // Percent
    const total = arrTemp.length || 100;

    this.lineChartLabels = [
      `Renuente: ${Number(this.EstadoGestion.renuente * 100 / total).toFixed(2)}%`,
      `Reclamo: ${Number(this.EstadoGestion.reclamo * 100 / total).toFixed(2)}%`,
      `Promesa: ${Number(this.EstadoGestion.promesa * 100 / total).toFixed(2)}%`,
      `Posible Acuerdo: ${Number(this.EstadoGestion.posibleAcuerdo * 100 / total).toFixed(2)}%`,
      `Pago Parcial: ${Number(this.EstadoGestion.pagoParcial * 100 / total).toFixed(2)}%`,
      `Ilocalizado: ${Number(this.EstadoGestion.ilocalizado * 100 / total).toFixed(2)}%`,
      `Mensajes: ${Number(this.EstadoGestion.mensajes * 100 / total).toFixed(2)}%`,
      `Otros: ${Number(this.EstadoGestion.otros * 100 / total).toFixed(2)}%`,
    ];

    this.spinner.hide('gestiones');

  }

}
