import { ErrorValidationComponent } from "./../shared/components/error-validation/error-validation.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TransactionsComponent } from "@pages/transactions/transactions.component";
import { AdministrationComponent } from "@pages/administration/administration.component";
import { ClientStudyComponent } from "@pages/client-study/client-study.component";
import { OperationsComponent } from "@pages/operations/operations.component";
import { ClaimsComponent } from "@pages/claims/claims.component";
import { AgreementsComponent } from "@pages/agreements/agreements.component";
import { ReportsComponent } from "@pages/reports/reports.component";
import { ComponentsModule } from "@components/components.module";
import { AppRoutingModule } from "../app-routing.module";
import { HeaderComponent } from "@pages/administration/profile/header/header.component";
import { IndividualTransactionComponent } from "@pages/transactions/individual-transaction/individual-transaction.component";
import { SharedModule } from "@shared/shared.module";
import { SearchComponent } from "@pages/transactions/search/search.component";
import { TabsComponent } from "@pages/transactions/tabs/tabs.component";
import { TabIndividualTransactionComponent } from "@pages/transactions/tab-individual-transaction/tab-individual-transaction.component";
import { TabMassiveTransactionComponent } from "@pages/transactions/tab-massive-transaction/tab-massive-transaction.component";
import { TabReservesComponent } from "@pages/transactions/tab-reserves/tab-reserves.component";
import { LoadSupportComponent } from "@pages/transactions/load-support/load-support.component";
import { TabBillComponent } from "@pages/transactions/tab-bill/tab-bill.component";
import { TabCheckComponent } from "@pages/transactions/tab-check/tab-check.component";
import { QuotaReserveComponent } from "@pages/transactions/quota-reserve/quota-reserve.component";
import { MassiveTransactionComponent } from "@pages/transactions/massive-transaction/massive-transaction.component";
import { TransactionsStatusComponent } from "@pages/transactions/transactions-status/transactions-status.component";
import { ModalLogoutComponent } from "@pages/shared/modals/modal-logout/modal-logout.component";
import { DisplayLegalPersonComponent } from "@pages/client-study/display-legal-person/display-legal-person.component";
import { DisplayNaturalPersonComponent } from "@pages/client-study/display-natural-person/display-natural-person.component";
import { CommercialReferenceComponent } from "@pages/client-study/commercial-reference/commercial-reference.component";
import { ContactChannelsComponent } from "@pages/client-study/contact-channels/contact-channels.component";
import { AcceptTermsComponent } from "@pages/client-study/accept-terms/accept-terms.component";
import { AddressInputComponent } from "@pages/client-study/address-input/address-input.component";
import { TabIndividualOperationComponent } from "@pages/operations/tab-individual-operation/tab-individual-operation.component";
import { TabMassiveOperationComponent } from "@pages/operations/tab-massive-operation/tab-massive-operation.component";
import { TooltipIconComponent } from "@pages/operations/tooltip-icon/tooltip-icon.component";
import { TabClaimsComponent } from "@pages/claims/tab-claims/tab-claims.component";
import { TabRefundsComponent } from "@pages/claims/tab-refunds/tab-refunds.component";
import { UploadClaimDocumentsComponent } from "@pages/claims/upload-claim-documents/upload-claim-documents.component";
import { ProgressUploadDocumentComponent } from "@pages/claims/progress-upload-document/progress-upload-document.component";
import { TabActiveAgreementsComponent } from "@pages/agreements/tab-active-agreements/tab-active-agreements.component";
import { TabAgreementsHistoryComponent } from "@pages/agreements/tab-agreements-history/tab-agreements-history.component";
import { TabReportsComponent } from "@pages/reports/tab-reports/tab-reports.component";
import { TabTransactionsReportComponent } from "@pages/reports/tab-transactions-report/tab-transactions-report.component";
import { TabSellersReportComponent } from "@pages/reports/tab-sellers-report/tab-sellers-report.component";
import { ChartReportComponent } from "@pages/reports/chart-report/chart-report.component";
import { ChartsModule } from "ng2-charts";
import { DoubleProgressBarComponent } from "@pages/reports/double-progress-bar/double-progress-bar.component";
import { UsersComponent } from "@pages/administration/users/users.component";
import { MyPaymentsComponent } from "@pages/administration/my-payments/my-payments.component";
import { DocumentsComponent } from "@pages/administration/documents/documents.component";
import { AddUserComponent } from "@pages/administration/users/add-user/add-user.component";
import { TabBillToPayComponent } from "@pages/administration/my-payments/tab-bill-to-pay/tab-bill-to-pay.component";
import { TabBillHistoryComponent } from "@pages/administration/my-payments/tab-bill-history/tab-bill-history.component";
import { ModalPdfComponent } from "@pages/administration/my-payments/modal-pdf/modal-pdf.component";
import { EmptySearchComponent } from "@pages/shared/empty-search/empty-search.component";
import { CardUserComponent } from "@pages/administration/users/card-user/card-user.component";
import { ProfileComponent } from "@pages/administration/profile/profile.component";
import { ChargeProfileImgComponent } from "@pages/administration/profile/charge-profile-img/charge-profile-img.component";
import { MassiveExonerationComponent } from "@pages/operations/massive-exoneration/massive-exoneration.component";
import { MassiveNopayComponent } from "@pages/operations/massive-nopay/massive-nopay.component";
import { DownloadUploadExcelComponent } from "@pages/operations/download-upload-excel/download-upload-excel.component";
import { OperationsTabsComponent } from "@pages/operations/operations-tabs/operations-tabs.component";
import { MassiveExonerationStatusComponent } from "@pages/operations/massive-exoneration-status/massive-exoneration-status.component";
import { MassiveNopayStatusComponent } from "@pages/operations/massive-nopay-status/massive-nopay-status.component";
import { DisplayErrorComponent } from "@pages/transactions/display-error/display-error.component";
import { OwnerInformationFormComponent } from "@pages/client-study/owner-information-form/owner-information-form.component";
import { TabsvideosComponent } from "./administration/tabs-videos/tabs-videos.component";
import { TabprincipalComponent } from "./administration/tab-principal-document/tab-principal.component";
import { FileComponent } from "@app/components/file/file.component";
import { UploadMailFileComponent } from './transactions/upload-mail-file/upload-mail-file.component';

@NgModule({
  declarations: [
    TransactionsComponent,
    TransactionsComponent,
    HeaderComponent,
    IndividualTransactionComponent,
    SearchComponent,
    TabsComponent,
    OperationsTabsComponent,
    TabIndividualTransactionComponent,
    TabMassiveTransactionComponent,
    TabReservesComponent,
    LoadSupportComponent,
    TabBillComponent,
    TabCheckComponent,
    QuotaReserveComponent,
    MassiveTransactionComponent,
    TransactionsStatusComponent,
    ClientStudyComponent,
    DisplayLegalPersonComponent,
    DisplayNaturalPersonComponent,
    CommercialReferenceComponent,
    ContactChannelsComponent,
    AcceptTermsComponent,
    AddressInputComponent,
    OperationsComponent,
    OperationsTabsComponent,
    TabIndividualOperationComponent,
    TabMassiveOperationComponent,
    TooltipIconComponent,
    MassiveExonerationComponent,
    MassiveNopayComponent,
    DownloadUploadExcelComponent,
    MassiveNopayStatusComponent,
    TabIndividualOperationComponent,
    TabMassiveOperationComponent,
    TooltipIconComponent,
    ClaimsComponent,
    TabClaimsComponent,
    TabRefundsComponent,
    UploadClaimDocumentsComponent,
    ProgressUploadDocumentComponent,
    AgreementsComponent,
    TabActiveAgreementsComponent,
    TabAgreementsHistoryComponent,
    ReportsComponent,
    TabReportsComponent,
    TabTransactionsReportComponent,
    TabSellersReportComponent,
    ChartReportComponent,
    DoubleProgressBarComponent,
    AdministrationComponent,
    UsersComponent,
    AddUserComponent,
    MyPaymentsComponent,
    TabBillToPayComponent,
    TabBillHistoryComponent,
    ModalPdfComponent,
    DocumentsComponent,
    TabprincipalComponent,
    TabsvideosComponent,
    ModalLogoutComponent,
    EmptySearchComponent,
    CardUserComponent,
    ProfileComponent,
    AddUserComponent,
    ChargeProfileImgComponent,
    MassiveExonerationStatusComponent,
    DisplayErrorComponent,
    OwnerInformationFormComponent,
    UploadMailFileComponent,
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    AppRoutingModule,
    SharedModule,
    ChartsModule,
  ],
  exports: [
    TransactionsComponent,
    TransactionsComponent,
    HeaderComponent,
    IndividualTransactionComponent,
    SearchComponent,
    TabsComponent,
    TabIndividualTransactionComponent,
    TabMassiveTransactionComponent,
    TabReservesComponent,
    LoadSupportComponent,
    TabBillComponent,
    TabCheckComponent,
    QuotaReserveComponent,
    MassiveTransactionComponent,
    TransactionsStatusComponent,
    AcceptTermsComponent,
    AddressInputComponent,
    ClientStudyComponent,
    DisplayLegalPersonComponent,
    DisplayNaturalPersonComponent,
    CommercialReferenceComponent,
    ContactChannelsComponent,
    OperationsComponent,
    OperationsTabsComponent,
    TabIndividualOperationComponent,
    TabMassiveOperationComponent,
    TooltipIconComponent,
    MassiveExonerationComponent,
    MassiveNopayComponent,
    DownloadUploadExcelComponent,
    MassiveNopayStatusComponent,
    ClaimsComponent,
    TabClaimsComponent,
    TabRefundsComponent,
    UploadClaimDocumentsComponent,
    ProgressUploadDocumentComponent,
    AgreementsComponent,
    TabActiveAgreementsComponent,
    TabAgreementsHistoryComponent,
    ReportsComponent,
    TabReportsComponent,
    TabTransactionsReportComponent,
    TabSellersReportComponent,
    ChartReportComponent,
    AdministrationComponent,
    UsersComponent,
    AddUserComponent,
    MyPaymentsComponent,
    TabBillToPayComponent,
    TabBillHistoryComponent,
    ModalPdfComponent,
    DocumentsComponent,
    TabprincipalComponent,
    TabsvideosComponent,
    ModalLogoutComponent,
    EmptySearchComponent,
    CardUserComponent,
    ProfileComponent,
    OwnerInformationFormComponent,
    ChargeProfileImgComponent,
  ],
})
export class PagesModule {}
