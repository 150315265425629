/* export const environment = {
  urlApi: 'https://clientes.covifactura.com:18443/ServiciosCovifactura/servicios',
  urlApiNit:'https://wscovp.c0v.co',
  urlApiPayzen: 'https://ws.micovinoc.com:18443/PayzenCorporativo/servicios',
  urlEndpoint:
  'https://wsconsultarpdfxmlfactura.dispafel.com:443/DFFacturaElectronicaConsultarPdfXmlFactura/consultarArchivos',
  urlApiDev : 'https://wscovpsdo.c0v.co',
  interactionToken: 'CLIENT_APP-doaneion45012:psnwods@3801VaPFRM',
  buyers: 'https://clientes.covifactura.com/compradores',
  app: 'CoviFactura',
  production: false,
 };  */

//*Lanzar a Pruebas Ruta http://200.32.80.181 in FileZilla  ==== Oracle ====
export const environment = {
  urlApi: 'http://desarrollo02.c0v.co:8080/ServiciosCovifacturaDSRL/servicios',
  urlApiNit: 'https://admincovi.c0v.co',
  urlApiPayzen: 'http://desarrollo02.c0v.co:8080/PayzenCorporativo/servicios',
  urlEndpoint:
    'https://wsconsultarpdfxmlfactura.dispafel.com:443/DFFacturaElectronicaConsultarPdfXmlFactura/consultarArchivos',
  urlApiDev: 'https://covidev.c0v.co',
  interactionToken: 'CLIENT_APP-doaneion1092:skjdoqj123@AfV', 
  buyers: 'http://desarrollo.covinoc.com:9091/',
  app: 'CoviFactura',
  production: true,
};

//*Lanzar a PostgresSQL Ruta http://200.32.80.181:8082 in FileZilla
// export const environment = {
//   urlApi: 'http://desarrollo02.c0v.co:8080/ServiciosCovifacturaPostgres/servicios',
//   urlApiPayzen: 'http://desarrollo02.c0v.co:8080/PayzenCorporativo/servicios',
//   urlApiNit: 'https://admincovi.c0v.co/',
//   urlEndpoint:
//     'https://wsconsultarpdfxmlfactura.dispafel.com:443/DFFacturaElectronicaConsultarPdfXmlFactura/consultarArchivos',
//   urlInteraction: 'https://covidev.c0v.co/NewCoviFactura/CoviFactura',
//   urlLogin : 'https://covidev.c0v.co/NewLogin',
//   interactionToken: 'CLIENT_APP-doaneion1092:skjdoqj123@AfV',
//   app: 'CoviFactura',
//   production: true,
// };

//*Lanzar a PreProduccionPostgresSQL Ruta 192.168.13.161:8765 in FileZilla
// export const environment = {
//   urlApi: 'http://desarrollo02.c0v.co:8080/ServiciosCovifacturaPostgresPRU/servicios',
//   urlApiPayzen: 'http://200.41.76.26:8080/PayzenCorporativo/servicios',
//   urlApiNit: 'https://admincovi.c0v.co/',
//   urlEndpoint:
//     'https://wsconsultarpdfxmlfactura.dispafel.com:443/DFFacturaElectronicaConsultarPdfXmlFactura/consultarArchivos',
//   urlInteraction: 'https://covidev.c0v.co/NewCoviFactura/CoviFactura',
//   urlLogin : 'https://covidev.c0v.co/NewLogin',
//   interactionToken: 'CLIENT_APP-doaneion1092:skjdoqj123@AfV',
//   app: 'CoviFactura',
//   production: true,
// };
