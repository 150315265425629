import { NgxSpinnerService } from 'ngx-spinner';
import { Component, OnInit, Input, OnDestroy, ViewChild, Output, EventEmitter } from '@angular/core';
import { ModalService } from '@services/modal.service';

import { Dimensions, ImageCroppedEvent, ImageTransform } from '../../interfaces/index';
import { ImageCropperComponent } from 'ngx-image-cropper';

@Component({
  selector: 'app-modal-configure-profile-img',
  templateUrl: './modal-configure-profile-img.component.html',
  styleUrls: ['./modal-configure-profile-img.component.scss']
})
export class ModalConfigureProfileImgComponent implements OnDestroy {

  @Input() imageChangedEvent: any;
  @Output() croppedImageReady: EventEmitter<object> = new EventEmitter<object>();
  @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;

  public croppedImage: any = '';
  public canvasRotation = 0;
  public rotation = 0;
  public scale = 1;
  public showCropper = false;
  public containWithinAspectRatio = false;
  public transform: ImageTransform = {};
  public imgPreview: string = '';


  constructor(public modal: ModalService,
    public spinner: NgxSpinnerService) {
  }


  ngOnDestroy() {
    this.modal.close();
  }

  init() {
    this.spinner.show('croppedImg');
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: any) {
    this.croppedImage = event;
    this.imgPreview = event.base64;
    // console.log('final cropp', this.croppedImage);
  }

  finishCropper() {
    this.croppedImageReady.emit(this.croppedImage);
    this.imageChangedEvent = '';
    this.modal.close();
  }

  imageLoaded() {
    this.showCropper = true;
  }

  cropperReady(sourceImageDimensions: Dimensions) {
    this.spinner.hide('croppedImg');
  }

  loadImageFailed() {
    this.spinner.hide('croppedImg');
  }

  flipHorizontal() {
    this.imageCropper.flipHorizontal();
  }

  flipVertical() {
    this.imageCropper.flipVertical();
  }

  rotateLeft() {
    this.imageCropper.rotateLeft();
  }

  rotateRight() {
    this.imageCropper.rotateRight();
  }

  toggleContainWithinAspectRatio() {
    this.containWithinAspectRatio = !this.containWithinAspectRatio;
  }

}