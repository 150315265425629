import * as _moment from 'moment';
const moment = (_moment as any).default ? (_moment as any).default : _moment;

export class GeneratePaymentRequest {
    idCountry: number = 1;
    documentType?: string;
    documentNumber?: string;
    bill: string;
    dueDate: string;
    totalIncludeIva: number;
    iva: number;
    productCode: string;
  
    public static toRequest(payment: GeneratePaymentRequest): any  {
      return {
        id_pais: payment.idCountry,
        tipo_documento: payment.documentType,
        documento: payment.documentNumber,
        factura: payment.bill,
        fecha_vencimiento: moment(payment.dueDate, 'MM/DD/YYYY').format('YYYY-MM-DD'), 
        total_con_iva: payment.totalIncludeIva,
        valor_iva: payment.iva,
        codigo_producto: payment.productCode,
      };
    }
  
  }

  
  
  


  