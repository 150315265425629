import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rolById',
})
export class RolByIdPipe implements PipeTransform {
  transform(value: any, args?: any) {
    const rol = Number(value);

    switch (rol) {
      case 1:
        return 'Súper Administrador';
      case 2:
        return 'Administrador';
      case 3:
        return 'Vendedor +';
      case 4:
        return 'Vendedor';
      default:
        return '';
    }
  }
}
