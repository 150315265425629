import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgModel } from '@angular/forms';
@Directive({
  selector: '[appOnlyNumber]'
})
export class OnlyNumberDirective {
  constructor(private el: ElementRef, public model: NgModel) { }


  @HostListener('input', ['$event']) onInputChange(event) {
    const initalValue = this.el.nativeElement.value;
    const val = initalValue.replace(/[^0-9]*/g, '');
    this.el.nativeElement.value = val;
    if (initalValue !== this.el.nativeElement.value) {
      event.stopPropagation();
    }
    this.model.viewToModelUpdate(val);
  }
}
