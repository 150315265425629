import * as moment from 'moment';
export class UsersResponse {
  name: string;
  lastName: string;
  cityOffice: string;
  rol: string;
  user: string;
  userPhoto: string;
  mustChangePass: string;
  email: string;
  cellphone: number;
  userDocumentType: string;
  userDocumentNumber: string;
  department: string;
  birthday?: Date;
  city: string;
  state: string;
  numberRecords?: number;

  public static fromResponse(json: any): any {
    const usersResponse: UsersResponse = {
      name: json['NOMBRES'],
      lastName: json['APELLIDOS'],
      cityOffice: json['SUCURSAL'],
      rol: json['DESCROL'],
      user: json['USUARIO'],
      userPhoto: json['FOTO_USUARIO'],
      mustChangePass: json['CAMBIO_CONTRASEÑA'],
      email: json['E_MAIL_OFICINA'],
      cellphone: json['TEL_DIRECTO_1'],
      userDocumentType: json['TIPO_DOCUMENTO_USUARIO'],
      userDocumentNumber: json['DOCUMENTO_USUARIO'],
      department: json['NOMBRE_DEPTO'],
      birthday: json['FECHA_CUMPLEANOS']
        ? moment(json['FECHA_CUMPLEANOS'], 'DD/MM/YYYY').toDate()
        : null,
      city: json['NOMBRE_CIUDAD'],
      state: json['ESTADO'],
      numberRecords: json['N_REGISTROS'],
    };
    return usersResponse;
  }
}
